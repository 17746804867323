import styled, { keyframes, css } from "styled-components";

export const InputPanelWrapper = styled.section`
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(props) => (props.canopy_panel ? "1fr auto" : "32px 1fr  auto")};
  /* height: 100%; */
  min-height: 80vh;
  width: 100%
  z-index: 999;
  

  /* TAB SELECTOR (PRODUCTS, FINANCE, PERFORMANCE, SITE) */
  .tab-selector {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-row: 1;
    grid-auto-flow: column;
    /* grid-template-columns: 75px 95px 75px 75px;
    place-items: center; */
    padding: 0px 2.5px;
    font-size: 14px;
    transition: width 0.7s var(--easing);

    .tab {
      min-width: 60px;
      color: #5f5f5f;
      background-color: #e2e2e2;
      padding: 5px;
      margin: 0px 2px;
      cursor: pointer;
      border-radius: 4px 4px 0 0;
      text-align: center;
      border-bottom: 1px solid rgb(226 226 226);
    }
    .tab:hover {
      color: var(--primary-brand-color);
      background-color: #e2e2e2;
    }
    .tab.selected {
      color: var(--primary-brand-color);
      background-color: #fff;
      border: solid 1px rgb(170, 169, 170);
      cursor: default;
    }
  }

  /* INPUT COLLAPASE PANEL (BASED ON TAB SELECTED) */
  .input-collapse {
    .ant-collapse-header {
      padding: 5px 33px !important;
    }
    .ant-collapse-content-box {
      padding: 4px 0px;
    }
    .ant-collapse-item.input-tab.config {
      /* position: absolute; */
      /* bottom: 65px; */
      width: 322px;
      background-color: #fafafa;
      /* border-top: 1px solid #d9d9d9; */
    }
  }

  .input-manager {
    /* overflow-y: auto;
    overflow-x: visible; */
    overflow: auto;
    scroll-behavior: smooth;
    /* z-index: 500; */

    /* max-height: 630px; */
  }
  .input-collapse.config {
    .collapse-header {
      border-top: 1px solid #d9d9d9;
    }
  }

  .input-collapse.canopy-res-accordian {
    width: 100%;
  }

  .input-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 13px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    /* background-color: #fafafa; */
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 4px;

    .collapse-item {
      .collapse-header {
        width: 100%;
        margin-top: -1px;
        display: flex;
        justify-content: space-between;
        border-bottom: ${(props) => (props.show_layout_editor ? "1px solid transparent" : "1px solid #d9d9d9")};
        border-top: ${(props) => (props.show_layout_editor ? "1px solid transparent" : "1px solid #d9d9d9")};
        align-items: center;
        padding: 5px 10px;
        color: #5f5f5f;
        background-color: #fff;
        cursor: pointer;

        .collapse-header-text {
          display: flex;
          pointer-events: none;

          img {
            margin-right: 8px;
            height: 25px;
            width: 25px;
          }

          span {
            font-size: 14px;
            margin-top: 2px;
          }
        }

        .collapse-arrow {
          font-size: 12px;
          pointer-events: none;
        }

        &:hover {
          color: var(--primary-brand-color);
          background-color: #e2e2e2;
        }

        &:hover .collapse-header-text img {
          /* filter: invert(0.4) sepia(0.5) saturate(20) hue-rotate(350deg) brightness(1.11); //orange */
          filter: ${(props) =>
            props.internal_sift ? "invert(82%) sepia(19%) saturate(1578%) hue-rotate(56deg) brightness(93%) contrast(87%)" : "sepia(100%) hue-rotate(190deg) saturate(100)"}; // green : blue
        }
      }
      /* .collapse-header:hover {
        color: var(--primary-brand-color);
        background-color: #e2e2e2;
        border-left: 2px solid var(--primary-brand-color);
        border-right: 2px solid var(--primary-brand-color);
      } */
      .collapse-header.selected {
        color: var(--primary-brand-color);
        img {
          /* filter: invert(0.4) sepia(0.5) saturate(20) hue-rotate(350deg) brightness(1.11); //orange */
          filter: ${(props) => (props.internal_sift ? "invert(82%) sepia(19%) saturate(1578%) hue-rotate(56deg) brightness(93%) contrast(87%)" : "sepia(100%) hue-rotate(190deg) saturate(100)")};
        }
        /* cursor: default; */
      }

      .collapse-content-container {
        overflow: hidden;
        color: #333;
        background-color: #fff;
        /* border-top: 1px solid #d9d9d9; */
        padding: 4px 0px;
      }
      .collapse-content-container.results {
        padding: 0px;

        .minimized-text {
          margin: 5px;
          text-align: center;
        }
      }
    }
  }

  .input-selector {
    height: 65px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    .select-content {
      text-align: center;
      display: flex;
      label {
        color: #5f5f5f;
        display: block;
        text-align: left;
        line-height: 15px;
      }
    }
    .input-name {
      padding: 0px 10px;
    }
  }
  .input-selector.topo {
    height: 120px;
    padding: 5px;
  }
  .input-selector.config {
    height: 60px;
    padding: 5px;
  }
  .input-selector.finance {
    height: 100px;
  }
  .input-selector.racking {
    height: 65px;
  }
  .input-panel {
    grid-row: 4;
    width: 100%;
    /* max-height: 400px; */
    /* overflow-y: scroll; */
    z-index: 8;

    .input-content-box {
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-columns: auto;

      .tou-container {
        width: 515px;
        height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        display: grid;
        padding-right: 15px;
        grid-template-rows: auto auto 1fr;

        .custom-schedule-label {
          z-index: 511;
          text-align: center;
          margin: 5px auto;
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          display: inline-block;
          pointer-events: none;
        }
        .custom-schedule-link {
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }

        @media (max-width: 768px) {
          width: auto;
        }
      }

      .info {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin: 0px 0px 5px 0px;
        p {
          margin: 3px 0px 0px 0px;
          font-size: 12px;
        }
        span {
          margin-right: 3px;
        }
      }
    }

    .info-msg {
      display: grid;
      grid-template-columns: min-content 1fr;
      gap: 5px;
      margin-top: 10px;
      align-items: baseline;
    }

    .custom-input-panel-header {
      border: none;
      border-radius: 0;
      background: #f3f3f3;
    }

    .input-row-3-col {
      width: 100%;
      padding: 3px 0px;
      display: grid;
      grid-template-columns: 60% 10% 30%;

      a {
        white-space: nowrap;
        text-decoration: underline;
        line-height: 16px;
        margin-left: 4px;
      }
    }

    .input-row,
    .perf-input {
      width: 100%;
      padding: 3px 0px;
      display: grid;
      align-items: baseline;
      grid-template-columns: 60% 40%;
      /* place-items: center right; */

      label {
        grid-column: 1;
        margin-right: auto;
      }

      input,
      button {
        grid-column: 2;
        margin-right: auto;
      }

      p {
        grid-column: 2;
        margin: 0px 5px 0px auto;
      }
      h3 {
        margin: 0px;
      }

      .gm-input {
        width: 100px;
      }
    }
    .input-subtext {
      display: grid;
      place-content: center end;
      margin-right: 5px;
    }

    .input-row.topo-source {
      grid-template-columns: 50% 50%;
    }
    .input-row.name {
      grid-template-columns: 1fr 180px;
    }
    .input-row.three {
      /* grid-template-columns: repeat(3, 1fr); */
      grid-template-columns: 60% 12% 28%;
      place-items: center;
    }
    .input-row.two {
      grid-template-columns: repeat(2, 1fr);

      .ant-checkbox-wrapper {
        padding-right: 5px;
      }
    }
    .topo-visual-layer-input {
      display: grid;
      grid-template-columns: 50% 20% 30%;
      margin: 5px 0px;
    }

    .input-row.one {
      grid-template-columns: 100%;
    }

    .input-row.center {
      text-align: center;
    }

    .weather-divider {
      margin: 8px -15px !important;
      font-size: 10pt;
      pointer-events: none;

      .ant-divider-inner-text {
        padding-left: 0px;
        cursor: pointer;
      }
      .ant-divider-inner-text:hover {
        color: var(--primary-brand-color);
      }
    }
    .input-row.weather {
      height: 440px;
    }

    .rack-icons {
      margin: 0 auto;
      max-width: 195px;
    }

    .toggled-input {
      margin: 0px auto;
      display: grid;
      flex-direction: column;
      place-items: center;
      grid-template-columns: repeat(2, 1fr);

      .toggled-input-labeled {
        display: flex;
        align-items: center;
        color: #333;
        margin: 2px auto;

        .small-label {
          color: #000000a6;
          margin-right: 10px;
          margin-bottom: 0;
        }

        .small-span {
          width: 95px;
          margin-left: auto;
        }
      }
    }
    .toggled-input.one-col {
      grid-template-columns: repeat(1, 1fr);
    }

    .input-table-container {
      max-height: 300px;
      overflow-y: auto;
      margin: 10px 0px;
    }

    .input-table {
    }
  }
  .input-panel.config {
    overflow: hidden;
    grid-row: auto;
    max-height: fit-content;
    margin-bottom: 5px;
  }

  .topo-source {
    width: 100%;
    label {
      margin-right: 10px;
    }
  }

  .topo-input {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin: 8px auto;

    label {
      grid-column: 1;
    }

    .topo-labeled-input-box {
      grid-column: 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      margin-left: auto;

      label {
        grid-column: 1;
        margin-right: 5px;
      }

      input {
        grid-column: 2;
      }
    }
  }

  .input-changed-warning {
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: flex-start;
    margin: 5px auto;

    p {
      font-size: 12px;
      margin: 0px 0px 0px 5px;
    }
  }

  .generate-buttons-spacer {
    grid-row: 3;
  }
  .generate-buttons-container {
    grid-row: 3;
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    place-items: center;
    border-top: 1px solid rgb(167 166 166);

    .run-button {
      grid-row: 2;
      width: 100%;
      display: grid;
      place-items: center;
      margin: 10px auto;

      button {
        width: 250px;
        height: 40px;
      }

      .run-button-content {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-right: 5px;
        }
      }
    }

    #config,
    #results {
      font-size: 14px;
      .config-header-text {
        img {
          height: 25px;
          width: 25px;
          margin-right: 20px;
        }
      }

      /* &:hover .collapse-header-text img {
        filter: sepia(100%) hue-rotate(190deg) saturate(100); // blue
      } */
    }
  }

  .empty-box {
    grid-row: 4;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
  }

  .tilt-input-label label {
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
  }
  .tilt-input-label label:before {
    content: "";
    display: block;
    height: 1px;
    width: 15px;
    background: #d9d9d9;
    margin-right: 5px;
  }
  .tilt-input-label label:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: #d9d9d9;
    margin-left: 5px;
  }
  .config-inputs {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(8, auto);

    .config-err {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;
      p {
        margin: 0px;
        font-size: 12px;
      }
      span {
        margin-right: 3px;
      }
    }

    .config-header {
      padding: 5px 10px;
    }

    .config-input-label {
      width: 100%;
      padding: 5px 10px;

      span {
        margin: 0 2px;
      }

      & > .ant-radio-group,
      label {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 100%;
          background: #d9d9d9;
          margin-left: 2px;
        }
      }
    }

    .config-input.error {
      grid-template-columns: 100%;
    }
    .config-input {
      width: 100%;
      display: grid;
      margin-top: 5px;
      grid-template-columns: 1fr min-content 1fr min-content 1fr min-content;
      place-items: center;

      .min {
        grid-column: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }

      .dash {
        grid-column: 2;
        height: 100%;

        span {
          font-size: 16px;
        }
      }

      .max {
        grid-column: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }
      .comma {
        grid-column: 4;
        height: 100%;
        margin-top: 10px;

        span {
          font-size: 16px;
        }
      }

      .increment {
        grid-column: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }
    }

    .total {
      grid-column: 6;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      p {
        margin: 0px;
      }
    }
    .spi-total {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      .spi-count,
      .dc-ac {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .config-stat {
      margin-top: 15px;
      width: 100%;
      display: grid;
      place-items: end;
      padding-right: 10px;
      color: #333;

      p {
        margin: 0;
        font-size: 16px;
      }

      .underline {
        margin-left: auto;
        display: grid;
        place-items: end;

        &:after {
          content: "";
          display: block !important;
          height: 1px !important;
          width: 70px !important;
          background: #444 !important;
        }
      }
    }

    .spacer {
      width: 50px;
      height: 2px;
      margin: 15px auto;
      border-radius: 2px;
      opacity: 0.7;
      background-color: var(--primary-brand-color);
    }

    .config-input-switch {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr min-content;
      place-items: center self-start;
      padding: 15px 10px;
      box-sizing: border-box;
    }

    .config-three-col {
      display: grid;
      grid-template-columns: 50% 12% 38%;
      padding: 10px;

      label {
        grid-column: 1;
        background-color: #fff;
        align-self: center;
      }
      Button {
        grid-column: 2;
        margin: 0px 5px 0px auto;
        align-self: center;
      }
      Input {
        grid-column: 3;
      }
    }
  }
`;

export const ResultReadOnlyTable = styled.section`
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* padding: 7px; */
  z-index: 510;

  .read-only-table.big {
    height: 100%;
  }
  .read-only-table {
    /* width: 100%; */
    height: 250px;
    display: grid;
    /* grid-template-rows: 1fr auto; */
    /* padding: 0px 5px 10px 0px; */
    cursor: pointer;
    overflow-y: scroll;
    /* border-bottom: 1px solid; */
    /* box-shadow: 0px 2px #ff4700; */

    h2 {
      grid-row: 1;
    }
    p {
      text-align: center;
    }
    table {
      text-align: center;
      position: relative;
      height: 26px;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    table thead {
      position: sticky;
      top: 0px;
      background: #fff;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        background: rgb(217, 217, 217);
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }

    table > tbody > tr > td {
      border: 1px solid rgb(217, 217, 217);
    }

    .column-name {
      border: 1px solid #d9d9d9;
      position: relative;
      vertical-align: bottom;
      font-size: 10pt;
      font-weight: 500;
      height: 44px;
      white-space: nowrap;
    }
    .column-name.active {
      color: var(--primary-brand-color);
      /* background-color: #e2e2e2; */
      border-top: 1px solid var(--primary-brand-color);
      border-bottom: 1px solid var(--primary-brand-color);
    }
    .column-name:hover {
      color: var(--primary-brand-color);
      /* background-color: #e2e2e2; */
    }
    .mini-sort {
      position: absolute;
      font-size: 15px;
      top: 0;
      right: 2px;
    }
    .mini-sort:hover {
      color: var(--primary-brand-color);
      /* background-color: #e2e2e2; */
    }
    .mini-data > td {
      text-align: left;
      padding: 2px 4px;
      min-width: 55px;
      white-space: nowrap;
    }

    .mini-data.selected,
    .mini-data:hover {
      color: var(--primary-brand-color);
      background-color: #f3f3f3;
    }

    /* span {
      grid-row: 2;
      font-size: 18px;
    } */
  }
`;

// export const ResultReadOnlyTable = styled.section`
//   width: 100%;
//   height: 100%;
//   background-color: #fff;
//   z-index: 510;

//   .read-only-table.big {
//     height: 100%;
//   }
//   .read-only-table {
//     overflow-x: scroll;
//     overflow-y: hidden;

//     table {
//       width: 100%;
//       height: 325px;
//       /* table-layout: fixed; */

//       thead,
//       tbody,
//       tr,
//       td,
//       th {
//         display: block;
//       }

//       thead {
//         tr {
//           display: flex;
//           th {
//             display: flex;
//             width: 100%;
//             justify-content: center;
//             align-items: flex-end;
//             position: relative;
//             font-size: 10pt;
//             font-weight: 500;
//             height: 44px;
//             color: #333;
//             cursor: pointer;
//             padding: 0px 8px;
//             white-space: nowrap;
//             border: 1px solid #d3d3d3;

//             &.active {
//               color: var(--primary-brand-color);
//               border-top: 1px solid var(--primary-brand-color);
//               border-bottom: 1px solid var(--primary-brand-color);
//             }

//             &:hover {
//               color: var(--primary-brand-color);
//             }

//             .mini-sort {
//               position: absolute;
//               font-size: 15px;
//               top: 0;
//               right: 2px;
//             }
//           }

//           &:after {
//             content: ' ';
//             display: block;
//             visibility: hidden;
//             clear: both;
//           }
//         }
//       }

//       tbody {
//         height: 280px;
//         overflow-y: auto;
//         overflow-x: scroll;

//         .mini-data {
//           display: flex;

//           &:after {
//             content: ' ';
//             display: block;
//             visibility: hidden;
//             clear: both;
//           }

//           &:hover {
//             color: var(--primary-brand-color);
//             background-color: #f3f3f3;
//             cursor: pointer;
//           }
//           td {
//             display: flex;
//             font-size: 10pt;
//             cursor: pointer;
//             padding: 0px 8px;
//             padding: 2px 4px;
//             border: 1px solid #d9d9d9;
//             white-space: nowrap;
//           }
//         }

//         .selected {
//           color: var(--primary-brand-color);
//           background-color: #f3f3f3;
//           cursor: pointer;
//         }
//       }

//       tbody tr td,
//       thead tr th {
//         float: left;
//         width: 55px;

//         &:nth-child(4) {
//           width: 65px;
//         }
//         &:nth-child(5) {
//           width: 75px;
//         }
//         &:nth-child(6) {
//           width: 75px;
//         }
//         &:nth-child(9) {
//           width: 85px;
//         }
//         &:nth-child(10) {
//           width: 80px;
//         }
//       }
//     }
//   }
// `;

export const ConfigWrapper = styled.section`
  .spinner {
    width: 100%;
    height: 100%;
    min-height: 150px;
    display: grid;
    place-items: center;
    .ant-spin-text {
      font-size: 12px;
    }
  }

  .config-inputs {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(8, auto);

    .config-err {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;
      p {
        margin: 0px;
        font-size: 12px;
      }
      span {
        margin-right: 3px;
      }
    }

    .config-header {
      padding: 5px 10px;
    }

    .config-input-label {
      width: 100%;
      padding: 5px 10px;

      span {
        margin: 0 2px;
      }

      & > .ant-radio-group,
      label {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 100%;
          background: #d9d9d9;
          margin-left: 2px;
        }
      }
    }

    .config-input.error {
      grid-template-columns: 100%;
    }
    .config-input {
      width: 100%;
      display: grid;
      margin-top: 5px;
      grid-template-columns: 1fr min-content 1fr min-content 1fr min-content;
      place-items: center;

      .min {
        grid-column: 1;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }

      .dash {
        grid-column: 2;
        height: 100%;

        span {
          font-size: 16px;
        }
      }

      .max {
        grid-column: 3;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }
      .comma {
        grid-column: 4;
        height: 100%;
        margin-top: 10px;

        span {
          font-size: 16px;
        }
      }

      .increment {
        grid-column: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }
    }

    .total {
      grid-column: 6;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      p {
        margin: 0px;
      }
    }

    /* .total {
      margin-top: 5px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 0 10px;
      span {
        margin-left: 5px;
      }
    } */
    .spi-total {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      .spi-count,
      .dc-ac {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .config-row {
      margin-top: 15px;
      width: 100%;
      display: grid;
      padding-right: 10px;
      color: #333;
      grid-template-columns: 1fr min-content;

      p {
        margin: 0;
        font-size: 16px;
      }

      .config-limit {
        display: grid;

        p {
          margin-left: auto;
          font-size: 16px;
        }

        .underline {
          margin-left: auto;
          display: grid;
          place-items: end;
          margin: 2px;
          font-size: 16px;

          &:after {
            content: "";
            display: block !important;
            height: 1px !important;
            width: 70px !important;
            background: #444 !important;
          }
        }
      }

      .config-info-msg {
        display: grid;
        width: 100%;
        place-items: center;
        p {
          font-size: 12px;
        }
      }
    }

    .config-row-slider {
      padding: 5px;
      display: flex;
      align-items: center;

      p {
        margin: 0px 5px;
      }

      .ant-slider {
        width: 100%;
      }
    }

    .spacer {
      width: 50px;
      height: 2px;
      margin: 15px auto;
      border-radius: 2px;
      opacity: 0.7;
      background-color: var(--primary-brand-color);
    }
  }
`;

export const LayoutInputWrapper = styled.section`
  width: 100%;
  padding: 10px;

  .layout-inputs-panel {
    display: grid;
    grid-template-rows: repeat(19, auto);
  }

  .layout-input-one-col {
    display: grid;
    width: 100%;
    height: 20px;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    padding: 0 5px;
    margin: 4px auto 10px auto;

    span {
      font-size: 11px;
    }
  }
  .layout-input-one-col.coord {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    place-items: normal;
  }
  .layout-input-one-col.indent {
    margin-left: 10px;
  }

  .layout-input-two-col {
    display: grid;
    grid-template-columns: 1fr 136px;
    padding: 0 5px;
    margin-top: 8px;
    align-content: center;

    label {
      grid-column: 1;
      background-color: #fff;
    }
    Button {
      grid-column: 2;
      margin: auto 0px auto auto;
    }
    Input {
      grid-column: 2;
    }
  }

  .layout-input-do-roads {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 5px;
    margin-top: 8px;
    align-content: center;
  }

  .layout-input-three-col {
    display: grid;
    grid-template-columns: 50% 12% 38%;
    padding: 0 5px;
    margin-top: 8px;

    label {
      grid-column: 1;
      background-color: #fff;
      align-self: center;
    }
    Button {
      grid-column: 2;
      margin: 0px 5px 0px auto;
      align-self: center;
    }
    Input {
      grid-column: 3;
    }
  }

  .layout-racking-input {
    padding: 0 5px;
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr;

    .radio-buttons {
      grid-column: 1;
      display: grid;
      place-items: center;
    }
  }

  .layout-text-block {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    padding: 0 5px;

    .ant-select-selector {
      width: 290px;
    }
  }
`;

export const SVGIndicatorWrap = styled.svg`
  position: absolute;
  width: 100%;
  z-index: -1;
  top: -14px;
`;

export const DimsReadOnlyTable = styled.section`
  width: 250px;
  background-color: #fff;
  margin: 0 auto;
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1000;

  .read-only-table {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 10px 0px;
    cursor: pointer;

    h2 {
      grid-row: 1;
    }
    p {
      text-align: center;
    }
    table {
      text-align: center;
    }
    table > tbody > tr > td {
      border: 1px solid rgb(217, 217, 217);
    }

    span {
      grid-row: 2;
      margin-left: auto;
      font-size: 18px;
    }
  }
`;

const blink = keyframes`
  50% { color: transparent }
`;

export const GeneratingResultsTab = styled.section`
  width: 100%;
  height: auto;

  .generating-steps {
    width: 325px;
    height: inherit;
    padding: 20px 5px;
    display: grid;
    grid-template-rows: repeat(2, 88px);
    place-items: center;
    box-sizing: border-box;

    .run-step {
      display: grid;
      place-items: center;
      text-align: center;
      color: var(--primary-brand-color);

      .step-title {
        width: 100%;
        margin-top: auto;
        display: flex;
        align-items: center;

        p {
          margin: 0px;
          font-size: 16px;
          font-weight: 700;
        }

        span {
          font-size: 30px;
        }

        .loader-dot {
          animation: ${blink} 1s infinite;
        }
        .loader-dot:nth-child(2) {
          animation-delay: 250ms;
        }
        .loader-dot:nth-child(3) {
          animation-delay: 500ms;
        }
      }
    }
    .step-bar {
      width: 100%;
      margin-bottom: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .steps {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        padding: 0px;
        margin: 0px;
      }

      .active {
        transition: all 0.9 var(--easing-spring);
        transform: scale(1.4);
        font-size: 18px;
      }
    }
  }

  .results-tab-actions {
    height: 176px;
    width: 100%;
    padding: 10px;

    .all-results-actions {
      p {
        margin: 0px;
        color: #666;
        font-weight: 600;
      }

      span {
        opacity: 0.8;
        font-size: 12px;
      }

      .current-result-info {
        margin-left: 10px;
      }

      .all-results-action-buttons {
        display: grid;
        grid-template-columns: repeat(3, 90px);
        grid-template-rows: auto auto;
        grid-gap: 8px;
        margin-top: 5px;
        margin-left: 10px;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const StepListItem = styled.li`
  position: relative;
  height: 30px;
  width: 30px;
  opacity: ${(props) => ((props.running && props.stepStatus === "process") || props.stepStatus === "finish" || props.stepStatus === "complete" ? "1" : "0.4")};
  color: var(--primary-brand-color);
  border: 2px solid var(--primary-brand-color);
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s var(--easing-spring);
  transform: scale(1);
  z-index: ${(props) => props.zIndex};

  &:not(:first-child):before {
    position: absolute;
    content: "";
    display: block;
    height: 2px;
    right: 28px;
    width: 28px;
    background-color: var(--primary-brand-color);
    z-index: -1;
  }
`;
