import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { siftActions, inputsActions } from "../Redux";

import { DownSquareOutlined, UpSquareOutlined, MinusSquareOutlined, FileExcelOutlined, CopyOutlined } from "@ant-design/icons";
import { Button } from "../BaseComponents";

import { ResultReadOnlyTable } from "../InputPanel/StyledComponents";
import { resultColumns } from "./resultColumns";
import { Tooltip, Popconfirm } from "antd";

const ResultTable = ({ onSelectResult, onOpenDetail, saved_results_table = false }) => {
  const [sortMode, setSortMode] = useState({ col: "gcr", sort: "ascend" });

  //const [settingColumns, setSettingColumns] = useState(false);
  //const [whichColumn, setWhichColumn] = useState(undefined);

  // const [selectedId, setSelectedId] = useState(undefined);

  const sift_inputs = useSelector((state) => state.sift.ioManager.backup_inputs);
  const saved_results = useSelector((state) => state.inputs.saved_results);
  //const current_plan = useSelector((state) => state.user.product_plan_paid);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);
  const selectedResult = outputs.selectedResult;

  const project_id = useSelector((state) => state.inputs.project_id);
  const variant_id = useSelector((state) => state.inputs.variant_id);
  const apiKey = useSelector((state) => state.user.apiKey);

  const projects = useSelector((state) => state.projects);
  const cannot_delete = project_id && projects.local[project_id]?.collab_on == 1 && projects.local[project_id]?.locked && !projects.local[project_id]?.locked_by_me;

  // console.log("selected", selectedResult);
  //const runState = outputs.runState;

  //const loading_layout = useSelector((state) => state.sift.ioManager.uiState.loading_layout);
  //const loading_files = useSelector((state) => state.sift.ioManager.uiState.loading_files);
  //const map_loading = useSelector((state) => state.sift.ioManager.inputs.map.loading_map);
  // const csvData = outputs.csvData
  // const fileName = outputs.fileName

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  const dispatch = useDispatch();

  // write a custom sorter for this results to be based on sortMode
  const results =
    saved_results_table && Object.values(saved_results || {}).length > 0
      ? onSortResults(Object.values(saved_results).filter((res) => res.saved_result))
      : onSortResults(Object.values(outputs.results));
  const code = internal_sift
    ? 153
    : saved_results_table
    ? 150
    : sift_inputs?.do_finance === 1 && sift_inputs?.metric === 0
    ? 151
    : sift_inputs?.do_finance != 0 && sift_inputs?.metric == 1
    ? 152
    : 150;
  // const code = outputs.code;
  const tableColumns = resultColumns(code);

  // TEST DATA
  // const code = 152
  // const results = onSortResults([
  //   {id:'1234',gcr:0.35,pitch:1.34,dcac:1.45,YIELD:1234.123,lcoe:0.45,irr:0.93},
  //   {id:'456',gcr:0.36,pitch:1.45,dcac:1.35,YIELD:4000.123,lcoe:0.434,irr:0.922}])

  function onSetMiniSort(sorter, event) {
    // this button is nested in a column header that is clickable too, so stop event from bubbling to that header
    event.stopPropagation();

    if (sorter === sortMode.col && sortMode.sort === "ascend") {
      // flip
      setSortMode({ col: sorter, sort: "descend" });
    } else {
      // defaults to ascend
      setSortMode({ col: sorter, sort: "ascend" });
    }
  }
  function onSelectMiniResult(result) {
    if (selectedResult && selectedResult.id == result.id) {
      return;
      // console.log('click')
      // setSelectedId(result.id)
      // dispatch(siftActions.selectResult(result))
    }
    onSelectResult(result);
  }
  function onDoubleClickResult(result) {
    onOpenDetail(result);
    dispatch(siftActions.getLayoutData(result));
  }
  function onSortResults(results) {
    if (sortMode.sort === "ascend") {
      // ascending
      results.sort((a, b) => a[sortMode.col] - b[sortMode.col]);
    } else {
      // descending
      results.sort((a, b) => b[sortMode.col] - a[sortMode.col]);
    }

    return results;
  }

  function deleteSavedResult(result) {
    if (!result) return;
    let result_to_remove = { result };
    result_to_remove.result["saved_result"] = false;
    result_to_remove.result["project_id"] = project_id;
    result_to_remove.result["variant_id"] = variant_id;
    result_to_remove.result["user_api_key"] = apiKey;
    dispatch(inputsActions.update_saved_results("remove_saved_result", result_to_remove));
  }

  return (
    <div className="result-holder" style={{ border: "3px solid transparent" }}>
      <ResultReadOnlyTable>
        <div className="read-only-table big">
          <table>
            <thead id="result-table-tip">
              <tr key="column-headers">
                {tableColumns.map((col, index) => {
                  if (!col.visible) return;
                  return (
                    <th className={`column-name ${sortMode.col === col.key ? "active" : ""}`} key={index} onClick={(e) => onSetMiniSort(col.key, e)}>
                      <span>{col.title}</span>
                      <span className="mini-sort">{sortMode.col === col.key ? sortMode.sort === "ascend" ? <UpSquareOutlined /> : <DownSquareOutlined /> : <span></span>}</span>
                    </th>
                  );
                })}
                {saved_results_table && (
                  <th className={`column-name`}>
                    <span>Actions</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {results.map((res) => {
                return (
                  <Tooltip placement="left" title="Double-click to Plot" mouseEnterDelay={0.1} key={res.id}>
                    <tr
                      id={res.id}
                      key={res.id}
                      style={{ height: "26px !important" }}
                      className={`mini-data ${selectedResult && selectedResult.id == res.id && "selected"}`}
                      onClick={() => onSelectMiniResult(res)}
                      onDoubleClick={() => onDoubleClickResult(res)}
                    >
                      {tableColumns.map((col, index) => {
                        if (col.visible) {
                          return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res[col.key]) : res[col.key]}</td>;
                        }
                      })}
                      {saved_results_table && (
                        <td key={`${res.id}_saved_res`} style={{ display: "flex", justifyContent: "center" }}>
                          <Popconfirm title="Delete this saved result?" onConfirm={() => deleteSavedResult(res)} okText="Yes" cancelText="No">
                            <Button label={"Delete"} disabled={cannot_delete} size={"small"} type={"link"} />
                          </Popconfirm>
                        </td>
                      )}
                    </tr>
                  </Tooltip>
                );
              })}
            </tbody>
          </table>
        </div>
      </ResultReadOnlyTable>
    </div>
  );
};

export { ResultTable };
