const inverter_schema = {
  type: "object",
  tab: "Inverter",
  properties: {
    inv_name: {
      label: "Inverter Name",
      _id: "inv_name",
      type: "string",
    },
    simple_inverter: {
      label: "Simple Inverter",
      _id: "simple_inverter",
      type: "integer",
    },
    simple_inverter_dcac: {
      label: "DC:AC (ILR):",
      _id: "simple_inverter_dcac",
      type: "number",
    },
    inv_rating: {
      label: "Inverter Rating",
      _id: "inv_rating",
      type: "number",
      validateInverter: true,
    },
    inv_pnom: {
      label: "AC Power Nom",
      _id: "inv_pnom",
      type: "number",
    },
    inv_pd_pacokw: {
      label: "AC Power Max",
      _id: "inv_pd_pacokw",
      type: "number",
    },
    mppt_low_inverter: {
      label: "Min MPPT",
      _id: "mppt_low_inverter",
      type: "number",
    },
    mppt_hi_inverter: {
      label: "Max MPPT",
      _id: "mppt_hi_inverter",
      type: "number",
    },
    inv_pd_vdcmax: {
      label: "Max PV Volts",
      _id: "inv_pd_vdcmax",
      type: "number",
    },
    maxEff: {
      label: "Max Efficiency",
      _id: "maxEff",
      type: "number",
    },
    inv_pd_pnt: {
      label: "Night Pwr Use",
      _id: "inv_pd_pnt",
      type: "number",
    },
    pthresh: {
      label: "Power Threshold",
      id_: "pthresh",
      type: "number",
    },
    derate_toggle: {
      label: "Temp Derate",
      _id: "derate_toggle",
      type: "integer",
    },
    derate_maxoutput: {
      type: "array",
      items: { type: "number" },
    },
    derate_temps: {
      type: "array",
      items: { type: "number" },
    },
    inv_pd_eff_pout: {
      type: "array",
      items: { type: "number" },
    },
    inv_pd_efficiency: {
      type: "array",
      items: { type: "number" },
    },
    inv_pd_pdcokw: {
      type: "array",
      items: { type: "number" },
    },

    v1_eff: { type: "array", items: { type: "number" } },
    v1_pl: { type: "array", items: { type: "number" } },
    v2_eff: { type: "array", items: { type: "number" } },
    v2_pl: { type: "array", items: { type: "number" } },
    v3_eff: { type: "array", items: { type: "number" } },
    v3_pl: { type: "array", items: { type: "number" } },
    vs_effeu: { type: "array", items: { type: "number" } },
    vs_effmax: { type: "array", items: { type: "number" } },
    vs_vnom: { type: "array", items: { type: "number" } },
    iv_curves: { type: "array", items: { type: "number" } },
  },
};

export { inverter_schema };
