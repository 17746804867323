const performance_schema = {
  type: "object",
  tab: "Performance",
  properties: {
    perf_name: {
      label: "Name",
      type: "string",
    },
    dc_degrade: {
      label: "DC Degradation",
      _id: "dc_degrade",
      type: "number",
    },
    dc_thermal_Uc: {
      label: "Thermal Constant Loss",
      _id: "dc_thermal_Uc",
      type: "number",
    },
    dc_thermal_Uv: {
      label: "Thermal Wind Loss",
      _id: "dc_thermal_Uv",
      type: "number",
    },
    dc_module_quality_loss: {
      label: "Mod Quality Loss",
      _id: "dc_module_quality_loss",
      type: "number",
    },
    dc_module_lid_loss: {
      label: "Mod LID Loss",
      _id: "dc_module_lid_loss",
      type: "number",
    },
    dc_module_mismatch: {
      label: "DC Module Mismatch",
      _id: "dc_module_mismatch",
      type: "number",
    },
    dc_strings_mismatch: {
      label: "DC Strings Mismatch",
      _id: "dc_strings_mismatch",
      type: "number",
    },
    dc_wiring_loss_at_stc: {
      label: "DC Wiring Loss @ STC",
      _id: "dc_wiring_loss_at_stc",
      type: "number",
    },
    bi_back_mismatch: {
      label: "Bifacial Back Mismatch",
      _id: "bi_back_mismatch",
      type: "number",
    },
    ac_aux_kw: {
      label: "Auxiliaries",
      _id: "ac_aux_kw",
      type: "number",
    },
    ac_wiring_loss_at_stc: {
      label: "AC Wiring Loss @ STC",
      _id: "ac_wiring_loss_at_stc",
      type: "number",
    },
    ac_transformer_loss_constant: {
      label: "MV Xfmr Constant Loss",
      _id: "ac_transformer_loss_constant",
      type: "number",
    },
    ac_transformer_loss_at_stc: {
      label: "MV Xfmr Loss @ STC",
      _id: "ac_transformer_loss_at_stc",
      type: "number",
    },
    ac_MV_line_loss_stc: {
      label: "MV Line Loss @ STC",
      _id: "ac_MV_line_loss_stc",
      type: "number",
    },
    en_powerfactor: {
      label: "Enable Power Factor",
      _id: "en_powerfactor",
      type: "integer",
    },
    power_factor: {
      label: "Power Factor",
      _id: "power_factor",
      type: "number",
    },
    pnom_apparent: {
      label: "Inverter Pnom",
      _id: "pnom_apparent",
      type: "number",
    },
    ac_hv_transformer_loss_at_stc: {
      label: "HV Xfmr @ STC",
      _id: "ac_hv_transformer_loss_at_stc",
      type: "number",
    },
    ac_transmission_loss: {
      label: "Transmission Loss",
      _id: "ac_transmission_loss",
      type: "number",
    },
    ac_other_loss: {
      label: "Other AC Losses",
      _id: "ac_other_loss",
      type: "number",
    },
    do_monthly_losses: {
      label: "Monthly Values",
      _id: "do_monthly_losses",
      type: "integer",
    },
    albedo_single: {
      label: "Albedo",
      _id: "albedo_single",
      type: "number",
    },
    soiling_single: {
      label: "Soiling",
      _id: "soiling_single",
      type: "number",
    },
    soiling: {
      type: "array",
      items: { type: "number" },
    },
    albedo: {
      type: "array",
      items: { type: "number" },
    },
  },
};

export { performance_schema };
