import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, Source, Layer } from "react-map-gl";

const HandleFeatureHover = ({ feature }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const cur_hover_id = useSelector((state) => state.inputs.cur_hover_id);

  useEffect(() => {
    map.triggerRepaint();
  }, [cur_hover_id]);

  return (
    <>
      {cur_hover_id === feature.properties.index && (
        <Source type="geojson" key={feature.properties.index} data={feature}>
          <Layer
            type="fill"
            paint={{
              fill: "#00d5e7",
              "fill-opacity": 0.5,
              "fill-outline-color": feature.properties.identity === 0 ? "#FFFFFF" : feature.properties.identity == 1 ? "#ff0000" : feature.properties.identity === 2 ? "#ffff00" : "#FFFFFF",
            }}
          />
        </Source>
      )}
    </>
  );
};

export { HandleFeatureHover };

// const HandleFeatureHover = () => {
//   const { current: mapRef } = useMap();
//   const map = mapRef.getMap();
//   const draw = useMapboxDraw();
//   const cur_hover_id = useSelector((state) => state.inputs.cur_hover_id);
//   let prev_hover_id = usePrevious(cur_hover_id); // Use a ref to store the previous hover id

//   //   useEffect(() => {
//   //     console.log("curent id", cur_hover_id);
//   //     console.log("prev id", prev_hover_id);
//   //   }, [cur_hover_id]);

//   useEffect(() => {
//     if (!map || !draw || !cur_hover_id) return;

//     // Unhighlight the previously highlighted feature
//     if (prev_hover_id && prev_hover_id !== cur_hover_id) {
//       const prevFeature = draw.get(prev_hover_id);
//       if (prevFeature) {
//         draw.setFeatureProperty(prev_hover_id, "highlight", false);
//       }
//     }

//     // Highlight the currently hovered feature
//     const currentFeature = draw.get(cur_hover_id);
//     if (currentFeature) {
//       draw.setFeatureProperty(cur_hover_id, "highlight", true);
//     }

//     // Update the previous hover ID ref
//     prev_hover_id = cur_hover_id;
//   }, [cur_hover_id, prev_hover_id, map, draw]); // Run this effect when cur_hover_id changes

//   return null; // No rendering needed for this component
// };

// export { HandleFeatureHover };
