import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { canopyActions, inputsActions, projectActions, siftActions } from "../Redux";
import { Tabs, Tab, Input } from "../BaseComponents";
import { Projects } from "./ProjectDataManager";
import { PresetManager } from "./PresetDataManager/PresetManager";
import { ProjectsWrap } from "./styles";
import proj_data from "./ProjectDataManager/fake_dm_data.json";

const DataManager = () => {
  const dispatch = useDispatch();
  // const projects = useSelector((state) => state.projects);

  const [searchTerm, setSearchTerm] = useState("");
  // const [local_projects, set_local_projects] = useState(projects.local);
  const [local_projects, set_local_projects] = useState(proj_data);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value); // Update search term state
    filterProjects(e.target.value); // Filter projects based on search term
  };

  const filterProjects = (term) => {
    const filteredProjects = Object.values(proj_data).reduce((acc, project) => {
      const projectName = project.project_name.toLowerCase();
      const searchTermLower = term.toLowerCase();

      const parentMatches = projectName.includes(searchTermLower);

      const matchingSubRows = project.subRows?.filter((subProject) => subProject.project_name.toLowerCase().includes(searchTermLower)) || [];

      if (parentMatches || matchingSubRows.length > 0) {
        acc.push({
          ...project,
          subRows: parentMatches ? project.subRows : matchingSubRows,
        });
      }

      return acc;
    }, []);

    set_local_projects(filteredProjects);
    // console.log("filteredProjects", filteredProjects);
  };

  return (
    <Tabs defaultActiveKey="1">
      <Tab eventKey="1" label="Projects">
        <ProjectsWrap>
          {/* <Input size="small" placeholder="Search Projects" addClearButton={true} value={searchTerm} onChange={handleSearchInputChange} /> */}
          <Projects />
        </ProjectsWrap>
      </Tab>
      {/* <Tab eventKey="2" label="Presets">
        <PresetManager />
      </Tab> */}
      {/* <Tab eventKey="3" label="Users">
        <h3>User admin stuff here</h3>
      </Tab> */}
    </Tabs>
  );
};

export { DataManager };
