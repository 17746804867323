const topography_schema = {
  type: "object",
  tab: "Topography",
  properties: {
    topo_source: {
      label: "Elevation",
      _id: "topo_source",
      type: "string",
    },
    topo__id: {
      _id: "topo_id",
      type: "string",
    },
    topo_bbox: {
      type: "array",
      items: { type: "number" },
      validateTopography: true,
    },
    topo_layout_action: {
      label: "Racking on Topography Actions",
      _id: "topo_layout_action",
      type: "string",
    },
    en_topo_perf: {
      label: "Topographic Performance Impact",
      _id: "en_topo_perf",
      type: "integer",
      enum: [0, 1],
    },
    topo_layout_surface: {
      label: "Act on Surface",
      _id: "topo_layout_surface",
      type: "string",
    },
    graded_avail: {
      label: "Graded",
      _id: "graded_avail",
      type: "boolean",
    },
    grade_target: {
      label: "Graded",
      _id: "grade_target",
      type: "number",
    },
    grade_cut_amt: {
      label: "Graded",
      _id: "grade_cut_amt",
      type: "number",
    },
    grade_fill_amt: {
      label: "Graded",
      _id: "grade_fill_amt",
      type: "number",
    },
    vis_ele_ungraded_avail: {
      label: "Unraded",
      _id: "vis_ele_ungraded_avail",
      type: "boolean",
    },
    vis_ns_ungraded_percent: {
      label: "Unraded",
      _id: "vis_ns_ungraded_percent",
      type: "number",
    },
    vis_ns_ungraded_avail: {
      label: "Unraded",
      _id: "vis_ns_ungraded_avail",
      type: "boolean",
    },
    vis_ew_ungraded_percent: {
      label: "Unraded",
      _id: "vis_ew_ungraded_percent",
      type: "number",
    },
    vis_ew_ungraded_avail: {
      label: "Unraded",
      _id: "vis_ew_ungraded_avail",
      type: "boolean",
    },
    vis_max_ungraded_percent: {
      label: "Unraded",
      _id: "vis_max_ungraded_percent",
      type: "number",
    },
    vis_max_ungraded_avail: {
      label: "Unraded",
      _id: "vis_max_ungraded_avail",
      type: "boolean",
    },
    topo_mode: {
      type: "string",
    },
    topo_url: {
      type: "string",
    },
    topo_scale_url: {
      type: "string",
    },
  },
};

export { topography_schema };
