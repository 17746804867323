import React from "react";
import { useDispatch } from "react-redux";
import { accountActions } from "../Redux";
import {
  Info_50MW,
  Info_Topo,
  Info_50runs,
  Info_customModInv,
  Info_1000MW,
  Info_Core,
  Info_CustomWeather,
  Info_AdvFin,
  Info_UnlimRuns,
  Info_CAD,
  Info_Collab,
  Info_customModInvWeath,
  Info_TopoSlope,
  Info_TopoAnalysis,
  Info_AdvFinMod,
  Info_PDF,
} from "./info";

const plan_specs = {
  starter: {
    plan_description: "Explore what SIFT can do with no commitment",
    amount: [0],
    plan_details: [
      { text: "Projects up to 100MW", component: <Info_50MW />, account_page_specific: true },

      { text: "Up to 20 SIFT runs per month", component: <Info_50runs /> },
      { text: "plus_divider" },
      { text: "Core SIFT Features", component: <Info_Core /> },
      { text: "Custom Modules, Inverters, Weather", component: <Info_customModInvWeath /> },
      { text: "Topography", component: <Info_TopoAnalysis /> },
    ],
  },
  standard: {
    plan_description: undefined,
    amount: [380, 900],
    plan_details: [
      { text: "Projects up to 100MW", component: <Info_50MW />, account_page_specific: true },
      { text: "Unlimited* SIFT runs per month", component: <Info_UnlimRuns /> },
      { text: "plus_divider" },
      { text: "Core SIFT Features", component: <Info_Core /> },
      { text: "Custom Modules, Inverters, Weather", component: <Info_customModInvWeath /> },
      { text: "Topography & Slope Analysis", component: <Info_TopoSlope /> },
      { text: "plus_divider" },
      { text: "Advanced Financial Models", component: <Info_AdvFinMod /> },
      { text: "Collaboration Tools", component: <Info_Collab /> },
      { text: "Export to CAD", component: <Info_CAD /> },
      { text: "PDF Reports", component: <Info_PDF /> },
    ],
  },
  pro: {
    plan_description: undefined,
    amount: [660, 1600],
    plan_details: [
      { text: "Projects up to 1000MW", component: <Info_1000MW />, account_page_specific: true },
      { text: "Unlimited* SIFT runs per month", component: <Info_UnlimRuns /> },
      { text: "plus_divider" },
      { text: "Core SIFT Features", component: <Info_Core /> },
      { text: "Custom Modules, Inverters, Weather", component: <Info_customModInvWeath /> },
      { text: "Topography & Slope Analysis", component: <Info_TopoSlope /> },
      { text: "plus_divider" },
      { text: "Advanced Financial Models", component: <Info_AdvFinMod /> },
      { text: "Collaboration Tools", component: <Info_Collab /> },
      { text: "Export to CAD", component: <Info_CAD /> },
      { text: "PDF Reports", component: <Info_PDF /> },
    ],
  },
};

const products = [
  {
    id: 1,
    name: "STARTER",
    product_description: "20 SIFT runs per month;Save/Load Projects;Boundaries up to 100ha., ≈50MWac;Import/Export KMZ;Import Weather (NSRDB, NASA);Default Products;Sunfig Performance Models",
    monthly_cost: "0.00",
    plan_restrictions: {
      can_dxf: false,
      can_download_8760: true,
      can_kmz: true,
      can_meteo: true,
      can_project: false,
      can_pan: true,
      can_ond: true,
      can_api_key: false,
      can_run_performance: true,
      can_run_finance: true,
      can_run_storage: false,
      can_pdf: false,
      can_lcoe: true,
      can_irr: false,
      can_collab: false,
      can_3ds: false,
      api_credit: 0,
      boundary_size_limit: 100,
      run_limit: 20,
    },
  },
  {
    id: 2,
    name: "STANDARD",
    product_description:
      "100 SIFT runs per month;Save/Load Projects;Boundaries up to 100ha., ≈50MWac;Import/Export KMZ;Import Weather (NSRDB, NASA, Custom);Default and User Defined Products;Sunfig Performance Models;Import PAN & OND;API Key;$70/month API Credit",
    monthly_cost: "330.00",
    plan_restrictions: {
      can_dxf: true,
      can_kmz: true,
      can_meteo: true,
      can_project: false,
      can_pan: true,
      can_ond: true,
      can_api_key: false,
      can_run_performance: true,
      can_run_finance: true,
      can_run_storage: false,
      can_download_8760: true,
      can_pdf: true,
      can_lcoe: true,
      can_irr: true,
      can_collab: true,
      can_3ds: true,
      api_credit: 0,
      boundary_size_limit: 100,
      run_limit: 2000,
    },
  },
  {
    id: 3,
    name: "PRO",
    product_description:
      "500 SIFT runs per month;Save/Load Projects;Boundaries up to 2000ha., ≈1000MWac;Import/Export KMZ;Import Weather (NSRDB, NASA, Custom);Default and User Defined Products;Sunfig Performance Models;Import PAN & OND;API Key;$190/month API Credit",
    monthly_cost: "570.00",
    plan_restrictions: {
      can_dxf: true,
      can_kmz: true,
      can_meteo: true,
      can_project: false,
      can_pan: true,
      can_ond: true,
      can_api_key: false,
      can_run_performance: true,
      can_run_finance: true,
      can_run_storage: false,
      can_download_8760: true,
      can_pdf: true,
      can_lcoe: true,
      can_irr: true,
      can_collab: true,
      can_3ds: true,
      api_credit: 0,
      boundary_size_limit: 1000,
      run_limit: 2000,
    },
  },
  // {
  //   id: 4,
  //   name: "STANDARD (original)",
  //   product_description: "original plan",
  //   monthly_cost: "70.00",
  //   plan_restrictions: {
  //     can_dxf: false,
  //     can_kmz: true,
  //     can_meteo: true,
  //     can_project: false,
  //     can_pan: true,
  //     can_ond: true,
  //     can_api_key: false,
  //     can_run_performance: true,
  //     can_run_finance: true,
  //     can_run_storage: false,
  //     can_download_8760: true,
  //     can_pdf: true,
  //     can_lcoe: true,
  //     can_irr: true,
  //     can_collab: true,

  //     api_credit: 0,
  //     boundary_size_limit: 100,
  //     run_limit: 20,
  //   }
  // },
  // {
  //   id: 5,
  //   name: "PRO (original)",
  //   product_description: "original plan",
  //   monthly_cost: "190.00",
  //   plan_restrictions: {
  //     can_dxf: true,
  //     can_kmz: true,
  //     can_meteo: true,
  //     can_project: false,
  //     can_pan: true,
  //     can_ond: true,
  //     can_api_key: false,
  //     can_run_performance: true,
  //     can_run_finance: true,
  //     can_run_storage: false,
  //     can_download_8760: true,
  //     can_pdf: true,
  //     can_lcoe: true,
  //     can_irr: true,
  //     can_collab: true,
  //     api_credit: 0,
  //     boundary_size_limit: 100,
  //     run_limit: 20,
  //   }
  // },
];

const InfoMessage = ({ sift_feature, toggleFunnel }) => (
  <section>
    {sift_feature} are available on our Standard and Pro plans. <a onClick={() => toggleFunnel(true)}>Click here to see our Pricing!</a>
  </section>
);

export { plan_specs, products, InfoMessage };
