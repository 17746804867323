import {
  module_schema,
  inverter_schema,
  racking_schema,
  weather_schema,
  performance_schema,
  horizon_shading_schema,
  environmental_shade_schema,
  finance_schema,
  layout_schema,
  topography_schema,
  config_schema,
  map_schema,
  project_schema,
} from "./InputSchemas";

const addTabToProperties = (schema, tabName) => {
  const temp_shema_properties = {};
  for (const [key, value] of Object.entries(schema.properties)) {
    temp_shema_properties[key] = { ...value, tab: tabName };
  }
  return temp_shema_properties;
};

const input_validation_schema = {
  type: "object",
  properties: {
    ...addTabToProperties(config_schema, "Config"),
    ...addTabToProperties(module_schema, "Module"),
    ...addTabToProperties(inverter_schema, "Inverter"),
    ...addTabToProperties(racking_schema, "Racking"),
    ...addTabToProperties(weather_schema, "Weather"),
    ...addTabToProperties(performance_schema, "Performance"),
    ...addTabToProperties(environmental_shade_schema, "Environmental Shade"),
    ...addTabToProperties(finance_schema, "Finance"),
    ...addTabToProperties(layout_schema, "Layout"),
    ...addTabToProperties(topography_schema, "Topography"),
    ...addTabToProperties(horizon_shading_schema, "Horizon Shading"),
    ...addTabToProperties(map_schema, "Map"),
    ...addTabToProperties(project_schema, "Project"),
    // ...module_schema.properties,
    // ...inverter_schema.properties,
    // ...racking_schema.properties,
    // ...weather_schema.properties,
    // ...performance_schema.properties,
    // ...horizon_shading_schema.properties,
    // ...environmental_shade_schema.properties,
    // ...finance_schema.properties,
    // ...layout_schema.properties,
    // ...topography_schema.properties,
    // ...config_schema.properties,
    // ...map_schema.properties,
    // ...project_schema.properties,
    version: {
      type: "string",
      pattern: "^\\d+\\.\\d+\\.\\d+$",
    },
    platform: {
      type: "string",
      enum: ["sift", "internal_sift"],
    },
    api_key: {
      type: "string",
    },
    warnings: {
      type: "array",
      items: {
        type: "string",
      },
    },
    job_type: {
      type: "string",
    },
    job_id: {
      type: "string",
      pattern: "^[a-f0-9]{24}$",
    },
    url: {
      type: "string",
    },
    result_handler: {
      type: "integer",
      enum: [0, 1],
    },

    // env: {
    //   type: "string",
    //   enum: ["production", "dev", "stage", "test"],
    // },

    // These aren't used any more that I can tell, and are not needed in validation
    // I'm leaving them here just in case though.
    // wiring_spi: { type: "number" },
    // do_dc_lock: { type: "number" },
    // dc_lock_value: { type: "number" },
    // do_dual_tilt: { type: "number" },
    // res_generated: { type: "boolean" },
    // changed_input_warning: { type: "boolean" },
  },
};

export { input_validation_schema };
