import React from "react";
// import { DrawerExt as DrawerDeep } from './Drawer'
// Drawer for displaying info text about a specific input
// activated through ? button from several different sections of SIFT
// Lives in CapacityTool.jsx
// drawer content pulled based on index provided in call
import { useSelector, useDispatch } from "react-redux";
import { siftActions, tutorialActions } from "../Redux";

import { Drawer } from "antd";
import * as Drawers from "./DrawerContent";

function getContent(props, drawer) {
  const content = [
    { key: "all_help", title: "SIFT Help", component: <Drawers.SIFT_Help {...props} />, width: "55%" },
    // New Users
    { key: "new_user_quick_look", title: "Video: SIFT Quick Look", component: <Drawers.New_QuickLook {...props} />, width: "40%" },
    { key: "new_user_overview", title: "Video: SIFT Overview", component: <Drawers.New_OverviewTutorial {...props} />, width: "40%" },
    // General
    { key: "collab_video", title: "Video: SIFT Collaboration", component: <Drawers.Collab_Video {...props} />, width: "40%" },
    { key: "project_collab", title: "Project Collaboration", component: <Drawers.Project_Collab {...props} />, width: "40%" },
    // { 'key': 'new_user_training', 'title': 'Schedule 1-on-1 Training', 'component': <Drawers.New_Training {...props} />, 'width': '40%'},
    // Map
    { key: "map_tutorial", title: "Video: Map Controls", component: <Drawers.Map_Tutorial {...props} />, width: "40%" },
    { key: "map_controls", title: "Map Controls", component: <Drawers.Map_Controls {...props} />, width: "40%" },
    { key: "map_layers", title: "Map Layers", component: <Drawers.Map_Layers {...props} />, width: "40%" },
    { key: "map_import_kmz", title: "Import from KMZ", component: <Drawers.Map_import_kmz {...props} />, width: "40%" },
    { key: "polygon_offset", title: "Polygon Offset", component: <Drawers.Polygon_Offset {...props} />, width: "40%" },
    { key: "map_export", title: "Export to KMZ or CAD", component: <Drawers.Map_export {...props} />, width: "40%" },
    { key: "export_kmz_from_cad", title: "Export a KMZ From CAD", component: <Drawers.Export_KMZ_From_CAD {...props} />, width: "40%" },
    { key: "map_plot", title: "Dimensions in Plot (GCR & Pitch Definitions)", component: <Drawers.Map_plot_dims {...props} />, width: "40%" },
    { key: "map_default_loc", title: "Change Default Location", component: <Drawers.Map_DefaultLoc {...props} />, width: "40%" },
    // Module
    { key: "module_pan_import", title: "Module Import from PAN", component: <Drawers.Module_PAN_drawer {...props} />, width: "40%" },
    { key: "module_pvsyst_import", title: "Module Import from PVSyst Text", component: <Drawers.Module_PVSyst_drawer {...props} />, width: "40%" },
    { key: "module_save_search", title: "Module Saving and Searching", component: <Drawers.Module_SaveSearch_drawer {...props} />, width: "40%" },
    { key: "module_gamma", title: "Tcoef of Pmpp and Tcoef of Gamma", component: <Drawers.Module_Gamma_drawer {...props} />, width: "40%" },
    // Inverter
    { key: "inverter_ond_import", title: "Inverter Import from OND", component: <Drawers.Inverter_OND_drawer {...props} />, width: "40%" },
    { key: "inverter_pvsyst_import", title: "Inverter Import from PVSyst Text", component: <Drawers.Inverter_PVSyst_drawer {...props} />, width: "40%" },
    { key: "inverter_save_search", title: "Inverter Saving and Searching", component: <Drawers.Inverter_SaveSearch_drawer {...props} />, width: "40%" },
    { key: "inverter_rating_and_dc", title: "Inverter Rating and DC:AC", component: <Drawers.Inverter_Rating_And_DC {...props} />, width: "40%" },
    // Racking
    { key: "racking_auto", title: "Racking Dimensions, Auto", component: <Drawers.Racking_Dims_Auto {...props} />, width: "40%" },
    { key: "racking_specs", title: "Racking Specs & Dimensions", component: <Drawers.Racking_Specs_drawer {...props} />, width: "40%" },
    // { key: "auto_racking", title: "Automated Racking Dimensions", component: <Drawers.Auto_Racking_Dims {...props} />, width: "40%" },
    { key: "racking_save_search", title: "Racking Saving and Searching", component: <Drawers.Racking_SaveSearch_drawer {...props} />, width: "40%" },
    { key: "design_temp", title: "Design Temp", component: <Drawers.Design_Temp {...props} />, width: "40%" },

    { key: "racking_shade_mode", title: "Linear and Electrical Shading", component: <Drawers.Racking_Shade_drawer {...props} />, width: "40%" },
    { key: "racking_bifacial", title: "Height Above Ground, Transparent Fraction, and Structure Shade Factor", component: <Drawers.Racking_Bifacial {...props} />, width: "40%" },
    // Weather
    { key: "weather_general", title: "General", component: <Drawers.Weather_General {...props} />, width: "40%" },
    { key: "weather_loc", title: "Location", component: <Drawers.Weather_Location_drawer {...props} />, width: "40%" },
    { key: "weather_source", title: "Weather Sources", component: <Drawers.Weather_Sources_drawer {...props} />, width: "40%" },
    { key: "weather_custom_import", title: "Importing Custom Weather Data", component: <Drawers.Weather_Custom_Weather {...props} />, width: "40%" },
    { key: "weather_pvsyst", title: "Weather Data Import from PVSyst", component: <Drawers.Weather_PVSyst {...props} />, width: "40%" },
    { key: "spectral_correction", title: "Spectral Correction", component: <Drawers.Spectral_Correction {...props} />, width: "40%" },

    // Performance
    { key: "perf_model", title: "SIFT Performance Model", component: <Drawers.Performance_model_drawer {...props} />, width: "40%" },
    { key: "perf_troubleshooting", title: "Troubleshootting: SIFT vs PVSyst", component: <Drawers.Troubleshooting_sift_vs_pvsyst {...props} />, width: "40%" },
    { key: "topo_perf_impact", title: "Topographic Performance Impact", component: <Drawers.TopoPerfImpact {...props} />, width: "40%" },
    { key: "power_factor", title: "Power Factor", component: <Drawers.PowerFactor {...props} />, width: "40%" },

    // { key: "perf_grid_power", title: "Grid Power Limit", component: <Drawers.Performance_grid_power {...props} />, width: "40%" },
    // Finance
    { key: "finance_period", title: "Analysis Period", component: <Drawers.Finance_AnalysisPeriod {...props} />, width: "40%" },
    { key: "install_cost_summed", title: "Install Costs, Summed", component: <Drawers.Install_Cost_Summed {...props} />, width: "40%" },
    { key: "install_cost_detailed", title: "Install Costs, Detail", component: <Drawers.Install_Cost_Detailed {...props} />, width: "40%" },
    // { key: "finance_install_cost", title: "Install Costs", component: <Drawers.Finance_InstallCosts_drawer {...props} />, width: "40%" },
    { key: "finance_op_cost", title: "Operating Costs", component: <Drawers.Finance_OperatingCosts_drawer {...props} />, width: "40%" },
    // { key: "finance_schedule", title: "Custom Schedule", component: <Drawers.Finance_CustomerSch {...props} />, width: "40%" },
    { key: "finance_custom_op_cost", title: "Custom Schedule: Operating Costs", component: <Drawers.Finance_Custom_OP_Cost {...props} />, width: "40%" },
    { key: "finance_custom_rev_and_incentives", title: "Custom Schedule: Revenue and Incentives", component: <Drawers.Finance_Custom_Rev_Incentives {...props} />, width: "40%" },

    { key: "finance_model_lcoe", title: "SIFT Financial Model, LCOE", component: <Drawers.Finance_Model_LCOE {...props} />, width: "40%" },
    { key: "finance_model_irr", title: "SIFT Financial Model, IRR", component: <Drawers.Finance_Model_IRR {...props} />, width: "40%" },

    // { key: "finance_model", title: "Sunfig Financial Model", component: <Drawers.Finance_SunfigModel {...props} />, width: "40%" },
    // Topography
    // { key: "topo_tutorial", title: "Video Tutorial: Slope Analysis", component: <Drawers.Topo_SlopeTutorial {...props} />, width: "40%" },
    { key: "topo_import", title: "Import Topography", component: <Drawers.Topo_Import {...props} />, width: "40%" },
    { key: "topo_layers", title: "Slope Analysis Layers", component: <Drawers.Topo_Layers {...props} />, width: "40%" },
    { key: "topo_remove", title: "Removing Racks Based on Grade Limited", component: <Drawers.Topo_Remove {...props} />, width: "40%" },
    { key: "topo_cut_fill", title: "Grading and Cut & Fill", component: <Drawers.Cut_And_Fill {...props} />, width: "40%" },
    { key: "topo_sources", title: "Elevation Sources", component: <Drawers.Topo_Sources {...props} />, width: "40%" },
    // Layout
    { key: "poi_limit", title: "POI Limit", component: <Drawers.POI_Limit {...props} />, width: "40%" },
    { key: "layout_inv_lock", title: "Inverter Quantity", component: <Drawers.Layout_InvLock {...props} />, width: "40%" },
    { key: "layout_align", title: "Racking Align", component: <Drawers.Layout_RackAlign {...props} />, width: "40%" },
    { key: "layout_azimuth", title: "Azimuth", component: <Drawers.Layout_Azimuth {...props} />, width: "40%" },
    { key: "layout_coord_system", title: "Geo-Referenced CAD with User-Defined Coordinate System", component: <Drawers.Layout_CoordSystem {...props} />, width: "40%" },
    { key: "inverters_and_grouping", title: "Inverters and Grouping", component: <Drawers.Inverters_And_Grouping {...props} />, width: "40%" },
    // { key: "layout_inv_cuts", title: "Draw Inverters", component: <Drawers.Layout_DrawInv {...props} />, width: "40%" },
    { key: "layout_ew_roads", title: "East-West Roads", component: <Drawers.Layout_Roads {...props} />, width: "40%" },
    { key: "layout_margin", title: "Designer Margin", component: <Drawers.Layout_DesignerMargin {...props} />, width: "40%" },
    // Config
    { key: "config_ranges", title: "Ranges and Increments", component: <Drawers.Config_Ranges {...props} />, width: "40%" },
    { key: "config_gcr", title: "Range: GCR or Pitch", component: <Drawers.Config_GCR {...props} />, width: "40%" },
    { key: "config_strings", title: "Range: DC:AC from String/Inverter", component: <Drawers.Config_Strings {...props} />, width: "40%" },
    { key: "config_workers", title: "Maximum Configuration Count", component: <Drawers.Config_Workers {...props} />, width: "40%" },
    // Archived
    // { key: "layout_fill", title: "Layout Fill", component: <Drawers.Layout_Fill {...props} />, width: "40%" },
  ];

  return content.find((c) => c.key == drawer);
}

const DrawerExt = () => {
  const helper_visible = useSelector((state) => state.sift.ioManager.uiState.helper_visible);
  const helper_deep_visible = useSelector((state) => state.sift.ioManager.uiState.helper_deep_visible);
  const current_deep_drawer = useSelector((state) => state.sift.ioManager.uiState.helper_drawer);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);

  const dispatch = useDispatch();

  let content = getContent({ onOpenDrawer, onShowTutorial }, "all_help");
  let deep_content = getContent({ onOpenDrawer, onShowTutorial, onOpenDocs }, current_deep_drawer);

  function onOpenDrawer(drawer) {
    dispatch(siftActions.toggleHelpToolbarVisibility("helper_deep_visible", true, drawer));
  }

  function onCloseDrawer() {
    dispatch(siftActions.toggleHelpToolbarVisibility("helper_visible", false));
  }
  function onCloseDeepDrawer() {
    dispatch(siftActions.toggleHelpToolbarVisibility("helper_deep_visible", false));
  }

  function onShowTutorial() {
    if (Object.values(outputs.results).length > 0) {
      dispatch(siftActions.newProject());
    }
    onCloseDrawer();
    dispatch(siftActions.toggleHelpToolbarVisibility("tutorial_visible", true));
    localStorage.setItem("hideTutorial", "false");
    setTimeout(() => {
      dispatch(tutorialActions.startTutorial());
    }, 500);
  }
  function onOpenDocs() {
    onCloseDrawer();
    dispatch(siftActions.toggleHelpToolbarVisibility("docs_visible", true));
  }

  if (!content) return <div></div>;
  return (
    <Drawer
      zIndex={1000}
      title={content.title}
      // placement="right"
      width={content.width}
      closable={false}
      onClose={onCloseDrawer}
      // visible={helper_visible}
      open={helper_visible}
      style={{ overflowY: "auto" }}
      className="helper-drawer"
    >
      {content.component}

      {deep_content && (
        <Drawer
          zIndex={1001}
          title={deep_content.title}
          width={deep_content.width}
          closable={false}
          onClose={onCloseDeepDrawer}
          // visible={helper_deep_visible}
          open={helper_deep_visible}
          Style={{ overflowY: "auto" }}
          className="helper-drawer"
        >
          {deep_content.component}
        </Drawer>
      )}
    </Drawer>
  );
};

export { DrawerExt as HelpDrawer };
