import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { pick } from "../Redux";
import { resultColumns } from "./resultColumns";
// redux
import { inputsActions, alertActions } from "../Redux";
import { VerticalDivider } from "./sc";

import { SelectedButtonsWrap } from "./sc";
import { Popconfirm } from "antd";

import { Button, ButtonGroup, Tooltip } from "../BaseComponents";

import { FileZipOutlined, AppstoreOutlined, FileImageOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { BudgetaryQuote } from "../_Internal";

import { input_validation_schema } from "../../_Schemas";

const Star = () => (
  <svg className="favorite" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49999 0L9.83644 4.28416L14.6329 5.18237L11.2804 8.72834L11.9084 13.5676L7.49999 11.475L3.0916 13.5676L3.71954 8.72834L0.367065 5.18237L5.16354 4.28416L7.49999 0Z" fill="#012BCB" />
  </svg>
);

const StarOff = () => (
  <svg className="favoriteOff" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99659 4.82151L0.754186 1.5791L1.57914 0.754144L13.2458 12.4208L12.4209 13.2458L10.926 11.7509L11.2035 13.3689L7 11.159L2.79651 13.3689L3.5993 8.68824L0.198608 5.37338L3.99659 4.82151ZM9.49723 10.3221L9.654 11.2362L7 9.84092L4.34599 11.2362L4.85286 8.28094L2.70573 6.188L5.02594 5.85085L9.49723 10.3221ZM11.2943 6.188L9.95338 7.49504L10.7784 8.32007L13.8014 5.37338L9.10174 4.69049L7 0.431885L5.64194 3.18361L6.51304 4.05471L7 3.06803L8.327 5.75683L11.2943 6.188Z"
      fill="#012BCB"
    />
  </svg>
);

const ResultDetailWrapper = styled.section`
  height: 305px;
  overflow-y: scroll;

  #masonry-effect {
    padding: 15px 10px 15px 10px;
    columns: ${(props) => (props.do_finance === 1 || props.do_finance === true ? "3 340px" : "2 400px")};
    column-gap: 1rem;

    .detail-box {
      width: 100%;
      margin-bottom: 10px;
      display: inline-block;
      border: 1px solid #e8e8e8;
      border-radius: 3px;

      h3 {
        padding: 5px;
        margin: 0;
        font-size: 10pt;
        border-radius: 3px 3px 0 0;
        color: var(--primary-brand-color);
        background: #fafafa;
        border-bottom: 1px solid #e8e8e8;
      }

      .detail-row {
        display: flex;
        justify-content: space-between;
        padding: 3px 10px;
        width: 100%;
        color: #444;
        border-bottom: 1px solid #e8e8e8;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

const ResultDetail = (props) => {
  const dispatch = useDispatch();
  const { result, loading_layout, loading_files, map_loading } = props;
  //Tutorial Controls
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);
  const edited_layout = useSelector((state) => state.inputs?.edited_layouts);
  const undo_available = edited_layout && edited_layout[selectedResultId]?.edited ? true : false;
  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  const project_id = useSelector((state) => state.inputs.project_id);
  const variant_id = useSelector((state) => state.inputs.variant_id);
  const apiKey = useSelector((state) => state.user.apiKey);
  const projects = useSelector((state) => state.projects);

  const tutorial = useSelector((state) => state.tutorial);
  //const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const show_live_report = useSelector((state) => state.sift.ioManager.uiState.show_live_report);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  const plan_restrictions = useSelector((state) => state.user.plan_restrictions);

  const current_plan = useSelector((state) => state.user.product_plan_paid);

  const track_mode = useSelector((state) => state.inputs.track_mode);
  // console.log("result", result);
  const editing_disabled = result.inverter_grouping > 0 || result.MWp > 100;
  // console.log(editing_disabled, result.inverter_grouping, result.MWp)

  const cannot_save_collab = project_id && projects.local[project_id]?.collab_on == 1 && projects.local[project_id]?.locked && !projects.local[project_id]?.locked_by_me;

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  // portal specific state
  const download_lock = useSelector((state) => (internal_sift && state.user.admin ? false : state.inputs.uiState.download_lock));

  const [loading_inputs, set_loading_inputs] = useState(false);

  // const calculate_masonry_layout = () => {
  //   let mainId = "masonry-effect";
  //   let itemIdentifier = "#masonry-effect .detail-box";

  //   // Programmatically get the column width
  //   let item = document.querySelector(itemIdentifier);
  //   let parentWidth = item.parentNode.getBoundingClientRect().width;
  //   let itemWidth = item.getBoundingClientRect().width + parseFloat(getComputedStyle(item).marginLeft) + parseFloat(getComputedStyle(item).marginRight);
  //   let columnWidth = Math.round(1 / (itemWidth / parentWidth));

  //   // We need this line since JS nodes are dumb
  //   let arrayOfItems = Array.prototype.slice.call(document.querySelectorAll(itemIdentifier));
  //   let trackHeights = {};
  //   arrayOfItems.forEach(function (item) {
  //     let height = item.clientHeight;
  //     item.style.height = `${height}px`;
  //     // Get index of item
  //     let thisIndex = arrayOfItems.indexOf(item);
  //     // Get column this and set width
  //     let thisColumn = thisIndex % columnWidth;
  //     if (typeof trackHeights[thisColumn] == "undefined") {
  //       trackHeights[thisColumn] = 0;
  //     }
  //     trackHeights[thisColumn] += item.getBoundingClientRect().height + parseFloat(getComputedStyle(item).marginBottom);
  //     // If the item has an item above it, then move it to fill the gap
  //     if (thisIndex - columnWidth >= 0) {
  //       let getItemAbove = document.querySelector(`${itemIdentifier}:nth-of-type(${thisIndex - columnWidth + 1})`);
  //       let previousBottom = getItemAbove.getBoundingClientRect().bottom;
  //       let currentTop = item.getBoundingClientRect().top - parseFloat(getComputedStyle(item).marginBottom);
  //       item.style.top = `-${currentTop - previousBottom}px`;
  //     }
  //   });
  //   let max = Math.max(...Object.values(trackHeights));
  //   document.getElementById(mainId).style.height = `${max}px`;
  // };

  const saveResult = (result) => {
    // const code = internal_sift ? 153 : result.do_finance === 1 && result.metric === 0 ? 151 : result.do_finance != 0 && result.metric == 1 ? 152 : 150;
    // const column_keys = resultColumns(code);
    // let keys = [];
    // column_keys.map((item) => item.visible && keys.push(item.key));
    // let table_values = pick(result, keys);

    let result_to_save = { result };

    result_to_save.result["project_id"] = project_id;
    result_to_save.result["variant_id"] = variant_id;
    result_to_save.result["result_id"] = result.id;
    result_to_save.result["user_api_key"] = apiKey;
    result_to_save.result["site_features"] = {};
    result_to_save.result["saved_results"] = {};
    if (result.saved_result === undefined || !result.saved_result) {
      result_to_save.result["saved_result"] = true;
      dispatch(inputsActions.update_saved_results("add_saved_result", result_to_save));
    } else {
      result_to_save.result["saved_result"] = false;
      dispatch(inputsActions.update_saved_results("remove_saved_result", result_to_save));
      // dispatch(inputsActions.update_saved_results("delete_result", result.id));
    }
  };

  const loadResultInputs = (result) => {
    set_loading_inputs(true);
    let inputs_keys = [];
    Object.values(input_validation_schema.properties).map((item) => item._id && inputs_keys.push(item._id));
    inputs_keys.filter((item) => item != null || item !== undefined);
    let input_values = pick(result, inputs_keys);
    const inputs_to_load = Object.fromEntries(Object.entries(input_values).filter(([key, value]) => key !== "undefined" && value != null));
    dispatch(inputsActions.update_bulk_inputs(inputs_to_load));
    setTimeout(() => {
      set_loading_inputs(false);
      dispatch(alertActions.success("Inputs from saved result loaded", 3));
    }, 2000);
  };

  return (
    <section>
      <SelectedButtonsWrap>
        <section className="result-header-text">
          <span>Result Actions:</span>
          {!props.saved_result_exists_in_current_results && (
            <section className="saved-result-info">
              <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: 14 }} />
              {<p>Saved results may not reflect current inputs and site boundaries</p>}
            </section>
          )}
        </section>
        {internal_sift ? (
          <section id="download-buttons-tip" className="selected-button-actions">
            {/* <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<BuildOutlined />} onClick={() => props.onPlot(result)} loading={loading_layout} size="small">
                Plot
              </Button>
            </Tooltip> */}

            <Tooltip placement="top" title={project_id ? "Save Result" : "Please Save your project before saving a result"} mouseEnterDelay={0.5}>
              <Button disabled={!project_id} type={result?.saved_result ? "primary" : "secondary"} onClick={() => saveResult(result)} loading={map_loading} size="small">
                {result?.saved_result ? <Star /> : <StarOff />}
                Save Result
              </Button>
            </Tooltip>

            {result.saved_result && (
              <Tooltip placement="top" title={"Load inputs from this saved result"} mouseEnterDelay={0.5}>
                <Button type={"secondary"} onClick={() => loadResultInputs(result)} loading={map_loading || loading_inputs} size="small">
                  Load Inputs
                </Button>
              </Tooltip>
            )}

            <VerticalDivider />

            <Tooltip placement="top" title="Formatted PDF Report" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileZipOutlined />} onClick={() => props.onShowReport(result)} loading={loading_files} size="small">
                {show_live_report ? "Hide PDF Report" : "PDF Report"}
              </Button>
            </Tooltip>

            <ButtonGroup>
              <Tooltip
                placement="top"
                title={
                  editing_disabled
                    ? "Site editing is disabled when running with Inverter Groups or if the site exceeds 100 MWp."
                    : current_plan > 1
                    ? "Edit this Layout"
                    : "Layout Editing available on our Standard and Pro plans."
                }
                mouseEnterDelay={0.5}
              >
                <Popconfirm
                  title="Editing this layout is strictly for map visualization and will not be reflected in this result's performance model."
                  onConfirm={() => props.onShowLayoutEditor()}
                  style={{ width: 250 }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="secondary"
                    size="small"
                    styles={{ borderRadius: "4px 0px 0px 4px" }}
                    disabled={current_plan == 1 || editing_disabled}
                    label={show_layout_editor ? "Close Editor" : "Edit Layout"}
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip placement="top" title={current_plan > 1 ? "Restore original layout" : null} mouseEnterDelay={0.5}>
                <Popconfirm title="Undo all edits?" onConfirm={() => props.undoAllLayoutEditing()} okText="Yes" cancelText="No">
                  <Button type="secondary" size="small" label="Undo" disabled={!undo_available} />
                </Popconfirm>
              </Tooltip>
            </ButtonGroup>

            <Tooltip placement="top" title="Detailed data for selected result" mouseEnterDelay={0.5}>
              <Button
                ghost
                type="secondary"
                icon={<FileZipOutlined />}
                onClick={() => {
                  if (download_lock) {
                    dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_xlsx"));
                  } else {
                    props.onDownloadFiles(result);
                  }
                }}
                loading={loading_files}
                size="small"
              >
                {download_lock ? "Unlock XLSX" : "XLSX"}
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={current_plan > 1 ? "Export layout to DXF" : "Export to CAD is available on our Standard and Pro plans."} mouseEnterDelay={0.5}>
              <Button
                ghost
                enabled={current_plan > 1 ? "true" : "false"}
                type="secondary"
                icon={<AppstoreOutlined />}
                onClick={() => {
                  if (download_lock) {
                    dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_cad"));
                  } else {
                    props.onExportMap("export_dxf");
                  }
                }}
                loading={map_loading}
                size="small"
              >
                {download_lock ? "Unlock CAD" : "CAD"}
              </Button>
            </Tooltip>

            <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_kml")} loading={map_loading} size="small">
                KMZ
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={result.track_mode === 2 ? "SHD Export not available for East-West Facing" : "Export map to SHD"} mouseEnterDelay={0.5}>
              <Button ghost disabled={result.track_mode === 2} type="secondary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_pvsyst")} loading={map_loading} size="small">
                SHD
              </Button>
            </Tooltip>

            <BudgetaryQuote result={result} />

            {/* 
              <Tooltip placement="top" title="Export map to SHD" mouseEnterDelay={0.5}>
                <Button ghost type="secondary" icon={<FileImageOutlined />} onClick={() => console.log("download bq pdf")} loading={map_loading} size="small">
                  BQ PDF
                </Button>
              </Tooltip>
             */}
          </section>
        ) : (
          <section id="download-buttons-tip" className="selected-button-actions">
            {/* <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<BuildOutlined />} onClick={() => props.onPlot(result)} loading={loading_layout} size="small">
                Plot
              </Button>
            </Tooltip> */}

            <Tooltip
              placement="top"
              title={
                !plan_restrictions.can_pdf
                  ? "Result saving is available on our Standard and Pro plans."
                  : cannot_save_collab
                  ? "Result cannot be saved when project is checked out by another"
                  : project_id
                  ? "Save Result"
                  : "Please Save your project before saving a result"
              }
              mouseEnterDelay={0.5}
            >
              <Button disabled={!plan_restrictions.can_pdf || !project_id} type={"secondary"} onClick={() => saveResult(result)} loading={map_loading} size="small">
                {result?.saved_result ? <Star /> : <StarOff />}
                Save Result
              </Button>
            </Tooltip>

            {result.saved_result && (
              <Tooltip placement="top" title={"Load inputs from this saved result"} mouseEnterDelay={0.5}>
                <Button type={"secondary"} onClick={() => loadResultInputs(result)} loading={map_loading || loading_inputs} size="small">
                  Load Inputs
                </Button>
              </Tooltip>
            )}

            <VerticalDivider />

            <Tooltip placement="top" title={plan_restrictions.can_pdf ? "Download Formatted PDF Report" : "Export PDF is available on our Standard and Pro plans."} mouseEnterDelay={0.5}>
              <Button ghost type="secondary" disabled={!plan_restrictions.can_pdf} icon={<FileZipOutlined />} onClick={() => props.onShowReport(result)} loading={loading_files} size="small">
                {show_live_report ? "Hide PDF Report" : "PDF Report"}
              </Button>
            </Tooltip>

            <ButtonGroup>
              <Tooltip
                placement="top"
                title={
                  editing_disabled
                    ? "Site editing is disabled when running with Inverter Groups or if the site exceeds 100 MWp."
                    : current_plan > 1
                    ? "Edit this Layout"
                    : "Layout Editing available on our Standard and Pro plans."
                }
                mouseEnterDelay={0.5}
              >
                <Popconfirm
                  title={"Editing this layout is strictly for map visualization and will not be reflected in this result's performance model."}
                  style={{ width: 250 }}
                  onConfirm={() => props.onShowLayoutEditor()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button ghost type="secondary" size="small" styles={{ borderRadius: "4px 0px 0px 4px" }} disabled={current_plan == 1 || editing_disabled}>
                    {show_layout_editor ? "Close Editor" : "Edit Layout"}
                  </Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip placement="top" title={current_plan > 1 ? "Restore original layout" : null} mouseEnterDelay={0.5}>
                <Popconfirm title="Undo All edits to Layout?" onConfirm={() => props.undoAllLayoutEditing()} okText="Yes" cancelText="No">
                  <Button type="secondary" size="small" label="Undo" disabled={!undo_available || loading_layout} />
                </Popconfirm>
              </Tooltip>
            </ButtonGroup>

            <Tooltip placement="top" title="Detailed data for selected result" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileZipOutlined />} onClick={() => props.onDownloadFiles(result)} loading={loading_files} size="small">
                XLSX
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={plan_restrictions.can_dxf ? "Export layout to DXF" : "Export to CAD is available on our Standard and Pro plans."} mouseEnterDelay={0.5}>
              <Button ghost disabled={!plan_restrictions.can_dxf} type="secondary" icon={<AppstoreOutlined />} onClick={() => props.onExportMap("export_dxf")} loading={map_loading} size="small">
                CAD
              </Button>
            </Tooltip>

            <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_kml")} loading={map_loading} size="small">
                KMZ
              </Button>
            </Tooltip>

            <Tooltip
              placement="top"
              title={
                plan_restrictions.can_3ds && track_mode !== 2 && !undo_available
                  ? "Export map to SHD"
                  : result.track_mode === 2
                  ? "SHD Export not available for East-West Facing"
                  : undo_available
                  ? "SHD Export not available when layout had been edited. Undo all edits to layout to enable SHD Export."
                  : "Export to SHD is available on our Standard and Pro plans."
              }
              mouseEnterDelay={0.5}
            >
              <Button
                ghost
                type="secondary"
                disabled={!plan_restrictions.can_3ds || result.track_mode === 2 || undo_available}
                icon={<FileImageOutlined />}
                onClick={() => props.onExportMap("export_pvsyst")}
                loading={map_loading}
                size="small"
              >
                SHD
              </Button>
            </Tooltip>
          </section>
        )}
      </SelectedButtonsWrap>

      <ResultDetailWrapper do_finance={result.do_finance}>
        <section id="masonry-effect">
          {Object.keys(result.summary).map((key, index) => {
            if (key === "tax_depriciation" && result.metric === 0) {
              return;
            } else if (Object.values(result.summary[key]).length > 0) {
              return (
                <section className="detail-box" key={index}>
                  <h3>{result.summary[key].label}</h3>
                  {Object.values(result.summary[key].value).map((item, index) => {
                    if (internal_sift && (item.label === "DC:AC" || item.label === "MW ac")) {
                      return;
                    } else {
                      if (item && item.value !== "") {
                        return (
                          <section className="detail-row" key={index}>
                            <label>{item.label}</label>
                            <span>{item.value}</span>
                          </section>
                        );
                      }
                    }
                  })}
                </section>
              );
            }
          })}
        </section>
      </ResultDetailWrapper>
    </section>
  );
};

export { ResultDetail, Star };
