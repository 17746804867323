import { createGlobalStyle } from "styled-components";

import { DropdownStyles, TooltipArrowStyles } from "../components";
import { MapboxContextMenuStyles } from "../components/MapboxMap/MapboxContext/styles";

const GlobalStyles = createGlobalStyle`

#root {
  /* colors */
  --main-background-Color: #fff;
  --primary-brand-color: ${(props) => (props.internal_sift ? "#60de4f" : "#002bcb")};
  --primary-brand-color-alpha: #002bcba9;
  --secondary-brand-color: #3d3d3d;
  --primary-text-color: #333;
  --primary-text-white: #fff;
  --gutter-background-color: #e5e7e7;
  --subtle-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  --input-focus-box-shadow: ${(props) => (props.internal_sift ? "rgba(96, 222, 79,  .2)" : "rgba(0, 43, 203, .2)")};
  --light-border: rgb(68, 68, 68, .25);
  /* animation timing */
  --duration: 0.6s;
  --easing: cubic-bezier(0.5, 0, 0.5, 1);
  --antd-transtion: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  /* --easing-spring: cubic-bezier(0.18, 0.89, 0.32, 1.28); */
  --easing-spring: cubic-bezier(0.23, 1, 0.32, 1.05);
  --background-highlight-color: ${(props) => (props.internal_sift ? "rgba(96 ,222, 79, .6)" : "rgba(0	,43,	203, .2)")};
  --background-hover-color: ${(props) => (props.internal_sift ? "rgba(96 ,222, 79, .2)" : "rgba(0	,43,	203, .2)")};

  --map-manager-hover-color: ${(props) => (props.internal_sift ? "rgba(96 ,222, 79, .2)" : "rgba(0	,43,	203, .2)")};

  // inteneral SIFT
  --background-gradient: linear-gradient(-40deg, rgb(96, 222, 79), rgb(0, 192, 182), rgb(0, 43, 219));
  --canopy-color: rgba(0, 213, 231, 1);

  --success: #52C41A;
  --warning: #FAAD14;
  --error: #FF4D4F;
  --info: #1677FF;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html.home {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: var(--gutter-background-color);

  font-size: 10px;
}
body {
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: hidden;
  background-color: var(--gutter-background-color);
  scroll-behavior: smooth;
  overflow-y: hidden; 
  margin: 0;
  color: #333;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';  
}

a.home {
  text-decoration: none;
  font-family: "Muli", sans-serif;
  color: var(--link-color);
  font-size: 1.6em;
  letter-spacing: 0.1rem;
  font-weight: 800; /* bold */
}

h1.home,
h2.home,
h3.home,
h4.home,
h5.home,
h6.home {
  font-family: "Muli", sans-serif;
}

h1.home {
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 3.5em;
  text-shadow: 5px 5px var(--text-shadow-primary), -3px -3px var(--text-shadow-secondary);
}

p.home {
  line-height: 1.75em;
  font-size: 1.8em;
  font-family: "Muli", sans-serif;
  font-weight: 400; /* regular */
  color: #333;
}



ul.home {
  list-style: none;
}

#map {
  position: relative ;
}


.wrapper {
  height: 100%;
}

// some style overrides using prop because antd modal is not in the root scope 
.ant-typography.ant-typography-secondary {
  color: ${(props) => (props.internal_sift ? "#60de4f" : "#002bcb")};;
}

span.ant-typography.ant-typography-secondary {
  font-weight: 400;
  color: ${(props) => (props.internal_sift ? "#60de4f" : "#002bcb")};;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

h4.ant-typography {
  color: #333;
  font-weight: 400px;
}

table {
  border-collapse: collapse;
}

/* SIFT BASE LAYOUT */
.base-page.ant-layout {
  /* display: flex; */
  width: 100%;
  min-height: 100vh;
  /* min-height: 0; */
  /* background: #efefef; */
  background: var(--gutter-background-color);
  align-items: center;
  justify-content: center;
  display: flex;
  flex: auto;
  flex-direction: column;
}
/* .footer {
  height: 150px;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 1300px;
  background-color: #3d3d3d;
  color: white;
  text-align: center;
} */

.push {
  height: 75px;
  background: white;
}
/* The sidebar menu */
.sidenav {
  min-height: 69%;
  width: 240px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 101px; /* Stay at the top */
  left: 47px;
  background-color: #fff; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}
.signup-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

/* Team page */
.centered-wrapper {
  text-align: center;
}
.centered-child {
  display: inline-block;
  max-width: 100%;
}

/*.
    Header.
*/
.header {
  max-width: 1300px;
  padding: 0px 2% 0% 2%;
  background: white;
  /* height: 54px; */
  width: 100%;
  /* position: fixed; */
  z-index: 750;
  top: 0px;
  line-height: 64px;
}
.logo-container {
  float: left;
}
.logo {
  width: 237px;
  height: 50.656px;
}
.menu-container {
  float: right;
}
.menu {
  line-height: 50px;
  background: white;
}

/*.
    Content.
*/
.content-container {
  max-width: 1300px;
  width: 100%;
  /* margin-top: 64px; */
  top: 0px;
  -webkit-box-flex: 1;
  flex: auto;
  /* min-height: 0; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 0px 2% 2% 2%;
  background: white;
}
.content {
  line-height: 64px;
  background: #fff;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  min-height: 400px;
}
.app-container {
  max-width: 1300px;
  /* padding: 0px 5% 0% 5%; */
  margin: auto;
  /* height: 100%; */
  min-height: 600px;
  height: auto !important;
  height: 600px;
}
.app-container.sift {
  padding-top: 10px;
}

.login-page {
  background: linear-gradient(110deg, #002bcb 60%, #002bcbc9 60%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

/* Leaflet styles */
.sr-only {
  display: none;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}
.leaflet-left {
  z-index: 500;
}
.leaflet-right {
  z-index: 500;
}
.leaflet-control .leaflet.menu {
  height: 100px;
  width: 50px;
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0 10px 20px, rgb(238, 238, 238) 0 0 0 1px;
  padding: 5px 0;
  min-width: 180px;
}

.leaflet-tooltip {
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  width: 275px;
  /* min-width: 225px;
  max-width: 275px; */
  height: auto;
  white-space: normal;
  text-align: left;
  border: 1px solid var(--primary-brand-color);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* animation-name: springIn;
  animation-duration: 200ms;
  animation-timing-function: var(--easing-spring); */
}

.leaflet-tooltip > .tip-content {
  display: flex;
  align-items: flex-start;
  line-height: 20px;
}

.leaflet-tooltip .tip-content .info-icon {
  margin-right: 8px;
  margin-top: 3px;
}
.leaflet-tooltip img {
  margin: 0px;
}

.leaflet-tooltip .tip-content p {
  color: #333;
  margin: 0px;
  text-align: left;
  white-space: normal;
}

.leaflet-popup-content {
  margin: 5px 20px;
 }

 .leaflet-popup-content-wrapper {
  border-radius: 4px;
 }
 
.leaflet-popup-content-wrapper .offset-tool {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 235px;
}
.leaflet-popup-content-wrapper .offset-tool label {
  color: #333;
  margin: 0px;
  font-size: 12px;
}

.mapboxgl-popup-content {
  /* width: 285px; */
  width: min-content;

  .offset-tool-controls {
    display: flex;
  }
}

.mapboxgl-popup-content .offset-tool .offset-tool-controls input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 140px;
  min-width: 0;
  padding: 2px 8px;
  color: #333;
  font-size: 13px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}


.mapboxgl-popup-content .offset-tool .offset-tool-close {
  position: absolute;
  top: 3px;
  right: 8px;
  display: flex;
}

.mapboxgl-popup-content .offset-tool .offset-tool-close p {
  color: var(--primary-brand-color);
  font-size: 12px;
}

.mapboxgl-popup-content .offset-tool .offset-tool-input {
  display: flex;
  align-items: center;

  > label{
     display: flex;
     width: 85px;
     margin-botton: 3px
  }

  input {
    width: 120px
  }
}
.mapboxgl-popup-content .offset-tool .offset-tool-button {
    height: 16px;
    line-height:14px;
    margin-bottom : 5px;
    outline: none;
}

.mapboxgl-popup-content .offset-tool .offset-tool-close .offset-loading {
  display: flex;
  margin-right: 16px;
}

.mapboxgl-popup-content .offset-tool .offset-tool-close:hover {
  cursor: pointer;
  opacity: 0.8;
}

.mapboxgl-popup-content .offset-tool .offset-tool-buttons {
  display: flex;
}

.mapboxgl-popup-content .offset-tool .offset-tool-buttons button {
  box-sizing: border-box;
  height: 25px;
  margin: 0px 5px;
  color: var(--primary-brand-color);
  background-color: #fff;
  border: 1px solid var(--primary-brand-color);
  border-radius: 3px;
  line-height: 15px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.mapboxgl-popup-content .offset-tool .offset-tool-buttons button:hover {
  cursor: pointer;
  color: #fff;
  background-color: var(--primary-brand-color);
}

.leaflet-touch .leaflet-bar {
  border: none;
}
/* tip animation */
@keyframes spring {
  0% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(1);
  }
}

/* Map css */
.ant-btn.toolbar-button {
  width: 30px;
  height: 30px;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.ant-btn.zoom-button {
  width: 25px;
  height: 25px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 20px;
  vertical-align: 0em;
  color: rgb(0, 0, 0);
  /* border-top-width: 0px; */
  /* border-bottom-width: 0px */
  margin: 1px;
}
.ant-btn.zoom-button > .anticon {
  vertical-align: 0em;
}
.ant-btn.zoom-button > .anticon:hover {
  color: var(--primary-brand-color);
}
.ant-tooltip {
  max-width: 325px !important;
}

.ant-tooltip-content .ant-tooltip-inner {
  padding: 2px 3px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-height: 15px;
  word-wrap: break-word;
  font-size: smaller;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline .ant-menu-item {
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 25px;
  height: 25px;
  width: 125px;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 75px;
  padding: 0;
  border-right: 0;
  transform-origin: 0 0;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 25px;
  height: 25px;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical .ant-menu-item {
  text-align: left;
  padding-left: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 25px;
  height: 25px;
  width: 125px;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical .ant-menu-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title {
  text-align: left;
  margin-bottom: 0px;
  padding-left: 5px;
  margin-top: 0px;
  line-height: 25px;
  height: 25px;
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.ant-menu-submenu-placement-rightTop .ant-menu-item {
  text-align: left;
  margin-bottom: 0px;
  padding-left: 5px;
  margin-top: 0px;
  line-height: 25px;
  height: 25px;
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.ant-menu-submenu-placement-leftTop .ant-menu-item {
  text-align: left;
  margin-bottom: 0px;
  padding-left: 5px;
  margin-top: 0px;
  line-height: 25px;
  height: 25px;
}
.ant-menu.ant-menu-sub.ant-menu-vertical {
  border-radius: 0px;
}

.ant-tabs-bar.ant-tabs-top-bar {
  margin: 0 0 6px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab-active.ant-tabs-tab {
  border-color: rgb(170, 169, 170);
  background: rgb(255, 255, 255);
  color: var(--primary-brand-color);
  padding-bottom: 1px;
}
.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab {
  background: #e2e2e2;
}

.account .ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 15px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}

.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
}

.editable-row {
  background: #ffffff;
  cursor: pointer;
}
.editable-row:hover {
  /* background: #3d3d3d46; */
  cursor: pointer;
}
.editable-row-selected {
  background: #3d3d3d23;
  cursor: pointer;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #3d3d3d25;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px;
}

.ant-row.ant-form-item {
  margin-bottom: 0px;
}
.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
  margin: 0px;
  border-bottom: 0px solid #e8e8e8;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  left: 10%;
}
.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
  margin-bottom: 0px;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 5px 10px;
  height: 40px;
}

.toolbar-control {
  width: 25px;
  height: 25px;
}
.zoom-control {
  padding: 3px;
  width: 100%;
  height: 100%;
  vertical-align: 0em;
}
.toolbar-spacing {
  width: 2px;
  height: 5px;
}
.toolbar {
  z-index: 700;
}

.error-triangle {
  width: 20%;
}
.filler-spacing {
  height: 5px;
}
.loader {
  text-align: center;
  padding: 75px;
  height: 100%;
  /* border-radius: 4px; */
  /* margin-bottom: 20px; */
  /* padding: 30px 50px; */
  /* margin: 20px 0; */
}

/* Inputs stuff */
.mod-row {
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
}
.mod-row.type {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mod-row.iam {
  height: 250px;
}
.mod-row.flex {
  display: flex;
  width: 50%;
  left: 105px;
  max-height: 232px;
  overflow-y: auto;
  /* border: 1px solid rgba(160, 160, 160, 0.541);   */
}
.mod-row.weather {
  display: flex;
  width: 500px;
  height: 700px;
  left: 145px;
  max-height: 500px;
  overflow-y: hidden;
  overflow-x: hidden;
  border: none;
}
.weather-overview {
  border: 1px solid rgba(160, 160, 160, 0.541);
  padding: 1%;
  border-bottom-width: 0px;
  margin-bottom: -2px;
  background: rgb(245, 245, 245);
  width: 401px;
}

.mod-row.pagination {
  display: flex;
  width: 500px;
  left: 105px;
  border: 1px solid rgba(160, 160, 160, 0.541);
}

.mod-input-label {
  text-align: right;
  /* top: 5px; */
  /* max-width: 100px; */
  width: 100px;
  position: absolute;
  line-height: 31px;
}
.mod-input-label.type {
  width: auto;
  text-align: left;
}

.mod-input-label.project {
  width: auto;
}

.mod-input-label.selector {
  width: 150px;
  left: -20px;
}

.mod-input-label.finance {
  width: 128px;
}
.mod-input-label.performance {
  width: 120px;
}
.mod-input-label-long {
  text-align: right;
  /* top: 5px; */
  /* max-width: 157px; */
  width: 157px;
  position: absolute;
  line-height: 31px;
}
.mod-input-label-long.finance {
  width: 130px;
  font-weight: bold;
}
.mod-input-label-right {
  text-align: right;
  /* top: 5px; */
  left: 256px;
  max-width: 110px;
  width: 110px;
  position: absolute;
  line-height: 31px;
}
.mod-input-label-right.weather {
  left: 181px;
  line-height: 31px;
}
.mod-input-label-right.rack {
  left: 299px;
  width: 141px;
  max-width: 141px;
}
.ant-input.mod-input-default {
  background: #fafafa;
}
.mod-input {
  left: 105px;
  width: 200px;
  position: absolute;
  line-height: 31px;
}
.mod-input.text {
  line-height: 31px;
  left: 110px;
}
.mod-input.rack {
  left: 164px;
  width: 125px;
  position: absolute;
  line-height: 31px;
}
.mod-input.radio {
  left: 132px;
  width: 350px;
  position: absolute;
}
.mod-input.radio.finance {
  left: 135px;
}
.mod-input.radio.weather {
  left: 106px;
  width: 350px;
  position: absolute;
}
.mod-input.weather-name {
  width: 300px;
}
.mod-input.selector {
  left: 134px;
  width: 100%;
  position: absolute;
}
.mod-input-sliders {
  position: absolute;
  left: 188px;
  width: 52px;
  text-align: center;
  line-height: 31px;
  top: -2px;
}
.mod-input-steps {
  position: absolute;
  left: 334px;
  width: 52px;
  text-align: center;
  line-height: 31px;
}
.mod-input-total {
  position: absolute;
  left: 412px;
  width: 31px;
  text-align: center;
  line-height: 31px;
}

/* CONFIG INPUTS */
.mod-row.config {
  height: 27px;
  margin-top: -20px;
}
.mod-input-small-left.mps {
  left: 125px;
  width: 115px;
}
.mod-input-small-left.config {
  left: 115px;
  width: 87px;
}
.mod-input-label.config-header {
  width: 338px;
  /* left: 34px; */
  line-height: 7px;
  text-align: left;
  top: 5px;
}

.mod-input-label.config {
  width: 105px;
}
.mod-input.config {
  left: 115px;
}
.mod-input.config.gcr_range {
  left: 122px;
}
.mod-input.config.fixed {
  text-align: center;
}
.mod-input.config.pitch_range {
  left: 115px;
}
.mod-input.config.switch {
  left: 115px;
  width: 50px;
}

.config-worker-stat {
  position: absolute;
  color: "black";
  left: 339px;
}

.dcac-display {
  position: relative;
  left: 400px;
}
.mod-input-small-extra.config {
  left: 165px;
  width: 134px;
}
.mod-input-small-extra.config.road {
  width: 244px;
  max-width: 244px;
}
.mod-input-small-left-question {
  position: absolute;
  left: 211px;
  padding-left: 15px;
  line-height: 31px;
}
.mod-input-small-left-question.module {
  left: 512px;
}
.mod-input-small-left-question.shade {
  left: 325px;
}
.mod-input-small-left-question.tilts {
  left: 275px;
  z-index: 1000;
}
.mod-input-small-left-question.bifacial {
  left: 537px;
  z-index: 1000;
}
.mod-input-small-left-question.schedule {
  left: 0px;
  z-index: 1000;
}
.mod-input-small-left-question.specs {
  left: 550px;
}
.mod-input-small-left-question.location {
  left: 410px;
}
.mod-input-small-left-question.source {
  left: 200px;
}
.mod-input-small-left-question.weather-source {
  left: 382px;
}
.mod-input-small-left-question.finance {
  left: 265px;
}
.mod-input-small-left-question.upload-custom {
  left: 166px;
  top: 15px;
}
.mod-input-small-left-question.config {
  left: -13px;
  z-index: 1000;
}

.ant-slider.config-slider .ant-slider-track-1:hover {
  background-color: var(--primary-brand-color);
}
.ant-slider:hover .ant-slider-track-1 {
  background-color: var(--primary-brand-color);
}
.ant-slider-track.ant-slider-track-1 {
  position: absolute;
  height: 4px;
  background-color: var(--primary-brand-color);
  border-radius: 4px;
  transition: none;
}


.question-map {
  margin-top: 5px;
  border-radius: 10px;
  width: 19px;
  height: 19px;
}

.mod-input.warning {
  left: 115px;
}
.default-warning {
  margin-left: 10px;
  background: #fffbe6;
  border-radius: 4px;
  height: 32px;
  position: absolute;
  line-height: 38px;
  width: 31px;
  text-align: center;
  border: 1px solid #ffe58f;
}

div.ant-input-number {
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 65px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.mod-label {
  color: rgba(0, 0, 0, 0.85);
  line-height: 31px;
}
.mod-label.type {
  padding-left: 4px;
  padding-right: 10px;
}
.mod-label.project {
  color: rgba(0, 0, 0, 0.85);
  font-size: medium;
  line-height: 38px;
}

.mod-label.config-header {
  color: #5f5f5f;
  line-height: 15px;
}
.mod-input.config-header {
  left: 390px;
}

.mod-input-small-left {
  left: 105px;
  max-width: 135px;
  position: absolute;
}
.mod-input-small-left.performance {
  left: 127px;
}
.mod-input-small-left.finance {
  left: 135px;
}
.mod-input-small-left.weather {
  width: 117px;
  max-width: 200px;
}
.mod-input-long-name {
  left: 127px;
  width: 200px;
  position: absolute;
}
.mod-input-long-name.finance {
  left: 135px;
}
.mod-input-small-right {
  left: 370px;
  max-width: 135px;
  position: absolute;
}
.mod-input-small-right.weather {
  left: 299px;
  width: 117px;
  max-width: 200px;
}
.mod-input-small-right.rack {
  left: 446px;
  width: 100px;
}
.mod-input-small-extra {
  left: 292px;
  max-width: 135px;
  position: absolute;
}

.ant-spin-nested-loading {
  position: inherit;
}

.custom-sheet {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.Checkout {
  width: 100%;
}

.payment-header {
  font-weight: bold;
  border: 1px solid #444444;
  text-align: center;
  padding: 1%;
  /* background: linear-gradient(180deg, #e2e2e2 10%, #a1a1a1c9 100%); */
}
.payment-cell {
  border: 1px solid #a0a0a0;
  text-align: center;
  padding: 1%;
}

.summary-text {
  font-size: 18px;
  padding: 2px 0;
}
.plan-overview {
  padding: 2%;
  width: 100%;
  position: relative;
}
.plan-card-cost {
  color: rgb(255, 72, 0);
  position: absolute;
  bottom: 70px;
  font-size: 20pt;
  width: 93%;
  text-align: center;
}
.plan-card-subtext {
  position: absolute;
  bottom: 57px;
  font-size: 9pt;
  width: 93%;
  text-align: center;
}

.email-verify {
  text-align: center;
}
.email-verify-response {
  padding-top: 5%;
  padding-left: 25%;
  padding-right: 25%;
}

.file-import-body {
  height: 200px;
  padding-top: 5px;
  padding-bottom: 5px;

  .clear-import-button {
    position: absolute;
     right: 3px; 
     padding: 5px
  }
}
.file-import-ready {
  text-align: center;
  width: 100%;
  height: 190px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding:10px;
}
.file-import-image {
  color: #ff6d29;
  font-size: 48px;
  margin: 20px 0px 10px 0px;
}
.file-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
}
.file-upload-error {
  color: red;
}

.map-uploaded .ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
}
.ant-upload.ant-upload-drag.map-uploader {
  border: 0;
}
/* .map-uploader .ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #ffffff;
  border: 1px solid #d9d9d900;
  border-radius: 0px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.map-uploader .ant-upload.ant-upload-drag .ant-upload {
  padding: 0px;
}


.map-uploader .ant-upload.ant-upload-drag .anticon-plus {
  color: rgb(0, 0, 0);
  font-size: 20px;
  vertical-align: 0em;
  transition: all 0.3s;
}
.map-uploader .ant-upload.ant-upload-drag:hover .anticon-plus {
  color: #002bcb;
} */

/* ANTD DRAGGER OVERRIDE */
.ant-upload.ant-upload-drag.map-uploader:hover,
.ant-upload.ant-upload-drag.map-uploader:focus {
  border: 0;
}
.map-uploader .ant-upload.ant-upload-btn {
  padding: 0px;
}
.map-uploader .ant-upload-text {
  color: rgba(51, 51, 51) !important;
}
.sift-dragger p.ant-upload-text {
  color: rgba(51, 51, 51) !important;
}

/* PROJECT MANAGER SAVE FORM CSS */
.ant-form.save-project-form {
  min-width: 0px;
}
.save-project-form .ant-row.ant-form-item {
  width: 215px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.map-message-box {
  display: table;
  padding-left: 3px;
  text-align: left;
  background: #ffffffb0;
  margin-bottom: 2px;
  padding: 2px;
  font-weight: bold;
}
.map-message-box.loading {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  width: max-content;
  color: #000;
}
.map-message-box.bottom {
  /* display: inline-block; */
}
.map-message-box a {
  color: var(--primary-brand-color);
}
.map-message-box a:hover {
  color: #fd723b;
}

.account-tab .ant-tabs-nav.ant-tabs-nav-animated {
  position: relative;
  float: right;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.account .ant-divider-inner-text {
  color: #333;
  display: inline-block;
  padding: 0 1em;
}

.results-draw a {
  color: var(--primary-brand-color);
}

.results-draw a:hover {
  color: #fd723b;
}

.result-table .ant-table-column-sorters {
  color: #333;
  display: inline-flex;
  align-items: center;
}

.formula {
  padding-left: 30%;
}

.ant-table-column-has-actions.ant-table-column-has-sorters {
  padding: 0px;
}

.home-sift-video-div {
  flex: 0 0 50%;
  max-width: 100%;
  align-content: center;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.home-sift-video-holder {
  max-width: 700px;
  max-height: 350px;
  height: 350px;
  left: 50%;
  transform: translate(-50%, 0%);
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.home-sift-video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  top: 0;
  bottom: 0;
  left: 0;
  border: 0;
}
.footer-left {
  width: 50%;
  left: 25%;
  position: absolute;
  text-align: left;
}
.footer-right {
  text-align: left;
  position: absolute;
  left: 50%;
}

.post-container h1,
h2,
h3,
h4,
h5 {
  color: #555;
  font-weight: 600;
  margin-bottom: 1em;
  margin-top: 1.5em;
  -webkit-font-smoothing: subpixel-antialiased;
}
.post-container h3 {
  display: block;
  font-size: 1.3em;
  font-weight: 700;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.post-container ul,
ol {
  margin-bottom: 1.25em;
}
.post-container li {
  margin-bottom: 0.25em;
}

.post-container p {
  color: #555;
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 16px;
  line-height: 1.58;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: -0.003em;
  display: block;
}

.post-container 
  /* Responsive default image width */
  img {
  max-width: 100%;
  height: auto;
}

/* Responsive floating */
@media only screen and (min-width: 720px) {
  .butter-float-left {
    float: left;
    margin: 0px 10px 10px 0px;
  }

  .butter-float-right {
    float: right;
    margin: 0px 0px 10px 10px;
  }
}

.post-container 
  /* Image caption */
  figcaption {
  font-style: italic;
  text-align: center;
  color: #ccc;
}

.post-container p code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.post-container pre {
  display: block;
  padding: 1em;
  margin: 0 0 2em;
  font-size: 1em;
  line-height: 1.4;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.tech-doc .ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.2;
}
.tech-doc .ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 0px;
}
.tech-doc .ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 0px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.ant-drawer-body img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0px;
}
.drawer-content {
  -webkit-column-width: 225px;
  -moz-column-width: 225px;
  column-width: 225px;
}
.drawer-sub-content {
  -webkit-column-width: 200px;
  -moz-column-width: 200px;
  column-width: 200px;
}
.drawer-content-help {
  width: 77%;
}

/* .leaflet-bottom.leaflet-right {
  position: absolute;
  z-index: 400;
  pointer-events: none;
  bottom: 10px;
} */
/* .leaflet-bottom.leaflet-right .leaflet-control {
  margin-bottom: 170px;
} */

/* error popup overrides */
.ant-result.ant-result-error.error-results {
  padding-top: 0px;
  padding-bottom: 0px;
}
/* .ant-modal-mask {
  z-index: 1002 !important;
}
.ant-modal-wrap {
	pointer-events: none !important;
  z-index: 1002 !important;
} */

.fade-leave-active {
  animation-duration: 0s;
}
.sift-modal {
   animation-duration: 0s;
}

/* .sift-modal {
  -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    transition: none !imporant ;
} */

/* sift modal generics */
.sift-modal .ant-modal-title {
  color: rgba(51, 51, 51) !important;
}
.sift-modal .ant-modal-close-x {
  color: rgba(51, 51, 51) !important;
}
.sift-modal .ant-modal-body {
  /* padding: 44px 24px 24px 24px; */
  padding: 25px;
}
.sift-modal .ant-modal-confirm-title {
  color: rgba(51, 51, 51) !important;
}
.sift-modal .ant-modal-confirm-close-x {
  color: rgba(51, 51, 51) !important;
}
.sift-modal .ant-modal-confirm-body {
  padding: 44px 24px 24px 24px;
}

.sift-modal-error .ant-modal-body {
  padding: 15px;
}

/* error modal */
.error-modal .ant-modal-title {
  color: rgba(51, 51, 51) !important;
}

/* helper drawer */
.helper-drawer .ant-drawer-title {
  color: rgba(51, 51, 51) !important;
}

/* ALERT MSG */
.sift-alert .ant-alert-message {
  color: rgba(51, 51, 51) !important;
}
.sift-alert .ant-alert-error {
  color: rgba(51, 51, 51) !important;
}

/* SAVE / SAVE AS BUTTON IN MODAL */
.ant-btn.login-form-button {
  margin: 0 auto;
  width: 100%;
}

/* SIFT RESULT ANTD OVERRIDE */
.sift-result .ant-result-title {
  color: rgba(51, 51, 51) !important;
}

.sift-result .ant-result-subtitle {
  color: rgba(51, 51, 51) !important;
}

/* HIGH LEVEL SIFT SPINNER/LOADER */
.ant-spin.sift-loader {
  margin: 0 auto;
  padding-top: 150px;
  width: 100%;
  font-size: 16px;
}

.project-table .ant-table.ant-table-bordered thead > tr > th {
  color: #333;
}
.sift-table .ant-table.ant-table-bordered thead > tr > th {
  color: #333;
}

/* PROJECT MANAGER CSS */

.ant-table-header {
  max-height: 40px;
}
.ant-table table {
  visibility: visible !important;
  table-layout: fixed !important;
}

.ant-table-wrapper.project-table .ant-table-header table {
  visibility: visible !important;
  table-layout: auto !important;
}


.ant-table-wrapper.project-table .ant-table-body table {
  width: auto !important;
  min-width: 100% !important;
  table-layout: auto !important;
}

.ant-table-wrapper.project-table .ant-table thead > tr > th {
  color: #333;
}
/* SIFT import modal */
.sift-modal.import .ant-modal-body {
  padding: 24px 24px 24px 24px;
}
.sift-modal.import .ant-tabs-bar {
  text-align: center;
}

.ant-upload-drag-container {
  height: 100%;
}

.ant-notification-topRight {
  right: 35px !important;
}

.ant-message-info {
  display: flex;
}

// ADDED FOR TOPO SCALE/CONTROLS IN MAP
.leaflet-bottom.leaflet-right {
  position: absolute;
  z-index: 400;
  pointer-events: none;
  bottom: 5px;
}
.topo-map-label {
  display: block;
  text-align: left;
}
.topo-controls {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 6px;
  pointer-events: all;

  .topo-button {
    margin-left: 3px;
    margin-right: 3px;
  }

  .topo-control-row {
    display: grid;
  }
}


@media (max-width: 1024px) {
  .ant-modal-body {
    padding: 10px !important;
  }

  .ant-result {
    padding: 15px 10px !important;
  }

  .ant-btn.toolbar-button {
    width: 35px;
    height: 35px;
    margin-right: 6px;
  }
}

@media (max-width: 1024px) {
  .ant-alert-with-description {
    padding: 20px;
  }

  .ant-alert-with-description .ant-alert-icon {
    top: 24px;
    left: 10px;
    font-size: 18px;
  }

  .ant-alert-message {
    left: 25px;
    position: relative;
  }
}

@media (max-width: 992px) {
  #empty-payment-container .ant-empty-img-default {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ant-alert-with-description {
    padding: 5px;
  }

  .ant-alert-message {
    left: 22px;
    position: relative;
  }

  .ant-alert-with-description .ant-alert-icon {
    top: 10px;
    left: 5px;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .ant-card-body {
    padding: 5px;
  }

  .ant-alert-with-description span ul {
    padding: 5px 15px;
  }
}


/* **** Max-Width Query *** */

// These are styles for our Tooltip component. They have to live here so that the styles are applied when the tooltip component is appended to #root.
${TooltipArrowStyles}
${DropdownStyles}
${MapboxContextMenuStyles}

// mainly used for the export cad dropdown option in the map tools export menu
.disabled-li {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

/* .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  top: 32px;
} */

// Map handle customization
#map.custom-vertex .marker-icon { 
   background-color: var(--primary-brand-color);
   border-radius: 0px; 
   border: none; 
   height:12px !important;
   width: 12px !important;
 } 

 .mapboxgl-ctrl-bottom-left {
  display: none; 
 }
 .mapboxgl-ctrl button:not(:disabled):hover {
    background-color: rgba(0, 43, 203, .5);
}

.image-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: nwse-resize;
}

canvas.mapboxgl-canvas {
  outline: none;
}

.ant-message-notice-content {
  max-width: 650px;
}


`;

export { GlobalStyles };
