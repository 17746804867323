const cleanSiftInputs = (inputs) => {
  let temp_inputs = { ...inputs };

  temp_inputs.technology = parseInt(temp_inputs.technology);
  temp_inputs.mod_rating = parseFloat(temp_inputs.mod_rating);
  temp_inputs.mlm_D2MuTau = parseFloat(temp_inputs.mlm_D2MuTau);
  temp_inputs.mlm_E_g = parseFloat(temp_inputs.mlm_E_g);
  temp_inputs.mlm_I_mp_ref = parseFloat(temp_inputs.mlm_I_mp_ref);
  temp_inputs.mlm_I_sc_ref = parseFloat(temp_inputs.mlm_I_sc_ref);
  temp_inputs.mlm_Length = parseFloat(temp_inputs.mlm_Length);
  temp_inputs.mlm_N_diodes = parseFloat(temp_inputs.mlm_N_diodes);
  temp_inputs.mlm_N_parallel = parseFloat(temp_inputs.mlm_N_parallel);
  temp_inputs.mlm_N_series = parseFloat(temp_inputs.mlm_N_series);
  temp_inputs.mlm_R_s = parseFloat(temp_inputs.mlm_R_s);
  temp_inputs.mlm_R_sh0 = parseFloat(temp_inputs.mlm_R_sh0);
  temp_inputs.mlm_R_shexp = parseFloat(temp_inputs.mlm_R_shexp);
  temp_inputs.mlm_R_shref = parseFloat(temp_inputs.mlm_R_shref);
  temp_inputs.mlm_S_ref = parseFloat(temp_inputs.mlm_S_ref);
  temp_inputs.mlm_T_c_fa_alpha = parseFloat(temp_inputs.mlm_T_c_fa_alpha);
  temp_inputs.mlm_T_ref = parseFloat(temp_inputs.mlm_T_ref);
  temp_inputs.mlm_V_mp_ref = parseFloat(temp_inputs.mlm_V_mp_ref);
  temp_inputs.mlm_V_oc_ref = parseFloat(temp_inputs.mlm_V_oc_ref);
  temp_inputs.mlm_Width = parseFloat(temp_inputs.mlm_Width);
  temp_inputs.mlm_alpha_isc = parseFloat(temp_inputs.mlm_alpha_isc);
  temp_inputs.mlm_beta_voc_spec = parseFloat(temp_inputs.mlm_beta_voc_spec);
  temp_inputs.mlm_mu_n = parseFloat(temp_inputs.mlm_mu_n);
  temp_inputs.mlm_n_0 = parseFloat(temp_inputs.mlm_n_0);
  temp_inputs.mod_width = parseFloat(temp_inputs.mod_width);
  temp_inputs.mod_height = parseFloat(temp_inputs.mod_height);
  temp_inputs.mod_area = _.round(temp_inputs.mod_width * temp_inputs.mod_height, 2);

  let combine_iam = [];
  temp_inputs.module_iam_ang.forEach((ang, index) => {
    temp_inputs.module_iam_ang[index] = parseFloat(ang);
  });
  temp_inputs.module_iam_eff.forEach((eff, index) => {
    temp_inputs.module_iam_eff[index] = parseFloat(eff);

    combine_iam.push([temp_inputs.module_iam_ang[index], temp_inputs.module_iam_eff[index]]);
  });
  // now that we have the IAM arrays fixed as floats, lets combine, sort, and split again
  combine_iam.sort(function (a, b) {
    if (a[0] === b[0]) {
      return a[1] - b[1];
    }
    return a[0] - b[0];
  });
  let stringArray = combine_iam.map(JSON.stringify);
  let uniqueStringArray = new Set(stringArray);
  temp_inputs.module_iam_ang = [];
  temp_inputs.module_iam_eff = [];
  Array.from(uniqueStringArray, JSON.parse).forEach((row) => {
    temp_inputs.module_iam_ang.push(row[0]);
    temp_inputs.module_iam_eff.push(row[1]);
  });

  // field for bifacial patch
  temp_inputs.bi_bifaciality = parseFloat(temp_inputs.bi_bifaciality);
  temp_inputs.mod_area = parseFloat(temp_inputs.mod_area);

  // INVERTER

  if (temp_inputs.simple_inverter === 1) {
    temp_inputs.simple_inverter_dcac = parseFloat(temp_inputs.simple_inverter_dcac);
  }

  let combine_temp_derate = [];
  temp_inputs.derate_maxoutput.forEach((output, index) => {
    temp_inputs.derate_maxoutput[index] = parseFloat(output);
  });
  temp_inputs.derate_temps.forEach((temp, index) => {
    temp_inputs.derate_temps[index] = parseFloat(temp);

    combine_temp_derate.push([temp_inputs.derate_maxoutput[index], temp_inputs.derate_temps[index]]);
  });

  let stringTemps = combine_temp_derate.map(JSON.stringify);
  let uniqueStringTempArray = new Set(stringTemps);
  temp_inputs.derate_maxoutput = [];
  temp_inputs.derate_temps = [];
  Array.from(uniqueStringTempArray, JSON.parse).forEach((row) => {
    temp_inputs.derate_maxoutput.push(row[0]);
    temp_inputs.derate_temps.push(row[1]);
  });

  temp_inputs.inv_pd_eff_pout.forEach((pout, index) => {
    temp_inputs.inv_pd_eff_pout[index] = parseFloat(pout);
  });
  temp_inputs.inv_pd_efficiency.forEach((eff, index) => {
    temp_inputs.inv_pd_efficiency[index] = parseFloat(eff);
  });

  temp_inputs.inv_pd_vdcmax = parseFloat(temp_inputs.inv_pd_vdcmax);
  temp_inputs.inv_pd_pacokw = parseFloat(temp_inputs.inv_pd_pacokw);
  temp_inputs.inv_pd_pnt = parseFloat(temp_inputs.inv_pd_pnt);
  temp_inputs.inv_rating = parseFloat(temp_inputs.inv_rating);
  temp_inputs.maxEff = parseFloat(temp_inputs.maxEff);
  temp_inputs.mppt_hi_inverter = parseFloat(temp_inputs.mppt_hi_inverter);
  temp_inputs.mppt_low_inverter = parseFloat(temp_inputs.mppt_low_inverter);
  temp_inputs.inv_pnom = parseFloat(temp_inputs.inv_pnom);
  temp_inputs.pthresh = 0;

  // RACKING
  temp_inputs.racks.forEach((rack, index) => {
    if (isNaN(parseFloat(rack.xdim)) || isNaN(parseFloat(rack.ydim)) || isNaN(parseFloat(rack.module))) {
      temp_inputs.racks[index].active = 0;
      temp_inputs.racks[index].xdim = 0;
      temp_inputs.racks[index].ydim = 0;
      temp_inputs.racks[index].module = 0;
    } else {
      temp_inputs.racks[index].xdim = parseFloat(rack.xdim);
      temp_inputs.racks[index].ydim = parseFloat(rack.ydim);
      temp_inputs.racks[index].module = parseFloat(rack.module);
    }
  });

  // Rack A check
  if (inputs.racks[0].active === 1) {
    if (inputs.racks[0].module < temp_inputs.racks[1].module && temp_inputs.racks[1].active === 1) errors.push("Rack A is smaller than Rack B. Navigate to the Racking Tab and adjust the Racks.");
    if (inputs.racks[0].module < temp_inputs.racks[2].module && temp_inputs.racks[2].active === 1) errors.push("Rack A is smaller than Rack C. Navigate to the Racking Tab and adjust the Racks.");
  }
  // Rack B check
  if (inputs.racks[1].active === 1) {
    if (inputs.racks[1].module < temp_inputs.racks[2].module && temp_inputs.racks[2].active === 1) errors.push("Rack B is smaller than Rack C. Navigate to the Racking Tab and adjust the Racks.");
  }

  for (var i = 0; i < temp_inputs.racks.length; i++) {
    temp_inputs.racks[i];
  }

  temp_inputs.track_mode = parseInt(temp_inputs.track_mode);
  temp_inputs.en_backtrack = parseInt(temp_inputs.en_backtrack);
  temp_inputs.shade_mode = parseInt(temp_inputs.shade_mode);

  temp_inputs.gap = parseFloat(temp_inputs.gap);
  temp_inputs.rlim = parseFloat(temp_inputs.rlim);
  temp_inputs.Fshd_StringSteps = parseFloat(temp_inputs.Fshd_StringSteps);
  temp_inputs.Fshd_Celltrav = parseFloat(temp_inputs.Fshd_Celltrav);
  // 2 new fields for Bifacial patch
  temp_inputs.bi_groundClearanceHeight = parseFloat(temp_inputs.bi_groundClearanceHeight);
  temp_inputs.bi_transmissionFactor = parseFloat(temp_inputs.bi_transmissionFactor);
  temp_inputs.bi_structureShadeFactor = parseFloat(temp_inputs.bi_structureShadeFactor);
  inputs.rack_grade_limit = parseInt(inputs.rack_grade_limit);

  // this is used to check for min/max GCR errors if the racking is EWF. We need the INITIAL value as later in validation it gets reasigned back to 1 and dual_tilt is enabled.
  temp_inputs.initial_track_mode = temp_inputs.track_mode;
  if (temp_inputs.track_mode === 2) {
    temp_inputs.track_mode = 1;
    temp_inputs.do_dual_tilt = 1;
    temp_inputs.tilts = [temp_inputs.tilt_min];
  }

  temp_inputs.mod_per_string = parseFloat(temp_inputs.mod_per_string);

  // SITE Inputs
  temp_inputs.inverter_limit = parseInt(temp_inputs.inverter_limit);
  temp_inputs.ac_grid_poi_lim = parseFloat(temp_inputs.ac_grid_poi_lim);
  temp_inputs.sazm = parseFloat(temp_inputs.sazm);

  temp_inputs.road_spacing = parseInt(temp_inputs.road_spacing);
  temp_inputs.road_width = parseFloat(temp_inputs.road_width);
  temp_inputs.road_to_rack = parseFloat(temp_inputs.road_to_rack);
  temp_inputs.inv_pad_x = parseInt(temp_inputs.inv_pad_x);
  temp_inputs.inv_pad_y = parseInt(temp_inputs.inv_pad_y);
  temp_inputs.road_to_rack_inv = parseInt(temp_inputs.road_to_rack_inv);
  temp_inputs.designer_margin = parseInt(temp_inputs.designer_margin);

  // PERFORMANCE VALIDATION
  // force convert all performance variables
  temp_inputs.dc_degrade = parseFloat(temp_inputs.dc_degrade);
  temp_inputs.dc_thermal_Uc = parseFloat(temp_inputs.dc_thermal_Uc);
  temp_inputs.dc_thermal_Uv = parseFloat(temp_inputs.dc_thermal_Uv);
  temp_inputs.dc_module_quality_loss = parseFloat(temp_inputs.dc_module_quality_loss);
  temp_inputs.dc_module_lid_loss = parseFloat(temp_inputs.dc_module_lid_loss);
  temp_inputs.dc_module_mismatch = parseFloat(temp_inputs.dc_module_mismatch);
  temp_inputs.dc_strings_mismatch = parseFloat(temp_inputs.dc_strings_mismatch);
  temp_inputs.dc_wiring_loss_at_stc = parseFloat(temp_inputs.dc_wiring_loss_at_stc);
  temp_inputs.bi_back_mismatch = parseFloat(temp_inputs.bi_back_mismatch);
  temp_inputs.power_factor = parseFloat(temp_inputs.power_factor);
  temp_inputs.ac_aux_kw = parseFloat(temp_inputs.ac_aux_kw);
  temp_inputs.ac_wiring_loss_at_stc = parseFloat(temp_inputs.ac_wiring_loss_at_stc);
  temp_inputs.ac_transformer_loss_constant = parseFloat(temp_inputs.ac_transformer_loss_constant);
  temp_inputs.ac_transformer_loss_at_stc = parseFloat(temp_inputs.ac_transformer_loss_at_stc);
  temp_inputs.ac_MV_line_loss_stc = parseFloat(temp_inputs.ac_MV_line_loss_stc);
  temp_inputs.ac_hv_transformer_loss_at_stc = parseFloat(temp_inputs.ac_hv_transformer_loss_at_stc);
  temp_inputs.ac_transmission_loss = parseFloat(temp_inputs.ac_transmission_loss);
  temp_inputs.ac_other_loss = parseFloat(temp_inputs.ac_other_loss);

  temp_inputs.soiling_single = parseFloat(temp_inputs.soiling_single);
  temp_inputs.soiling.forEach((soil, index) => {
    if (temp_inputs.do_monthly_losses === 1) {
      temp_inputs.soiling[index] = parseFloat(soil);
    } else {
      temp_inputs.soiling[index] = temp_inputs.soiling_single;
    }
  });

  temp_inputs.albedo_single = parseFloat(temp_inputs.albedo_single);
  temp_inputs.albedo.forEach((albedo, index) => {
    if (temp_inputs.do_monthly_losses === 1) {
      temp_inputs.albedo[index] = parseFloat(albedo);
    } else {
      temp_inputs.albedo[index] = temp_inputs.albedo_single;
    }
  });

  temp_inputs.en_shade_impact = parseInt(temp_inputs.en_shade_impact);

  if (temp_inputs.track_mode === 2) {
    temp_inputs.track_mode = 1;
    temp_inputs.do_dual_tilt = 1;
    temp_inputs.tilts = [temp_inputs.tilt_min];
  }

  // if (temp_inputs.do_finance === 1) {
  let finance_check = [
    { key: "analysis_period", value: parseInt(temp_inputs.analysis_period), isNaN: isNaN(parseInt(temp_inputs.analysis_period)) },
    { key: "discount_rate", value: parseFloat(temp_inputs.discount_rate), isNaN: isNaN(parseFloat(temp_inputs.discount_rate)) },

    { key: "dc_op_cost", value: parseFloat(temp_inputs.dc_op_cost), isNaN: isNaN(parseFloat(temp_inputs.dc_op_cost)) },
    { key: "ac_op_cost", value: parseFloat(temp_inputs.ac_op_cost), isNaN: isNaN(parseFloat(temp_inputs.ac_op_cost)) },
    { key: "footprint_op_cost", value: parseFloat(temp_inputs.footprint_op_cost), isNaN: isNaN(parseFloat(temp_inputs.footprint_op_cost)) },
    { key: "fixed_op_cost", value: parseFloat(temp_inputs.fixed_op_cost), isNaN: isNaN(parseFloat(temp_inputs.fixed_op_cost)) },
    { key: "lease_cost", value: parseFloat(temp_inputs.lease_cost), isNaN: isNaN(parseFloat(temp_inputs.lease_cost)) },
    { key: "escalation", value: parseFloat(temp_inputs.escalation), isNaN: isNaN(parseFloat(temp_inputs.escalation)) },
    // ...isNan(...customSchedule),
    { key: "ri_power", value: parseFloat(temp_inputs.ri_power), isNaN: isNaN(parseFloat(temp_inputs.ri_power)) },
    { key: "ri_escalation", value: parseFloat(temp_inputs.ri_escalation), isNaN: isNaN(parseFloat(temp_inputs.ri_escalation)) },
    { key: "itc_percent", value: parseFloat(temp_inputs.itc_percent), isNaN: isNaN(parseFloat(temp_inputs.itc_percent)) },
    { key: "debt_percent", value: parseFloat(temp_inputs.debt_percent), isNaN: isNaN(parseFloat(temp_inputs.debt_percent)) },
    { key: "debt_dscr", value: parseFloat(temp_inputs.debt_dscr), isNaN: isNaN(parseFloat(temp_inputs.debt_dscr)) },
    { key: "debt_interest", value: parseFloat(temp_inputs.debt_interest), isNaN: isNaN(parseFloat(temp_inputs.debt_interest)) },
    { key: "debt_tenor", value: parseFloat(temp_inputs.debt_tenor), isNaN: isNaN(parseFloat(temp_inputs.debt_tenor)) },
    { key: "dep_5yrSL", value: parseFloat(temp_inputs.dep_5yrSL), isNaN: isNaN(parseFloat(temp_inputs.dep_5yrSL)) },
    { key: "dep_15yrSL", value: parseFloat(temp_inputs.dep_15yrSL), isNaN: isNaN(parseFloat(temp_inputs.dep_15yrSL)) },
    { key: "dep_20yrSL", value: parseFloat(temp_inputs.dep_20yrSL), isNaN: isNaN(parseFloat(temp_inputs.dep_20yrSL)) },
    { key: "dep_30yrSL", value: parseFloat(temp_inputs.dep_30yrSL), isNaN: isNaN(parseFloat(temp_inputs.dep_30yrSL)) },
    { key: "dep_35yrSL", value: parseFloat(temp_inputs.dep_35yrSL), isNaN: isNaN(parseFloat(temp_inputs.dep_35yrSL)) },
    { key: "dep_39yrSL", value: parseFloat(temp_inputs.dep_39yrSL), isNaN: isNaN(parseFloat(temp_inputs.dep_39yrSL)) },
    { key: "dep_5yrMACRS", value: parseFloat(temp_inputs.dep_5yrMACRS), isNaN: isNaN(parseFloat(temp_inputs.dep_5yrMACRS)) },
    { key: "dep_15yrMACRS", value: parseFloat(temp_inputs.dep_15yrMACRS), isNaN: isNaN(parseFloat(temp_inputs.dep_15yrMACRS)) },
    { key: "state_taxes", value: parseFloat(temp_inputs.state_taxes), isNaN: isNaN(parseFloat(temp_inputs.state_taxes)) },
    { key: "federal_taxes", value: parseFloat(temp_inputs.federal_taxes), isNaN: isNaN(parseFloat(temp_inputs.federal_taxes)) },

    { key: "do_include_itc", value: parseInt(temp_inputs.do_include_itc), isNaN: isNaN(parseInt(temp_inputs.do_include_itc)) },
    { key: "do_include_ptc", value: parseInt(temp_inputs.do_include_ptc), isNaN: isNaN(parseInt(temp_inputs.do_include_ptc)) },
    { key: "ptc_value", value: parseFloat(temp_inputs.ptc_value), isNaN: isNaN(parseFloat(temp_inputs.ptc_value)) },

    { key: "do_ri_customSchedule", value: parseFloat(temp_inputs.do_ri_customSchedule), isNaN: isNaN(parseFloat(temp_inputs.do_ri_customSchedule)) },
  ];
  // toggle_finance_type: temp_inputs.data.toggle_finance_type ? temp_inputs.data.toggle_finance_type : tabDefaults.data.toggle_finance_type,
  if (temp_inputs.toggle_finance_type === 0) {
    // summarized data
    finance_check.push({ key: "summarized_dc", value: parseFloat(temp_inputs.summarized_dc), isNaN: isNaN(parseFloat(temp_inputs.summarized_dc)) });
    finance_check.push({ key: "summarized_ac", value: parseFloat(temp_inputs.summarized_ac), isNaN: isNaN(parseFloat(temp_inputs.summarized_ac)) });
    // finance_check.push({ key: "summarized_dc_wiring_wp", value: parseFloat(temp_inputs.summarized_dc_wiring_wp.total), isNaN: isNaN(parseFloat(temp_inputs.summarized_dc_wiring_wp.total)) })
    // finance_check.push({ key: "summarized_dc_wiring_gcr", value: parseFloat(temp_inputs.summarized_dc_wiring_gcr.total), isNaN: isNaN(parseFloat(temp_inputs.summarized_dc_wiring_gcr.total)) })
    // finance_check.push({ key: "foot_print", value: parseFloat(temp_inputs.foot_print), isNaN: isNaN(parseFloat(temp_inputs.foot_print)) })
  } else {
    // D/C Units
    finance_check.push({
      key: "module_dc_cost",
      value: { type: temp_inputs.module_dc_cost.type, value: parseFloat(temp_inputs.module_dc_cost.value) },
      isNaN: isNaN(parseFloat(temp_inputs.module_dc_cost.value)),
    });
    finance_check.push({
      key: "rack_a_finance",
      value: { type: temp_inputs.rack_a_finance.type, value: parseFloat(temp_inputs.rack_a_finance.value) },
      isNaN: isNaN(parseFloat(temp_inputs.rack_a_finance.value)),
    });
    finance_check.push({
      key: "rack_b_finance",
      value: { type: temp_inputs.rack_b_finance.type, value: parseFloat(temp_inputs.rack_b_finance.value) },
      isNaN: isNaN(parseFloat(temp_inputs.rack_b_finance.value)),
    });
    finance_check.push({
      key: "rack_c_finance",
      value: { type: temp_inputs.rack_c_finance.type, value: parseFloat(temp_inputs.rack_c_finance.value) },
      isNaN: isNaN(parseFloat(temp_inputs.rack_c_finance.value)),
    });
    finance_check.push({
      key: "bos_other",
      value: { type: temp_inputs.bos_other.type, value: parseFloat(temp_inputs.bos_other.value) },
      isNaN: isNaN(parseFloat(temp_inputs.bos_other.value)),
    });

    // fixed units
    finance_check.push({
      key: "interconnection",
      value: { type: temp_inputs.interconnection.type, value: parseFloat(temp_inputs.interconnection.value) },
      isNaN: isNaN(parseFloat(temp_inputs.interconnection.value)),
    });
    finance_check.push({
      key: "permits_fees",
      value: { type: temp_inputs.permits_fees.type, value: parseFloat(temp_inputs.permits_fees.value) },
      isNaN: isNaN(parseFloat(temp_inputs.permits_fees.value)),
    });
    finance_check.push({
      key: "engineering",
      value: { type: temp_inputs.engineering.type, value: parseFloat(temp_inputs.engineering.value) },
      isNaN: isNaN(parseFloat(temp_inputs.engineering.value)),
    });
    finance_check.push({ key: "margin", value: { type: temp_inputs.margin.type, value: parseFloat(temp_inputs.margin.value) }, isNaN: isNaN(parseFloat(temp_inputs.margin.value)) });
    finance_check.push({
      key: "itc_ineligible",
      value: { type: temp_inputs.itc_ineligible.type, value: parseFloat(temp_inputs.itc_ineligible.value) },
      isNaN: isNaN(parseFloat(temp_inputs.itc_ineligible.value)),
    });
    finance_check.push({
      key: "other_fixed",
      value: { type: temp_inputs.other_fixed.type, value: parseFloat(temp_inputs.other_fixed.value) },
      isNaN: isNaN(parseFloat(temp_inputs.other_fixed.value)),
    });

    // A/C Units
    finance_check.push({
      key: "inverter",
      value: { type: temp_inputs.inverter_cost.type, value: parseFloat(temp_inputs.inverter_cost.value) },
      isNaN: isNaN(parseFloat(temp_inputs.inverter_cost.value)),
    });
    finance_check.push({ key: "ac_aux", value: { type: temp_inputs.ac_aux.type, value: parseFloat(temp_inputs.ac_aux.value) }, isNaN: isNaN(parseFloat(temp_inputs.ac_aux.value)) });
    finance_check.push({ key: "mv_wire", value: { type: temp_inputs.mv_wire.type, value: parseFloat(temp_inputs.mv_wire.value) }, isNaN: isNaN(parseFloat(temp_inputs.mv_wire.value)) });
    finance_check.push({ key: "other_ac", value: { type: temp_inputs.other_ac.type, value: parseFloat(temp_inputs.other_ac.value) }, isNaN: isNaN(parseFloat(temp_inputs.other_ac.value)) });

    // Misc Units
    finance_check.push({
      key: "rack_footprint",
      value: { type: temp_inputs.rack_footprint.type, value: parseFloat(temp_inputs.rack_footprint.value) },
      isNaN: isNaN(parseFloat(temp_inputs.rack_footprint.value)),
    });
    finance_check.push({
      key: "boundary_area_per",
      value: { type: temp_inputs.boundary_area_per.type, value: parseFloat(temp_inputs.boundary_area_per.value) },
      isNaN: isNaN(parseFloat(temp_inputs.boundary_area_per.value)),
    });
    finance_check.push({
      key: "contingency",
      value: { type: temp_inputs.contingency.type, value: parseFloat(temp_inputs.contingency.value) },
      isNaN: isNaN(parseFloat(temp_inputs.contingency.value)),
    });

    if (temp_inputs.do_finance === 1) {
      // spacing adder
      let spacing_gcr = [];
      _.forEach(temp_inputs.spacing_gcr, (gcr) => {
        let _gcr = isNaN(parseFloat(gcr)) === true ? 0.0 : parseFloat(gcr);
        spacing_gcr.push(_gcr);
      });
      let spacing_per_wp = [];
      _.forEach(temp_inputs.spacing_per_wp, (wp) => {
        let _wp = isNaN(parseFloat(wp)) === true ? 0.0 : parseFloat(wp);
        spacing_per_wp.push(_wp);
      });
      temp_inputs.spacing_gcr = spacing_gcr;
      // ex: [0.3,0.45,0.6,0,0.2,0.1,1,0]
      temp_inputs.spacing_per_wp = spacing_per_wp;
      // ex: [30,45,60,0,20,10,100,0]

      // we need to fix the sorting of these now that we've fixed the variable type(*)
      let sort_array = [];
      _.forEach(temp_inputs.spacing_gcr, (gcr, index) => {
        sort_array.push({ gcr: temp_inputs.spacing_gcr[index], wp: temp_inputs.spacing_per_wp[index] });
      });
      // console.log(sort_array)
      /*
                 SAMPLE unorganized object
                     {gcr: 0.3, wp: 30}
                     {gcr: 0.45, wp: 45}
                     {gcr: 0.6, wp: 60}
                     {gcr: 0, wp: 0}
                     {gcr: 0.2, wp: 20}
                     {gcr: 0.1, wp: 10}
                     {gcr: 1, wp: 100}
                     {gcr: 0, wp: 0}
             */

      // Sort the object by GCR ascending for backend
      let sorted_array = _.sortBy(sort_array, ["gcr"], ["asc"]);
      // console.log(sorted_array)
      /*
                 AFTER sorting
                     {gcr: 0, wp: 0}
                     {gcr: 0, wp: 0}
                     {gcr: 0.1, wp: 10}
                     {gcr: 0.2, wp: 20}
                     {gcr: 0.3, wp: 30}
                     {gcr: 0.45, wp: 45}
                     {gcr: 0.6, wp: 60}
                     {gcr: 1, wp: 100}
             */

      temp_inputs.spacing_gcr = _.map(sorted_array, "gcr");
      // console.log(temp_inputs.spacing_gcr)
      // ex: [0,0,0.1,0.2,0.3,0.45,0.6,1]
      temp_inputs.spacing_per_wp = _.map(sorted_array, "wp");
      // console.log(temp_inputs.spacing_per_wp)
      // ex: [0,0,10,20,30,45,60,100]
    }
  }
  // finance_check.push({ key: "spacing_gcr", value: parseFloat(temp_inputs.spacing_gcr.value), isNaN: isNaN(parseFloat(temp_inputs.spacing_gcr.value)) })
  // finance_check.push({ key: "spacing_per_wp", value: parseFloat(temp_inputs.spacing_per_wp.value), isNaN: isNaN(parseFloat(temp_inputs.spacing_per_wp.value)) })

  finance_check.forEach((input) => {
    if (input.isNaN === true) {
      // temp_inputs.finance[input.key] = tabDefaults.data[input.key];
      // warnings.push(`Finance Input (${input.key}) found blank, setting to default:${tabDefaults.data[input.key]}`);
      temp_inputs[input.key] = 0;
    } else {
      temp_inputs[input.key] = input.value;
    }
  });
  // console.log("cleanSIFTInputs:", temp_inputs);

  return temp_inputs;
};

export { cleanSiftInputs };
