const map_schema = {
  type: "object",
  tab: "Map",
  properties: {
    site_features: {
      type: "array",
      items: {
        type: "object",
        properties: {
          type: {
            type: "string",
            enum: ["Feature"],
          },
          properties: {
            type: "object",
            properties: {
              index: {
                type: "string",
              },
              identity: {
                type: "integer",
              },
              active: {
                type: "boolean",
              },
              sortIndex: {
                type: "integer",
              },
              area: {
                type: "number",
              },
              acreage: {
                type: "number",
              },
              center: {
                type: "array",
                items: {
                  type: "number",
                },
                minItems: 2,
                maxItems: 2,
              },
            },
          },
          geometry: {
            type: "object",
            properties: {
              type: {
                type: "string",
                enum: ["Polygon"],
              },
              coordinates: {
                type: "array",
                items: {
                  type: "array",
                  items: {
                    type: "array",
                    items: {
                      type: "number",
                    },
                    minItems: 2,
                    maxItems: 2,
                  },
                },
              },
            },
          },
        },
      },
      validateSiteFeatures: true,
    },
    coord_system_bbox: {
      type: "object",
      properties: {
        type: {
          type: "string",
          enum: ["Feature"],
        },
        properties: {
          type: "object",
          properties: {
            index: {
              type: "string",
              format: "uuid",
            },
            skip_zoom_extent: {
              type: "boolean",
            },
          },
        },
        geometry: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["Polygon"],
            },
            coordinates: {
              type: "array",
              items: {
                type: "array",
                items: {
                  type: "array",
                  items: {
                    type: "number",
                  },
                  minItems: 2,
                  maxItems: 2,
                },
              },
              minItems: 1,
            },
          },
        },
      },
      validateCoordSystem: true,
    },
    boundary_bbox: {
      type: "array",
      items: { type: "number" },
      minItems: 4,
      maxItems: 4,
    },
    map_center: {
      type: "array",
      items: { type: "number" },
      minItems: 2,
      maxItems: 2,
    },
    recenter: { type: "number" },
    edited_layouts: { type: "object" },
  },
};

export { map_schema };
