const layout_schema = {
  type: "object",
  tab: "Layout",
  properties: {
    layout_name: {
      label: "Name",
      _id: "layout_name",
      type: "string",
    },
    do_grid_poi_lim: {
      label: "POI Limit",
      _id: "do_grid_poi_lim",
      type: "number",
      validateGridPoiLimit: true,
    },
    ac_grid_poi_lim: {
      label: "POI Limit",
      _id: "ac_grid_poi_lim",
      type: "number",
    },
    do_inverter_limit_lock: {
      label: "Fixed Inverter Qty",
      _id: "do_inverter_limit_lock",
      type: "number",
      validateInverterLimitLock: true,
    },
    inverter_limit: {
      labl: "Fixed Inverter Qty",
      _id: "inverter_limit",
      type: "number",
    },
    do_rack_align: {
      label: "Rack Positioning",
      _id: "do_rack_align",
      type: "number",
    },
    sazm: {
      label: "Azimuth",
      _id: "sazm",
      type: "number",
    },
    epsg: {
      label: "Layout Coordinate System",
      _id: "epsg",
      type: "number",
    },
    do_roads: {
      _id: "do_roads",
      type: "number",
    },
    road_spacing: {
      _id: "road_spacing",
      type: "number",
    },
    road_spacing_option: {
      label: "Draw East-West Roads",
      _id: "road_spacing_option",
      type: "number",
    },
    road_width: {
      label: "Road Width",
      _id: "road_width",
      type: "number",
    },
    road_to_rack: {
      label: "Road to Rack",
      label: "road_to_rack",
      type: "number",
    },
    inverter_grouping: {
      label: "Inverter Groups",
      _id: "inverter_grouping",
      type: "number",
      validateInvertersAndGrouping: true,
    },
    do_inv_spacing: {
      label: "Draw Inverters",
      _id: "do_inv_spacing",
      type: "number",
    },
    inverter_per_cut: {
      _id: "inverter_per_cut",
      type: "number",
    },
    inv_pad_x: {
      label: "Pad X",
      _id: "inv_pad_x",
      type: "number",
    },
    inv_pad_y: {
      label: "Pad Y",
      _id: "inv_pad_y",
      type: "number",
    },
    road_to_rack_inv: {
      label: "Road to Rack, Inv",
      _id: "road_to_rack_inv",
      type: "number",
    },
    do_wiring: {
      label: "String Wiring",
      _id: "do_wiring",
      type: "number",
    },
    combiner_location: {
      label: "Combiner Location",
      _id: "combiner_location",
      type: "number",
    },
    strings_per_combiner: {
      label: "Strings per Combiner",
      _id: "strings_per_combiner",
      type: "number",
    },
    wire_stringing: {
      label: "Stringing",
      _id: "wire_stringing",
      type: "number",
    },
    do_designer_margin: {
      label: "Designer Margin",
      _id: "do_designer_margin",
      type: "number",
    },
    designer_margin: {
      label: "Designer Margin",
      _id: "designer_margin",
      type: "number",
    },
    wiring_inv_rating: { type: "number" },
  },
};

export { layout_schema };
