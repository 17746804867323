/*
	VisualShadeLayer.js
	This is a visual representation of shade being cast on canopies
*/
// let shade_style = {
//   stroke: 1,
//   color: "#666", //🤘
//   fillColor: "#666", //🤘
//   fillOpacity: 0.5,
//   opacity: 0.9,
// };

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMap, Source, Layer } from "react-map-gl";

const MBVisualShadeArea = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const shade_results = useSelector((state) => state.canopy.shade_results);
  const shade_month = useSelector((state) => state.canopy.shade_month);
  const shade_hour = useSelector((state) => state.canopy.shade_hour);

  useEffect(() => {
    map.triggerRepaint();
  }, [shade_hour]);

  if (Object.values(shade_results).length === 0) return null;
  return (
    <>
      {Object.values(shade_results.shadeAreas).map((shade) => {
        return Object.keys(shade[shade_month]).map((key) => {
          if (key != shade_hour) return null;
          // console.log("shade[shade_month][key]", shade[shade_month][key]);
          return (
            <Source type="geojson" key={shade[shade_month][key].properties.index} data={shade[shade_month][key]}>
              <Layer
                type="fill"
                paint={{
                  "fill-color": "#666",
                  "fill-opacity": 0.6,
                  // "line-width": 1,
                }}
              />
            </Source>
          );
        });
      })}
    </>
  );
};

export { MBVisualShadeArea };
