import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, Marker, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { Button } from "../../BaseComponents";
import { invisibleMarker, lock, delete_single_polygon } from "../../../assets/images";
import { getMidPoint } from "../DrawToolProvider/_draw.helpers";
import { canopyActions } from "../..";
import { Popconfirm } from "antd";
import { debounce } from "lodash";

const MapboxImageLayerContainer = () => {
  const images = useSelector((state) => state.canopy.images);

  return <>{Object.values(images).map((image) => image && <MapboxImageLayer key={image.imageId} imageData={image} />)}</>;
};

const MapboxImageLayer = ({ imageData }) => {
  const dispatch = useDispatch();
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const draggingRef = useRef(false);
  const currentMarkerRef = useRef(null);
  const startCoordRef = useRef(null);
  const updatedCoordinatesRef = useRef([]);
  const markersRef = useRef([]);
  const editMode = useRef(true);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);

  const [opacity, setOpacity] = useState(imageData.opacity || 0.7);
  const [popupVisible, setPopupVisible] = useState(true);
  const [popupPosition, setPopupPosition] = useState();
  const [selected_image, set_selected_image] = useState(undefined);

  useEffect(() => {
    if (!map || !imageData) return;

    const imageId = `imported-image-${imageData.imageId}`;
    const polygonId = `image-polygon-${imageData.imageId}`;

    if (map.getSource(imageId)) return;
    // console.log("imageData:", imageData);

    const imageBlob = new Image();
    imageBlob.src = imageData.imgSrc;

    imageBlob.onload = () => {
      const center = map.getCenter();
      const aspectRatio = imageBlob.width / imageBlob.height;
      const imageHeight = 0.005;
      const imageWidth = imageHeight * aspectRatio;

      const initialCoordinates = imageData.corners
        ? imageData.corners
        : [
            [center.lng - imageWidth / 2, center.lat + imageHeight / 2],
            [center.lng + imageWidth / 2, center.lat + imageHeight / 2],
            [center.lng + imageWidth / 2, center.lat - imageHeight / 2],
            [center.lng - imageWidth / 2, center.lat - imageHeight / 2],
          ];

      if (!imageData.corners) {
        debouncedCornersUpdate(initialCoordinates);
      }

      const polyCoords = [...initialCoordinates, initialCoordinates[0]];

      updatedCoordinatesRef.current = initialCoordinates;
      const midPoint = getMidPoint(initialCoordinates[0], initialCoordinates[1]);
      setPopupPosition(midPoint.geometry.coordinates);

      const targetLayerId = map.getStyle().layers[4].id;

      map.addSource(imageId, {
        type: "image",
        url: imageBlob.src,
        coordinates: initialCoordinates,
      });

      map.addLayer(
        {
          id: imageId,
          type: "raster",
          source: imageId,
          paint: { "raster-opacity": opacity },
        },
        targetLayerId
      );

      map.addSource(polygonId, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: { type: "Polygon", coordinates: [polyCoords] },
        },
      });

      map.addLayer(
        {
          id: polygonId,
          type: "fill",
          source: polygonId,
          paint: { "fill-color": "transparent" },
        },
        targetLayerId
      );

      const createMarker = (coord, index) => {
        const el = document.createElement("div");
        el.className = "image-marker";
        el.style.width = "15px";
        el.style.height = "15px";
        el.style.backgroundColor = "#002bcb";
        el.style.border = "2px solid #fff";
        el.style.borderRadius = "50%";
        el.style.display = editMode.current ? "block" : "none";

        const marker = new mapboxgl.Marker(el).setLngLat(coord).addTo(map);

        el.addEventListener("mousedown", (e) => {
          e.stopPropagation();
          if (editMode.current) {
            currentMarkerRef.current = index;
            map.getCanvas().style.cursor = "nwse-resize";
          }
        });

        return marker;
      };

      markersRef.current = initialCoordinates.map((coord, index) => createMarker(coord, index));

      map.on("dblclick", polygonId, (e) => {
        if (is_drawing) return;
        e.preventDefault();
        editMode.current = !editMode.current;
        setPopupVisible(editMode.current);

        markersRef.current.forEach((marker) => {
          marker.getElement().style.display = editMode.current ? "block" : "none";
        });

        if (editMode.current) {
          set_selected_image(imageData.imageId);
        } else {
          set_selected_image(undefined);
        }
      });

      map.on("mousedown", (e) => {
        if (!editMode.current) return;

        if (map.getLayer(polygonId)) {
          const features = map.queryRenderedFeatures(e.point, { layers: [polygonId] });

          if (features.length) {
            draggingRef.current = true;
            startCoordRef.current = e.lngLat;
            map.getCanvas().style.cursor = "move";
            map.dragPan.disable();
          }
        }
      });

      map.on("mousemove", (e) => {
        if (!editMode.current) return;

        if (draggingRef.current) {
          const dx = e.lngLat.lng - startCoordRef.current.lng;
          const dy = e.lngLat.lat - startCoordRef.current.lat;

          const movedCoordinates = updatedCoordinatesRef.current.map(([lng, lat]) => [lng + dx, lat + dy]);
          const newMidPoint = getMidPoint(movedCoordinates[0], movedCoordinates[1]);
          setPopupPosition(newMidPoint.geometry.coordinates);

          map.getSource(polygonId).setData({
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [movedCoordinates] },
          });
          map.getSource(imageId).setCoordinates(movedCoordinates);

          markersRef.current.forEach((marker, index) => marker.setLngLat(movedCoordinates[index]));
          updatedCoordinatesRef.current = movedCoordinates;
          startCoordRef.current = e.lngLat;
        }

        if (editMode.current && currentMarkerRef.current !== null) {
          const updatedCoordinates = [...updatedCoordinatesRef.current];
          updatedCoordinates[currentMarkerRef.current] = [e.lngLat.lng, e.lngLat.lat];

          const newMidPoint = getMidPoint(updatedCoordinates[0], updatedCoordinates[1]);
          setPopupPosition(newMidPoint.geometry.coordinates);

          map.getSource(polygonId).setData({
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [updatedCoordinates] },
          });
          map.getSource(imageId).setCoordinates(updatedCoordinates);

          markersRef.current.forEach((marker, index) => marker.setLngLat(updatedCoordinates[index]));
          updatedCoordinatesRef.current = updatedCoordinates;
          debouncedCornersUpdate(updatedCoordinatesRef.current);
        }
      });

      map.on("mouseup", () => {
        if (!editMode.current) return;
        if (draggingRef.current) {
          draggingRef.current = false;
          map.getCanvas().style.cursor = "";
          map.dragPan.enable();
        }
        if (currentMarkerRef.current !== null) {
          currentMarkerRef.current = null;
          map.getCanvas().style.cursor = "";
        }
      });
    };

    return () => {
      map.off("mousedown");
      map.off("mousemove");
      map.off("mouseup");
    };
  }, [map, imageData, opacity, is_drawing]);

  const debouncedOpacityUpdate = useCallback(
    debounce((newOpacity) => {
      dispatch(canopyActions.updateImportedImages("edit_image", { opacity: newOpacity }, imageData.imageId));
    }, 500),
    [dispatch, imageData.imageId]
  );

  const debouncedCornersUpdate = useCallback(
    debounce((corners) => {
      dispatch(canopyActions.updateImportedImages("edit_image", { corners }, imageData.imageId));
    }, 500),
    [dispatch, imageData.imageId]
  );

  const handleOpacityChange = (e) => {
    const newOpacity = parseFloat(e.target.value);

    setOpacity(newOpacity);
    if (map.getLayer(`imported-image-${imageData.imageId}`)) {
      map.setPaintProperty(`imported-image-${imageData.imageId}`, "raster-opacity", newOpacity);
    }
    debouncedOpacityUpdate(newOpacity);
  };

  const toggleEditMode = () => {
    if (draggingRef.current) return;
    editMode.current = !editMode.current;
    setPopupVisible(editMode.current);

    markersRef.current.forEach((marker) => {
      marker.getElement().style.display = editMode.current ? "block" : "none";
    });
  };

  const deleteImage = () => {
    dispatch(canopyActions.updateImportedImages("delete_image", imageData.imageId));

    const imageId = `imported-image-${imageData.imageId}`;
    const polygonId = `image-polygon-${imageData.imageId}`;

    if (map.getLayer(imageId)) {
      map.removeLayer(imageId);
    }
    if (map.getSource(imageId)) {
      map.removeSource(imageId);
    }

    if (map.getLayer(polygonId)) {
      map.removeLayer(polygonId);
    }
    if (map.getSource(polygonId)) {
      map.removeSource(polygonId);
    }

    // Hide markers
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
  };

  return (
    <>
      {editMode.current && popupPosition && popupVisible && (
        <Marker latitude={popupPosition[1] + 0.0003} longitude={popupPosition[0]} anchor="bottom">
          <img src={invisibleMarker} alt="Invisible Marker" />
          <Popup latitude={popupPosition[1] + 0.0003} longitude={popupPosition[0]} closeButton={true} onClose={toggleEditMode}>
            <section style={{ display: "flex", marginBottom: "8px" }}>
              <Popconfirm title="Double-click the image to re-enter edit mode" onConfirm={toggleEditMode} okText="Okay" showCancel={false}>
                <Button size="small">
                  <img style={{ width: "14px" }} src={lock} />
                </Button>
              </Popconfirm>
              <Popconfirm title="Delete image?" onConfirm={deleteImage} okText="Yes" cancelText="No">
                <Button size="small">
                  <img style={{ width: "14px" }} src={delete_single_polygon} />
                </Button>
              </Popconfirm>
            </section>
            <section style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
              <label htmlFor="opacity">Opacity: </label>
              <input type="range" id="opacity" min="0" max="1" step="0.1" value={opacity} onChange={handleOpacityChange} />
              <span>{Math.round(opacity * 100)}%</span>
            </section>
            <span style={{ whiteSpace: "nowrap" }}>*Double-click Image to enable edit mode*</span>
          </Popup>
        </Marker>
      )}
    </>
  );
};

export { MapboxImageLayerContainer, MapboxImageLayer };
