import { useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../DrawToolProvider";
import { handleFeatureCreated } from "../DrawToolProvider/_draw.helpers";
import { inputsActions } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { useKey } from "../../Map/components/MapEventHandler/useKey";

const HandleFeatureCreation = () => {
  const dispatch = useDispatch();
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const is_drawing_ref = useRef(is_drawing);
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();

  const drawingTree = useSelector((state) => state.canopy.drawing_tree);
  const drawingBuilding = useSelector((state) => state.canopy.drawing_building);
  const drawingTreeLine = useSelector((state) => state.canopy.drawing_tree_line);

  // const complete_key = useKey("c");

  // useEffect(() => {
  //   if (is_drawing_ref.current && complete_key) {
  //     completePolygon();
  //   }
  // }, [complete_key]);

  useEffect(() => {
    is_drawing_ref.current = is_drawing;
  }, [is_drawing]);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("draw.create", handleCreated);

    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  // const completePolygon = () => {
  //   if (!draw) return;

  //   const currentMode = draw.getMode();
  //   if (currentMode !== "draw_polygon") return;

  //   const activeFeature = draw.getAll().features.find((feature) => Object.values(feature.properties).length === 0);

  //   if (!activeFeature || activeFeature.geometry.coordinates[0].length === 3) {
  //     draw.changeMode("simple_select");
  //     return;
  //   }

  //   const coordinates = [...activeFeature.geometry.coordinates[0]];
  //   if (coordinates.length >= 3 && (coordinates[0][0] !== coordinates[coordinates.length - 1][0] || coordinates[0][1] !== coordinates[coordinates.length - 1][1])) {
  //     coordinates.push(coordinates[0]); // Close the loop
  //   }

  //   activeFeature.geometry.coordinates[0] = coordinates;
  //   // put it in the store bro
  //   const geoJson = handleFeatureCreated(activeFeature);
  //   dispatch(inputsActions.update_site_input("add_site_feature", geoJson));

  //   draw.delete(activeFeature.id);
  //   draw.changeMode("simple_select");
  // };

  const handleCreated = (e) => {
    if (!draw || drawingBuilding || drawingTree || drawingTreeLine) return;
    if (!is_drawing_ref.current) return;
    // console.log("Feature created:", e.features[0]);
    if (e.features[0].geometry.type === "LineString" || e.features[0].geometry.type === "Point") return;
    const geoJson = handleFeatureCreated(e.features[0]);
    dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
    draw.delete(e.features[0].id);
  };

  return null;
};

export { HandleFeatureCreation };
