// import config from "config";
// import { getBounds, getNewCenterPoint, uuid, getCenterPointofFeature } from "../_helpers";
// import { fakeCanopyResults } from "./FAKE_canopyResults";

export const default_canopy_inputs = {
  canopies: {},
  selected_canopy: undefined,
  deleted_canopy_id: undefined,
  copy_canopy_id: undefined,
  running_canopy: false,
  running_shade_sim: false,
  run_shade_job_id: undefined,
  canopy_results_generated: false,
  show_canopy_results: false,
  show_canopy_report: false,
  show_percent_report: false,
  selected_canopy_result: undefined,
  images: {},
  activeImageEditTool: undefined,
  selectedImageId: undefined,
  is_drawing: false,
  is_dragging: false,
  is_rotating: false,
  is_editing: false,
  is_editing_modules: false,
  drawing_tree: false,
  drawing_tree_line: false,
  drawing_building: false,
  show_names: false,
  canopy_results: {},
  report_ready: false,
  errors: [],
  tsvResult: {},
  csvResult: {},
  input_changed_warning: false,
  project_name: undefined,
  project_id: undefined,
  project_type: 3,
  shade_objects: {},
  shade_height_unit: "m",
  en_shade_impact: 1,
  selected_shade_object_id: undefined,
  delete_shade_id: undefined,
  shade_results: {},
  shade_month: "jun21",
  shade_hour: 4,
  shade_marker_pos: undefined,
};

// state machine for controlling which tools are active and highlighted
export const toolbar_ui_state = {
  default: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
    edit_shade: false,
    move_shade: false,
    rotate_shade: false,
  },
  is_drawing: {
    is_drawing: true,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
  },
  is_dragging: {
    is_drawing: false,
    is_dragging: true,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
  },
  is_rotating: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: true,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
  },
  is_editing: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: true,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
  },
  is_editing_modules: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: true,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
  },
  drawing_tree: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: true,
    drawing_tree_line: false,
    drawing_building: false,
  },
  drawing_tree_line: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: true,
    drawing_building: false,
  },
  drawing_building: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: true,
  },
  edit_shade: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
    edit_shade: true,
    move_shade: false,
    rotate_shade: false,
  },
  move_shade: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
    edit_shade: false,
    move_shade: true,
    rotate_shade: false,
  },
  rotate_shade: {
    is_drawing: false,
    is_dragging: false,
    is_rotating: false,
    is_editing: false,
    is_editing_modules: false,
    drawing_tree: false,
    drawing_tree_line: false,
    drawing_building: false,
    edit_shade: false,
    move_shade: false,
    rotate_shade: true,
  },
};

export const csvFields = [
  "Key",
  "Name",
  "Module",
  "Canopy Type",
  "Azimuth (°)",
  "Module Qty",
  "String Count",
  "Capacity (kw)",
  "Tilt (°)",
  "Generation (KWh)",
  "Yield (kWh/kWp)",
  "Tilt From Azimuth",
  "Tilt To Azimuth",
  "Toward Azimuth",
  "Orientation",
  "Mod Dimension X Qty",
  "Mod Dimension Y Qty",
  "Mod Dimension X Ft",
  "Mod Dimension Y Ft",
];

// not used but saving for later?
const report_data_inputs = { date: "", overview: {}, results: {}, thirtyPercentInputs: {}, images: {} };
