const horizon_shading_schema = {
  type: "object",
  tab: "Horizon Shading Tab",
  properties: {
    horizon_shade_loading: {
      _id: "horizon_shade_loading",
      type: "boolean",
    },
  },
};

export { horizon_shading_schema };
