export const inputsConstants = {
  //
  SELECT_PRESET_BY_INDEX: "SELECT_PRESET_BY_INDEX",

  UPDATE_INPUT_UI_STATE: "UPDATE_INPUT_UI_STATE",
  UPDATE_BULK_UI_STATE: "UPDATE_BULK_UI_STATE",

  UPDATE_INPUT_BY_NAME: "UPDATE_INPUT_BY_NAME",
  UPDATE_BULK_INPUTS: "UPDATE_BULK_INPUTS",
  UPDATE_SITE_INPUT: "UPDATE_SITE_INPUT",
  UPDATE_OFFSET_TYPE: "UPDATE_OFFSET_TYPE",
  LOAD_PROJECT_FROM_INPUTS: "LOAD_PROJECT_FROM_INPUTS",

  RESET_INPUTS_TO_DEFAULT: "RESET_INPUTS_TO_DEFAULT",

  IMPORT_INPUTS: "IMPORT_INPUTS",
  PRESET_SAVED: "PRESET_SAVED",
  RESET_WEATHER: "RESET_WEATHER",
  TOPO_JOB_COMPLETE: "TOPO_JOB_COMPLETE",
  CLEAR_TOPO_DATA: "CLEAR_TOPO_DATA",
  UNDO_FEATURES: "UNDO_FEATURES",
  REDO_FEATURES: "REDO_FEATURES",

  // Portal Salesforce capture
  TOGGLE_SF_MODAL: "TOGGLE_SF_MODAL",
  SF_CAPTURE_REQUEST: "SF_CAPTURE_REQUEST",
  SF_CAPTURE_FAILURE: "SF_CAPTURE_FAILURE",
  SF_CAPTURE_SUCCESS: "SF_CAPTURE_SUCCESS",
  SF_CAPTURE_RESET: "SF_CAPTURE_RESET",
  EDIT_LAYOUT: "EDIT_LAYOUT",
  GET_EDITABLE_LAYOUT_REQUEST: "GET_EDITABLE_LAYOUT_REQUEST",
  GET_EDITABLE_LAYOUT_SUCCESS: "GET_EDITABLE_LAYOUT_SUCCESS",
  GET_EDITABLE_LAYOUT_FAILURE: "GET_EDITABLE_LAYOUT_FAILURE",

  UPDATE_SAVED_RESULTS: "UPDATE_SAVED_RESULTS",
};
