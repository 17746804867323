import Ajv from "ajv";
import * as turf from "@turf/turf";
import { checkCoordSystemBounds, checkSiteLocation, getBounds } from "../components/Redux";
import _ from "lodash";
import { input_validation_schema, module_schema, validateFinance } from "./";

export const validateSiteFeatures = (site_features) => {
  let errors = [];

  let boundary_site_features = Object.values(site_features).filter((feature) => feature.properties.identity == 1);
  let exclusion_site_features = Object.values(site_features).filter((feature) => feature.properties.identity == 2);

  // Boundary check
  if (site_features.length === 0 && boundary_site_features.length === 0) {
    errors.push({
      keyword: "validateSiteFeatures",
      message:
        "SIFT requires at least one project boundary to run. Use the draw tool to define your project boundaries, or drop a KMZ into the map. Be sure to set at least one polygon to a boundary (red). See help for additional information and tutorials.",
      instancePath: "/site_features",
    });
  } else if (boundary_site_features.length === 0) {
    errors.push({
      keyword: "validateSiteFeatures",
      message: "SIFT requires at least one project boundary to run. Be sure to set at least one polygon to a boundary (red).",
      instancePath: "/site_features",
    });
  }

  // Buildable area check
  if (site_features.length >= 2 && exclusion_site_features.length >= 1) {
    let no_buildable_area = true;

    // loop through each boundary and check to see if it's inside an exclusion
    boundary_site_features.forEach((boundary) => {
      exclusion_site_features.forEach((exclusion) => {
        if (!turf.booleanContains(exclusion, boundary)) {
          no_buildable_area = false; // There's at least one buildable area
        }
      });
    });

    if (no_buildable_area) {
      errors.push({
        keyword: "validateSiteFeatures",
        message: "There is no buildable area (Boundaries - Exclusions = Buildable). Check your project polygons. Polygons set as boundaries are red, polygons set as exclusions are yellow.",
        instancePath: "/site_features",
      });
    }
  }

  return errors.length > 0 ? errors : true;
};

export const validateLatitudeLongitude = (input = undefined, inputs) => {
  //input is not used here but it does need a placeholder  because of the way these validations work.
  let errors = [];

  if (!isFinite(inputs.latitude) || isNaN(inputs.latitude)) {
    errors.push({
      keyword: "validateLatitudeLongitude",
      message: `Latitude appears to be incorrect. Please navigate to the Weather Tab and check your inputs. You can click "Recenter Pin" to refresh the location`,
      instancePath: "/latitude",
    });
  }

  if (!isFinite(inputs.longitude) || isNaN(inputs.longitude)) {
    errors.push({
      keyword: "validateLatitudeLongitude",
      message: `Longitude appears to be incorrect. Please navigate to the Weather Tab and check your inputs. You can click "Recenter Pin" to refresh the location`,
      instancePath: "/longitude",
    });
  }

  return errors.length > 0 ? errors : true;
};

export const validateCoordSystem = (coord_system_bbox, inputs) => {
  let errors = [];
  if (inputs.site_features.length > 0 && coord_system_bbox) {
    let coordSystemCheck = checkCoordSystemBounds(inputs.site_features, coord_system_bbox);
    if (coordSystemCheck === false) {
      errors.push({
        keyword: "validateCoordSystem",
        message: `Your project boundary exists outside of the selected layout coordinate system and would adversely affect your layout results. Please select a more specific layout coordinate system, or select "WGS84" from the Layout Coordinate System dropdown in the Layout menu.`,
        instancePath: "/coord_system_bbox",
      });
    }

    if (inputs.weather !== undefined || inputs.latitude !== 0) {
      let _boundaries = inputs.site_features.filter((poly) => poly.properties.identity == 1);
      let weather_center = [inputs.latitude, inputs.longitude];
      if (!checkSiteLocation(_boundaries, weather_center)) {
        // errors.push("Latitude/Longitude inputs are +/- 5 degrees from the site location. Recenter site location in weather tab.");
        errors.push({ message: "show_recenter_pin_error" });
        // errors.push(`Latitude/Longitude inputs are +/- 5 degrees from the site location. Recenter pin in weather tab or click this link → ${ReactDOM.render(<RecenterPinButton />)}`);
      }
    }
  }

  return errors.length > 0 ? errors : true;
};

export const validateRoads = (road_spacing_option, inputs) => {
  let errors = [];
  if (road_spacing_option > 0) {
    if (inputs.road_width < 2 || inputs.road_width > 6) {
      errors.push({
        keyword: "validateRoads",
        message: "Invalid Road Width. Must be between 2-6 . Please adjust your inputs in the Layout Menu",
        intancePath: "/road_spacing_option",
      });
    }

    if (inputs.road_to_rack < 1 || inputs.road_to_rack > 10) {
      errors.push({
        keyword: "validateRoads",
        message: "Invalid Road to Rack. Must be between 1-10 . Please adjust your inputs in the Layout Menu",
        intancePath: "/road_to_rack",
      });
    }
  }
  return errors.length > 0 ? errors : true;
};

export const validateGridPoiLimit = (input = undefined, inputs) => {
  let errors = [];
  if (inputs.do_grid_poi_lim === 1 && (inputs.ac_grid_poi_lim == null || inputs.ac_grid_poi_lim < 0)) {
    errors.push({
      keyword: "validateGridPoiLimit",
      message: "POI limit is enabled so value should be greater than zero",
      instancePath: "/do_grid_poi_lim",
    });
  }
  if (inputs.do_inverter_limit_lock === 1 && (inputs.inverter_limit == null || inputs.inverter_limit < 0)) {
    errors.push({
      keyword: "validateLimits",
      message: "Fixed Inverter Qty limit is enabled so value should be greater than zero",
      instancePath: "/do_inverter_limit_lock",
    });
  }

  return errors.length > 0 ? errors : true;
};

export const validateInverterLimitLock = (input = undefined, inputs) => {
  let errors = [];

  if (inputs.do_inverter_limit_lock === 1 && (inputs.inverter_limit == null || inputs.inverter_limit < 0)) {
    errors.push({
      keyword: "validateInverterLimitLock",
      message: "Fixed Inverter Qty limit is enabled so value should be greater than zero",
      instancePath: "/do_inverter_limit_lock",
    });
  }

  return errors.length > 0 ? errors : true;
};

export const validateInvertersAndGrouping = (input = undefined, inputs) => {
  let errors = [];
  if (inputs.inverter_grouping > 0) {
    // if (inputs.inverter_grouping === 1) {
    //   inputs.inverter_grouping = 0;
    // }
    if (inputs.do_inv_spacing === 1 && (inputs.inverter_per_cut < 1 || inputs.inverter_per_cut > 10)) {
      errors.push({
        keyword: "validateInvertersAndGrouping",
        message: "Invalid Draw Inverters. Must be between 1-10 . Please adjust your inputs in the Layout Menu",
        instancePath: "/inverter_per_cut",
      });
    }
    if (inputs.do_inv_spacing === 1 && (inputs.inv_pad_x < 1 || inputs.inv_pad_x > 10)) {
      errors.push({
        keyword: "validateInvertersAndGrouping",
        message: "Invalid Pad X Must be between 1-10. Please adjust your inputs in the Layout Menu",
        instancePath: "/inv_pad_x",
      });
    }

    if (inputs.do_inv_spacing === 1 && (inputs.inv_pad_Y < 1 || inputs.inv_pad_Y > 4)) {
      errors.push({
        keyword: "validateInvertersAndGrouping",
        messag: "Invalid Pad Y Must be between 1-4. Please adjust your inputs in the Layout Menu",
        instancePath: "/inv_pad_Y",
      });
    }

    if (inputs.do_inv_spacing === 1 && (inputs.road_to_rack_inv < inputs.inv_pad_y || inputs.road_to_rack_inv < 1 || inputs.road_to_rack_inv > 10)) {
      errors.push({
        keyword: "validateInvertersAndGrouping",
        message: "Invalid Road to Rack, Inv. Must be betweeen 1-10 but >= Pad Y. Please adjust your inputs in the Layout Menu",
        instancePath: "/do_inv_spacing",
      });
    }
  }

  return errors.length > 0 ? errors : true;
};

export const validateModRating = (mod_rating) => {
  let errors = [];

  if (mod_rating <= 0 || isNaN(mod_rating)) {
    errors.push({ keyword: "validateModRating", message: "Invalid module rating. Select or import a module in the Module Tab.", instancePath: "/do_inv_spacing", instancePath: "/mod_ratig" });
  }
  return errors.length > 0 ? errors : true;
};

export const validateInverter = (input = undefined, inputs) => {
  let errors = [];

  if (inputs.inv_rating <= 0 || isNaN(inputs.inv_rating)) {
    errors.push({
      keyword: "validateInverter",
      message: "Invalid Inverter rating. Select or import an inverter in the Inverter Tab.",
    });
  }
  if (inputs.maxEff <= 0 || inputs.maxEff > 100 || isNaN(inputs.maxEff)) {
    errors.push({ keyword: "validateInverter", message: "Invalid Inverter Max Efficiency. Must be >0 and <=100." });
  }
  return errors.length > 0 ? errors : true;
};

export const validateRacks = (input = undefined, inputs) => {
  let errors = [];

  if (inputs.racking_errors && inputs.racking_errors?.show_in_validation) {
    Object.values(inputs.racking_errors).map((error) => {
      if (!_.isUndefined(error) && typeof error != "boolean") {
        errors.push({ message: error });
      }
    });
  }

  if (inputs.racks.findIndex((r) => r.active == true) === -1) {
    errors.push({
      keyword: "validateRacks",
      message: "There are no active Racking dimesions. Navigate to the Racking Tab and mark at least one Type (A,B,or C) as Active.",
    });
  }

  if (inputs.track_mode === 0) {
    if (inputs.tilts.length === 0) {
      errors.push({ keyword: "validateRacks", message: "Invalid Tilt Inputs in Racking tab" });
    }
    if (inputs.tilts.length > 20) {
      errors.push({
        keyword: "validateRacks",
        message: "Invalid tilt range. Total possible tilts within range exceeds 20. Navigate to the Racking Tab and adjust your tilt range.",
      });
    }
  }

  if (inputs.shade_mode === 1 && inputs.en_backtrack === 0) {
    if (inputs.Fshd_StringSteps <= 0) {
      errors.push({ keyword: "validateRacks", message: "String Steps in Racking tab must be greater than 0." });
    }
    if (inputs.Fshd_Celltrav <= 0) {
      errors.push({ keyword: "validateRacks", message: "Cell Transverse in Racking tab must be greater than 0." });
    }
  }

  if (inputs.mod_per_string === undefined || inputs.mod_per_string <= 0) {
    errors.push({ keyword: "validateRacks", message: "Invalid Modules per String. Review the Modules Per String input in the Racking Tab." });
  }

  return errors.length > 0 ? errors : true;
};

export const validateTopography = (input = undefined, inputs) => {
  let errors = [];
  let boundary_site_features = Object.values(inputs.site_features).filter((feature) => feature.properties.identity == 1);
  if (inputs.topo_bbox && inputs.topo_id) {
    let fixed_bbox = [inputs.topo_bbox[0] - 0.0009, inputs.topo_bbox[1] - 0.0009, inputs.topo_bbox[2] + 0.0009, inputs.topo_bbox[3] + 0.0009];
    let topo_bounding_box = turf.bboxPolygon(fixed_bbox);
    let feature_bounding_box = turf.bboxPolygon(getBounds(boundary_site_features));
    if (!turf.booleanContains(topo_bounding_box, feature_bounding_box)) {
      errors.push({
        keyword: "validateTopography",
        message:
          "There are polygons classed as Boundaries (red) located outside of the topography area. Please delete and re-import Topography or remove boundaries that are outside of the topography area. If the problem persists, please submit a bug report.",
      });
    }
  }
  return errors.length > 0 ? errors : true;
};

export const validateConfig = (input = undefined, inputs) => {
  let errors = [];
  if (inputs.config_spacing_toggle === "gcr") {
    if (inputs.initial_track_mode === 2) {
      if (inputs.gcrs[0] < 0.2) {
        errors.push({ keyword: "validateConfig", message: "The GCR Min is invalid. Adjust value in the /config tab" });
      }
      if (inputs.gcrs[inputs.gcrs.length - 1] > 0.5) {
        errors.push({ keyword: "validateConfig", message: "The GCR Max is invalid. Adjust value in the /config tab" });
      }
    } else {
      if (inputs.gcrs[0] < 0.2) {
        errors.push({ keyword: "validateConfig", message: "The GCR Min is invalid. Adjust value in the /config tab" });
      }
      if (inputs.gcrs[inputs.gcrs.length - 1] > 1.0) {
        errors.push({ keyword: "validateConfig", message: "The GCR Max is invalid. Adjust value in the /config tab" });
      }
    }

    if (inputs.simple_inverter !== 1 && inputs.gcrs.length * inputs.spis.length > inputs.worker_limit) {
      errors.push({
        keyword: "validateConfig",
        message:
          "The number of workers required for this SIFT run are over your limit. Adjust the range or Increment fields in the /config tab. The number of workers and your limit are shown in the /config tab.",
      });
    }
  }

  if (inputs.config_spacing_toggle === "pitch") {
    if (inputs.pitches[0] < inputs.pitch_min_limit) {
      errors.push({ keyword: "validateConfig", message: "The Pitch Min is invalid. Adjust value in the /config tab" });
    }
    if (inputs.pitches[inputs.pitches.length - 1] > inputs.pitch_max_limit) {
      errors.push({ keyword: "validateConfig", messsag: "The Pitch Max is invalid. Adjust value in the /config tab" });
    }

    if (inputs.en_mod_target !== 1 && inputs.pitches.length * inputs.spis.length > inputs.worker_limit) {
      errors.push({
        keyword: "validateConfig",
        message:
          "The number of workers required for this SIFT run are over your limit. Adjust the range or Increment fields in the /config tab. The number of workers and your limit are shown in the /config tab.",
      });
    }
  }

  if (inputs.simple_inverter !== 1 && inputs.spis[0] < inputs.spi_min_limit) {
    errors.push({
      keyword: "validateConfig",
      message: "The Strings Per Inverter Min is invalid. Adjust value in the /config tab",
    });
  }
  if (inputs.simple_inverter !== 1 && inputs.spis[inputs.spis.length - 1] > inputs.spi_max_limit) {
    errors.push({
      keyword: "validateConfig",
      message: "The Strings Per Inverter Max is invalid. Adjust value in the /config tab",
    });
  }

  if (inputs.simple_inverter === 1 && inputs.simple_inverter_dcac > 1.8) {
    errors.push({
      keyword: "validateConfig",
      message: "DC:AC is greater than 1.8. Adjust value in the /config tab",
    });
  }
  if (inputs.simple_inverter === 1 && inputs.simple_inverter_dcac < 0.9) {
    errors.push({
      keyword: "validateConfig",
      message: "DC:AC is less than 0.9. Adjust value in the /config tab",
    });
  }

  return errors.length > 0 ? errors : true;
};

export const validateWeather = (input = undefined, inputs) => {
  let errors = [];
  if (inputs.weather_summary) {
    let t_HorizGlobIrrad = 0;
    let t_HorizDiffIrrad = 0;

    for (var w in inputs.weather_summary[0]) {
      let row = inputs.weather_summary[0][w];
      t_HorizGlobIrrad += row["HorizGlobIrrad"];
      t_HorizDiffIrrad += row["HorizDiffIrrad"];
    }
    if (t_HorizGlobIrrad <= 0) {
      errors.push({ keyword: "validateWeather", message: "Weather validation failed. Please check your inputs in the Weather Tab." });
    }

    if (t_HorizGlobIrrad <= 0 && t_HorizDiffIrrad <= 0) {
      errors.push({
        keyword: "validateWeather",
        message: "Input Validation Failed. No weather data loaded to the project. Navigate to the Weather tab and import weather information for this location.",
      });
    }

    if (_.isNaN(inputs.timezone) || inputs.timezone > 12 || inputs.timezone < -12) {
      errors.push({
        keyword: "validateWeather",
        message: "Performance error. Please check the Timezone input in the weather tab.",
      });
    }
  }
  return errors.length > 0 ? errors : true;
};

export const validateWithSchema = (inputs) => {
  const ajv = new Ajv({ allErrors: true, strict: false });

  // Add custom validation keywords
  const validators = [
    { name: "validateSiteFeatures", input_name: "site_features", fn: validateSiteFeatures },
    { name: "validateLatitudeLongitude", input_name: "latitude-longitude", fn: validateLatitudeLongitude },
    { name: "validateCoordSystem", input_name: "coord_system_bbox", fn: validateCoordSystem },
    { name: "validateRoads", input_name: "road_spacing_option", fn: validateRoads },
    { name: "validateGridPoiLimit", input_name: "do_grid_poi_lim", fn: validateGridPoiLimit },
    { name: "validateInverterLimitLock", input_name: "do_inverter_limit_lock", fn: validateInverterLimitLock },
    { name: "validateInvertersAndGrouping", input_name: "inverter_grouping", fn: validateInvertersAndGrouping },
    { name: "validateModRating", input_name: "mod_rating", fn: validateModRating },
    { name: "validateInverter", input_name: "inv_rating", fn: validateInverter },
    { name: "validateRacks", input_name: "racks", fn: validateRacks },
    { name: "validateTopography", input_name: "topo_bbox", fn: validateTopography },
    { name: "validateConfig", input_name: "config_spacing_toggle", fn: validateConfig },
    { name: "validateWeather", input_name: "weather_summary", fn: validateWeather },
    { name: "validateFinance", input_name: "do_finance", fn: validateFinance },
  ];

  validators.forEach(({ name, input_name, fn }) => {
    ajv.addKeyword(name, {
      validate: (_, input) => {
        const inputSchema = input_validation_schema.properties[input_name];
        if (inputSchema && inputSchema[name] === true) {
          const result = fn(input, inputs);
          if (result === true) {
            return true;
          } else {
            ajv.errors = ajv.errors || [];
            result.forEach((error) => {
              ajv.errors.push({
                keyword: name,
                message: error.message,
                instancePath: undefined,
              });
            });
            return false;
          }
        }
        return true;
      },
    });
  });

  const validate = ajv.compile(input_validation_schema);
  const valid = validate(inputs);

  const combinedErrors = [...(validate.errors || []), ...(ajv.errors || [])].map((err) => {
    const fieldName = err?.instancePath?.replace(/^[./]+/, "");
    // debugger;
    const schemaProp = input_validation_schema.properties[fieldName];
    const customLabel = schemaProp?.label || fieldName;
    const tabName = schemaProp?.tab || "";

    if (err.keyword === "type" && schemaProp?.label) {
      return {
        ...err,
        message: `Please check the ${customLabel} input in the ${tabName} tab`,
        // message: `${customLabel} must be of type ${err.params.type}`,
      };
    } else {
      return {
        ...err,
        message: `${customLabel || "error:"} ${err.message} ${tabName ? `(Tab: ${tabName}` : ``})`,
      };
    }
  });

  if (!valid) {
    return { valid: false, errors: combinedErrors };
  }

  return { valid: true, errors: [] };
};

// export const validateWithSchema = (inputs) => {
//   const ajv = new Ajv({ allErrors: true, strict: false });

//   //input_name is the variable name in the schema where the boolean for running these functions is located.
//   // I.E. validateSiteFeatures is set to true inside the site_features property in the inputschema file.
//   const validators = [
//     { name: "validateSiteFeatures", input_name: "site_features", fn: validateSiteFeatures },
//     { name: "validateLatitudeLongitude", input_name: "latitude-longitude", fn: validateLatitudeLongitude },
//     { name: "validateCoordSystem", input_name: "coord_system_bbox", fn: validateCoordSystem },
//     { name: "validateRoads", input_name: "road_spacing_option", fn: validateRoads },
//     { name: "validateGridPoiLimit", input_name: "do_grid_poi_lim", fn: validateGridPoiLimit },
//     { name: "validateInverterLimitLock", input_name: "do_inverter_limit_lock", fn: validateInverterLimitLock },
//     { name: "validateInvertersAndGrouping", input_name: "inverter_grouping", fn: validateInvertersAndGrouping },
//     { name: "validateModRating", input_name: "mod_rating", fn: validateModRating },
//     { name: "validateInverter", input_name: "inv_rating", fn: validateInverter },
//     { name: "validateRacks", input_name: "racks", fn: validateRacks },
//     { name: "validateTopography", input_name: "topo_bbox", fn: validateTopography },
//     { name: "validateConfig", input_name: "config_spacing_toggle", fn: validateConfig },
//     { name: "validateWeather", input_name: "weather_summary", fn: validateWeather },
//     { name: "validateFinance", input_name: "do_finance", fn: validateFinance },
//   ];

//   validators.forEach(({ name, input_name, fn }) => {
//     ajv.addKeyword(name, {
//       validate: (_, input) => {
//         const inputSchema = input_validation_schema.properties[input_name];
//         // Only run validation if the flag is set t true inside of the schema
//         if (inputSchema && inputSchema[name] === true) {
//           const result = fn(input, inputs); // pass in inputs to be validated
//           if (result === true) {
//             return true;
//           } else {
//             ajv.errors = ajv.errors || [];
//             result.forEach((error) => {
//               ajv.errors.push({
//                 keyword: name,
//                 message: error.message,
//                 instancePath: undefined,
//               });
//             });
//             return false;
//           }
//         }
//         // If the validation flag is false we just consider it to be valid.
//         return true;
//       },
//     });
//   });

//   const validate = ajv.compile(input_validation_schema);
//   const valid = validate(inputs);

//   const combinedErrors = [...(validate.errors || []), ...(ajv.errors || [])].filter((error) => !error.params?.keyword);

//   combinedErrors.forEach((err) => {
//     if (err.dataPath) {
//       let custom_label = input_validation_schema.properties[err.dataPath.replace(/^\./, "")].label || "";
//       let err_msg = err.message;
//       err.message = `${custom_label} ${err_msg}`;
//       return err;
//     } else {
//       return err;
//     }
//   });

//   if (!valid) {
//     return { valid: false, errors: combinedErrors };
//   }

//   return { valid: true, errors: [] };
// };
