import React, { useState } from "react";
import { accountActions, UserActions } from "../Redux";

//import { Info } from "../Funnel/info";
import { Spinner } from "../Spinner";
import { usePrevious } from "../Redux";

// assets
import { pass_hidden, pass_visible, triAlert } from "../../assets/images";
import { RegisterWrapper } from "./authstyles";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "./AuthProvider";

const Register = () => {
  const dispatch = useDispatch();
  const { useApi, setUser } = useAuth();
  const { register, get_library } = useApi();

  const user = useSelector((state) => state.user);

  const [user_form, set_user_form] = useState({
    first_name: "",
    company: "",
    email: "",
    password1: "",
    password2: "",
    apiKey: "none",
    apiKeyId: "",
    stripeId: "",
    product_plan: "",
  });

  const [is_registering, set_is_registering] = useState(false);
  const [error, set_error] = useState(undefined);
  const [agree_terms, set_agree_terms] = useState(false);
  const [terms_error, set_terms_error] = useState(undefined);
  const [passCriteria, set_passCriteria] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePassword2Visibility = () => {
    setShowPassword2(!showPassword2);
  };

  // const comparePassword = () => {
  //   let pw_valid = user_form.password1 === user_form.password2;
  //   if (!pw_valid) {
  //     set_comparePassError(true);
  //     set_error(`This Password doesn't match.`);
  //   } else if (pw_valid) {
  //     set_comparePassError(false);
  //     set_error(undefined);
  //   }
  // };

  const isPasswordValid = (password) => {
    const symbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/; // Add more symbols if needed
    return password.length > 8 && symbolRegex.test(password);
  };

  const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };
  const confirmPasswordCriteria = () => {
    if (!isPasswordValid(user_form.password1)) {
      set_passCriteria(true);
    } else {
      set_passCriteria(false);
    }
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    const newPassword = e.target.value;
    set_user_form({ ...user_form, password1: newPassword });
    // if(newPassword.length >=8 ){
    //   confirmPasswordCriteria()
    // }
  };
  const handleBlur = (e) => {
    e.preventDefault();
    confirmPasswordCriteria();
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (prev_user_form?.password2 && prev_user_form?.password2 !== user_form?.password2) {
  //       setTimeout(() => {
  //         comparePassword();
  //       }, 1000);
  //     }
  //
  //     if (prev_user_form?.password1.length > 0 && prev_user_form?.password1.length < 8) {
  //       set_passCriteria(true);
  //     } else {
  //       set_passCriteria(false);
  //     }
  //   }, 1000);
  // }, [user_form.password1, user_form.password2]);

  const agree_to_terms = (bool) => {
    if (bool) {
      localStorage.setItem("TOS_Agreement", 1);
    } else {
      localStorage.setItem("TOS_Agreement", 0);
    }
    set_agree_terms(bool);
  };
  const isStringValid = (str) => {
    return typeof str === "string" && str.trim() !== "";
  };

  const enableSignUp = () => {
    return isStringValid(user_form.email) && isPasswordValid(user_form.password1) && isStringValid(user_form.password2) && agree_terms;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    set_is_registering(true);
    // get a reCaptcha token to send to backend
    // const token = await this.reCaptchaRef.executeAsync();
    // console.log('reCaptcha token', token)
    // this resets the the recaptcha in case there was some error with submitting the form, so that recaptcha can work a subsequent time.
    // this.reCaptchaRef.reset();
    set_error(undefined);
    set_terms_error(undefined);
    if (!validateEmail(user_form.email)) {
      set_error("Email format is not valid");
      set_is_registering(false);
    } else if (user_form.password1 !== user_form.password2) {
      set_error(`This Password doesn't match.`);
      set_is_registering(false);
    } else if (!agree_terms) {
      set_terms_error("Must accept Terms to continue");
    } else {
      //set_submitted(true);
      let response = register(user_form);

      // dispatch(UserActions.register(user_form));
    }
  };

  const handleToggleFunnel = (e, val = true) => {
    dispatch(accountActions.toggleFunnelVis(val));
  };

  return (
    <RegisterWrapper>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="form-input">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            autoComplete="email"
            onChange={(e) => {
              e.preventDefault();
              set_user_form({ ...user_form, email: e.target.value.trimEnd() });
            }}
          />
        </div>
        <div className="form-input">
          <label htmlFor="password1">Password</label>
          <div className="password-input">
            <input type={showPassword ? "text" : "password"} name="password1" onChange={handlePasswordChange} onBlur={handleBlur} autoComplete="new-password"></input>
            <span className="toggle-password" onClick={togglePasswordVisibility}>
              <img src={showPassword ? pass_visible : pass_hidden} />
            </span>
          </div>
          {passCriteria && <span className="pw-error">Password must be greater than 8 Characters and contain at least 1 symbol</span>}
        </div>
        <div className="form-input">
          <label htmlFor="password2">Confirm Password</label>

          <div className="password-input">
            <input
              type={showPassword2 ? "text" : "password"}
              name="password2"
              autoComplete="password2"
              onChange={(e) => {
                e.preventDefault();
                set_user_form({ ...user_form, password2: e.target.value });
                //console.log("isfasdfsaf", user_form);
              }}
            />
            <span className="toggle-password" onClick={togglePassword2Visibility}>
              <img src={showPassword2 ? pass_visible : pass_hidden} />
            </span>
          </div>
          {error && (
            <span className="form-alert">
              <img src={triAlert} />
              <p>{error}</p>
            </span>
          )}
        </div>
        <div className="terms">
          <input type="checkbox" name="agree_terms" disabled={user.registering || user.loggingIn} checked={agree_terms} onChange={(e) => agree_to_terms(e.target.checked)} />
          <p onClick={() => agree_to_terms(!agree_terms)}>
            I accept the{" "}
            <span>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(UserActions.showTerms(true));
                }}
              >
                Terms and Conditions
              </a>
            </span>
          </p>
        </div>
        {terms_error && !agree_terms && (
          <span className="form-alert" style={{ justifyContent: "center" }}>
            <img src={triAlert} />
            <p>{terms_error}</p>
          </span>
        )}
        <button type="submit" disabled={!enableSignUp()} className={!enableSignUp() ? "disabled-button" : ""}>
          <span className="button-content">
            {is_registering && <Spinner />}
            Sign Up
          </span>
        </button>
        <div className="pricing-link">
          <a
            onClick={(e) => {
              e.preventDefault();
              handleToggleFunnel();
            }}
          >
            Plans and Pricing
          </a>
        </div>
      </form>
    </RegisterWrapper>
  );
};

export { Register };
