import React, { Fragment, useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, EPSGData, checkCoordSystemBounds } from "../..";

import { Input, Switch, Tooltip, Select, Radio, Divider } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { gft_on, gft_off, sat_on, sat_off } from "../../../assets/images";

import { PanelBody } from "../PanelBody";
import * as turf from "@turf/turf";

let disabled_layout_inputs = {
  default: [],
  simple_inv: ["inverter_limit", "inverter_grouping"],
};

const LayoutInputs = memo(() => {
  const dispatch = useDispatch();
  // const inputs = useSelector((state) => state.sift.ioManager.inputs.layout.data);
  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);
  const [input_feedback, set_input_feedback] = useState({ type: "", present: false, msg: "" });
  const [EPSG_dropdown, set_EPSG_dropdown] = useState();
  const [disabled_inputs, set_disabed_inputs] = useState("default");

  const coord_code = inputs.epsg;

  const export_unit = EPSGData[coord_code].name.toLowerCase().indexOf("feet") >= 0 ? "Feet" : "Meter";

  useEffect(() => {
    if (inputs.simple_inverter === 1) {
      set_disabed_inputs("simple_inv");
    } else {
      set_disabed_inputs("default");
    }
  }, [inputs.simple_inverter]);

  useEffect(() => {
    set_local_inputs(inputs);

    // if (inputs.do_inv_spacing == 1 && inputs.road_to_rack_inv < inputs.inv_pad_y) {
    //   set_inv_error({ present: true, msg: "Road to Rack, Inv. must be >= PadY" });
    // } else {
    //   set_inv_error({ present: false, msg: "" });
    // }

    // if (inputs.road_spacing_option == 0) {
    //   updateInput("inverter_grouping", 0);
    // }
  }, [inputs]);

  useEffect(() => {
    if (tab === "layout" || local_inputs.site_features) {
      update_EPSG_dropdown();
    }
  }, [tab, local_inputs.site_features]);
  const update_EPSG_dropdown = () => {
    let temp_EPSG_dropdown = {};
    let temp_EPSG_items = { [0]: EPSGData[0] };

    if (Object.values(local_inputs.site_features).length > 0) {
      Object.values(EPSGData).map((item) => {
        if (item.bbox) {
          let temp_coord_system_bbox = turf.flip(turf.bboxPolygon([item.bbox["south_latitude"], item.bbox["west_longitude"], item.bbox["north_latitude"], item.bbox["east_longitude"]]));
          let in_bounds = checkCoordSystemBounds(local_inputs.site_features, temp_coord_system_bbox);
          if (in_bounds) {
            temp_EPSG_items = { ...temp_EPSG_items, [item.id.code]: item };
          }
        }
      });
    }
    if (Object.values(temp_EPSG_items).length > 1) {
      temp_EPSG_dropdown = temp_EPSG_items;
    } else {
      temp_EPSG_dropdown = EPSGData;
    }

    set_EPSG_dropdown(temp_EPSG_dropdown);
  };

  // const road_to_rack_update = () => {
  //   if (local_inputs.do_inv_spacing == 1 && local_inputs.road_to_rack_inv < local_inputs.inv_pad_y) {
  //     updateInput("road_to_rack_inv", local_inputs.inv_pad_y);
  //   }
  // };

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });

    // do some input validation here
    let fixed_value = value;
    let decPart = (value + "").split(".")[1];
    if (decPart && decPart.slice(-1) == "0") return;
    // console.log(key, fixed_value)

    if (key === "road_spacing" || key === "strings_per_combiner" || key === "sazm" || key === "wiring_inv_rating" || key === "inverter_per_cut") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      if (key === "road_spacing" || key === "sazm") {
        // Should parse the value as the type the input is (float / int / etc)
        fixed_value = parseFloat(value);
      } else {
        //  'strings_per_combiner' or 'wiring_inv_rating'
        fixed_value = parseInt(value);
      }
    }

    // dispatch(portalActions.updateWiring(key,fixed_value));
    dispatch(inputsActions.update_input(key, fixed_value));
  };
  function validateAndUpdateInput(id, value, minValue, maxValue) {
    if (value < minValue) {
      updateInput(id, minValue);
    } else if (value > maxValue) {
      updateInput(id, maxValue);
    }
  }
  const check_ranges = (id, value) => {
    set_input_feedback({ type: "", present: false, msg: "" });
    switch (id) {
      case "road_width":
        validateAndUpdateInput(id, value, 2, 6);
        break;
      case "road_to_rack":
        validateAndUpdateInput(id, value, 1, 10);
        break;
      case "inverter_per_cut":
        validateAndUpdateInput(id, value, 1, 10);
        break;
      case "inv_pad_x":
        validateAndUpdateInput(id, value, 1, 10);
        break;
      case "inv_pad_y":
        validateAndUpdateInput(id, value, 1, 4);
        break;
      case "road_to_rack_inv":
        if (local_inputs.do_inv_spacing === 1 && value >= 1 && value <= 10) {
          if (value < local_inputs.inv_pad_y) {
            updateInput(id, local_inputs.inv_pad_y);
          }
        } else {
          validateAndUpdateInput(id, value, 1, 10);
        }
        break;
      default:
        break;
    }
  };

  const display_inputs_feedback = (id) => {
    switch (id) {
      case "road_width":
        set_input_feedback({ type: "roads", present: true, msg: "Road Width must be 2-6" });
        break;
      case "road_to_rack":
        set_input_feedback({ type: "roads", present: true, msg: "Road to Rack must be 1-10" });
        break;
      case "inverter_per_cut":
        set_input_feedback({ type: "inverters_grouping", present: true, msg: "Draw Inverters must be 1-10" });
        break;
      case "inv_pad_x":
        set_input_feedback({ type: "inverters_grouping", present: true, msg: "Pad X must be 1-10" });
        break;
      case "inv_pad_y":
        set_input_feedback({ type: "inverters_grouping", present: true, msg: "Pad Y must be 1-4" });
        break;
      case "road_to_rack_inv":
        set_input_feedback({ type: "inverters_grouping", present: true, msg: "Road to Rack, Inv must be 1-10 but >= Pad Y" });
        break;
      default:
        set_input_feedback({ type: "", present: false, msg: "" });
        break;
    }
  };

  return (
    <PanelBody>
      <div className="inputs-panel">
        <Divider className="weather-divider" orientation="left">
          <span>Limits</span>
        </Divider>
        <Fragment>
          <div className="input-three-col">
            <label>POI Limit</label>
            <Switch
              size="small"
              id="do_grid_poi_lim"
              checked={local_inputs.do_grid_poi_lim === 1}
              // onChange={(b, e) => { updateInput("doGridPower", b ? 1 : 0, "performance"); }}
              onChange={(checked) => {
                updateInput("do_grid_poi_lim", checked ? 1 : 0);
              }}
            />
            <Input
              // style={{ width: '136px', marginLeft: 'auto' }}
              size="small"
              disabled={local_inputs.do_grid_poi_lim !== 1}
              id="ac_grid_poi_lim"
              type="number"
              min={1}
              max={10000000000}
              value={local_inputs.ac_grid_poi_lim}
              // onChange={(event) => { updateInput(event.target.id, event.target.value, "performance"); }}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kWac</span>}
            />
          </div>
          {/* Inverter Quantity */}
          <Tooltip placement="right" title={disabled_layout_inputs[disabled_inputs].indexOf("inverter_limit") !== -1 ? "Fixed Inverter Qty is not available while running with a Simple Inverter" : ""}>
            <div className="input-three-col">
              <label>Fixed Inverter Qty</label>
              <Switch
                size="small"
                id="do_inverter_limit_lock"
                checked={local_inputs.do_inverter_limit_lock === 1}
                // onChange={(b, e) => { updateInput("do_inverter_limit_lock", b ? 1 : 0); }}
                disabled={disabled_layout_inputs[disabled_inputs].indexOf("inverter_limit") !== -1 ? true : undefined}
                onChange={(checked) => {
                  updateInput("do_inverter_limit_lock", checked ? 1 : 0);
                }}
              />
              <Input
                // style={{ width: '136px', marginLeft: 'auto' }}
                size="small"
                disabled={local_inputs.do_inverter_limit_lock === 0 || disabled_layout_inputs[disabled_inputs].indexOf("inverter_limit") !== -1 ? true : undefined}
                id="inverter_limit"
                type="number"
                min={1}
                max={500}
                value={local_inputs.do_inverter_limit_lock === 0 ? `Max` : local_inputs.inverter_limit}
                // onChange={(event) => { updateInput(event.target.id, event.target.value); }}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>inv qty</span>}
              />
            </div>
          </Tooltip>

          <div className="input-one-col">
            {local_inputs.do_inverter_limit_lock === 1 ? (
              <span>{`Layout Limited to ~${((local_inputs.inverter_limit * local_inputs.inv_rating) / 1000).toFixed(2)}MWac`}</span>
            ) : (
              <span>Run will maximize inverter quantity for each config</span>
            )}
          </div>
        </Fragment>

        <Divider className="weather-divider" orientation="left">
          <span>Rack Positioning</span>
        </Divider>
        <Fragment>
          {/* racking align images */}
          <div className="racking-input">
            <div className="radio-buttons">
              {local_inputs.track_mode === 0 ? (
                <span>
                  <img style={{ marginRight: "40px" }} src={gft_on}></img>
                  <img src={gft_off}></img>
                </span>
              ) : (
                <span>
                  <img style={{ marginRight: "40px" }} src={sat_on}></img>
                  <img src={sat_off}></img>
                </span>
              )}
            </div>
          </div>
          <div className="racking-input">
            <div className="radio-buttons">
              <Radio.Group
                name="do_rack_align"
                onChange={(event) => {
                  if (event.target.value == 0) {
                    updateInput("do_roads", 0);
                    updateInput("road_spacing_option", 0);
                  } else if (event.target.value == 1) {
                    updateInput("do_roads", 1);
                    updateInput("road_spacing_option", 2);
                  }
                  updateInput(event.target.name, event.target.value);
                }}
                value={local_inputs.do_rack_align}
              >
                <Radio style={{ marginRight: "40px" }} value={1}>
                  Aligned
                </Radio>
                <Radio value={0}>Unaligned</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="input-two-col small">
            <label>Azimuth</label>
            <Input
              id="sazm"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.sazm}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
            />
          </div>

          <div className="input-one-col coord">
            <label>Layout Coordinate System</label>
          </div>
          <div className="text-block">
            <Select
              id="coord-system-menu"
              showSearch
              // style={{ width: '100%' }}
              // placeholder={`Select a ${props.name} Preset`}
              optionFilterProp="children"
              onChange={(value) => {
                updateInput("epsg", value);
              }}
              value={saving ? "Loading..." : local_inputs.epsg}
              filterOption={(input, option) => option.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toLowerCase()) >= 0}
              // filterOption={(input, option) => console.log(option, input)}
            >
              {/* Drop in any custom modules the user has saved to their account */}
              {EPSG_dropdown &&
                Object.values(EPSG_dropdown).map((item, index) => {
                  return (
                    <Select.Option value={item.id.code} key={index}>
                      <Tooltip placement="top" title={item.name}>
                        {/* {item.id.code != '0' ? <span>{`${item.id.code} - ${item.name}`}</span> : <span>{item.name}</span>} */}
                        <span>{item.name}</span>
                      </Tooltip>
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          {coord_code > 0 && (
            <div className="input-one-col coord indent">
              <a href={`https://epsg.io/${coord_code}`} target="_blank">
                Coordinate System Data
              </a>
            </div>
          )}
          <div className="input-one-col coord indent">
            <label>Export Unit: {export_unit}</label>
          </div>

          <Divider className="weather-divider" orientation="left">
            <span>Roads</span>
          </Divider>

          {local_inputs.road_spacing_option > 0 && (
            <>
              {input_feedback.present && input_feedback.type === "roads" ? (
                <div className="err">
                  <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "12px" }} />
                  <p>{input_feedback.msg}</p>
                </div>
              ) : (
                <div className="err">
                  <CheckCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "12px" }} />
                  <p>These inputs look okay</p>
                </div>
              )}
            </>
          )}
          {/* East-West Roads */}
          <Tooltip placement="top" title={local_inputs.do_rack_align === 0 ? "Roads are disabled if racking positioning is unaligned" : ""}>
            <div className="input-do-roads">
              <label>Draw East-West Roads</label>
              <Radio.Group
                style={{ marginLeft: "auto" }}
                value={local_inputs.road_spacing_option}
                disabled={local_inputs.do_rack_align === 0}
                buttonStyle="solid"
                size="small"
                onChange={(event) => {
                  if (event.target.value === 0) {
                    updateInput("do_roads", 0);
                  } else {
                    updateInput("do_roads", 1);
                  }
                  updateInput(event.target.id, event.target.value);
                }}
              >
                <Radio.Button id="road_spacing_option" value={0}>
                  Off
                </Radio.Button>
                <Radio.Button id="road_spacing_option" value={2}>
                  Auto
                </Radio.Button>
                <Radio.Button id="road_spacing_option" value={1}>
                  Manual
                </Radio.Button>
              </Radio.Group>
            </div>
          </Tooltip>
          {/* value dependent on local_inputs */}
          <div className="input-two-col small">
            <label>Max Road Spacing</label>
            <Input
              disabled={local_inputs.road_spacing_option === 2 || local_inputs.road_spacing_option === 0}
              size="small"
              id="road_spacing"
              min={1}
              max={500}
              value={local_inputs.road_spacing}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>

          <div className="input-two-col small">
            <label>Road Width</label>
            <Input
              id="road_width"
              type="number"
              onFocus={(e) => display_inputs_feedback(e.target.id)}
              onBlur={(e) => check_ranges(e.target.id, e.target.value)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.road_width}
              size="small"
              disabled={saving || local_inputs.road_spacing_option === 0}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="input-two-col small">
            <label>Road to Rack</label>
            <Input
              id="road_to_rack"
              type="number"
              onFocus={(e) => display_inputs_feedback(e.target.id)}
              onBlur={(e) => check_ranges(e.target.id, e.target.value)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.road_to_rack}
              size="small"
              disabled={saving || local_inputs.road_spacing_option === 0}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>

          <Divider className="weather-divider" orientation="left">
            <span> Inverters & Grouping</span>
          </Divider>

          {local_inputs.inverter_grouping > 0 && (
            <>
              {input_feedback.present && input_feedback.type === "inverters_grouping" ? (
                <div className="err">
                  <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "12px" }} />
                  <p>{input_feedback.msg}</p>
                </div>
              ) : (
                <div className="err">
                  <CheckCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "12px" }} />
                  <p>These inputs look okay</p>
                </div>
              )}
            </>
          )}

          {inputs.simple_inverter === 1 && (
            <div className="err">
              <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "12px" }} />
              <p>Grouping disabled when using a Simple Inverter</p>
            </div>
          )}

          <div className="input-do-roads">
            <label>Inverter Groups</label>
            <Tooltip placement="right" title={local_inputs.road_spacing_option === 0 && "Draw East-West Roads must be on Auto or Manual to run Inverters & Grouping"}>
              <Radio.Group
                style={{ marginLeft: "auto" }}
                value={local_inputs.inverter_grouping}
                disabled={local_inputs.road_spacing_option === 0 || disabled_layout_inputs[disabled_inputs].indexOf("inverter_grouping") !== -1 ? true : undefined}
                // disabled={rackType == 2}
                buttonStyle="solid"
                size="small"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
              >
                <Radio.Button id="inverter_grouping" value={0}>
                  Off
                </Radio.Button>
                <Radio.Button id="inverter_grouping" value={1} disabled>
                  Fill
                </Radio.Button>
                <Radio.Button id="inverter_grouping" value={2}>
                  Integer
                </Radio.Button>
              </Radio.Group>
            </Tooltip>
          </div>
          <div className="input-three-col">
            <label>Draw Inverters</label>
            <Switch
              // style={{ marginLeft: 'auto' }}
              size="small"
              disabled={local_inputs.inverter_grouping === 0}
              //   disabled={local_inputs.do_inv_spacing == 0}
              id="do_inv_spacing"
              checked={local_inputs.do_inv_spacing === 1}
              onChange={(checked) => {
                updateInput("do_inv_spacing", checked ? 1 : 0);
              }}
            />
            <Input
              // style={{ width: '136px', marginLeft: 'auto' }}
              size="small"
              disabled={local_inputs.do_inv_spacing === 0}
              id="inverter_per_cut"
              min={1}
              max={500}
              type="number"
              step="1"
              value={local_inputs.inverter_per_cut}
              onFocus={(e) => display_inputs_feedback(e.target.id)}
              onBlur={(e) => check_ranges(e.target.id, e.target.value)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>inv/pad</span>}
            />
          </div>
          <div className="input-two-col small">
            <label>Pad X</label>
            <Input
              id="inv_pad_x"
              type="number"
              onFocus={(e) => display_inputs_feedback(e.target.id)}
              onBlur={(e) => check_ranges(e.target.id, e.target.value)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_pad_x}
              size="small"
              disabled={saving || local_inputs.do_inv_spacing === 0}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="input-two-col small">
            <label>Pad Y</label>
            <Input
              id="inv_pad_y"
              type="number"
              onFocus={(e) => display_inputs_feedback(e.target.id)}
              onBlur={(e) => check_ranges(e.target.id, e.target.value)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_pad_y}
              size="small"
              disabled={saving || local_inputs.do_inv_spacing === 0}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="input-two-col small">
            <label>Road to Rack, Inv.</label>
            <Input
              id="road_to_rack_inv"
              type="number"
              onFocus={(e) => display_inputs_feedback(e.target.id)}
              onBlur={(e) => check_ranges(e.target.id, e.target.value)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.road_to_rack_inv}
              size="small"
              disabled={saving || local_inputs.do_inv_spacing === 0}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>

          {/* <div className="input-two-col">
					<label style={{ gridColumn: "1 / -1" }}>Racking on Topography Actions</label>
				</div>
				<div className="input-one-column" style={{ marginLeft: 5 }}>
					<Radio.Group style={{ display: "flex", flexDirection: "column", marginBottom: 8 }} value={local_inputs.topo_action} onChange={(e) => updateInput("topo_action", e.target.value)}>
						<Radio value="nothing" checked={local_inputs.topo_action == "nothing"}>
							No Action, Ignore Grade
						</Radio>
						<Radio value="delete" checked={local_inputs.topo_action == "delete"}>{`Remove if Grade > Racking Grade Limit`}</Radio>
					</Radio.Group>
				</div> */}

          {internal_sift && (
            <>
              <Divider className="weather-divider" orientation="left">
                <span>Wiring</span>
              </Divider>

              <div className="input-three-col">
                <label>String Wiring</label>
                <Switch style={{ gridColumn: 3, margin: "0px auto 0px 0px" }} size="small" checked={local_inputs.do_wiring === 1} onChange={(checked) => updateInput("do_wiring", checked ? 1 : 0)} />
                <></>
              </div>

              <div className="input-two-col small radio grid-start-3">
                <label>Combiner Location</label>
                <Radio.Group value={local_inputs.combiner_location} onChange={(e) => updateInput("combiner_location", e.target.value)} disabled={local_inputs.do_wiring === 0}>
                  <Radio value={0}>Centroid</Radio>
                  <Radio value={1} disabled={local_inputs.do_roads === 0}>
                    Road
                  </Radio>
                </Radio.Group>
              </div>

              <div className="input-two-col small">
                <label>Strings per Combiner:</label>
                <Input
                  className="gm-input"
                  size="small"
                  value={local_inputs.strings_per_combiner}
                  onChange={(e) => updateInput("strings_per_combiner", e.target.value)}
                  min="0"
                  max="100"
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>#</span>}
                  disabled={local_inputs.do_wiring === 0}
                />
              </div>

              <div className="input-two-col small radio grid-start-3">
                <label>Stringing</label>
                <Radio.Group value={local_inputs.wire_stringing} onChange={(e) => updateInput("wire_stringing", e.target.value)} disabled={local_inputs.do_wiring === 0}>
                  <Radio value={0}>Standard</Radio>
                  <Radio value={1} disabled>
                    Skip
                  </Radio>
                </Radio.Group>
              </div>
            </>
          )}
          <Divider className="weather-divider" orientation="left">
            <span>Misc</span>
          </Divider>

          <div className="input-three-col">
            <label>Designer Margin</label>
            <Switch
              size="small"
              id="do_designer_margin"
              checked={local_inputs.do_designer_margin === 1}
              onChange={(b, e) => {
                updateInput("do_designer_margin", b ? 1 : 0);
              }}
            />
            {/* value dependant local_inputs */}
            <Input
              disabled={local_inputs.do_designer_margin !== 1}
              size="small"
              type="number"
              id="designer_margin"
              min={0}
              max={20}
              value={local_inputs.designer_margin}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </div>
          {local_inputs.do_designer_margin === 1 && (
            <div className="input-one-col">
              <span>
                Layout is reduced by this percent. See <span style={{ color: "var(--primary-brand-color)" }}>?</span> for details.
              </span>
            </div>
          )}
        </Fragment>
      </div>
    </PanelBody>
  );
});

export { LayoutInputs };
