import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EPSGData, inputsActions, siftActions, create_UUID } from "../Redux";
import { OutputPanelWrapper, ToggleResultWrapper } from "./sc";
import { ResultDetail, Star } from "./ResultDetail";
import { ResultTable } from "./ResultTable";
import { createData, defaultOptions, Graph3D } from "../Graph3D";
import { DownloadPDF } from "../PDFReport";

import { ReportActions, ResultActions } from "./PanelButtons";

import domtoimage from "dom-to-image";

import { generateLossTable, LiveReport } from "../LiveReport";

import { CloseCircleOutlined, CopyOutlined, DownOutlined, FileExcelOutlined } from "@ant-design/icons";

import { message, Popconfirm, Spin, Tabs, Tooltip, Typography } from "antd";
import { Button } from "../BaseComponents";
import { CSVLink } from "react-csv";
import { LayoutEditor } from "../LayoutEditor";
import { RecenterPinButton } from "../InputPanel";

const { TabPane } = Tabs;

const { Paragraph, Text } = Typography;

//import { hexagon_1 } from "../../assets/images";

// const icons = {
//   0: <FileUnknownOutlined />,
//   1: <ClusterOutlined />,
//   2: <BuildOutlined />,
//   3: <BarChartOutlined />,
//   4: <SmileOutlined />,
// };

const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

let MM = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
let DD = d.getDate() + 1 < 10 ? `0${d.getDate() + 1}` : d.getDate() + 1;
let YYYY = d.getFullYear().toString();
let MMDDYYYY = MM + DD + YYYY;

const OutputPanel = () => {
  const ioManager = useSelector((state) => state.sift.ioManager);
  const userName = useSelector((state) => state.user.email);
  const reportData = useSelector((state) => state.sift.ioManager.report.reportData);
  const graph_id = create_UUID();
  // const preparingReport = useSelector((state) => state.sift.ioManager.report.preparingReport);
  const reportComplete = useSelector((state) => state.sift.ioManager.report.reportComplete);
  const detailsVisible = useSelector((state) => ioManager.uiState.output_detail_visible);
  //const weather = useSelector((state) => state.sift.ioManager.inputs.weather);
  const selectedResult = useSelector((state) => state.sift.ioManager.outputs.selectedResult);
  const plan_restrictions = useSelector((state) => state.user.plan_restrictions);
  const project_id = useSelector((state) => state.inputs.project_id || undefined);
  const saved_results = useSelector((state) => state.inputs.saved_results);
  const sift_inputs = useSelector((state) => state.sift.ioManager.backup_inputs);
  const do_finance = useSelector((state) => state.inputs.do_finance);
  const [file_name, set_file_name] = useState(undefined);

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  const download_lock = useSelector((state) => (internal_sift && state.user.admin ? false : state.inputs.uiState.download_lock));
  const download_type = useSelector((state) => state.inputs.uiState.download_type);
  const outputs = ioManager.outputs;
  const error_messages = useSelector((state) => (state.sift.ioManager.uiState.error_messages.length > 0 ? state.sift.ioManager.uiState.error_messages : outputs.errors));
  const saved_results_present = Object.values(saved_results || {}).filter((res) => res.saved_result).length > 0;
  // useEffect(() => {
  //   console.log("error_messages", error_messages);
  // }, [error_messages]);

  // useEffect(() => {
  //   // console.log("weather", weather);
  // }, [weather]);

  // useEffect(() => {
  //   console.log("results", results);
  // }, [results]);

  // const { layout } = outputs;
  const doFinance = do_finance === 1;
  const [graphMode, setGraphMode] = useState(doFinance ? 1 : 0);
  //const epsgCode = ioManager.inputs.layout.data.epsg;
  // const [detailsVisible, setDetailsVisible] = useState(false)
  const [activeTab, setActiveTab] = useState(outputs.runState.generated ? "resultList" : saved_results_present ? "saved_results" : "resultList");
  const [detailedResults, setDetailedResults] = useState([]);
  const [hold, setHold] = useState(false);
  const [capturingReport, setCapturingReport] = useState(false);
  const [fake_report_loader, set_fake_report_loader] = useState(false);

  const [mapRefs, setMapRefs] = useState({});
  // const [selectedResultId, setSelectedResultId] = useState(undefined);
  const textArea = useRef(null);
  const csvRef = useRef();

  const code = internal_sift ? 153 : sift_inputs?.do_finance === 1 && sift_inputs?.metric === 0 ? 151 : sift_inputs?.do_finance !== 0 && sift_inputs?.metric === 1 ? 152 : 150;
  const results = Object.values(outputs.results);
  const show_live_report = ioManager.uiState.show_live_report;
  const show_layout_editor = ioManager.uiState.show_layout_editor;
  const edited_layouts = useSelector((state) => state.inputs.edited_layouts);

  const loading_layout = ioManager.uiState.loading_layout;
  const loading_files = ioManager.uiState.loading_files;
  const map_loading = ioManager.inputs.map.loading_map;

  const [saved_result_exists_in_current_results, set_saved_result_exists_in_current_results] = useState(false);

  useEffect(() => {
    if (!results || !selectedResult) return;
    let exists = results?.some((res) => res?.id === selectedResult?.id);
    set_saved_result_exists_in_current_results(exists);
  }, [selectedResult]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("ACtive", activeTab);
  // }, [activeTab]);

  // useEffect(() => {
  //   console.log("results.length ", ioManager);
  // }, [ioManager]);

  // useEffect(() => {
  //   console.log(detailsVisible);
  //   if (selectedResult && activeTab != `detail_${selectedResult.id}`) {
  //     if (detailedResults.findIndex((resId) => resId != selectedResult.id) > 0) {
  //       // setActiveTab(`detail_${selectedResult.id}`);
  //     } else {
  //       onEdit(selectedResult.id, 'add');
  //     }
  //   }
  // }, [detailsVisible]);

  useEffect(() => {
    if (download_lock === false) {
      if (download_type === "ground_report") {
        captureLiveReport();
      }
      if (download_type === "ground_csv") {
        setTimeout(() => {
          csvRef.current.link.click();
        }, 1000);
      }
      if (download_type === "ground_cad") {
        setTimeout(() => {
          onExportMap("export_dxf");
        }, 1000);
      }
      if (download_type === "ground_xlsx") {
        setTimeout(() => {
          onDownloadFiles(selectedResult);
        }, 1000);
      }
    }
  }, [download_lock]);

  useEffect(() => {
    if (outputs.runState.generated && activeTab !== "resultList") {
      setActiveTab("resultList");
    } else if (saved_results_present || Object.values(outputs.results).length > 0) {
      // setDetailsVisible(true)
      dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", true));

      setActiveTab(saved_results_present ? "saved_results" : "resultList");
    } else if (Object.values(outputs.results).length > 0 && !saved_results_present) {
      setActiveTab("resultList");
      setDetailedResults([]);
    } else {
      setActiveTab(saved_results_present ? "saved_results" : "resultList");
      setDetailedResults([]);
    }
  }, [Object.values(outputs.results).length, saved_results_present, outputs.runState.generated]);

  // useEffect(() => {
  //   if (!saved_results_present && Object.values(outputs.results).length > 0) {
  //     setActiveTab("resultList");
  //     setDetailedResults([]);
  //   }
  // }, [saved_results_present]);

  let graphData = undefined;
  let zLabel = graphMode === 0 ? "Yield" : code === 151 ? "$/MWh" : "IRR %";
  if (results.length) {
    graphData = createData(graphMode, code, results);
  }

  // function toggleFunnel(bool) {
  //   dispatch(accountActions.toggleFunnelVis(bool));
  // }

  function captureGraphImage(key, imageData) {
    let tempReportImages = {
      ...reportData.images,
      [key]: imageData,
    };
    // tempReportImages[key] = imageData;
    dispatch(siftActions.updateReportData("images", tempReportImages));
  }

  function addMapRefs(key, value) {
    setMapRefs((prevState) => ({ ...prevState, [key]: value }));
  }

  function buildFileName(result) {
    let PROJECT_NAME = _.isEmpty(result?.project_name) ? "sift_output" : result.project_name;
    let TRACK_MODE_STRING = result.track_mode === 0 ? "GFT" : result.track_mode === 1 ? "SAT" : result.track_mode === 2 && "EWF";
    let MOD_RATING = result.mod_rating;
    let INV_RATING = result.inv_rating;
    let GCR = result.gcr;
    let SPI = result.spi;
    return `${PROJECT_NAME}_${TRACK_MODE_STRING}_${MOD_RATING}W_${INV_RATING}kW_${GCR}_${SPI}_${MMDDYYYY}`;
  }

  function generateLiveReport(selectedResult) {
    let date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    let numOfPages = document.querySelectorAll(".page-wrap").length;
    // console.log("selected_", selectedResult);
    let fileName = buildFileName(selectedResult);
    let reportData = {
      ...selectedResult,
      loss_stack: generateLossTable(selectedResult),
      // location: ioManager.inputs.map.map_center,
      // images: {},
      // results: selectedResult,
      // racking: ioManager.inputs.racking.data,
      // module: ioManager.inputs.module.data,
      // inverter: ioManager.inputs.inverter.data,
      // performance: ioManager.inputs.performance.data,
      // layout: ioManager.inputs.layout.data,
      // weather: ioManager.inputs.weather.data,
      // topoData: this.props.portal.topoData,
      // projectName: selectedResult.project_name,
      userName: userName,
      totalArea: undefined,
      inactiveArea: undefined,
      boundaryArea: undefined,
      exclusionArea: undefined,
      coordSystem: EPSGData[selectedResult.epsg].name,
      // address: undefined,
      date,
      numOfPages: numOfPages,
      file_name: fileName,
      platform: platform,
    };

    set_file_name(fileName);

    dispatch(siftActions.prepareReport(JSON.parse(JSON.stringify(reportData)), true));
  }

  async function captureLiveReport(keys = undefined) {
    setCapturingReport(true);
    setHold(false);

    let tempMapimages = await collectMapImages(keys);

    // Object.keys(mapRefs).map(async (key) => {
    //   let value = await getPngUrl(mapRefs[key]).then((res) => (tempMapImages[key] = res.url));
    //   let url = { keys: keys || "overall", value };
    //   tempMapImages[key] = url.value.url;
    // });

    dispatch(siftActions.updateReportData("images", tempMapimages, true));

    setTimeout(() => {
      setCapturingReport(false);
      setHold(true);
      log_download("sift-pdf");
    }, 3000);
  }

  // const captureMapImageAsync = async (mapInstance, format = "png") => {
  //   if (!mapInstance) return null;

  //   await new Promise((resolve) => {
  //     if (mapInstance.loaded()) {
  //       resolve();
  //     } else {
  //       mapInstance.once("render", resolve);
  //     }
  //   });

  //   const canvas = mapInstance.getCanvas();
  //   const offscreenCanvas = document.createElement("canvas");
  //   offscreenCanvas.width = canvas.width;
  //   offscreenCanvas.height = canvas.height;
  //   const context = offscreenCanvas.getContext("2d");

  //   // console.log("content", context);

  //   context.drawImage(canvas, 0, 0);

  //   const imageData = await new Promise((resolve) => {
  //     setTimeout(() => {
  //       offscreenCanvas.toBlob((blob) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => resolve(reader.result);
  //         reader.readAsDataURL(blob);
  //       }, `image/${format}`);
  //     });
  //   }, 3000);

  //   return imageData;
  // };
  async function takeScreenshot(map) {
    return new Promise((resolve, reject) => {
      map.once("render", function () {
        resolve(map.getCanvas().toDataURL());
      });
      /* trigger a render and get the image url for the report */
      map.setBearing(map.getBearing());
    });
  }

  async function collectMapImages(keys = undefined) {
    let tempMapImages = {
      ...reportData.images,
      vicinityMap: undefined,
      layoutMap: undefined,
      largeLayoutMap: undefined,
    };
    for (let key in mapRefs) {
      tempMapImages[key] = await getPngUrl(mapRefs[key]);
    }
    // console.log("report map images", tempMapImages);

    return tempMapImages;
  }

  async function getPngUrl(mapRef) {
    if (mapRef.className === "layoutMap" || mapRef.className === "largeLayoutMap") {
      try {
        const imageDataUrl = await takeScreenshot(mapRef.theMapRef);
        return imageDataUrl;
      } catch (error) {
        console.error("Error capturing map image:", error);
      }
    } else {
      let width = mapRef?.width;
      let height = mapRef?.height;
      let node = document.getElementById(`${mapRef.className}`);
      return await domtoimage.toPng(node, { width, height, quality: 0.6, cacheBust: true }).catch((error) => console.log("Image capture error", error));
    }

    // if (mapRef.type == "scale") {
    //   return await domtoimage.toPng(node, { width, height, quality: 0.6, cacheBust: true }).catch((error) => console.log("Image capture error", error));
    // } else {
    //   return await domtoimage.toPng(node, { width, height, quality: 0.6, cacheBust: true }).catch((error) => console.log("Image capture error", error));
    // }
    // return new Promise((resolve, reject) => {
    //   let width = mapRef.width;
    //   let height = mapRef.height;
    //   let node = document.getElementById(`${mapRef.className}`);

    //   if (mapRef.type == "scale") {
    //     domtoimage
    //       .toPng(node, { width, height, quality: 0.6, cacheBust: true })
    //       .then((url) => resolve({ url, width, height }))
    //       .catch((error) => reject(error));
    //   } else {
    //     domtoimage
    //       .toJpeg(node, { width, height, quality: 0.6, cacheBust: true })
    //       .then((url) => resolve({ url, width, height }))
    //       .catch((error) => reject(error));
    //   }
    // });
  }

  function onPlot(result = undefined) {
    let selectedResult = result || outputs.selectedResult;
    dispatch(siftActions.getLayoutData(selectedResult));
    onEdit(selectedResult.id, "add");
  }

  function onDownloadFiles(result = undefined) {
    let selectedResult = result || outputs.selectedResult;
    let file_inputs = {
      perf_id: selectedResult.id,
      inputs: {
        ...ioManager.backup_inputs,
        site_features: [],
      },
    };
    dispatch(siftActions.downloadFiles(file_inputs));
    // dispatch(siftActions.downloadFiles(selectedResult.id));
  }

  function onShowReport(result = undefined) {
    let selectedResult = result || outputs.selectedResult;

    // console.log(selectedResult);

    if (!show_live_report) {
      set_fake_report_loader(true);
      // dispatch(siftActions.getLayoutData(selectedResult));
      generateLiveReport(selectedResult);
      setTimeout(() => {
        set_fake_report_loader(false);
      }, 3000);
    }
    dispatch(siftActions.updateUIState("show_live_report", !show_live_report));
    setActiveTab(result.saved_result ? "saved_results" : "resultList");
  }

  function onShowLayoutEditor() {
    if (!show_layout_editor) {
      set_fake_report_loader(true);

      if (!(selectedResult.id && edited_layouts && edited_layouts[selectedResult.id])) {
        let properties = {
          track_mode: selectedResult.track_mode,
          rack_sizes: selectedResult.racks,
          do_rack_align: selectedResult.do_rack_align,
          // layout_MWp_limit: parseFloat(selectedResult.capacity / 1000).toFixed(2) > 100, // converted to MWp
        };
        // Get Layout from S3
        dispatch(inputsActions.edit_layout("get_editable_layout", undefined, selectedResult.id, properties));

        // Utilize hard coded Test Data
        // console.log("LayoutTestData", LayoutTestData);
        // dispatch(inputsActions.edit_layout("add_edited_layout", temp_layout));

        // Build our own layout using a custom function and the original layout data
        // let temp_layout = createEditableLayout(layout, selectedResult.id);
        // console.log("temp_layout", temp_layout);
        // dispatch(inputsActions.edit_layout("add_edited_layout", temp_layout));
      }

      setTimeout(() => {
        set_fake_report_loader(false);
      }, 3000);
    }
    dispatch(siftActions.updateUIState("show_layout_editor", !show_layout_editor));

    setActiveTab(selectedResult?.saved_result ? "saved_results" : "resultList");
  }

  function undoAllLayoutEditing(from_details = undefined) {
    if (show_layout_editor) {
      set_fake_report_loader(true);
      dispatch(inputsActions.edit_layout("get_editable_layout", undefined, selectedResult.id));
      setTimeout(() => {
        set_fake_report_loader(false);
      }, 3000);
    } else {
      dispatch(inputsActions.edit_layout("undo_all_edits", undefined, selectedResult.id));
    }
  }

  function onCopyResults() {
    // Copy to Clipoard
    textArea.current.hidden = false;
    textArea.current.select();
    document.execCommand("copy");
    textArea.current.hidden = true;
    message.success("Results copied to clipboard", 3.5);
    log_download("sift-copy");
    // dispatch(alertActions.success('Results copied to clipboard'));
  }

  function log_download(download_type, url = undefined) {
    dispatch(siftActions.log_download(download_type, url, project_id));
  }
  function onExportMap(fileType) {
    // console.log("exporting map", fileType);
    dispatch(siftActions.exportMap(fileType));
  }
  function onSelectResult(result) {
    // setSelectedResultId(result.id);
    dispatch(siftActions.updateReportData("file_name", buildFileName(result)));
    dispatch(siftActions.selectResult(result));
  }

  function onHideResults(toggle) {
    // setDetailsVisible(toggle);
    // setSlideDownResults(true);
    dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", toggle));
  }
  function onOpenDetail(result) {
    let selectedResult = result || outputs.selectedResult;
    // dispatch(siftActions.getLayoutData(selectedResult))
    onEdit(selectedResult.id, "add");
  }

  function onEdit(targetKey, action) {
    if (action === "add" && selectedResult && !detailedResults.includes(selectedResult.id)) {
      let newOpenedResultsData = [...detailedResults, selectedResult.id];
      setDetailedResults(newOpenedResultsData);
      setActiveTab(`detail_${selectedResult.id}`);
    }

    if (action === "remove") {
      if (!selectedResult?.saved_result) {
        dispatch(inputsActions.update_saved_results("delete_saved_result", selectedResult.id));
      }
      let id = targetKey.replace("detail_", "");
      let newOpenedResultsData = detailedResults.filter((resId) => resId != id);
      setDetailedResults(newOpenedResultsData);
      setActiveTab(!selectedResult?.saved_result && results.length > 0 ? "resultList" : "saved_results");
    }
  }

  function onChangeTab(tab) {
    if (tab !== "resultList" && tab !== "saved_results") {
      let id = tab.replace("detail_", "");
      onSelectResult(outputs.results[id] || saved_results[id]);
      onPlot();
      // dispatch(siftActions.getLayoutData(selectedResult));
    }
    setActiveTab(tab);
  }

  function Export_to_Word(id, filename = "live_report_to_doc") {
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(id).innerHTML + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  const output_data_style = { outline: "unset" };
  if (error_messages.length > 0) {
    output_data_style.height = "440px";
  }

  return (
    <OutputPanelWrapper generated={outputs.runState.generated} detailsVisible={detailsVisible} show_live_report={show_live_report} show_layout_editor={show_layout_editor} id="result-table-tip">
      {(results.length > 0 || error_messages.length > 0 || saved_results_present) && (
        <ToggleResultWrapper detailsVisible={detailsVisible}>
          <section className="toggle-result">
            <a
              onClick={() => {
                if (show_live_report) {
                  dispatch(siftActions.updateUIState("show_live_report", !show_live_report));
                } else {
                  onHideResults(!detailsVisible);
                }
              }}
            >
              {detailsVisible && <DownOutlined />}
            </a>
          </section>
        </ToggleResultWrapper>
      )}
      {/* {detailsVisible && results.length > 0 && ( */}

      {(results.length > 0 || error_messages.length > 0 || saved_results_present) && (
        <section className="output-data" style={output_data_style}>
          <Tabs type="editable-card" onChange={onChangeTab} activeKey={activeTab || "resultList"} onEdit={onEdit} hideAdd={true} className="output-tabs">
            {/* <UnorderedListOutlined /> */}

            {error_messages.length === 0 && results.length > 0 && (
              <TabPane
                tab={
                  <section id="yolo">
                    <p>Results</p>
                  </section>
                }
                key="resultList"
                closable={false}
              >
                <section className="tab-results">
                  <section className="results-buttons">
                    {show_live_report || show_layout_editor ? (
                      <ReportActions
                        isLiveReport={show_live_report}
                        show={show_live_report || show_layout_editor}
                        setShow={(value) => dispatch(siftActions.updateUIState(show_live_report ? "show_live_report" : "show_layout_editor", value))}
                        isInternalSift={internal_sift}
                        canDownloadPDF={plan_restrictions.can_pdf}
                        isLoading={loading_layout || fake_report_loader || capturingReport}
                        downloadLock={download_lock}
                        captureReport={captureLiveReport}
                        toggleDownloadModal={() => dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_report"))}
                        undoEdits={undoAllLayoutEditing}
                      />
                    ) : (
                      <ResultActions
                        isInternalSift={internal_sift}
                        downloadLock={download_lock}
                        csvRef={csvRef}
                        projectName={sift_inputs?.project_name || ""}
                        csvData={outputs.csvData}
                        logDownload={log_download}
                        onCopyResults={onCopyResults}
                      />
                    )}
                  </section>

                  {show_live_report ? (
                    <>
                      {fake_report_loader ? (
                        <Spin spinning={fake_report_loader} tip="Loading PDF Report..." style={{ marginTop: 200 }} />
                      ) : (
                        <LiveReport addMapRefs={addMapRefs} loadingLayout={loading_layout} captureGraphImage={captureGraphImage} />
                      )}
                    </>
                  ) : show_layout_editor ? (
                    <>{fake_report_loader ? <Spin spinning={fake_report_loader} tip="Loading Layout Editor..." style={{ marginTop: 200 }} /> : <LayoutEditor />}</>
                  ) : (
                    <>
                      <ResultTable onSelectResult={onSelectResult} onOpenDetail={onOpenDetail} />

                      <section className="output-graph">
                        <Graph3D
                          key={graph_id}
                          data={graphData}
                          options={{
                            ...defaultOptions,
                            zLabel: zLabel,
                            zValueLabel: function (z) {
                              return graphMode === 1 && code === 151 ? z * -1 : z;
                            },
                            width: "380px",
                            height: "280px",
                            tooltip: function ({ x, y, z }) {
                              let tooltipHtml = `<table>
                                                      <tbody>
                                                        <tr>
                                                          <td>GCR:</td>
                                                          <td>${x.toFixed(4)}</td>
                                                        </tr>
                                                        <tr>
                                                          <td>DC:AC:</td>
                                                          <td>${y.toFixed(4)}</td>
                                                        </tr>
                                                        <tr>
                                                          <td>${zLabel}:</td>
                                                          <td>${Math.abs(z).toFixed(3)}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>`;
                              return tooltipHtml.toString();
                            },
                          }}
                          code={code}
                          graph={graphMode}
                        />
                        {code > 150 && (
                          <section className="result-graph-toggle">
                            <span>3D Plot Z-Axis: </span>
                            <Button size="small" type="link" onClick={() => setGraphMode(0)} disabled={graphMode === 0} style={{ color: graphMode === 0 && "#5f5f5f" }}>
                              Yield
                            </Button>
                            or
                            <Button size="small" type="link" onClick={() => setGraphMode(1)} disabled={graphMode === 1} style={{ color: graphMode === 1 && "#5f5f5f" }}>
                              {code === 151 ? "$/MWh" : "IRR %"}
                            </Button>
                          </section>
                        )}
                      </section>
                    </>
                  )}

                  {reportData && reportComplete && hold && <DownloadPDF reportData={reportData} file_name={file_name} />}
                </section>
              </TabPane>
            )}

            {saved_results_present && (
              <TabPane
                tab={
                  <section>
                    <section style={{ display: "flex", alignItems: "center" }}>
                      <Star style={{ height: 18, width: 18 }} />
                      <p style={{ marginLeft: 3 }}>Saved Results</p>
                    </section>
                  </section>
                }
                key="saved_results"
                closable={false}
              >
                <section className="tab-results">
                  <section className="results-buttons">
                    {show_live_report || show_layout_editor ? (
                      <ReportActions
                        isLiveReport={show_live_report}
                        show={show_live_report || show_layout_editor}
                        setShow={(value) => dispatch(siftActions.updateUIState(show_live_report ? "show_live_report" : "show_layout_editor", value))}
                        isInternalSift={internal_sift}
                        canDownloadPDF={plan_restrictions.can_pdf}
                        isLoading={loading_layout || fake_report_loader || capturingReport}
                        downloadLock={download_lock}
                        captureReport={captureLiveReport}
                        toggleDownloadModal={() => dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_report"))}
                        undoEdits={undoAllLayoutEditing}
                      />
                    ) : activeTab !== "saved_results" ? (
                      <ResultActions
                        isInternalSift={internal_sift}
                        downloadLock={download_lock}
                        csvRef={csvRef}
                        projectName={sift_inputs?.project_name || ""}
                        csvData={outputs.csvData}
                        logDownload={log_download}
                        onCopyResults={onCopyResults}
                      />
                    ) : null}
                  </section>

                  {show_live_report ? (
                    <>
                      {fake_report_loader ? (
                        <Spin spinning={fake_report_loader} tip="Loading PDF Report..." style={{ marginTop: 200 }} />
                      ) : (
                        <LiveReport addMapRefs={addMapRefs} loadingLayout={loading_layout} captureGraphImage={captureGraphImage} />
                      )}
                    </>
                  ) : show_layout_editor ? (
                    <>{fake_report_loader ? <Spin spinning={fake_report_loader} tip="Loading Layout Editor..." style={{ marginTop: 200 }} /> : <LayoutEditor />}</>
                  ) : (
                    <section className="saved-results-table">
                      <ResultTable onSelectResult={onSelectResult} onOpenDetail={onOpenDetail} saved_results_table={true} />
                    </section>
                  )}

                  {reportData && reportComplete && hold && <DownloadPDF reportData={reportData} file_name={file_name} />}
                </section>
              </TabPane>
            )}

            {!show_layout_editor && !show_live_report && (
              <>
                {detailedResults.map((resId) => {
                  let res = outputs?.results[resId] || saved_results[resId];
                  if (!res) return;
                  return (
                    <TabPane
                      tab={
                        <span className="tab-res">
                          {parseFloat(res?.gcr?.toFixed(3))}GCR,{parseFloat(res?.dcac?.toFixed(4))}DC:AC
                        </span>
                      }
                      key={`detail_${res?.id}`}
                      closable={true}
                    >
                      <section className="output-detail">
                        <ResultDetail
                          saved_result_exists_in_current_results={saved_result_exists_in_current_results}
                          result={res}
                          meta={outputs.meta}
                          loading_layout={loading_layout}
                          loading_files={loading_files}
                          map_loading={map_loading}
                          onPlot={onPlot}
                          onCopyResults={onCopyResults}
                          onExportMap={onExportMap}
                          onDownloadFiles={onDownloadFiles}
                          onShowReport={onShowReport}
                          onShowLayoutEditor={onShowLayoutEditor}
                          undoAllLayoutEditing={undoAllLayoutEditing}
                        />
                      </section>
                    </TabPane>
                  );
                })}
              </>
            )}

            {error_messages.length > 0 && (
              <TabPane
                tab={
                  <section id="yolo">
                    <p>Errors</p>
                  </section>
                }
                key="resultList"
                closable={false}
              >
                <div className="output-panel-error">
                  <Paragraph>
                    <Text
                      strong
                      style={{
                        fontSize: 16,
                      }}
                    >
                      SIFT detected the following errors and warnings:
                    </Text>
                  </Paragraph>

                  {/* map errors_messages below */}

                  {error_messages &&
                    error_messages.length > 0 &&
                    error_messages?.map((message, index) => {
                      return (
                        <Paragraph key={index} style={{ marginLeft: 10 }}>
                          <CloseCircleOutlined style={{ color: "red" }} />{" "}
                          {message === "show_recenter_pin_error" ? (
                            <>
                              Latitude/Longitude inputs are +/- 5 degrees from the site location. Recenter pin inside weather tab or click this link → <span>{<RecenterPinButton />}</span>
                            </>
                          ) : message === "show_weather_failure_error" ? (
                            <>
                              Weather file failed to imort. Please manually input data into provided excel template →{" "}
                              <a href="https://sift-template-files.s3.us-east-2.amazonaws.com/sift_met_template_v2.xls" download>
                                Download Template
                              </a>
                            </>
                          ) : (
                            message
                          )}
                        </Paragraph>
                      );
                    })}
                </div>
              </TabPane>
            )}
          </Tabs>
        </section>
      )}

      {/* {runState.generated && !detailsVisible && (
          <section className="result-buttons-content">
            <section className="results-buttons">
              <span style={{ paddingLeft: '14px' }}>All Results:</span>

              <section style={{ textAlign: 'center' }}>
                <span style={{ paddingRight: 5 }}>
                  <CSVLink filename={outputs.fileName} data={outputs.csvData}>
                    <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" size="small">
                        <FileExcelOutlined />
                        CSV
                      </Button>
                    </Tooltip>
                  </CSVLink>
                </span>

                <Tooltip placement="top" title="All results to clipboard" mouseEnterDelay={0.5}>
                  <Button ghost type="primary" onClick={onCopyResults} size="small">
                    <CopyOutlined />
                    Copy
                  </Button>
                </Tooltip>
              </section>
            </section>
            
            {!detailsVisible && selectedResult && (
              <section className="selected-buttons">
                <section>
                  <span>
                    Plotted Result: {selectedResult.gcr.toFixed(3)} GCR, {selectedResult.dcac.toFixed(3)} DC:AC
                  </span>
                </section>
                <section>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={onPlot} loading={loading_layout} size="small">
                        {!loading_layout && <BuildOutlined />}Plot
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Selected result and 8760 performance to CSV" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={onDownloadFiles} loading={loading_files} size="small">
                        {!loading_files && <FileZipOutlined />}Report
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Export layout to DXF" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={() => onExportMap('export_dxf')} loading={map_loading} size="small">
                        {!map_loading && <AppstoreOutlined />}CAD
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={() => onExportMap('export_kml')} loading={map_loading} size="small">
                        {!map_loading && <FileImageOutlined />}KMZ
                      </Button>
                    </Tooltip>
                  </span>
                </section>
              </section>
            )}
          </section>
        )} */}

      {/* {!runState.generated && (
          <section className="progress">
            <Steps>
              {Object.values(runState.steps).map((step) => {
                return (
                  <Step
                    status={step.status}
                    title={step.title}
                    key={step.index}
                    icon={step.status == 'process' ? <LoadingOutlined /> : step.status == 'error' ? <FrownOutlined /> : icons[step.index]}
                  />
                );
              })}
            </Steps>
          </section>
        )} */}

      <textarea
        hidden
        ref={textArea}
        name="TextResults"
        cols="40"
        rows="50"
        value={outputs.tsvData}
        onChange={(e) => {
          console.log("copied to clipboard");
        }}
      />
    </OutputPanelWrapper>
  );
};

export { OutputPanel };
