const weather_schema = {
  type: "object",
  tab: "Weather",
  properties: {
    weather_name: {
      type: "string",
    },
    latitude: { label: "Latitude", _id: "latitude", type: "number", validateLatitudeLongitude: true },
    longitude: { label: "Longitude", _id: "longitude", type: "number", validateLatitudeLongitude: true },
    elevation: {
      label: "Elevation",
      _id: "elevation",
      type: "number",
    },
    timezone: {
      label: "Timezone",
      _id: "timezone",
      type: "integer",
    },
    location_obj: {
      label: "Recenter Pin",
      _id: "location",
      type: "object",
      properties: {
        city: {
          type: "string",
        },
        state: {
          type: "string",
        },
        country: {
          type: "string",
        },
      },
    },
    weather_source: {
      label: "Weather Data Source",
      _id: "weather_data",
      type: "integer",
      validateWeather: true,
    },
    weather: {
      type: "string",
      pattern: "^[a-f0-9]{32}$",
    },
    weather__id: {
      label: "Weather ID",
      _id: "weather_id",
      type: "string",
      pattern: "^[a-f0-9]{32}$",
    },
    local_weather: {
      _id: "local_weather",
      type: "integer",
      enum: [0, 1],
    },
  },
};

export { weather_schema };
