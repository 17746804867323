import React, { useState, useEffect } from "react";
import styled from "styled-components";

import blerb from "./blerb.jpg";
import sunset_bg from "./sunset_bg.png";
import { ts_logo_vert } from "../../../../assets/images";

const AnnouncementBody = styled.section`
  height: 100%;
  width: 100%;
  background: url(${sunset_bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 210px 220px 1fr;
  grid-template-areas:
    "terra-logo"
    "terra-text"
    "terra-button";
  font-family: Arial, Helvetica, sans-serif;

  .terra-logo {
    grid-area: terra-logo;
    display: grid;
    place-items: center;

    img {
      height: 125px;
    }
  }

  .terra-text {
    grid-area: terra-text;
    width: 100%;
    text-align: center;
    display: grid;
    place-items: center;

    > h2 {
      color: #fff;
      font-size: 32px;
      margin-top: 28px;
      letter-spacing: 2px;
      margin-bottom: 5px;
    }

    > p {
      color: #fff;
      font-size: 18px;
      width: 678px;
      font-weight: 600;
    }
  }

  .terra-button {
    grid-area: terra-button;
    display: grid;
    place-items: start center;
    margin-top: 20px;

    button {
      padding: 20px 105px;
      border-radius: 6px;
      background-color: #00c0b6;
      font-size: 24px;
      border: 1px solid transparent;
      color: #fff;
      font-weight: 800;
      transition: all 0.2s cubic-bezier(0.5, 0, 0.5, 1);

      &:hover {
        cursor: pointer;
        transform: translateY(-2px);
      }
    }
  }
`;

import { Modal } from "antd";

const NewsPopup = () => {
  const [announcement, setAnnouncement] = useState(true);

  return (
    <Modal
      title={"Announcement"}
      // visible={announcement}
      open={announcement}
      onCancel={() => setAnnouncement(false)}
      footer={null}
      width="900px"
      centered
      style={{ height: 600, overflowY: "auto", padding: 0 }}
    >
      <AnnouncementBody>
        <section className="terra-logo">{/* <img src={ts_logo_vert} /> */}</section>
        <section className="terra-text">
          {/* <h2>Powering Progress Together</h2>
          <p>We're bringing together our four solar companies under one unified new brand; connecting our rich legacies and shared passion for something greater.</p>
          <p>Together as Terrasmart, we will power progress for our peopleour partners, and our industry.</p> */}
        </section>
        <section className="terra-button">
          <button onClick={() => window.open("https://www.terrasmart.com/", "_blank", "noopener,noreferrer")}>LEARN MORE</button>
        </section>
      </AnnouncementBody>
    </Modal>
  );
};

export { NewsPopup };
