import { ProjectConstants, inputsConstants, canopyConstants } from "../_constants";
import { siftService, userService } from "../_services";
import { tutorialActions, alertActions, inputsActions, UserActions, canopyActions } from "../_actions";
// import { simple_inverter, default_inverter_inputs } from "../_reducers";

export const projectActions = {
  update_project,
  get_projects,
  load_project,
  refresh_project,
  share_project,
  check_in_collab,
  force_check_in,
  update_project_inputs,
};

function update_project(inputs, action_type = "save_proj") {
  // console.log("save_inputs", inputs);
  return (dispatch) => {
    dispatch(request(inputs));
    let save_inputs = {
      project_id: inputs.project_id,
      project_name: inputs.project_name,
      variant_name: inputs.variant_name,
      project_type: inputs.project_type,
      variant_id: inputs.variant_id,
      active: inputs.active,
      action: action_type,
    };
    let saved_results = inputs.saved_results;
    // console.log("save_inputs", save_inputs);
    siftService.save_project_inputs(save_inputs).then((save_response) => {
      // console.log("save_response", save_response);

      if (save_response.error) {
        dispatch(alertActions.error(save_response.error));
      } else {
        // Upload project to S3
        inputs.saved_results = inputs.saved_results ? Object.fromEntries(Object.entries(inputs?.saved_results).filter(([key, value]) => value?.saved_result)) : {};
        userService.upload_to_s3(save_response.url, JSON.stringify(inputs)).then((_response) => {
          save_response.action_type = action_type;
          // console.log(_response)
          dispatch(success(inputs, save_response));
          // saveasvariant saveasproject
          if (action_type == "saveasproject" || action_type == "saveasvariant") {
            dispatch(alertActions.success("Save successful"));
          } else if (action_type == "delete_project") {
            dispatch(alertActions.success("Project deleted successfully"));
          } else if (action_type == "delete_variant") {
            dispatch(alertActions.success("Project deleted successfully"));
          }
          if (inputs.active == 1 || inputs.active == 2) {
            if (inputs.project_type == 3 || inputs.project_type == 4) {
              dispatch(update_canopy(inputs.project_id, inputs.project_name));
            } else {
              // dispatch(update(save_response.project_id ? save_response.project_id : inputs.project_id));
              dispatch(
                inputsActions.update_bulk_inputs({
                  project_id: save_response.project_id ? save_response.project_id : inputs.project_id,
                  variant_id: save_response.variant_id ? save_response.variant_id : inputs.variant_id,
                  saved_results: action_type == "saveasvariant" ? {} : saved_results || {},
                })
              );
            }
          }
        });
      }
    });
  };
  function request(current_project) {
    return { type: ProjectConstants.UPDATE_PROJECT_REQUEST, current_project };
  }
  function success(inputs, response) {
    return { type: ProjectConstants.UPDATE_PROJECT_SUCCESS, inputs, response };
  }

  // function update(project_id) {
  //   return {
  //     type: inputsConstants.UPDATE_INPUT_BY_NAME,
  //     key: "project_id",
  //     value: project_id,
  //   };
  // }

  function update_canopy(project_id, project_name) {
    return { type: canopyConstants.UPDATE_CANOPY_BULK_INPUTS, keys: { project_id, project_name } };
  }
}

function get_projects() {
  return (dispatch) => {
    dispatch(request());

    siftService.getProjects().then(
      (response) => {
        if (response.url) {
          // download projects from s3 first
          siftService.downloadFromS3(response.url).then((_response) => dispatch(success(_response)));
        } else {
          dispatch(success(response.data));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: ProjectConstants.GET_USER_DATA_REQUEST };
  }
  function success(data) {
    return { type: ProjectConstants.GET_USER_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: ProjectConstants.GET_USER_DATA_FAILURE, error };
  }
}

function load_project(project, current_plan = 1) {
  console.log(project);
  return (dispatch) => {
    // end tutorial call
    // dispatch(tutorialActions.endTutorial());
    dispatch(request(project.project_id));
    // , project.collab_on || 0,
    // must provide project_id as well as a variant_id to load inputs
    userService.getProjectById(project.project_id, project.variant_id).then((response) => {
      // console.log(response)
      siftService.downloadFromS3(response.url).then((_response) => {
        // console.log(_response)
        if (_response.project_type === 4 || _response.project_type === 3 || (_response.rectangles && Object.values(_response.rectangles)?.length >= 0)) {
          // TODO improve this somehow. Not the best way to update these two things on loading a canopy project.
          dispatch(inputsActions.update_ui_state("pm_modal_visible", false));
          dispatch(
            inputsActions.update_bulk_inputs({
              map_center: _response.map_center,
              // project_id: _response?.project_meta?.project_id ? _response?.project_meta?.project_id : project.project_id,
              // project_name: _response.json_inputs.project_name,
              // project_type: _response.json_inputs.project_type,
            })
          );
          dispatch(loadDownloadedCanopyProject(_response, project));
        } else {
          if (_response?.images && Object.values(_response.images)?.length > 0) {
            dispatch(canopyActions.updateImportedImages("load_bulk_images", _response.images));
          }
          if (_response?.shade_objects && Object.values(_response.shade_objects)?.length > 0) {
            dispatch(canopyActions.updateShadeObject("load_bulk_shade_objects", _response.shade_objects));
            if (_response.en_shade_impact === 1) {
              // just avoiding an uneeded redux action
              dispatch(canopyActions.updateShadeObject("en_shade_impact", _response.en_shade_impact));
            }
          }

          dispatch(downloaded(_response, project, current_plan));
        }

        dispatch(complete());

        // this controls the config menu state when a project is loaded. If the project was saved as a project using target inputs, the config menu will reflect that and "Simple Inverter Input" will be loaded.
        // If if the project was saved as a projects without target inputs, the config menu will display string per inverter and the default 1000 kwh inverter is loaded.
        if (_response.en_target_inputs) {
          dispatch(UserActions.updateUserValue("en_target_inputs", true));
        } else {
          dispatch(UserActions.updateUserValue("en_target_inputs", false));
        }
      });
    });
  };
  function request(id) {
    return { type: ProjectConstants.LOAD_PROJECT_REQUEST, id };
  }

  function loadDownloadedCanopyProject(response, project_meta) {
    return { type: canopyConstants.LOAD_CANOPY_PROJECT, response, project_meta };
  }

  function downloaded(response, project_meta, current_plan) {
    return { type: inputsConstants.LOAD_PROJECT_FROM_INPUTS, response, project_meta, current_plan };
  }
  function complete() {
    return { type: ProjectConstants.LOAD_PROJECT_COMPLETE };
  }
}

function refresh_project(project_id) {
  return (dispatch) => {
    dispatch(request());
    siftService.refreshCollab(project_id).then((response) => dispatch(success(response)));
  };

  function request() {
    return { type: ProjectConstants.REFRESH_PROJECT_REQUEST };
  }
  function success(response) {
    return { type: ProjectConstants.REFRESH_PROJECT_SUCCESS, response };
  }
}

function share_project(inputs, userList = undefined) {
  return (dispatch) => {
    dispatch(request(inputs));
    siftService.shareCollab(inputs).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(inputs) {
    return { type: ProjectConstants.SHARE_COLLAB_REQUEST, inputs };
  }
  function success(response) {
    return { type: ProjectConstants.COLLAB_SUCCESS, response };
  }
  function failure(error) {
    return { type: ProjectConstants.COLLAB_FAILURE, error };
  }
}
function check_in_collab(inputs) {
  return (dispatch) => {
    dispatch(request(inputs));

    // Check-in -> save -> update collab meta
    // Check-out -> update collab meta -> load

    if (inputs.checkin) {
      // checking in, save this project first
      siftService.save_project_inputs(inputs.project).then((save_response) => {
        // dispatch(success(inputs, save_response));
        siftService.shareCollab(inputs).then(
          (response) => {
            dispatch(success(response));
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
        );
      });
    } else {
      dispatch(request_load(inputs.project_id));
      // checking out, super simple
      // dispatch(projectActions.load_project(inputs));
      siftService.shareCollab(inputs).then(
        (share_response) => {
          userService.getProjectById(inputs.project_id, 1).then((response) => {
            dispatch(success(share_response));
            siftService.downloadFromS3(response.url).then((_response) => {
              dispatch(downloaded(_response, share_response.updated_project));
              dispatch(complete_load());
            });
          });
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    }
  };

  function request(inputs) {
    return { type: ProjectConstants.CHECK_COLLAB_REQUEST, inputs };
  }
  function success(response) {
    return { type: ProjectConstants.COLLAB_SUCCESS, response };
  }
  function failure(error) {
    return { type: ProjectConstants.COLLAB_FAILURE, error };
  }

  function request_load(id) {
    return { type: ProjectConstants.LOAD_PROJECT_REQUEST, id };
  }
  function downloaded(response, project_meta) {
    return { type: inputsConstants.LOAD_PROJECT_FROM_INPUTS, response, project_meta };
  }
  function complete_load() {
    return { type: ProjectConstants.LOAD_PROJECT_COMPLETE };
  }
}
function force_check_in(inputs) {
  return (dispatch) => {
    dispatch(request(inputs));
    siftService.shareCollab(inputs).then(
      (response) => {
        dispatch(success(response));

        // dispatch(projectActions.load_project(inputs));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request(inputs) {
    return { type: ProjectConstants.CHECK_COLLAB_REQUEST, inputs };
  }
  function success(response) {
    return { type: ProjectConstants.COLLAB_SUCCESS, response };
  }
  function failure(error) {
    return { type: ProjectConstants.COLLAB_FAILURE, error };
  }
}

function update_project_inputs(key, value) {
  return { type: ProjectConstants.UPDATE_PROJECT_INPUT, key, value };
}
