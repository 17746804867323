import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";

import { canopyActions, usePrevious, getCenterOfLineString } from "../../../Redux";
import { build_shade_object } from "../ShadeHelpers";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

const DrawTreeLine = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const drawingTreeLine = useSelector((state) => state.canopy.drawing_tree_line);
  const prevDrawingTreeLine = usePrevious(drawingTreeLine);
  const shadeHeightUnit = useSelector((state) => state.canopy.shade_height_unit);

  const drawingTreeLineRef = useRef(drawingTreeLine);
  const canceledRef = useRef(false); //
  const cancelKey = useKey("Escape");

  useEffect(() => {
    drawingTreeLineRef.current = drawingTreeLine;
  }, [drawingTreeLine]);

  useEffect(() => {
    if (drawingTreeLine && !prevDrawingTreeLine) {
      canceledRef.current = false;
      drawTreeLine();
    } else if (!drawingTreeLine && prevDrawingTreeLine) {
      cancelDraw();
    }
  }, [drawingTreeLine, prevDrawingTreeLine]);

  useEffect(() => {
    canceledRef.current = cancelKey;
    if (cancelKey && drawingTreeLineRef.current) {
      cancelDraw();
    }
  }, [cancelKey]);

  useEffect(() => {
    if (!map || !draw) return;

    const onDrawCreate = debounce(handleCreated, 300);

    map.on("draw.create", onDrawCreate);

    return () => {
      map.off("draw.create", onDrawCreate);
    };
  }, [map, draw, shadeHeightUnit]);

  const drawTreeLine = () => {
    draw.changeMode("draw_line_string");
  };

  const cancelDraw = () => {
    canceledRef.current = true;
    dispatch(canopyActions.update_canopy_toolbar("drawing_tree_line", false));
    draw.changeMode("simple_select");
  };

  const handleCreated = (e) => {
    if (!draw || !drawingTreeLineRef.current || canceledRef.current) return;

    const geoJson = e.features[0];
    const center = getCenterOfLineString(geoJson);
    const corners = geoJson.geometry.coordinates[0];
    const shadeObject = build_shade_object(geoJson, corners, center, shadeHeightUnit, "treeLine");

    dispatch(canopyActions.updateShadeObject("add_shade_object", shadeObject));

    setTimeout(() => {
      draw.delete(geoJson.id);
    }, 1500);
  };

  return null;
};

const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

export { DrawTreeLine };
