const finance_schema = {
  type: "object",
  tab: "Finance Tab",
  properties: {
    finance_name: {
      label: "name",
      _id: "finance_name",
      type: "string",
    },
    do_finance: {
      label: "Enable Finance",
      _id: "do_finance",
      type: "number",
      validateFinance: true,
    },
    metric: {
      label: "Metric",
      _id: "metric",
      type: "number",
    },
    analysis_period: {
      label: "Analysis Period",
      _id: "analysis_period",
      type: "number",
    },
  },
  discount_rate: {
    label: "Discount Rate (Nom)",
    _id: "discount_rate",
    type: "number",
  },
  toggle_finance_type: {
    label: "Cost Breakdown",
    _id: "toggle_finance_type",
    type: "number",
  },
  dc_op_cost: {
    label: "DC",
    _id: "dc_op_cost",
    type: "number",
  },
  ac_op_cost: {
    label: "AC",
    _id: "ac_op_cost",
    type: "number",
  },
  fixed_cost: {
    label: "Fixed Cost",
    _id: "fixed_cost",
    type: "number",
  },
  fixed_op_cost: {
    label: "Fixed Op Costs",
    _id: "fixed_op_cost",
    type: "number",
  },
  module_dc_cost: {
    type: "object",
    label: "Module",
    _id: "module_dc_cost",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  rack_a_finance: {
    type: "object",
    label: "Rack A",
    _id: "rack_a_finance",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  rack_b_finance: {
    type: "object",
    label: "Rack B",
    _id: "rack_b_finance",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  rack_c_finance: {
    type: "object",
    label: "Rack C",
    _id: "rack_c_finance",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  bos_other: {
    type: "object",
    label: "BOS Other",
    _id: "bos_other",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  inverter_cost: {
    type: "object",
    label: "Inverter Cost",
    _id: "inverter_cost",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  ac_aux: {
    type: "object",
    label: "AC Aux",
    _id: "ac_aux",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  mv_wire: {
    type: "object",
    label: "MV Wire",
    _id: "mv_wire",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  other_ac: {
    type: "object",
    label: "Other AC",
    _id: "other_ac",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  interconnection: {
    type: "object",
    label: "Interconnect",
    _id: "interconnection",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  permits_fees: {
    type: "object",
    label: "Permits, Fees",
    _id: "permits_fees",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  engineering: {
    type: "object",
    label: "Engineering",
    _id: "engineering",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  margin: {
    type: "object",
    label: "Margin",
    _id: "margin",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  itc_ineligible: {
    type: "object",
    label: "ITC Ineligible",
    _id: "ITC Ineligible",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  other_fixed: {
    type: "object",
    label: "Other Fixed",
    _id: "Other Fixed",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  rack_footprint: {
    type: "object",
    label: "Rack Footprint",
    _id: "rack_footprint",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  boundary_area_per: {
    type: "object",
    label: "Buildable Area",
    _id: "boundary_area_per",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  contingency: {
    type: "object",
    label: "Contingency",
    _id: "contingency",
    properties: {
      value: { type: "number" },
      type: { type: "number" },
      total: { type: "number" },
    },
  },
  spacing_gcr: {
    _id: "spacing_gcr",
    type: "array",
    items: { type: "number" },
  },
  spacing_per_wp: {
    type: "array",
    _id: "spacing_per_wp",
    items: { type: "number" },
  },
  do_custom_schedule: {
    _id: "do_custom_schedule",
    type: "number",
  },
  customSchedule: {
    _id: "customSchedule",
    type: "array",
    items: { type: "number" },
  },
  lease_cost: {
    lable: "Lease Cost",
    _id: "lease_cost",
    type: "number",
  },
  revenue_type: {
    label: "Revenue Type",
    _id: "revenue_type",
    type: "number",
  },
  ri_power: {
    label: "Power",
    _id: "ri_power",
    type: "number",
  },
  escalation: {
    label: "Escalation",
    _id: "escalation",
    type: "number",
  },
  do_include_itc: {
    label: "Include ITC",
    _id: "do_include_itc",
    type: "number",
  },
  itc_percent: {
    label: "ITC Percent",
    _id: "itc_percent",
    type: "number",
  },
  do_include_ptc: {
    label: "Include PTC",
    _id: "do_include_ptc",
    type: "number",
  },
  ptc_value: {
    label: "PTC Value",
    _id: "ptc_value",
    type: "number",
  },
  debt_structure: {
    label: "Debt Structure",
    _id: "debt_structure",
    type: "number",
  },
  debt_percent: {
    label: "Percent Debt",
    _id: "debt_percent",
    type: "number",
  },
  debt_dscr: {
    label: "DSCR",
    _id: "debt_dscr",
    type: "number",
  },
  debt_interest: {
    label: "Interest",
    _id: "debt_interest",
    type: "number",
  },
  debt_tenor: {
    label: "Tenor",
    _id: "debt_tenor",
    type: "number",
  },
  dep_5yrSL: {
    label: "5yr SL",
    _id: "dep_5yrSL",
    type: "number",
  },
  dep_15yrSL: {
    label: "15yr SL",
    _id: "dep_15yrSL",
    type: "number",
  },
  dep_20yrSL: {
    label: "20yr SL",
    _id: "dep_20yrSL",
    type: "number",
  },
  dep_30yrSL: {
    label: "30yr SL",
    _id: "dep_30yrSL",
    type: "number",
  },
  dep_35yrSL: {
    label: "35yr SL",
    _id: "dep_35yrSL",
    type: "number",
  },
  dep_39yrSL: {
    label: "39yr SL",
    _id: "dep_39yrSL",
    type: "number",
  },
  dep_5yrMACRS: {
    label: "5yr MACRS",
    _id: "dep_5yrMACRS",
    type: "number",
  },
  dep_15yrMACRS: {
    label: "15yr MACRS",
    _id: "dep_15yrMACRS",
    type: "number",
  },
  state_taxes: {
    label: "State Taxes",
    _id: "state_taxes",
    type: "number",
  },
  federal_taxes: {
    label: "Federal Taxes",
    _id: "federal_taxes",
    type: "number",
  },
  tou_gr_id: {
    label: "TOU 12x24 ($/kWh)",
    _id: "tou_grid",
    type: "array",
    items: {
      type: "array",
      items: { type: "number" },
    },
  },
  summarized_dc: {
    _id: "summarized_dc",
    type: "number",
  },
  summarized_ac: {
    _id: "summarized_ac",
    type: "number",
  },
  foot_print: {
    _id: "foot_print",
    type: "number",
  },
  footprint_op_cost: {
    _id: "footprint_op_cost",
    type: "number",
  },
  area_install_cost: {
    _id: "area_install_cost",
    type: "number",
  },
  area_op_cost: {
    _id: "area_op_cost",
    type: "number",
  },

  ri_escalation: {
    _id: "ri_escalation",
    type: "number",
  },

  do_ri_customSchedule: {
    _id: "do_ri_customSchedule",
    type: "number",
  },
  ri_customSchedule: {
    type: "array",
    items: { type: "number" },
  },
};

export { finance_schema };
