import React, { Fragment } from "react";
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet } from "@react-pdf/renderer";

import {
  Tracker_Breakdown,
  ModuleSection,
  Racking,
  Performance,
  Rundown,
  IAMTable_report,
  InverterTable,
  LayoutTable,
  BOMTable,
  SoilingAlbedoTable,
  PerfSummaryTable,
  LossTable,
  TopoLossTable,
} from "./SummaryTable";

//product images
import { dahlia_fuse, glide_front, glide_rear, terratrack } from "../../assets/images";

//import { getTopoScale } from "../LiveReport";

import sift_logo from "./sift_logo.png";
import { ts_logo_hori } from "../../assets/images";

// const cur_product = {
//   0: { active: 1, key: 0, module: 112, xdim: 2, ydim: 114.578404 },
//   1: { active: 1, key: 1, module: 112, xdim: 2, ydim: 114.578404 },
//   2: { active: 1, key: 2, module: 112, xdim: 2, ydim: 114.578404 },
//   3: { active: 1, key: 3, module: 112, xdim: 2, ydim: 114.578404 },
// };

// const results_data = {
//   YIELD: 1625.75,
//   ac_loss: 2,
//   array_nominal_energy: 0,
//   azimuth: 180,
//   bifacial_gain: 0,
//   capacity: 2503.9,
//   cashflow_after_tax: "[]",
//   dc_degradation: 0.5,
//   dc_mismatch_loss: 0,
//   dcac: 37.9378787878788,
//   e_array: 0,
//   gcr: 0.32,
//   generation: 4070726,
//   generation_array: "[]",
//   global_effective_irradiation_on_col: 0,
//   global_incident_in_col_plane: 0,
//   horizontal_global_irradiance: 1523.38305664062,
//   iam_factor_on_global: 0,
//   id: "ed6cc6e9-3619-4ebb-8398-2a3c155bf031",
//   interconnect_lim_loss: 0,
//   inverter_count: 1,
//   inverter_loss: 0,
//   irr: 0,
//   layout_id: "981c87d5-dd13-4099-9c8b-e7383005f104",
//   lcoe: 0,
//   lid_loss: 0,
//   module_count: 10220,
//   module_name: "245W Generic Module",
//   modules_in_layout: 10220,
//   modules_per_string: 28,
//   mw_ac: 0.066,
//   mw_peak: 2.5,
//   near_shadings_irradiance_loss: 0,
//   npv: 0,
//   ohmic_wiring_loss: 0,
//   op_cost_array: "[]",
//   pitch: 6.25,
//   pv_elec_loss: 0,
//   pv_loss_irrad: 0,
//   pv_loss_temp: 0,
//   quality_loss: 0,
//   rack_breakdown: "[46, 37, 28, 14]",
//   rack_count: 125,
//   racking_footprint: 6.68653518125,
//   racking_name: "Single Axis",
//   removed_modules: 0,
//   rtr: 4.25,
//   soiling_loss: 0,
//   spi: 365,
//   string_count: 365,
//   table_a: 46,
//   table_b: 37,
//   table_c: 28,
//   table_d: 14,
//   tilt: 0,
//   total_install_cost: 0,
//   total_modules: 10220,
// };

//  ************** Ground Mount Report

// New Ground Mount Report Stuff

const GM_Header_Styles = StyleSheet.create({
  row: {
    width: "100%",
    height: 50,
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#333",
    borderBottomWidth: 1,
  },
  logo: {
    height: "100%",
    width: "17%",
    paddingHorizontal: 5,
    borderRightColor: "#333",
    borderRightWidth: 1,
    display: "flex",
    alignItems: "Center",
    justifyContent: "center",
  },
  report_header: {
    width: "83%",
    display: "flex",
    flexDirection: "column",
  },
  report_header_row_1: {
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#333",
  },
  project_name_container: {
    width: "77%",
    borderRightWidth: 1,
    borderRightColor: "#333",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  project_name: {
    color: "#333",
    marginLeft: 5,
  },
  date_container: {
    width: "23%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  date: {
    width: "100%",
    textAlign: "center",
    color: "#333",
    marginLeft: 5,
  },
  report_header_row_2: {
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "row",
  },
  location_container: {
    width: "50%",
    borderRightWidth: 1,
    color: "#333",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  location: {
    color: "#333",
    marginLeft: 5,
  },
  user_container: {
    width: "50%",
    color: "#333",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  user: {
    color: "#333",
    marginLeft: 5,
  },
});

const GM_Header = ({ reportData }) => (
  <View style={GM_Header_Styles.row}>
    <View style={GM_Header_Styles.logo}>{reportData.platform === "internal_sift" ? <Image src={ts_logo_hori} /> : <Image src={sift_logo} />}</View>
    <View style={GM_Header_Styles.report_header}>
      <View style={GM_Header_Styles.report_header_row_1}>
        <View style={GM_Header_Styles.project_name_container}>
          <Text style={GM_Header_Styles.project_name}>{`Project Name: ${!_.isEmpty(reportData.project_name) ? reportData.project_name : "Solar Project"}`}</Text>
        </View>
        <View style={GM_Header_Styles.date_container}>
          <Text style={GM_Header_Styles.date}>{`${reportData.date}`}</Text>
        </View>
      </View>
      <View style={GM_Header_Styles.report_header_row_2}>
        <View style={GM_Header_Styles.location_container}>
          <Text style={GM_Header_Styles.location}>{`Location: (${reportData?.latitude?.toFixed(3)}°, ${reportData?.longitude?.toFixed(3)}°)`}</Text>
        </View>
        <View style={GM_Header_Styles.user_container}>
          <Text style={GM_Header_Styles.user}>{`User: ${reportData.userName ? reportData.userName : "n/a"}`}</Text>
        </View>
      </View>
    </View>
  </View>
);

const GMFooterStyles = StyleSheet.create({
  footer_container: {
    width: "100%",
    height: 25,
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    borderTopColor: "#333",
    borderTopWidth: 1,
  },
  footer_column: {
    width: "33%",
  },
  page_designation: {
    width: "15%",
    height: "100%",
    borderRightColor: "#333",
    borderRightWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  powered_by_box: {
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer_image: {
    width: 90,
  },
  page_box: {
    width: "15%",
    height: "100%",
    borderLefttColor: "#333",
    borderLeftWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  page_text: {
    width: "100%",
    color: "#333",
    fontSize: 10,
    textAlign: "right",
    paddingRight: 5,
  },
});

const GM_Footer = ({ page, designation, numOfPages }) => {
  return (
    <View style={GMFooterStyles.footer_container}>
      <View style={GMFooterStyles.page_designation}>
        <Text>{designation}</Text>
      </View>
      <View style={GMFooterStyles.powered_by_box}>{/* <Image style={GMFooterStyles.footer_image} src={bySunfig} /> */}</View>
      <View style={GMFooterStyles.page_box}>
        {/* <Text>{`Page ${page} of ${numOfPages}`}</Text> */}
        <Text>{`Page ${page}`}</Text>
      </View>
    </View>
  );
};

const NewGMStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    orientation: "portrait",
    width: "100%",
    height: "100%",
    fontSize: 10,
    paddingVertical: 3,
    paddingHorizontal: 3,
  },
  section: {
    position: "relative",
    flexGrow: 1,
    borderTopColor: "#333",
    borderTopWidth: 1,
    borderLeftColor: "#333",
    borderLeftWidth: 1,
    borderRightColor: "#333",
    borderRightWidth: 1,
    borderBottomColor: "#333",
    borderBottomWidth: 1,
    height: "100%",
    width: "100%",
  },
  full_page_map: {
    width: "100%",
  },
  title_container: {
    height: 50,
    width: "100%",
    textAlign: "center",
    paddingVertical: 10,
    borderBottomColor: "#333",
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#333",
    marginBottom: 3,
  },
  address: {
    color: "#333",
  },
  content: {
    width: "100%",
    padding: 10,
    marginBottom: 10,
  },
  layout_view: {
    width: "100%",
    display: "flex",
    padding: 10,
    flexDirection: "column",
  },
  layout_map_view: {
    width: 550,
    height: 390,
  },
  large_map_view: {
    width: 550,
    height: 700,
  },
  module_section: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  column: {
    width: "50%",
  },
  module_column: {
    width: "50%",
  },
  racking_section: {
    width: "100%",
    height: "100%",
  },
  reacking_breakdown: {
    width: "60%",
  },
  product_image: {
    width: 450,
    height: 350,
    marginHorizontal: "auto",
    marginTop: 60,
  },

  IAM_column: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingTop: 18,
  },
  IAM_column_small: {
    width: "40%",
  },
  perf_section: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },
  perf_soiling: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
  },
  perf_column: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
  },
  perf_row: {
    width: "100%",
    height: 330,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  losses_table: {
    width: "52%",
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
  },
  Iam_graph_style: {
    width: "100%",
    height: 500,
  },
  header_text: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  layoutMapNoBackground: {
    height: 280,
    width: 330,
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
    borderTopWidth: 2,
    borderTopColor: "#333",
    borderLeftWidth: 2,
    borderLeftColor: "#333",
    borderRightWidth: 1,
    borderRightColor: "#333",
  },
  topo_image_container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  topo_image: {
    height: 300,
  },
  topo_scale: {
    height: 300,
    marginLeft: 60,
  },
  perf_row_table: {
    width: "65%",
    display: "flex",
  },
  perf_row_graphs: {
    width: "33%",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  perf_row_graph_images: {
    height: 125,
    marginBottom: 10,
  },
  perf_graph_image: {
    height: 340,
  },
  loss_table_column: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: 0,
    marginRight: 10,
  },
  loss_table_bifacial_column: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: 138,
  },
  topo_impact_box: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: 350,
  },
});

const SIFTReport = ({ reportData }) => {
  // const getProductImage = (product = undefined) => {
  //   // switch (reportData.racking_name) {
  //   // console.log("product", product);
  //   switch (product) {
  //     case "Terrasmart GLIDE Fuse":
  //       return dahlia_fuse;
  //       break;
  //     case "Terrasmart TerraTrak":
  //       return terratrack;
  //       break;
  //     case "Terrasmart GLIDE Wave":
  //       return glide_front;
  //       break;
  //     case "Terrasmart GLIDE Agile":
  //       return glide_rear;
  //       break;
  //     default:
  //       break;
  //   }
  // };
  return (
    <Document title={`${!_.isEmpty(reportData.project_name) ? reportData.project_name : "Solar Project"}`}>
      <Page size="A4" style={NewGMStyles.page} wrap={false}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.title_container}>
            <Text style={NewGMStyles.title}>{`${!_.isEmpty(reportData.project_name) ? reportData.project_name : "Solar Project"}`}</Text>
            <Text style={NewGMStyles.address}>{reportData.address ? reportData.address : ""}</Text>
          </View>
          <Rundown reportData={reportData} />
          <View style={NewGMStyles.layout_view}>
            <Text style={NewGMStyles.header_text}>Layout Map</Text>
            {reportData.images.layoutMap && <Image style={NewGMStyles.layout_map_view} src={reportData.images.layoutMap} />}
          </View>
          <GM_Footer page={1} numOfPages={reportData.numOfPages} designation={"A001"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <Text style={NewGMStyles.header_text}>Large Layout Map</Text>
            {reportData.images.largeLayoutMap && <Image style={NewGMStyles.large_map_view} src={reportData.images.largeLayoutMap} />}
          </View>
          <GM_Footer page={2} numOfPages={reportData.numOfPages} designation={"A002"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.racking_section}>
              <View style={NewGMStyles.reacking_breakdown}>
                <Text style={NewGMStyles.header_text}>Racking</Text>
                <Racking reportData={reportData} />
                <Tracker_Breakdown reportData={reportData} />
              </View>
              {/* {reportData.platform == "internal_sift" && <Image style={NewGMStyles.product_image} src={getProductImage(reportData.racking_name)} />} */}
            </View>
          </View>
          <GM_Footer page={3} numOfPages={reportData.numOfPages} designation={"B001"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.module_section}>
              <View style={NewGMStyles.column}>
                <Text style={NewGMStyles.header_text}>Module</Text>
                <ModuleSection reportData={reportData} />
              </View>
              <View style={NewGMStyles.IAM_column}>
                <Image style={NewGMStyles.IAM_graph_style} src={reportData.images["iamGraph"]} />
                <View style={NewGMStyles.IAM_column_small}>
                  <IAMTable_report reportData={reportData} />
                </View>
              </View>
            </View>
          </View>
          <GM_Footer page={4} numOfPages={reportData.numOfPages} designation={"B002"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.module_section}>
              <View style={NewGMStyles.column}>
                <Text style={NewGMStyles.header_text}>Inverter</Text>
                <InverterTable reportData={reportData} />
              </View>
              <View style={NewGMStyles.column}>
                <Image style={NewGMStyles.IAM_graph_style} src={reportData.images["inverterEffGraph"]} />
              </View>
            </View>
          </View>
          <GM_Footer page={5} numOfPages={reportData.numOfPages} designation={"B003"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.perf_section}>
              <View style={NewGMStyles.perf_column}>
                <Text style={NewGMStyles.header_text}>Perfomance</Text>
                <Performance reportData={reportData} />
              </View>
            </View>
            <View style={NewGMStyles.perf_soiling}>
              <SoilingAlbedoTable reportData={reportData} />
            </View>
          </View>
          <GM_Footer page={6} numOfPages={reportData.numOfPages} designation={"B004"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.module_section}>
              <View style={NewGMStyles.column}>
                <Text style={NewGMStyles.header_text}>Layout</Text>
                <LayoutTable reportData={reportData} />
              </View>
            </View>
          </View>
          <GM_Footer page={7} numOfPages={reportData.numOfPages} designation={"B005"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.perf_column}>
              <Text style={NewGMStyles.header_text}>Bill of Materials</Text>
              <BOMTable reportData={reportData} />
              <Text>*Bill of materials is presented here for budgetary and general guidance purposes only.</Text>
            </View>
          </View>
          <GM_Footer page={8} numOfPages={reportData.numOfPages} designation={"R001"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <Text style={NewGMStyles.header_text}>Perfomance Results</Text>
            <View style={NewGMStyles.perf_row}>
              <View style={NewGMStyles.perf_row_table}>
                <PerfSummaryTable reportData={reportData} />
              </View>
              <View style={NewGMStyles.perf_row_graphs}>
                <Image style={NewGMStyles.perf_row_graph_images} src={reportData.images["windAndTempGraph"]} />
                <Image style={NewGMStyles.perf_row_graph_images} src={reportData.images["eArrayGraph"]} />
              </View>
            </View>
            <View style={NewGMStyles.perf_row}>
              <Image style={NewGMStyles.perf_graph_image} src={reportData.images["perfGraph"]} />
            </View>
          </View>
          <GM_Footer page={9} numOfPages={reportData.numOfPages} designation={"R002"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header reportData={reportData} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.perf_section}>
              <View style={NewGMStyles.loss_table_column}>
                <Text style={NewGMStyles.header_text}>Loss Tables</Text>
                <LossTable reportData={reportData} />
              </View>
              <View style={NewGMStyles.loss_table_bifacial_column}>
                {reportData.en_bifacial === 1 && <LossTable reportData={reportData} bifacial={true} />}
                {Object.values(reportData.summary?.topo_impact)?.length > 0 && (
                  <View>
                    <TopoLossTable topo_impact={reportData?.summary?.topo_impact} />
                  </View>
                )}
              </View>
            </View>
          </View>
          <GM_Footer page={10} numOfPages={reportData.numOfPages} designation={"B005"} />
        </View>
      </Page>

      {reportData?.topo_id && (
        <Fragment>
          <Page size="A4" style={NewGMStyles.page}>
            <View style={NewGMStyles.section}>
              <GM_Header reportData={reportData} />
              <View style={NewGMStyles.content}>
                <Text>{`Elevation (Ungraded)`}</Text>
                <View style={NewGMStyles.topo_image_container}>
                  {reportData.images["ele"] && <Image style={NewGMStyles.topo_image} src={reportData.images["ele"]} />}
                  {reportData.images["topo_scale_ele"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_ele"]} />}

                  {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, "ele")} /> */}
                </View>
              </View>
              {reportData.vis_ele_graded_avail && (
                <View style={NewGMStyles.content}>
                  <Text>{`Elevation (Graded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images["ele-g"] && <Image style={NewGMStyles.topo_image} src={reportData.images["ele-g"]} />}
                    {reportData.images["topo_scale_ele-g"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_ele-g"]} />}
                    {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `ele/G/${reportData.vis_ele_graded_percent}`)} /> */}
                  </View>
                </View>
              )}
              <GM_Footer page={11} numOfPages={reportData.numOfPages} designation={"T001"} />
            </View>
          </Page>

          {reportData?.vis_g?.graded_avail && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header reportData={reportData} />
                <View style={NewGMStyles.content}>
                  <Text>{`Cut and Fill `}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images["CF"] && <Image style={NewGMStyles.topo_image} src={reportData.images["CF"]} />}
                    {reportData.images["topo_scale_CF"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_CF"]} />}

                    {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `CF/${reportData.vig_g_ungraded_percent}`)} /> */}
                  </View>
                </View>
                <GM_Footer page={12} numOfPages={reportData.numOfPages} designation={"T002"} />
              </View>
            </Page>
          )}

          {(reportData?.vis_max?.ungraded_avail || reportData?.vis_max?.graded_avail) && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header reportData={reportData} />
                {reportData?.vis_max?.ungraded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`Max Slope (Ungraded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {reportData.images["U"] && <Image style={NewGMStyles.topo_image} src={reportData.images["U"]} />}
                      {reportData.images["topo_scale_U"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_U"]} />}

                      {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `U/${reportData.vis_max_ungraded_percent}`)} /> */}
                    </View>
                  </View>
                )}
                {reportData?.vis_max?.graded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`Max Slope (Graded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {reportData.images["U-G"] && <Image style={NewGMStyles.topo_image} src={reportData.images["U-G"]} />}
                      {reportData.images["topo_scale_U-G"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_U-G"]} />}

                      {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `U/G/${reportData.vis_max_graded_percent}`)} /> */}
                    </View>
                  </View>
                )}
                <GM_Footer page={13} numOfPages={reportData.numOfPages} designation={"T003"} />
              </View>
            </Page>
          )}

          {(reportData?.vis_ns?.ungraded_avail || reportData?.vis_ns?.graded_avail) && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header reportData={reportData} />
                {reportData?.vis_ns?.ungraded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`NS Grade (Ungraded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {reportData.images["NS"] && <Image style={NewGMStyles.topo_image} src={reportData.images["NS"]} />}
                      {reportData.images["topo_scale_NS"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_NS"]} />}

                      {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `NS/${reportData.vis_ns_ungraded_percent}`)} /> */}
                    </View>
                  </View>
                )}
                {reportData?.vis_ns?.graded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`NS Grade (Graded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {reportData.images["NS-G"] && <Image style={NewGMStyles.topo_image} src={reportData.images["NS-G"]} />}
                      {reportData.images["topo_scale_NS-G"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_NS-G"]} />}

                      {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `NS/G/${reportData.vis_ns_graded_percent}`)} /> */}
                    </View>
                  </View>
                )}
                <GM_Footer page={14} numOfPages={reportData.numOfPages} designation={"T004"} />
              </View>
            </Page>
          )}

          {(reportData?.vis_ew?.ungraded_avail || reportData?.vis_ew?.graded_avail) && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header reportData={reportData} />
                {reportData?.vis_ew?.ungraded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`EW Grade (Ungraded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {reportData.images["EW"] && <Image style={NewGMStyles.topo_image} src={reportData.images["EW"]} />}
                      {reportData.images["topo_scale_EW"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_EW"]} />}

                      {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `EW/${reportData.vis_ew_ungraded_percent}`)} /> */}
                    </View>
                  </View>
                )}
                {reportData?.vis_ew?.graded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`EW Grade (Graded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {reportData.images["EW-G"] && <Image style={NewGMStyles.topo_image} src={reportData.images["EW-G"]} />}
                      {reportData.images["topo_scale_EW-G"] && <Image style={NewGMStyles.topo_scale} src={reportData.images["topo_scale_EW-G"]} />}

                      {/* <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topo_id, `EW/G/${reportData.vis_ew_graded_percent}`)} /> */}
                    </View>
                  </View>
                )}
                <GM_Footer page={15} numOfPages={reportData.numOfPages} designation={"T004"} />
              </View>
            </Page>
          )}
        </Fragment>
      )}

      {/* {reportData.topoData.topo_live && (
      <>
        <Page size="A4" style={NewGMStyles.page}>
          <View style={NewGMStyles.section}>
            <GM_Header reportData={data} />
            <View style={NewGMStyles.content}>
              <Text>{`Elevation (Ungraded)`}</Text>
              <View style={NewGMStyles.topo_image_container}>
                {reportData.images.mapImages["ele"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["ele"]} />}
                <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, "ele")} />
              </View>
            </View>
            {reportData.topoData.layers_generated.ele.gavail && (
              <View style={NewGMStyles.content}>
                <Text>{`Elevation (Graded)`}</Text>
                <View style={NewGMStyles.topo_image_container}>
                  {reportData.images.mapImages["ele-g"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["ele-g"]} />}
                  <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, "ele/G")} />
                </View>
              </View>
            )}
            <GM_Footer page={8} numOfPages={reportData.numOfPages} designation={"T001"} />
          </View>
        </Page>
        {reportData.topoData.layers_generated.CF.avail && (
          <Page size="A4" style={NewGMStyles.page}>
            <View style={NewGMStyles.section}>
              <GM_Header reportData={data} />
              <View style={NewGMStyles.content}>
                <Text>{`Cut and Fill `}</Text>
                <View style={NewGMStyles.topo_image_container}>
                  {reportData.images.mapImages["CF"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["CF"]} />}
                  <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `CF`, reportData.topoData.layers_generated.CF.limit)} />
                </View>
              </View>
              <GM_Footer page={9} numOfPages={reportData.numOfPages} designation={"T002"} />
            </View>
          </Page>
        )}
        {(reportData.topoData.layers_generated.U.avail || reportData.topoData.layers_generated.U.gavail) && (
          <Page size="A4" style={NewGMStyles.page}>
            <View style={NewGMStyles.section}>
              <GM_Header reportData={data} />
              {reportData.topoData.layers_generated.U.avail && (
                <View style={NewGMStyles.content}>
                  <Text>{`Max Slope (Ungraded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images.mapImages["U"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["U"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `U`, reportData.topoData.layers_generated.U.limit)} />
                  </View>
                </View>
              )}
              {reportData.topoData.layers_generated.U.gavail && (
                <View style={NewGMStyles.content}>
                  <Text>{`Max Slope (Graded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images.mapImages["U-G"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["U-G"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `U/G`, reportData.topoData.layers_generated.U.limit)} />
                  </View>
                </View>
              )}
              <GM_Footer page={10} numOfPages={reportData.numOfPages} designation={"T003"} />
            </View>
          </Page>
        )}
        {(reportData.topoData.layers_generated.NS.avail || reportData.topoData.layers_generated.NS.gavail) && (
          <Page size="A4" style={NewGMStyles.page}>
            <View style={NewGMStyles.section}>
              <GM_Header reportData={data} />
              {reportData.topoData.layers_generated.NS.avail && (
                <View style={NewGMStyles.content}>
                  <Text>{`NS Grade (Ungraded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images.mapImages["NS"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["NS"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `NS`, reportData.topoData.layers_generated.NS.limit)} />
                  </View>
                </View>
              )}
              {reportData.topoData.layers_generated.NS.gavail && (
                <View style={NewGMStyles.content}>
                  <Text>{`NS Grade (Graded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images.mapImages["NS-G"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["NS-G"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `NS/G`, reportData.topoData.layers_generated.NS.limit)} />
                  </View>
                </View>
              )}
              <GM_Footer page={11} numOfPages={reportData.numOfPages} designation={"T004"} />
            </View>
          </Page>
        )}
        {(reportData.topoData.layers_generated.EW.avail || reportData.topoData.layers_generated.EW.gavail) && (
          <Page size="A4" style={NewGMStyles.page}>
            <View style={NewGMStyles.section}>
              <GM_Header reportData={data} />
              {reportData.topoData.layers_generated.EW.avail && (
                <View style={NewGMStyles.content}>
                  <Text>{`EW Grade (Ungraded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images.mapImages["EW"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["EW"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `EW`, reportData.topoData.layers_generated.EW.limit)} />
                  </View>
                </View>
              )}
              {reportData.topoData.layers_generated.EW.gavail && (
                <View style={NewGMStyles.content}>
                  <Text>{`EW Grade (Graded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {reportData.images.mapImages["EW-G"] && <Image style={NewGMStyles.topo_image} src={reportData.images.mapImages["EW-G"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(reportData.topoData.topo_id, `EW/G`, reportData.topoData.layers_generated.EW.limit)} />
                  </View>
                </View>
              )}
              <GM_Footer page={12} numOfPages={reportData.numOfPages} designation={"T004"} />
            </View>
          </Page>
        )}
      </>
    )} */}
    </Document>
  );
};

const Report = (props) => (
  <PDFViewer style={{ width: "100%", height: "1200px" }}>
    <GroundMountReport props />
  </PDFViewer>
);

export { Report, SIFTReport };
