import React, { useState, useEffect } from "react";
import { useMap } from "react-map-gl";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, inputsActions } from "../../Redux";

import { MapMessageBoxWrap, MapboxMessageWrap } from "./styles";

import { Spinner } from "../../Spinner";

import { getArea, getAcreage, isLinearRing } from "../../Map/components";

const LayerLoadingText = {
  wetlands_on: "Wetlands layer loading",
  riparian_on: "Riparian layer loading",
  fema_on: "FEMA layer loading",
  transmission_lines_on: "Electric transmission layer loading",
  gas_lines_on: "Gas pipline layer loading",
  railway_lines_on: "Railway lines layer loading",
};

const LayerLoadingTextA = {
  wetlands_layer: "Wetlands layer loading",
  rip_lay: "Riparian layer loading",
  fema_layer: "FEMA layer loading",
  transmission_lines_layer: "Electric transmission layer loading",
  gas_lines_layer: "Gas pipline layer loading",
  railway_lines_layer: "Railway lines layer loading",
};

const MapboxMessageBox = ({ position, layerLoading }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();

  const site_features = useSelector((state) => state.inputs.site_features);
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const totalArea = useSelector((state) => state.sift.ioManager.inputs.map.totalArea);
  const total_acreage = useSelector((state) => state.sift.ioManager.inputs.map.total_acreage);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);

  const additional_layers = useSelector((state) => state.inputs.uiState.additional_layers);

  //canopy stuff
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy || undefined);
  const canopies = useSelector((state) => state.canopy.canopies);
  const canopy = useSelector((state) => state.canopy.canopies[selected_canopy] || undefined);
  const [total_can_mod_count, set_total_can_mod_count] = useState(0);

  useEffect(() => {
    if (!site_features) return;
    calculateTotalArea();
  }, [site_features]);

  const calculateTotalArea = () => {
    let total_area = 0;
    let total_acreage = 0;
    Object.values(site_features).forEach((feature) => {
      if (feature.properties.identity == 1) {
        total_area += getArea(feature.geometry.coordinates);
        total_acreage += getAcreage(feature.geometry.coordinates);
      }
    });
    dispatch(siftActions.updateTotalArea(total_area, total_acreage));
  };

  useEffect(() => {
    if (!canopies) return;
    set_total_can_mod_count(Object.values(canopies).reduce((total, { module_count }) => total + module_count, 0));
  }, [canopies]);

  const updateIdentity = (identity, feature) => {
    if (feature) {
      feature.properties.identity = identity;

      if (identity == 0) feature.properties.active = !feature.properties.active;
      else feature.properties.active = true;
      //   let layer = this.getLayer(feature.properties.index);
      //   layer?.setStyle({ color: colors[identity] });
      dispatch(siftActions.updateFeature(feature));
      dispatch(inputsActions.update_site_input("edit_site_feature", feature));
    }
  };

  const [layerLoadingFlag, setLayerLoadingFlag] = useState(layerLoading);

  useEffect(() => {
    const ob = {
      ...layerLoadingFlag,
      ...layerLoading,
    };
    // console.log("ob", ob);
    setLayerLoadingFlag(ob);
  }, [layerLoading]);

  return (
    <MapboxMessageWrap>
      {using_canopy ? (
        <>
          {canopy && canopy.module_count ? (
            <MapMessageBoxWrap>
              <p>{`${canopy.name} Mod Qty: ${canopy.module_count} `}</p>
            </MapMessageBoxWrap>
          ) : total_can_mod_count > 0 ? (
            <MapMessageBoxWrap>
              <p>{`Total Modules: ${total_can_mod_count}`}</p>
            </MapMessageBoxWrap>
          ) : null}
        </>
      ) : (
        <>
          <MapMessageBoxWrap>
            {site_features[selectedFeatureId] && site_features[selectedFeatureId]?.geometry?.type !== "LineString" ? (
              <section style={{ color: "rgb(0, 0, 0)" }}>
                {site_features[selectedFeatureId].properties.area && site_features[selectedFeatureId].properties.acreage
                  ? `Selected Area: ${site_features[selectedFeatureId].properties.area?.toFixed(0)} ha, ${site_features[selectedFeatureId].properties.acreage?.toFixed(0)} ac`
                  : isLinearRing(site_features[selectedFeatureId].geometry.coordinates)
                  ? `Selected Area:  ${getArea(site_features[selectedFeatureId].geometry.coordinates).toFixed(0)} ha, ${getAcreage(site_features[selectedFeatureId].geometry.coordinates).toFixed(
                      0
                    )} ac `
                  : null}
              </section>
            ) : totalArea > 0 ? (
              <section style={{ color: "rgb(0, 0, 0)" }}>
                <p>{`Total Boundary Area: ${parseInt(totalArea)} ha, ${parseInt(total_acreage)} ac`}</p>
                {/* TODO: FIX THIS CHECK */}
                {/* {this.props.UserPlan && this.props.map.totalArea > this.props.UserPlan.boundary_size_limit && (
            <section style={{ color: "red" }}>Boundary exceeds user limit ({this.props.UserPlan.boundary_size_limit} ha.).</section>
          )} */}
              </section>
            ) : (
              <section>Drag and drop a KMZ or draw a project boundary.</section>
            )}
          </MapMessageBoxWrap>

          {is_drawing && (
            <MapMessageBoxWrap>
              <p>Press "Esc" to auto-complete current polygon</p>
            </MapMessageBoxWrap>
          )}

          {site_features[selectedFeatureId] && (
            <MapMessageBoxWrap style={{ width: 315 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                {site_features[selectedFeatureId]?.properties.identity == 1 ? "Boundary" : site_features[selectedFeatureId]?.properties.identity == 2 ? "Exclusion" : "Inactive"} polygon. Set: [
              </span>
              {site_features[selectedFeatureId]?.properties.identity != 1 && (
                <section className="identity-link-button">
                  <a
                    onClick={() => {
                      updateIdentity(1, site_features[selectedFeatureId]);
                    }}
                  >
                    <span style={{ color: "var(--primary-brand-color)" }}>B</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>oundary, </span>
                  </a>
                </section>
              )}
              {site_features[selectedFeatureId]?.properties.identity != 2 && (
                <section className="identity-link-button">
                  <a
                    onClick={() => {
                      updateIdentity(2, site_features[selectedFeatureId]);
                    }}
                  >
                    <span style={{ color: "var(--primary-brand-color)" }}>E</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>xclusion</span>
                  </a>
                </section>
              )}
              {site_features[selectedFeatureId]?.properties.identity == 1 && <span style={{ color: "rgb(0, 0, 0)" }}>, </span>}
              {site_features[selectedFeatureId]?.properties.identity != 0 && (
                <section className="identity-link-button">
                  <a
                    onClick={() => {
                      updateIdentity(0, site_features[selectedFeatureId]);
                    }}
                  >
                    <span style={{ color: "var(--primary-brand-color)" }}>I</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>nactive</span>
                  </a>
                </section>
              )}
              <span style={{ color: "rgb(0, 0, 0)" }}>{`] `}</span>

              <section className="identity-link-button" style={{ color: "rgb(0, 0, 0)" }}>
                <span>
                  Cmd:[
                  <a onClick={() => map.fire("toggleOffset", { id: selectedFeatureId })}>
                    <span style={{ color: "var(--primary-brand-color)" }}> O</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>ffset </span>
                  </a>
                  ]
                </span>
              </section>
            </MapMessageBoxWrap>
          )}
        </>
      )}

      {Object.keys(layerLoadingFlag).map(
        (key) =>
          layerLoadingFlag[key] && (
            <MapMessageBoxWrap key={key}>
              <Spinner height={"15px"} width={"15px"} color={"var(--primary-brand-color)"} marginLeft={"0px"} />
              <p>{LayerLoadingTextA[key]}</p>
            </MapMessageBoxWrap>
          )
      )}

      {/* {Object.keys(additional_layers).map(
        (layer) =>
          additional_layers[layer] &&
          layerLoadingFlag[layer] && (
            <MapMessageBoxWrap key={layer}>
              <Spinner height={"15px"} width={"15px"} color={"var(--primary-brand-color)"} marginLeft={"0px"} />
              <p>{LayerLoadingText[layer]}</p>
            </MapMessageBoxWrap>
          )
      )} */}
    </MapboxMessageWrap>
  );
};

export { MapboxMessageBox };
