import styled, { css } from "styled-components";

const ToggleResultWrapper = styled.section`
  position: absolute;
  top: 10px;
  right: 70px;
  z-index: 1001;

  @media (max-width: 480px) {
    width: 85%;
  }

  .toggle-result {
    height: 100%;
    z-index: 1;
    display: grid;
    place-content: end;

    a {
      border-radius: 3px 3px 0 0;
      display: block;
      width: min-content;
      padding: 8px 5px;
      text-align: center;
    }
  }
`;

export const OutputPanelWrapper = styled.section`
  position: absolute;
  bottom: 0;
  padding-top: 5px;
  width: 100%;
  height: ${(props) => (props.detailsVisible ? (props.show_live_report || props.show_layout_editor ? "100vh" : "445px") : "0px")};
  /* height: ${(props) => (props.detailsVisible ? "445px" : "87px")}; */
  background-color: #fff0;
  z-index: 510;
  overflow-y: hidden;
  transition: all 0.3s var(--easing-spring);

  .output-header {
    width: 100%;
    height: 80px;
    padding-top: 5px;

    .complete {
      display: grid;
      grid-template-columns: 220px 200px auto;

      .meta-data {
        border-right: 1px solid rgb(160, 160, 160);
        overflow-x: hidden;
      }

      /* .selected-buttons {
        margin-left: 15px !important;
      } */
    }
  }
  .progress {
    height: 100%;
    background-color: #fff0;
    padding: 0 50px 0 50px;
    border-radius: 3px;

    .ant-steps {
      background-color: #fff;
      margin: 0 auto;
      padding: 0 20px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .output-data {
    width: ${(props) => (props.show_live_report || props.show_layout_editor ? "100%" : "95%")};
    height: ${(props) => (props.show_live_report || props.show_layout_editor ? "unset" : "unset")};
    /* padding-top: 10px; */
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr; */

    .ant-tabs {
      padding: 0px 5px !important;

      .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
        margin-right: 50px !important;
        padding-top: 5px;
      }
    }

    .results-table-graph {
      display: grid;
      grid-template-columns: 440px auto;
    }

    .output-tabs {
      .tab-results {
        .output-graph {
          height: 100%;
          display: grid;
          place-items: center;
          border: 1px solid #aaa9aa;
          margin-right: 10px;
          border-radius: 3px;
          grid-area: results_graph;
          .result-graph-toggle {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
      }
    }
  }
  .result-buttons-content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    border-left: 1px solid #aaa9aa;
    height: 64px;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
  }

  .output-detail {
    /* height: 415px; */
    padding: 5px 0;
  }

  .tab-res {
    font-size: 9pt;
  }

  .tab-results {
    display: grid;
    overflow-x: hidden;
    ${({ show_live_report, show_layout_editor }) =>
      (!show_live_report || !show_layout_editor) &&
      css`
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        grid-template-rows: 60px 1fr;
        grid-auto-flow: dense;
        margin-bottom: 10px;
        grid-template-areas:
          "results_buttons results_graph"
          "results_table results_graph";
      `}

    ${({ show_live_report, show_layout_editor }) =>
      (show_live_report || show_layout_editor) &&
      css`
        height: 100vh;
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        grid-auto-flow: dense;
        margin-bottom: 10px;
        grid-template-areas:
          "results_buttons"
          "live_report";
      `}

    .results-buttons {
      grid-area: results_buttons;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      padding: 5px;

      .report-button {
        margin-top: 5px;
      }

      .report-download-button {
        display: flex;
        align-items: center;
        margin: 5px 0 0 10px;
      }

      .all-results {
        margin-left: 10px;
        .all-results-header {
          p {
            margin: 0px;
          }
        }
      }
    }

    .canopy-result-buttons {
      /* height: 100%; */
      display: flex;
      width: 100%;
      align-items: center;
      align-content: center start;
      padding: 20px;

      button {
        margin-right: 5px;
      }
    }

    .results-stats {
      display: grid;
      grid-template-columns: repeat(3, auto);

      .stat {
        margin: 0 20px;
        display: flex;
        align-items: flex-start;
        padding: 10px;
        white-space: nowrap;

        .stat-img {
          margin: 0 10px;
          /* background: #dce0e8; */
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          /* img {
            filter: sepia(100%) hue-rotate(-165deg) saturate(100); // changed #1bac54 to blue
          } */
        }
      }

      .canopy-result-minimize {
        height: 70%;
        width: 100%;
        display: grid;
        place-content: center;
        button {
          background: none;
          border: none;
          color: var(--primary-brand-color);
        }
      }
    }

    #TheLiveReport {
      /* overflow-y: scroll; */
      overflow-y: auto;
      height: calc(100% - 50px);
      background: #dadada;
    }
  }

  .result-holder {
    overflow-y: auto;
    height: 300px;
    border: 1px solid #aaa9aa;
    margin: 0 10px;
    border-radius: 3px;
    grid-area: results_table;
  }

  .saved-results-table {
    margin: 0 10px;
    grid-row: span result_buttons / result_table;
    grid-column: span result-button / result_graph;

    & > .result-holder {
      height: 360px;
    }
  }

  .canopy-results {
    overflow-y: auto;
    height: 400px;
    margin: 0 10px;
    grid-row: 2;
    grid-column: 1 / -1;
  }

  @media (max-width: 1200px) {
    overflow-y: auto;

    .tab-results {
      padding: 0;
      ${({ show_layout_editor }) =>
        !show_layout_editor &&
        css`
          grid-gap: 20px;
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;
          grid-template-areas:
            "results_buttons"
            "results_table"
            "results_graph";
        `}
    }
    .output-tabs {
      .tab-results {
        .output-graph {
          border: 1px solid #aaa9aa;
          margin-left: 10px;
        }
      }
    }

    .result-holder {
      border: 1px solid #aaa9aa;
      height: 200px;
    }
  }

  .output-panel-error {
    width: 80%;
    margin: 0 auto;
    padding-top: 36px;
  }
`;

const SelectedButtonsWrap = styled.section`
  /* margin-left: 15px; */
  padding: 0px 0px 10px 10px;
  display: grid;
  /* grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr 1fr; */
  grid-template-areas:
    "result-header-text salesforce-input "
    "action-buttons salesforce-input";

  .result-header-text {
    grid-area: result-header-text;
    display: grid;
    grid-template-columns: 285px 1fr;
    margin-left: 3px;
    margin-bottom: 6px;

    .saved-result-info {
      display: flex;
      align-items: center;
      p {
        margin: 0px 3px;
      }
    }
  }

  .selected-button-actions {
    grid-area: action-buttons;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .capture {
    display: flex;

    input {
      width: 100%;
      max-width: 340px;
      margin-right: 5px;
      border-radius: 2px;
      border: 1px solid #e5e5e5;

      &:focus {
        border-color: #60de4f;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgb(27 172 81 / 20%);
      }
    }

    .the-buttons {
      button {
        margin-right: 5px;
        background: #fff;
        border: 1px solid #60de4f;
        border-radius: 2px;
        padding: 3px 8px;
        color: #60de4f;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;

const VerticalDivider = styled.section`
  border-left: 1px solid rgb(217, 217, 217);
  border-right: 1px solid rgb(217, 217, 217);
  margin: 0px 5px;
  height: 100%;
  max-height: 30px;
  width: 2px;
`;

export { ToggleResultWrapper, SelectedButtonsWrap, VerticalDivider };
