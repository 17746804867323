import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import config from "config";
import { authHeader } from "../../Redux/_helpers";

import { Tooltip, Button, Modal, Alert } from "antd";
import { AuditOutlined, FileImageOutlined } from "@ant-design/icons";
import { execute_job, export_data } from "../../Redux";

import { BQWrap } from "./styles";
import fetchUtil from "../../Redux/_services/fetchUtil";

const BudgetaryQuote = (props) => {
  const dispatch = useDispatch();
  const { result } = props;

  const [bq_loading, set_bq_loading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [bq_res, set_bq_res] = useState({});
  const [bq_err, set_bq_err] = useState(undefined);

  const [showDebug, setShowDebug] = useState(false);
  const [success_to_sf, set_success_to_sf] = useState("none");

  const quote_id = useSelector((state) => (state.projects.quote_id ? state.projects.quote_id : ""));
  const [cur_quote_id, set_cur_quote_id] = useState(quote_id);
  const [actual_id, set_actual_id] = useState(quote_id);
  const [submit_bq_response, set_submit_bq_response] = useState(undefined);
  const [bq_pdf_available, set_bq_pdf_available] = useState(false);

  const isUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "https:" || url.protocol === "https:";
  };

  const update_quote_id = (value) => {
    set_cur_quote_id(value);
    set_actual_id(value);

    let is_it_a_url = isUrl(value);
    if (is_it_a_url) {
      let url = new URL(value);
      let quote_url = new URLSearchParams(url.search);
      let id = quote_url.get("quote_id");
      if (id) {
        set_actual_id(id);
      }
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  async function get_ele_tz(latlng) {
    const requestOptions = {
      method: "POST",
      //headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(latlng),
    };

    return fetchUtil(`${config.apiUrl}/dash/swm/eletz`, requestOptions);
  }

  async function get_dist(latlng) {
    const requestOptions = {
      method: "POST",
      //headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(latlng),
    };

    return fetchUtil(`${config.apiUrl}/dash/swm/dist`, requestOptions);
  }

  async function create_job(inputs) {
    const requestOptions = {
      method: "POST",
      //headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({ job_type: "bq", ...inputs }),
    };
    return fetchUtil(`${config.apiUrl}/dash/jobs/`, requestOptions);
  }
  async function get_job_status(job_id) {
    const requestOptions = {
      method: "GET",
      //headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetchUtil(`${config.apiUrl}/dash/jobs/?job_id=${job_id}`, requestOptions);
  }

  const generate_bq = async () => {
    // console.log("generating bq", result);
    set_bq_loading(true);

    let ele_tz = await get_ele_tz([result.latitude, result.longitude]);
    let state = "";
    let county = "";
    if (ele_tz.data.loc.status === "OK") {
      county = ele_tz.data.loc.results[0].address_components[0].short_name;
      state = ele_tz.data.loc.results[0].address_components[1].short_name;
    }

    let g_dist = await get_dist([result.latitude, result.longitude]);
    let distance_cinci_m = 0;
    let distance_selki_m = 0;
    let dist_debug = {};
    if (g_dist.data.status === "OK") {
      distance_selki_m = g_dist.data.rows[0].elements[0].distance.value;
      distance_cinci_m = g_dist.data.rows[0].elements[1].distance.value;
      dist_debug["distance_selki"] = g_dist.data.rows[0].elements[0];
      dist_debug["distance_cinci"] = g_dist.data.rows[0].elements[1];
    }

    let inputs = {
      product: _.endsWith(_.lowerCase(result.racking_name), "wave") ? "wave" : "agile",
      fs_mod: result.technology == 2,

      tilt: result.tilt,
      mods_high: result.modules_high,
      mod_rating: result.mod_rating,
      mod_width_mm: result.mod_width * 1000,
      mod_length_mm: result.mod_height * 1000,
      // "mod_mount_width_mm": 1046,
      // "mod_mount_length_mm": 1312,
      mod_count: result.module_count,
      mod_orientation: result.orientation,
      row_count: result.row_count,
      table_a_qty: result.rack_breakdown[0],
      table_b_qty: result.rack_breakdown[1],
      table_c_qty: result.rack_breakdown[2],
      atc_wind_load: 0,
      atc_snow_load: 0,
      distance_cinci: distance_cinci_m,
      distance_selki: distance_selki_m,
      state: state,
      county: county,
      latitude: result.latitude,
      longitude: result.longitude,
      starter_bays: result.starter_bays,

      dist_debug: dist_debug,
    };
    console.log(inputs);

    let created_job = await create_job(inputs);
    console.log(created_job);

    let status = 99;
    let run_count = 0;
    while (status == 99 && run_count < 100) {
      let job_status = await get_job_status(created_job["job_id"]);
      status = job_status.status;
      // console.log(job_status);
      if (job_status["status"] == 99) {
        await sleep(1000);
        run_count += 1;
      } else {
        if (job_status.output["error"]) {
          set_bq_err(job_status.output["error"]);
          console.log(job_status.output["error"]);
        } else {
          set_bq_res(job_status.output);
          status = 100;
        }
        set_bq_loading(false);
        setIsOpen(true);
      }
    }
  };

  async function submit_bq(quote_data) {
    console.log("submitting bq", quote_data);
    const requestOptions = {
      method: "POST",
      //headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(quote_data),
    };
    return fetchUtil(`${config.apiUrl}/dash/quote`, requestOptions);
  }

  const send_bq_sf = async () => {
    set_bq_loading(true);
    console.log("sending bq to sf", bq_res, actual_id);
    if (actual_id) {
      let update_data = {
        quote_id: actual_id,
        mod_rating: bq_res["debug"]["mod_rating"],
        mod_qty: bq_res["debug"]["mod_count"],
        mat_cost: bq_res["cost"]["Final Material Cost"],
        freight_cost: bq_res["cost"]["Final Freight Cost"],
        install_cost: bq_res["cost"]["Final Install Cost"],
      };
      try {
        let resp = await submit_bq(update_data);
        console.log("bq sumitting?", resp);
        if (resp["sucess"] == true) {
          set_success_to_sf("success");
          set_submit_bq_response(resp);
          if (bq_res) {
            set_bq_pdf_available(true);
          }
        } else {
          set_success_to_sf("failed");
          set_bq_pdf_available(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        set_bq_loading(false);
      }
    } else {
    }
  };

  const exportBQPDF = () => {
    let temp_bq_final_resp = {
      ...submit_bq_response.final_values,
      REVASHTON_Product_Type: result.racking_id,
      REVASHTON_Module_Quantity: result.module_count,
      REVASHTON_Module_Rating: result.mod_rating,
      REVASHTON_System_size: result.capacity,
    };

    let bq_export_inputs = { BQ_data: { ...temp_bq_final_resp }, export_type: "pdf-export-bq", job_type: "export-job", meta: { file_name: `SIFT_BQ_${actual_id}.pdf` } };
    dispatch(execute_job(export_data(bq_export_inputs)));
  };
  return (
    <>
      <span style={{ marginRight: 5 }}>
        <Tooltip placement="top" title="Generate Budgetary Quote" mouseEnterDelay={0.5}>
          <Button ghost type="primary" icon={<AuditOutlined />} onClick={() => generate_bq()} loading={bq_loading} size="small">
            BQ
          </Button>
        </Tooltip>
      </span>

      <span style={{ marginRight: 5 }}>
        <Tooltip placement="top" title={bq_pdf_available ? "Download BQ PDF" : "BQ has to be generated in order to download a PDF"} mouseEnterDelay={0.5}>
          <Button ghost disabled={!bq_pdf_available} type="primary" icon={<FileImageOutlined />} onClick={() => exportBQPDF()} loading={bq_loading} size="small">
            BQ PDF
          </Button>
        </Tooltip>
      </span>

      {isOpen && (
        <Modal
          title={"Results Complete"}
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          footer={null}
          width={showDebug ? "900px" : "400px"}
          centered
          // style={{
          //   margin: "auto",
          // }}
          bodyStyle={{ margin: "auto", maxHeight: 600, overflowY: "auto", padding: 0 }}
        >
          <BQWrap>
            {!bq_err && (
              <>
                <div className="bq-results">
                  <input placeholder="Enter Quote ID or paste Quote URL" value={cur_quote_id} onChange={(e) => update_quote_id(e.target.value)} />
                  <Button ghost type="primary" icon={<AuditOutlined />} onClick={() => send_bq_sf()} loading={bq_loading} size="small" disabled={cur_quote_id == undefined}>
                    Send to Salesforce
                  </Button>
                </div>
                {/* div that is centered  */}
                <div className="bq-results">
                  <span>{success_to_sf === "success" ? "Successfully updated Salesforce" : success_to_sf === "failed" ? "Failed to update Salesforce" : ""}</span>
                </div>
              </>
            )}
            {bq_err && <Alert message={bq_err} type="error" showIcon />}
            <>
              <div
                className="accordion-title"
                onClick={() => setShowDebug(!showDebug)}
                // style={{padding: '10px', textAlign: 'center', border: '1px solid #e8e8e8', cursor: 'pointer'}}
                style={{
                  textAlign: "right",
                  marginRight: "10px",
                  cursor: "help",
                  border: showDebug ? "1px solid #e8e8e8" : "",
                }}
              >
                <div>{showDebug ? "X" : "+"} Debug Data</div>
              </div>

              {showDebug && (
                <div>
                  <h3 className="table-header"> BOM Table </h3>
                  <table className="bq-table">
                    <thead>
                      <tr>
                        <th>Part Number</th>
                        <th>Quantity</th>
                        <th>Length (ft)</th>
                        <th>Unit Weight</th>
                        <th>Unit Cost ($)</th>
                        <th>Total Cost ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bq_res.bom &&
                        Object.keys(bq_res.bom).map((key) => {
                          if (key === " part number") return;
                          console.log(key, bq_res.bom[key]);
                          return (
                            <tr>
                              <td>{key}</td>
                              <td>{bq_res.bom[key][0]}</td>
                              <td>{bq_res.bom[key][1]}</td>
                              <td>{bq_res.bom[key][2]}</td>
                              <td>{bq_res.bom[key][3]}</td>
                              <td>{bq_res.bom[key][4]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <br /> <br />
                  <h3 className="table-header"> Cost Table </h3>
                  <table className="bq-table">
                    <tbody>
                      {Object.keys(bq_res.cost).map((key) => {
                        if (key === " part number") return;
                        console.log(key, bq_res.cost[key]);
                        return (
                          <tr>
                            <td className="td-hl">{key}</td>
                            <td>{bq_res.cost[key]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <h3 className="table-header">Debug Table </h3>
                  <table className="bq-table">
                    <tbody>
                      {Object.keys(bq_res.debug).map((key) => {
                        console.log(key, bq_res.debug[key]);
                        return (
                          <tr>
                            <td className="td-hl">{key}</td>
                            <td>{bq_res.debug[key]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          </BQWrap>
        </Modal>
      )}
    </>
  );
};

export { BudgetaryQuote };
