import React, { useState, useEffect } from "react";
import { useMap } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { edit_canopies, rotate, move, draw_tree, draw_tree_line, draw_building } from "../../../assets/images";

import { DrawTree, DrawTreeLine, DrawBuilding } from "./";
import { Radio } from "antd";
import { alertActions, canopyActions } from "../../Redux";
import { Button, Tooltip } from "../../BaseComponents";

const ShadeDrawingTools = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);

  const [selected_shape, set_selected_shape] = useState("building");

  useEffect(() => {
    if (shade_objects && Object.values(shade_objects)?.length > 0 && selected_shade_object_id) {
      set_selected_shape(shade_objects[selected_shade_object_id]?.type);
    }
  }, [selected_shade_object_id]);

  return (
    <section className="map-action-buttons">
      <DrawTree />
      <DrawTreeLine />
      <DrawBuilding />
      {/* {shade_objects && Object.values(shade_objects)?.length > 0 && selected_shade_object_id && (
        <>
          <section style={{ height: "60%", width: 1, backgroundColor: "#d9d9d9" }} />
          <Tooltip placement="bottom" title="Edit Object" mouseEnterDelay={1000}>
            <Button type="secondary" size="small" onClick={() => map.fire("edit_shade_object", { id: selected_shade_object_id })}>
              <img draggable={false} src={edit_canopies} />
            </Button>
            type="secondary" size="small"
          </Tooltip>
          {selected_shape != "tree" && (
            <>
              <Tooltip placement="bottom" title="Move Object" mouseEnterDelay={1000}>
                <Button type="secondary" size="small" onClick={() => map.fire("rotate_shade_object", { id: selected_shade_object_id })}>
                  <img draggable={false} src={rotate} />
                </Button>
                type="secondary" size="small"
              </Tooltip>
              <Tooltip placement="bottom" title="Rotate Object" mouseEnterDelay={1000}>
                <Button type="secondary" size="small" onClick={() => map.fire("move_shade_object", { id: selected_shade_object_id })}>
                  <img draggable={false} src={move} />
                </Button>
                type="secondary" size="small"
              </Tooltip>
            </>
          )}
        </>
      )} */}
    </section>
  );
};

const ShadeDrawing = () => {
  const dispatch = useDispatch();
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);

  const drawing_tree = useSelector((state) => state.canopy.drawing_tree);
  const drawing_tree_line = useSelector((state) => state.canopy.drawing_tree_line);
  const drawing_building = useSelector((state) => state.canopy.drawing_building);
  const edit_shade = useSelector((state) => state.canopy.edit_shade);
  const move_shade = useSelector((state) => state.canopy.move_shade);
  const rotate_shade = useSelector((state) => state.canopy.rotate_shade);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const [selected_shape, set_selected_shape] = useState("building");

  const disable_shade_draw = Object.values(shade_objects).length >= 8;

  useEffect(() => {
    if (disable_shade_draw) {
      dispatch(alertActions.info("Project limit of 8 shade objects has been met", 5));

      // dispatch(alertActions.info("Project limit of 8 shade objects has been met. You can edit current shapes or remove an object to continue placing shade objects", 5));
    }
  }, [disable_shade_draw]);

  useEffect(() => {
    if (shade_objects && Object.values(shade_objects)?.length > 0 && selected_shade_object_id) {
      set_selected_shape(shade_objects[selected_shade_object_id]?.type);
    }
  }, [selected_shade_object_id]);

  const handleHeightUnitChange = () => {
    dispatch(canopyActions.updateShadeObject("shade_height_unit", shade_height_unit === "m" ? "ft" : "m"));
  };
  return (
    <>
      <section className="draw_buttons">
        <Tooltip placement={disable_shade_draw ? "right" : "bottom"} title={disable_shade_draw ? "Limit of 8 shade objects has been met" : "Draw Tree"} mouseEnterDelay={1000}>
          <Button
            size="small"
            type="secondary"
            disabled={disable_shade_draw}
            style={{ backgroundColor: drawing_tree ? "var(--primary-brand-color)" : "unset" }}
            onClick={() => dispatch(canopyActions.update_canopy_toolbar("drawing_tree", !drawing_tree))}
          >
            <img draggable={false} src={draw_tree} />
          </Button>
        </Tooltip>
        <Tooltip placement={disable_shade_draw ? "right" : "bottom"} title={disable_shade_draw ? "Limit of 8 shade objects has been met" : "Draw Tree Line"} mouseEnterDelay={1000}>
          <Button
            size="small"
            type="secondary"
            disabled={disable_shade_draw}
            style={{ backgroundColor: drawing_tree_line ? "var(--primary-brand-color)" : "unset" }}
            onClick={() => dispatch(canopyActions.update_canopy_toolbar("drawing_tree_line", !drawing_tree_line))}
          >
            <img draggable={false} src={draw_tree_line} />
          </Button>
        </Tooltip>
        <Tooltip placement={disable_shade_draw ? "right" : "bottom"} title={disable_shade_draw ? "Limit of 8 shade objects has been met" : "Draw Building"} mouseEnterDelay={1000}>
          <Button
            size="small"
            type="secondary"
            disabled={disable_shade_draw}
            style={{ backgroundColor: drawing_building ? "var(--primary-brand-color)" : "unset" }}
            onClick={() => dispatch(canopyActions.update_canopy_toolbar("drawing_building", !drawing_building))}
          >
            <img draggable={false} src={draw_building} />
          </Button>
        </Tooltip>

        {/* {shade_objects && Object.values(shade_objects)?.length > 0 && selected_shade_object_id && (
          <>
            <section style={{ height: "70%", width: 1, backgroundColor: "#d9d9d9", margin: "0px 3px" }} />
            <Tooltip placement="bottom" title="Edit Object" mouseEnterDelay={1000}>
              <Button
                size="small"
                type="secondary"
                style={{ backgroundColor: edit_shade ? "var(--primary-brand-color)" : "unset" }}
                onClick={() => dispatch(canopyActions.update_canopy_toolbar("edit_shade", !edit_shade))}
              >
                <img draggable={false} src={edit_canopies} />
              </Button>
            </Tooltip>
            {selected_shape != "tree" && (
              <>
                <Tooltip placement="bottom" title="Rotate Object" mouseEnterDelay={1000}>
                  <Button
                    size="small"
                    type="secondary"
                    style={{ backgroundColor: rotate_shade ? "var(--primary-brand-color)" : "unset" }}
                    onClick={() => dispatch(canopyActions.update_canopy_toolbar("rotate_shade", !rotate_shade))}
                  >
                    <img draggable={false} src={rotate} />
                  </Button>
                </Tooltip>
                <Tooltip placement="bottom" title="Move Object" mouseEnterDelay={1000}>
                  <Button
                    size="small"
                    type="secondary"
                    style={{ backgroundColor: move_shade ? "var(--primary-brand-color)" : "unset" }}
                    onClick={() => dispatch(canopyActions.update_canopy_toolbar("move_shade", !move_shade))}
                  >
                    <img draggable={false} src={move} />
                  </Button>
                </Tooltip>
              </>
            )}
          </>
        )} */}
      </section>
      <section className="measurement_toggle">
        <Radio.Group buttonStyle="solid" size="small" value={shade_height_unit} style={{ display: "flex" }} onChange={handleHeightUnitChange}>
          <Radio.Button key="feet" value="ft">
            ft
          </Radio.Button>
          <Radio.Button key="meter" value="m">
            m
          </Radio.Button>
        </Radio.Group>
      </section>
    </>
  );
};

export { ShadeDrawingTools, ShadeDrawing };
