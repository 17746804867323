import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { Button } from "../../BaseComponents";
import { CSVLink } from "react-csv";
import { FileExcelOutlined, CopyOutlined } from "@ant-design/icons";
import { inputsActions } from "../../Redux";

const ResultActions = ({ isInternalSift, downloadLock, csvRef, projectName, csvData, logDownload, onCopyResults }) => {
  return (
    <section className="all-results">
      <section className="all-results-header">
        <p>All Results:</p>
      </section>
      <section className="all-results-buttons">
        {!isInternalSift ? (
          <Tooltip placement="top" title="All results to clipboard" mouseEnterDelay={0.5}>
            <Button ghost type="secondary" onClick={onCopyResults} size="small">
              <CopyOutlined />
              Copy
            </Button>
          </Tooltip>
        ) : (
          <span id="download-csv-tip" style={{ marginRight: 5, outline: "unset" }}>
            {downloadLock ? (
              <Button ghost type="secondary" size="small" onClick={() => dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_csv"))}>
                Unlock CSV
              </Button>
            ) : (
              <CSVLink ref={csvRef} filename={projectName ? `${projectName}_${MMDDYYYY}.csv` : `sift_project_${MMDDYYYY}.csv`} data={csvData}>
                <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5} onClick={() => logDownload("ground-csv")}>
                  <Button ghost type="secondary" size="small">
                    <FileExcelOutlined />
                    CSV
                  </Button>
                </Tooltip>
              </CSVLink>
            )}
          </span>
        )}
      </section>
    </section>
  );
};

export { ResultActions };
