import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//styles
import { LiveReportLayout } from "./styles";
import { losses } from "./components/perfTestData";
// components
import {
  LiveReportHeader,
  LiveReportFooter,
  ReportTitle,
  InfoBoxes,
  LiveReportMap,
  RackingTable,
  TrackerBreakdownTable,
  LayoutTable,
  ModuleTable,
  PerformanceTable,
  InverterTable,
  BOMTable,
  IAMGraph,
  IAMTable,
  SoilingAlbedoTable,
  PerformanceSummaryTable,
  WindAndTemp,
  EArrayEGridGraph,
  PerfGraph,
  LossTable,
  InverterEffGraph,
  TopoMap,
  TopoScaleView,
  TopoLossTable,
} from "./components";
import { Spin } from "../BaseComponents";
import { siftActions } from "..";

import { LiveReportMapboxMap } from "..";

//product images
//import { dahlia_fuse, glide_front, glide_rear, terratrack } from "../../assets/images";

const LiveReport = ({ addMapRefs, loadingLayout, captureGraphImage }) => {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.sift.ioManager.report.reportData);

  // useEffect(() => {
  //   console.log("report Data", reportData);
  // }, [reportData]);

  useEffect(() => {
    if (!loadingLayout) {
      let pageLength = reportData.topo_id ? 15 : document.querySelectorAll(".page-wrap").length;
      dispatch(siftActions.updateReportData("numOfPages", pageLength));
    }
  }, [loadingLayout]);

  // const getProductImage = (product = undefined) => {
  //   // switch (reportData.racking_name) {
  //   // console.log("product", product);
  //   const productImages = {
  //     "Terrasmart GLIDE Fuse": dahlia_fuse,
  //     "Terrasmart TerraTrak": terratrack,
  //     "Terrasmart GLIDE Wave": glide_front,
  //     "Terrasmart GLIDE Agile": glide_rear,
  //   };
  //
  //   return productImages[product] || '';
  // };

  return (
    <section id="TheLiveReport">
      {loadingLayout && !reportData ? (
        <LiveReportLayout>
          <section className="page-wrap">
            <section className="page">
              <section className="report-loader">
                <Spin size="large" text="Loading PDF Report..." />
              </section>
            </section>
          </section>
        </LiveReportLayout>
      ) : (
        <LiveReportLayout>
          {Object.values(reportData).length > 0 && (
            <>
              <section className="page-wrap">
                <section className="page">
                  <LiveReportHeader />
                  <section className="report-rundown">
                    <ReportTitle />
                    <InfoBoxes reportData={reportData} />
                    <section className="vicinity-map">
                      <LiveReportMap mapName={"vicinityMap"} showToggles={false} addMapRefs={addMapRefs} />
                    </section>
                    <section className="layout-map">
                      <LiveReportMapboxMap mapName={"layoutMap"} showToggles={true} addMapRefs={addMapRefs} />
                    </section>
                  </section>
                  <LiveReportFooter designation={"A001"} pageNum={1} />
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="large-layout-map">
                    <LiveReportMapboxMap mapName={"largeLayoutMap"} showToggles={true} addMapRefs={addMapRefs} />
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"A002"} pageNum={2} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Racking</h3>
                    </section>
                    <section className="topLeft">
                      <RackingTable reportData={reportData} />
                      <TrackerBreakdownTable reportData={reportData} />
                    </section>
                    {reportData.platform === "internal_sift" && <section className="racking-image">{/* <img src={getProductImage(reportData.racking_name)} /> */}</section>}
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B001"} pageNum={3} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="module-content">
                    <section className="title">
                      <h3>Module</h3>
                    </section>
                    <section className="module-table">
                      <ModuleTable reportData={reportData} />
                    </section>
                    <section className="iam-graph">
                      <IAMGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                      <section className="iam-table">
                        <IAMTable reportData={reportData} />
                      </section>
                    </section>
                    {/* <section className="iam">
                  <IAMGraph reportData={reportData} />
                  <section className="iam-table">
                    <IAMTable reportData={reportData} />
                  </section>
                </section> */}
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B002"} pageNum={4} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Inverter</h3>
                    </section>
                    <section className="topLeft">
                      <InverterTable reportData={reportData} />
                    </section>
                    <section className="topRight">
                      <InverterEffGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B003"} pageNum={5} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Performance</h3>
                    </section>
                    <section className="topLeft-perf-table ">
                      <PerformanceTable reportData={reportData} />
                      <SoilingAlbedoTable reportData={reportData} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B004"} pageNum={6} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Layout</h3>
                    </section>
                    <section className="topLeft">
                      <LayoutTable reportData={reportData} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B005"} pageNum={7} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Bill of Materials</h3>
                    </section>
                    <section className="topLeft">
                      {reportData?.rack_breakdown && <BOMTable reportData={reportData} />}
                      <p>*Bill of materials is presented here for budgetary and general guidance purposes only.</p>
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"R001"} pageNum={8} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="perf-results">
                    <h3>Performance Results</h3>
                    <section className="perf-table">
                      <PerformanceSummaryTable reportData={reportData} />
                    </section>
                    <section className="wind-graph">
                      <WindAndTemp reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                    <section className="eArray-graph">
                      <EArrayEGridGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                    <section className="perf-graph">
                      <PerfGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"R002"} pageNum={9} />
                  </section>
                </section>
              </section>

              <section className="page-wrap margin-vert">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Loss Tables</h3>
                    </section>
                    <section className="left-column">
                      <LossTable reportData={reportData} losses={losses} />
                    </section>
                    <section className="bifacial-column">{reportData.en_bifacial === 1 && <LossTable reportData={reportData} bifacial={true} losses={losses} />}</section>
                    <section className="bifacial-column">{Object.values(reportData.summary?.topo_impact).length > 0 && <TopoLossTable reportData={reportData} />}</section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"R003"} pageNum={10} />
                  </section>
                </section>
              </section>

              {reportData?.topo_id && (
                <>
                  <section className="page-wrap margin-vert">
                    <section className="page">
                      <section className="report-header">
                        <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                      </section>
                      <section className="topo-content">
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`Elevation (Ungraded)`}</h3>
                          </section>
                          <section className="topo-map">
                            <TopoMap mapName={"ele"} topoMode={"ele"} setLiveReportMapRefs={addMapRefs} />
                          </section>
                          <section className="topo-scale">
                            <TopoScaleView name="topo_scale_ele" topo_id={reportData.topo_id} mode="ele" setLiveReportMapRefs={addMapRefs} />
                            {/* <img src={getTopoScale(reportData.topo_id, "ele")} /> */}
                          </section>
                        </section>
                        {reportData.vis_ele_graded_avail && (
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Elevation (Graded)`}</h3>
                            </section>
                            <section className="topo-map">
                              <TopoMap mapName={"ele-g"} topoMode={`ele/G/${reportData.vis_ele_graded_percent}`} setLiveReportMapRefs={addMapRefs} />
                            </section>
                            <section className="topo-scale">
                              <TopoScaleView name="topo_scale_ele-g" topo_id={reportData.topo_id} mode={`ele/G/${reportData.vis_ele_graded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              {/* <img src={getTopoScale(reportData.topo_id, `ele/G/${reportData.vis_ele_graded_percent}`)} /> */}
                            </section>
                          </section>
                        )}
                      </section>
                      <section className="report-footer">
                        <LiveReportFooter pageNum={11} designation={"T001"} />
                      </section>
                    </section>
                  </section>

                  {reportData?.vis_g?.graded_avail && (
                    <section className="page-wrap margin-vert">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Cut and Fill`}</h3>
                            </section>
                            <section className="topo-map">
                              <TopoMap mapName={"CF"} topoMode={`CF/${reportData?.vis_g?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                            </section>
                            <section className="topo-scale">
                              <TopoScaleView name="topo_scale_CF" topo_id={reportData.topo_id} mode={`CF/${reportData?.vis_g?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              {/* <img src={getTopoScale(reportData.topo_id, `CF/${reportData.vig_g_ungraded_percent}`)} /> */}
                            </section>
                          </section>
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter pageNum={12} designation={"T002"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData?.vis_max?.ungraded_avail || reportData?.vis_max?.graded_avail) && (
                    <section className="page-wrap margin-vert">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Max Slope (Ungraded)`}</h3>
                            </section>
                            <section className="topo-map">
                              <TopoMap mapName={"U"} topoMode={`U/${reportData?.vis_max?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                            </section>
                            <section className="topo-scale">
                              <TopoScaleView name="topo_scale_U" topo_id={reportData.topo_id} mode={`U/${reportData?.vis_max?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />

                              {/* <img src={getTopoScale(reportData.topo_id, `U/${reportData.vis_max_ungraded_percent}`)} /> */}
                            </section>
                          </section>
                          {reportData?.vis_max?.graded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`Max Slope (Graded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"U-G"} topoMode={`U/G/${reportData?.vis_max?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <TopoScaleView name="topo_scale_U-G" topo_id={reportData.topo_id} mode={`U/G/${reportData?.vis_max?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />

                                {/* <img src={getTopoScale(reportData.topo_id, `U/G/${reportData.vis_max_graded_percent}`)} /> */}
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter pageNum={13} designation={"T003"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData?.vis_ns?.ungraded_avail || reportData?.vis_ns?.graded_avail) && (
                    <section className="page-wrap margin-vert">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData?.vis_ns?.ungraded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Ungraded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"NS"} topoMode={`NS/${reportData?.vis_ns?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <TopoScaleView name="topo_scale_NS" topo_id={reportData.topo_id} mode={`NS/${reportData?.vis_ns?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />

                                {/* <img src={getTopoScale(reportData.topo_id, `NS/${reportData.vis_ns_ungraded_percent}`)} /> */}
                              </section>
                            </section>
                          )}

                          {reportData?.vis_ns?.graded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Graded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"NS-G"} topoMode={`NS/G/${reportData?.vis_ns?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <TopoScaleView name="topo_scale_NS-G" topo_id={reportData.topo_id} mode={`NS/G/${reportData?.vis_ns?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />

                                {/* <img src={getTopoScale(reportData.topo_id, `NS/G/${reportData.vis_ns_graded_percent}`)} /> */}
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter pageNum={14} designation={"T004"} />
                        </section>
                      </section>
                    </section>
                  )}
                  {reportData && (reportData?.vis_ew?.ungraded_avail || reportData?.vis_ew?.graded_avail) && (
                    <section className="page-wrap margin-vert">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData?.vis_ew?.ungraded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Ungraded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"EW"} topoMode={`EW/${reportData?.vis_ew?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <TopoScaleView name="topo_scale_EW" topo_id={reportData.topo_id} mode={`EW/${reportData?.vis_ew?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />

                                {/* <img src={getTopoScale(reportData.topo_id, `EW/${reportData?.vis_ew?.ungraded_percent}`)} /> */}
                              </section>
                            </section>
                          )}

                          {reportData?.vis_ew?.graded_avail && reportData?.vis_ew?.ungraded_percent && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Graded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"EW-G"} topoMode={`EW/G/${reportData?.vis_ew?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <TopoScaleView name="topo_scale_EW-G" topo_id={reportData.topo_id} mode={`EW/G/${reportData?.vis_ew?.ungraded_percent}`} setLiveReportMapRefs={addMapRefs} />

                                {/* <img src={getTopoScale(reportData.topo_id, `EW/G/${reportData.vis_ew_graded_percent}`)} /> */}
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter pageNum={15} designation={"T005"} />
                        </section>
                      </section>
                    </section>
                  )}
                </>
              )}

              {/* {reportData?.topo_id && (
                <>
                  <section className="page-wrap">
                    <section className="page">
                      <section className="report-header">
                        <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                      </section>
                      <section className="topo-content">
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`Elevation (Ungraded)`}</h3>
                          </section>
                          <section className="topo-map">
                            <TopoMap mapName={"ele"} topoMode={"ele"} setLiveReportMapRefs={addMapRefs} />
                          </section>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topoData.topo_id, "ele")} />
                          </section>
                        </section>
                        {reportData.topoData.layers_generated.ele.gavail && (
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Elevation (Graded)`}</h3>
                            </section>
                            <section className="topo-map">
                              <TopoMap mapName={"ele-g"} topoMode={"ele/G"} setLiveReportMapRefs={addMapRefs} />
                            </section>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topoData.topo_id, "ele/G")} />
                            </section>
                          </section>
                        )}
                      </section>
                      <section className="report-footer">
                        <LiveReportFooter designation={"T001"} />
                      </section>
                    </section>
                  </section>

                  {reportData.topoData.layers_generated.CF.avail && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Cut and Fill`}</h3>
                            </section>
                            <section className="topo-map">
                              <TopoMap mapName={"CF"} topoMode={`CF/${reportData.topoData.layers_generated.CF.limit}`} setLiveReportMapRefs={addMapRefs} />
                            </section>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topoData.topo_id, `CF`, reportData.topoData.layers_generated.CF.limit)} />
                            </section>
                          </section>
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T002"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData.topoData.layers_generated.U.avail || reportData.topoData.layers_generated.U.gavail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Max Slope (Ungraded)`}</h3>
                            </section>
                            <section className="topo-map">
                              <TopoMap mapName={"U"} topoMode={`U/${reportData.topoData.layers_generated.U.limit}`} setLiveReportMapRefs={addMapRefs} />
                            </section>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topoData.topo_id, `U`, reportData.topoData.layers_generated.U.limit)} />
                            </section>
                          </section>
                          {reportData.topoData.layers_generated.U.gavail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`Max Slope (Graded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"U-G"} topoMode={`U/G/${reportData.topoData.layers_generated.U.limit}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `U/G`, reportData.topoData.layers_generated.U.limit)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T003"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData.topoData.layers_generated.NS.avail || reportData.topoData.layers_generated.NS.gavail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData.topoData.layers_generated.NS.avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Ungraded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"NS"} topoMode={`NS/${reportData.topoData.layers_generated.NS.limit}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `NS`, reportData.topoData.layers_generated.NS.limit)} />
                              </section>
                            </section>
                          )}

                          {reportData.topoData.layers_generated.NS.gavail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Graded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"NS-G"} topoMode={`NS/G/${reportData.topoData.layers_generated.NS.limit}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `NS/G`, reportData.topoData.layers_generated.NS.limit)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T004"} />
                        </section>
                      </section>
                    </section>
                  )}
                  {reportData && (reportData.topoData.layers_generated.EW.avail || reportData.topoData.layers_generated.EW.gavail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData.topoData.layers_generated.EW.avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Ungraded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"EW"} topoMode={`EW/${reportData.topoData.layers_generated.EW.limit}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `EW`, reportData.topoData.layers_generated.EW.limit)} />
                              </section>
                            </section>
                          )}

                          {reportData.topoData.layers_generated.EW.gavail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Graded)`}</h3>
                              </section>
                              <section className="topo-map">
                                <TopoMap mapName={"EW-G"} topoMode={`EW/G/${reportData.topoData.layers_generated.EW.limit}`} setLiveReportMapRefs={addMapRefs} />
                              </section>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `EW/G`, reportData.topoData.layers_generated.EW.limit)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T005"} />
                        </section>
                      </section>
                    </section>
                  )}
                </>
              )} */}
            </>
          )}
        </LiveReportLayout>
      )}
    </section>
  );
};

export { LiveReport };
