import React, { useState, useEffect } from "react";
import { Tooltip, Popconfirm } from "antd";
import { Button } from "../../BaseComponents";
import { useDispatch } from "react-redux";
import { siftActions } from "../../Redux";

const ReportActions = ({ isLiveReport, isInternalSift, show, canDownloadPDF, isLoading, downloadLock, captureReport, toggleDownloadModal, undoEdits }) => {
  const dispatch = useDispatch();

  return (
    <section className="report-download-button">
      <Button
        ghost
        style={{ marginRight: 5 }}
        disabled={isLoading || !canDownloadPDF}
        loading={isLoading}
        type="secondary"
        size="large"
        onClick={() => {
          dispatch(siftActions.updateUIState(isLiveReport ? "show_live_report" : "show_layout_editor", !show));
        }}
      >
        {show ? `Hide ${isLiveReport ? "Report" : "Editor"}` : `Show ${isLiveReport ? "Report" : "Editor"}`}
      </Button>

      {isLiveReport ? (
        <Tooltip placement="right" title={canDownloadPDF ? null : "PDF Reports are available on our Standard and Pro plans."}>
          <Button
            ghost
            disabled={isLoading || !canDownloadPDF}
            loading={isLoading}
            type="secondary"
            size="large"
            onClick={() => (isInternalSift && downloadLock ? toggleDownloadModal() : captureReport())}
          >
            {downloadLock && isInternalSift ? "Unlock Download" : "Download Report"}
          </Button>
        </Tooltip>
      ) : (
        <Popconfirm title="Undo all edits and restore original layout?" onConfirm={undoEdits} okText="Yes" cancelText="No">
          <Button style={{ marginRight: 5 }} type="secondary" size="large">
            Undo All Edits
          </Button>
        </Popconfirm>
      )}
    </section>
  );
};

export { ReportActions };
