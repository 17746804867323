import styled from "styled-components";

const ShadeInputWrap = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 310px min-content;
`;

const DrawToolsWrap = styled.section`
  grid-row: 1;
  border-bottom: 1px solid rgb(229, 231, 231);
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 10px;
  grid-template-areas: "draw_buttons measurement_toggle";

  .draw_buttons {
    grid-area: draw_buttons;
    display: flex;
    align-items: center;
    button {
      width: 24px;
      img {
        height: 16px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .measurement_toggle {
    grid-area: measurement_toggle;
  }

  /* button {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid var(--primary-brand-color);
    border-radius: 2px;
    margin-right: ${(props) => (props.vertical ? "0px" : "5px")};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s var(--easing-spring);

    img {
      width: ${(props) => (props.vertical ? "16px" : "20px")};
      height: ${(props) => (props.vertical ? "16px" : "20px")};
    }

    &:hover {
      cursor: pointer;
      background: var(--background-highlight-color);
    }

    &:hover > img {
      filter: invert(100%);
    }

    &:last-child {
      margin-right: 0px;
    }
  } */
`;

const ShadeRowWrap = styled.section`
  width: 100%;
  grid-row: 2;
  height: 100%;
  overflow-y: scroll;
  border-bottom: 1px solid rgb(229, 231, 231);
`;

const ShadeInputRow = styled.section`
  width: 100%;
  height: 32px;
  place-items: center start;
  display: grid;
  grid-template-columns: 40% 40% 20%;
  padding: 0px 10px;
  margin-bottom: 5px;
  background: ${(props) => props.selected && "var(--background-highlight-color)"};

  &:hover {
    cursor: pointer;
    background: ${(props) => !props.selected && "var(--background-hover-color)"};
  }

  p {
    font-size: 14px;
    margin: 0px;
  }

  button {
    padding: 2px:
    bottom: 6px;

    img {
      height: 20px;
      width: 20px;
      filter: brightness(2);
    }
  }
`;

const RunShadeButtonWrap = styled.section`
  border-bottom: 1px solid #d9d9d9;
  display: grid;
  grid-row: 3;

  width: 100%;
  height: auto;
  padding: 10px;
  grid-template-columns: 1fr 1fr min-content;
  /* grid-template-rows: repeat(3, min-content); */
  grid-template-rows: 50px 10px 75px;
  align-content: center;
  gap: 20px 0px;
  grid-template-areas:
    "run_shade_buttons run_shade_buttons delete_all"
    "shade_impact shade_impact shade_impact"
    "shade_days shade_days shade_days";

  .en-shade-impact {
    grid-area: shade_impact;
    display: flex;
    margin-top: -20px;
    align-items: center;
    justify-content: space-between;
  }

  .shade-days {
    grid-area: shade_days;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    gap: 25px 0px;
    justify-content: space-between;
    grid-template-areas:
      "shade_day_label day_picker"
      "slider slider";

    .shade-day-label {
      grid-area: shade_day_label;
    }
    .day-picker {
      grid-area: day_picker;
    }
    .shade-slider {
      grid-area: slider;
    }
  }

  .run-shade-btns {
    grid-area: run_shade_buttons;
    display: flex;
  }

  .shade-delete-all {
    grid-area: delete_all;
  }
`;

export { ShadeInputWrap, ShadeRowWrap, ShadeInputRow, RunShadeButtonWrap, DrawToolsWrap };
