import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Radio } from "antd";
import { collect_shade_inputs } from "../ShadeHelpers/Shade.editor";
import { RunShadeButtonWrap } from "./styles";
import { delete_polygon } from "../../../assets/images";

import { Button, Tooltip, RadioGroup, RadioButton } from "../../BaseComponents";
import { ShadeSlider } from "./ShadeSlider";
import { run_shade_job, execute_job, canopyActions, siftActions, usePrevious, get_ele_tz } from "../../Redux";

const RunShadeButton = () => {
  const dispatch = useDispatch();

  const canopies = useSelector((state) => state.canopy.canopies);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const map_center = useSelector((state) => state.inputs.map_center);
  const running_shade_sim = useSelector((state) => state.canopy.running_shade_sim);
  const shade_results = useSelector((state) => state.canopy.shade_results);
  const en_shade_impact = useSelector((state) => state.canopy.en_shade_impact);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);
  const shade_month = useSelector((state) => state.canopy.shade_month);
  const run_shade_job_id = useSelector((state) => state.canopy.run_shade_job_id);
  const timezone = useSelector((state) => state.inputs.timezone);
  const weather_tz = useSelector((state) => state.inputs.weather_tz);
  const elevation = useSelector((state) => state.inputs.elevation);
  const weather_source = useSelector((state) => state.inputs.weather_source);
  // const firstTimeShadeObjectsNonEmpty = useRef(true);

  // const disable_shade_vis = Object.values(shade_results).length < 1;

  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";

  const [disable_run_shade, set_disable_run_shade] = useState(true);
  const [disable_shade_vis, set_disable_shade_vis] = useState(true);
  const [disable_impact, set_disable_impact] = useState(true);

  const geTimezone = async () => {
    let ele_tz = await get_ele_tz([map_center[0], map_center[1]]);
    let theTimeZone = -5;
    if (ele_tz.data.tz.status === "OK") {
      theTimeZone = ele_tz.data.tz.rawOffset / 3600;
    }

    return theTimeZone;
  };

  const handleRunShade = async () => {
    let time_zone = timezone;
    if (!time_zone) {
      time_zone = await geTimezone();
    }

    let shade_options = {
      latitude: map_center[0],
      longitude: map_center[1],
      shade_height_unit,
      elevation: 1,
      type: using_canopy ? "canopy" : "ground",
      timeZone: time_zone,
      shadow_day: "Jan 1 2021",
    };

    const shade_inputs = collect_shade_inputs(canopies, shade_objects, shade_options);
    // console.log("shade job inputs", shade_inputs);
    dispatch(execute_job(run_shade_job(shade_inputs)));
  };

  const handleCancelRunShade = () => {
    if (!run_shade_job_id) return;
    dispatch(siftActions.cancelRun(run_shade_job_id, "cancel_shade"));
  };

  const prev_en_shade_impact = usePrevious(en_shade_impact);

  useEffect(() => {
    const shadeObjectsLength = Object.values(shade_objects).length;

    if (shadeObjectsLength > 0) {
      set_disable_run_shade(false);
      set_disable_impact(false);

      // if (prev_en_shade_impact !== 1 && en_shade_impact === 0) {
      //   dispatch(canopyActions.updateShadeObject("en_shade_impact", 1));
      // }
    } else {
      set_disable_run_shade(true);
      set_disable_impact(true);
      // if (shadeObjectsLength == 0 && en_shade_impact == 1) {
      //   dispatch(canopyActions.updateShadeObject("en_shade_impact", 0));
      // }
    }
  }, [shade_objects, prev_en_shade_impact, set_disable_run_shade, set_disable_impact]);

  // useEffect(() => {
  //   const shadeObjectsLength = Object.values(shade_objects).length;
  //   if (shadeObjectsLength == 0) {
  //     dispatch(canopyActions.updateShadeObject("en_shade_impact", 0));
  //     set_disable_impact(true);
  //   }
  // }, [en_shade_impact, shade_objects]);

  useEffect(() => {
    if (Object.values(shade_results).length > 0) {
      set_disable_shade_vis(false);
    } else {
      set_disable_shade_vis(true);
    }
  }, [shade_results]);

  const clearShade = () => {
    dispatch(canopyActions.updateShadeObject("clear_shade_results"));
  };

  const delete_all_shade_objects = () => {
    dispatch(canopyActions.updateShadeObject("delete_all_shade_objects"));
  };

  return (
    <RunShadeButtonWrap>
      <section className="run-shade-btns">
        <Tooltip placement="right" title={disable_run_shade ? "Place shade objects in the map to run a Shade simulation." : null}>
          <Button
            type="secondary"
            size="medium"
            label={running_shade_sim && run_shade_job_id ? "Cancel" : "Run Shade"}
            styles={{ width: 120 }}
            loading={running_shade_sim && !run_shade_job_id}
            disabled={disable_run_shade}
            onClick={running_shade_sim ? handleCancelRunShade : handleRunShade}
          />
        </Tooltip>
        <Tooltip placement="right" title={disable_shade_vis ? "No shade data to clear" : null}>
          <Button type="ghost" size="medium" label="Clear Shade" styles={{ width: 120 }} loading={running_shade_sim} disabled={disable_shade_vis} onClick={clearShade} />
        </Tooltip>
      </section>
      <section className="shade-delete-all">
        <Tooltip placement="right" title="Delete Shade Objects and Results" mouseEnterDelay={0.25}>
          <Popconfirm
            placement="right"
            title={`Delete All Shade Objects?`}
            onCancel={() => console.log("canceled")}
            okText="Yes"
            cancelText="No"
            disabled={running_shade_sim}
            style={{ maxWidth: "300px" }}
            // disabled={Object.values(shade_results).length == 0}
            onConfirm={() => {
              delete_all_shade_objects();
            }}
          >
            <Button type="link" styles={{ opacity: running_shade_sim ? 0.4 : 1, cursor: running_shade_sim ? "not-allowed" : "unset" }}>
              <img style={{ width: 20 }} src={delete_polygon} />
            </Button>
          </Popconfirm>
        </Tooltip>
      </section>

      <section className="en-shade-impact">
        <Tooltip title="Run shade impact when generating">
          <label>Enable Shade Impact:</label>
        </Tooltip>
        <section className="racking-input.radio-buttons">
          <RadioGroup
            value={en_shade_impact}
            onChange={(e) => {
              dispatch(canopyActions.updateShadeObject("en_shade_impact", parseInt(e.target.value)));
            }}
          >
            <Radio
              disabled={disable_impact}
              value={1}
              checked={en_shade_impact === 1}
              onClick={() => {
                dispatch(canopyActions.updateShadeObject("en_shade_impact", 1));
              }}
            >
              Enable
            </Radio>
            <Radio
              disabled={disable_impact}
              value={0}
              checked={en_shade_impact === 0}
              onClick={() => {
                dispatch(canopyActions.updateShadeObject("en_shade_impact", 0));
              }}
            >
              Disable
            </Radio>
          </RadioGroup>
        </section>
      </section>
      {shade_results?.shade_day_marks && (
        <section className="shade-days">
          <section className="shade-day-label">
            <label>Shade Visualization:</label>
          </section>
          <section className="day-picker">
            <Tooltip placement="right" title={disable_shade_vis ? "Run a shade simulation to see the shade for June 21st and December 21st." : null}>
              <Radio.Group
                buttonStyle="solid"
                size="small"
                value={shade_month}
                style={{ display: "flex" }}
                // onChange={(e) => {
                //   dispatch(canopyActions.updateShadeObject("shade_month", e.target.value));
                // }}
              >
                <Radio.Button
                  disabled={disable_shade_vis}
                  key="june"
                  value={"jun21"}
                  size="small"
                  checked={shade_month === "jun21"}
                  onClick={() => {
                    dispatch(canopyActions.updateShadeObject("shade_month", "jun21"));
                  }}
                >
                  June 21
                </Radio.Button>
                <Radio.Button
                  disabled={disable_shade_vis}
                  key="dec"
                  value={"dec21"}
                  size="small"
                  checked={shade_month === "dec21"}
                  onClick={() => {
                    dispatch(canopyActions.updateShadeObject("shade_month", "dec21"));
                  }}
                >
                  December 21
                </Radio.Button>
              </Radio.Group>
            </Tooltip>
          </section>
          <section className="shade-slider">
            <ShadeSlider />
          </section>
        </section>
      )}
    </RunShadeButtonWrap>
  );
};

export { RunShadeButton };
