import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../Redux";

import { Modal } from "antd";

const { confirm } = Modal;

const AgreementUpdate = ({ visible, toggleAgreement }) => {
  const dispatch = useDispatch();

  const agreement_visible = useSelector((state) => state.user.show_agreement_modal);

  const [local_show_agreement_modal, set_local_inputs] = useState(false);
  const [modal_loading, set_modal_loading] = useState(false);
  const [modal_canceling, set_modal_canceling] = useState(false);

  const showTerms = (openedFromAgreementUpdate) => {
    dispatch(authActions.showTerms(true, openedFromAgreementUpdate));
  };

  useEffect(() => {
    set_local_inputs(agreement_visible);
  }, [agreement_visible]);

  const agreement_declined = () => {
    set_modal_loading(false);
    set_modal_canceling(true);
    setTimeout(() => {
      dispatch(authActions.agreementDeclined());
    }, 1200);
  };

  const agreement_accepted = () => {
    set_modal_loading(true);
    set_modal_canceling(false);
    let user = {
      ...JSON.parse(localStorage.getItem("user")),
      terrasmart_terms: true,
      show_agreement_modal: false,
    };
    dispatch(authActions.agreementAccepted(user));
  };

  return (
    <Modal
      style={{ maxWidth: 600 }}
      width="65%"
      okButtonProps={{ loading: modal_loading, disabled: modal_canceling }}
      cancelButtonProps={{ loading: modal_canceling, disabled: modal_loading }}
      title="Updated Terms and Conditions"
      // visible={local_show_agreement_modal}
      open={local_show_agreement_modal}
      closable={false}
      mask={true}
      maskClosable={false}
      className="sift-modal"
      style={{ padding: "10px 20px" }}
      okText="Agree"
      cancelText={`Decline`}
      onOk={() => agreement_accepted()}
      onCancel={() => agreement_declined()}
    >
      <p>
        Effective 1/1/2022, Sunfig Corporation is operating as Terrasmart, Inc. This change is reflected in the updated <a onClick={() => showTerms(true)}>Terms and Conditions</a>. Please agree to
        these terms to continue.
      </p>
    </Modal>
  );
};

export { AgreementUpdate };
