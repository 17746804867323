import React, { useEffect, useState } from "react";
import { Source, Layer, useMap } from "react-map-gl";

const ESRIMapServerLayer = ({ url, id, setLoadingState }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const [imageUrl, setImageUrl] = useState(null);
  const [currentBBox, setCurrentBBox] = useState(null);
  const [firstLayer, setFirstLayer] = useState(null);

  useEffect(() => {
    if (map) {
      const firstLayerId = map.getStyle().layers[5]?.id;
      setFirstLayer(firstLayerId);
    }
  }, [map]);

  const fetchMapServerData = async (url, bounds) => {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const bbox = `${sw.lng},${sw.lat},${ne.lng},${ne.lat}`;

    setLoadingState(id, true);
    const response = await fetch(`${url}/export?bbox=${bbox}&size=1127,968&dpi=96&format=png32&transparent=true&bboxSR=4326&imageSR=3857&f=json`);

    if (!response.ok) {
      setLoadingState(id, false);
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    setLoadingState(id, false);
    return { imageUrl: data.href, bbox: bounds };
  };

  const isOutsideBBox = (mapBounds, currentBBox) => {
    if (!currentBBox) return true;

    const mapSW = mapBounds.getSouthWest();
    const mapNE = mapBounds.getNorthEast();
    const bboxSW = currentBBox.southWest;
    const bboxNE = currentBBox.northEast;

    return mapSW.lng < bboxSW.lng || mapSW.lat < bboxSW.lat || mapNE.lng > bboxNE.lng || mapNE.lat > bboxNE.lat;
  };

  const updateLayer = async () => {
    if (!map) return;

    const zoom = map.getZoom();
    if (zoom < 8) return;

    const mapBounds = map.getBounds();

    if (isOutsideBBox(mapBounds, currentBBox)) {
      try {
        const { imageUrl: newImageUrl, bbox: newBBox } = await fetchMapServerData(url, mapBounds);
        setImageUrl(newImageUrl);

        setCurrentBBox({
          southWest: newBBox.getSouthWest(),
          northEast: newBBox.getNorthEast(),
        });
      } catch (error) {
        console.error("Error fetching the map server data", error);
        setLoadingState(id, false);
      }
    }
  };

  useEffect(() => {
    if (!map) return;

    updateLayer();

    map.on("moveend", updateLayer);

    return () => {
      map.off("moveend", updateLayer);
    };
  }, [map, url, id, currentBBox, setLoadingState]);

  if (!imageUrl || !currentBBox) return null;

  return (
    <>
      {firstLayer && (
        <Source
          id={id}
          type="image"
          url={imageUrl}
          coordinates={[
            [currentBBox.southWest.lng, currentBBox.northEast.lat],
            [currentBBox.northEast.lng, currentBBox.northEast.lat],
            [currentBBox.northEast.lng, currentBBox.southWest.lat],
            [currentBBox.southWest.lng, currentBBox.southWest.lat],
          ]}
        >
          <Layer id={id} type="raster" source={id} paint={{ "raster-opacity": 0.5 }} beforeId={firstLayer} />
        </Source>
      )}
    </>
  );
};

const ESRIWetlandsLayer = ({ id, setLoadingState }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const [geojsonData, setGeojsonData] = useState(null);
  const [bbox, setBbox] = useState(null);
  const [firstLayer, setFirstLayer] = useState(null);

  const baseUrl = "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer/0/query";

  const fetchWetlandsData = async (boundingBox) => {
    const zoom = map.getZoom();
    if (zoom < 12) return;

    const params = {
      where: "1=1",
      geometry: JSON.stringify({
        xmin: boundingBox[0],
        ymin: boundingBox[1],
        xmax: boundingBox[2],
        ymax: boundingBox[3],
        spatialReference: { wkid: 4326 },
      }),
      geometryType: "esriGeometryEnvelope",
      spatialRel: "esriSpatialRelIntersects",
      returnGeometry: true,
      outFields: "*",
      f: "geojson",
    };

    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}?${queryString}`;

    try {
      setLoadingState(id, true);
      const response = await fetch(url);
      const data = await response.json();
      setGeojsonData(data);
    } catch (error) {
      setLoadingState(id, false);
      console.error("Error fetching wetlands data:", error);
    }
    setLoadingState(id, false);
  };

  const handleMapMove = () => {
    const bounds = map.getBounds();
    setBbox([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]);
  };

  useEffect(() => {
    if (bbox) {
      fetchWetlandsData(bbox);
    }
  }, [bbox]);

  useEffect(() => {
    if (map) {
      const firstLayerId = map.getStyle().layers[5]?.id;
      setFirstLayer(firstLayerId);
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;

    handleMapMove();

    map.on("moveend", handleMapMove);

    return () => {
      map.off("moveend", handleMapMove);
    };
  }, [map]);

  return (
    <>
      {geojsonData && (
        <Source id="wetlands-data" type="geojson" data={geojsonData}>
          <Layer
            id={id}
            type="fill"
            paint={{
              // "fill-color": "#008000",
              "fill-color": "#00FFFF",
              "fill-opacity": 0.5,
            }}
            beforeId={firstLayer}
          />
        </Source>
      )}
    </>
  );
};

export { ESRIWetlandsLayer, ESRIMapServerLayer };
