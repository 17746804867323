import { ProjectConstants, UserConstants } from "../_constants";
//import { map_to_1_8 } from "./sift_inputs";

const initialState = {
  loading: false,
  saving: false,
  loaded: false,
  local: {},
  collab_project: {},
  quote_id: undefined,
};

export function projects(state = initialState, action) {
  switch (action.type) {
    case UserConstants.GET_USER_LIB_REQ:
    case ProjectConstants.LOAD_PROJECT_REQUEST:
    case ProjectConstants.SHARE_COLLAB_REQUEST:
    case ProjectConstants.REFRESH_PROJECT_REQUEST:
    case ProjectConstants.CHECK_COLLAB_REQUEST:
      // let clone = {}
      // Object.values(state.local).map((proj) => {
      //   clone[proj.project_id] = proj;
      //   let _variants = {};
      //   Object.values(proj.variants).map((variant) => {
      //     _variants[variant.variant_id] = {...variant};
      //   });
      //   clone[proj.project_id].variants = _variants;
      // });
      // console.log('asdas',clone)

      return { ...state, loading: true, loaded: false };

    case ProjectConstants.GOT_PROJECT_DATA:
      // console.log("GOT PROJECTS", action.projects);
      let projects = {};
      let sorted_project_data = action.projects.sort(function (a, b) {
        return b.edit_dt - a.edit_dt;
      });

      Object.values(sorted_project_data).map((proj) => {
        // console.log("proj in GOT_PROJECT_DATA ", proj);
        // update_project_data = map_to_1_8(proj);
        projects[proj.project_id] = proj;
        let _variants = {};

        proj.variants.map((variant) => {
          // console.log('creating variant', variant)
          _variants[variant.variant_id] = { ...variant };
        });

        projects[proj.project_id].variants = _variants;
        // console.log(projects[proj.project_id].variants)
        // console.log(projects[proj.project_id])
      });
      // console.log(projects)

      return {
        ...state,
        loaded: true,
        loading: false,
        local: { ...projects },
      };

    case ProjectConstants.GET_USER_DATA_FAILURE:
      return { ...JSON.parse(JSON.stringify(initialState)) };

    case ProjectConstants.UPDATE_PROJECT_REQUEST:
      return { ...state, loading: true, loaded: false };

    case ProjectConstants.UPDATE_PROJECT_SUCCESS:
      // console.log("UPDATE_PROJECT_SUCCESS", action);

      if (action.response.error) {
        return { ...state, loading: false, loaded: true, saving: false };
      }

      let updated_inputs = action.inputs;
      let updated_local = { ...state.local };
      // console.log(updated_local)
      // console.log(updated_inputs)

      if (updated_inputs.active == 0) {
        // delete project
        if (action.response.action_type === "delete_variant" && updated_inputs.variant_id) {
          let var_index = _.findIndex(updated_local[action.response.project_id].variants, { variant_id: updated_inputs.variant_id });
          if (var_index > -1) {
            // only splice array when item is found
            updated_local[action.response.project_id].variants.splice(var_index, 1); // 2nd parameter means remove one item only
          }
        } else {
          updated_local[action.response.project_id].active = action.inputs.active;
        }

        // if (updated_inputs.variant_id) {

        //   if (updated_local[action.response.project_id].variants.length == 0) {
        //     delete updated_local[action.response.project_id];
        //   }
        // } else {
        //   delete updated_local[action.response.project_id];
        // }
      } else {
        // update project / overwrite previous
        updated_inputs.project_id = action.response.project_id;
        updated_inputs.variant_id = action.response.variant_id;
        updated_inputs.project_name = action.inputs.project_name;

        let updated_project = {
          project_id: updated_inputs.project_id,
          variant_id: updated_inputs.variant_id,
          variant_name: updated_inputs.variant_name,
          project_name: updated_inputs.project_name,
          project_type: updated_inputs.project_type,
          edit_dt: action.response.editDate,
          inputs: updated_inputs,
          // active: action.response.action_type === "delete_variant" ? 0 : 1,
        };

        if (action.inputs.collab_on == 1) {
          updated_project.collab_on = 1;
          updated_project.creator = action.inputs.creator;
          updated_project.locked = action.inputs.locked;
          updated_project.locked_by_email = action.inputs.locked_by_email;
          updated_project.locked_by_me = action.inputs.locked_by_me;
          updated_project.notes = action.inputs.notes;
          updated_project.user_list = action.inputs.user_list;
        }

        if (action.response.action_type == "saveasproject") {
          updated_local[action.response.project_id] = { ...updated_project };
          updated_local[action.response.project_id].variants = [{ ...updated_project }];
          updated_local[action.response.project_id].variant_id = undefined;
          updated_local[action.response.project_id].variant_name = undefined;
          // console.log(updated_local[action.response.project_id])
        } else {
          // this is either a saving a variant or a save AS variant
          // console.log(action.response.action_type)
          if (action.response.action_type == "saveasvariant") {
            updated_local[action.response.project_id].variants.push(updated_project);
          } else {
            // this is a save variant
            let var_index = _.findIndex(updated_local[action.response.project_id].variants, { variant_id: updated_inputs.variant_id });
            if (var_index > -1) {
              updated_local[action.response.project_id].variants[var_index] = updated_project;
            }
          }
        }
      }
      // console.log("updated_local", updated_local);

      return { ...state, local: { ...updated_local }, loading: false, loaded: true, saving: false };

    case ProjectConstants.LOAD_PROJECT_COMPLETE:
      // console.log(action);
      if (action.error) {
        console.log(action.error);
      }

      // projectManager.loading = false;
      // projectManager.modal_visible = false;
      // ioManager.uiState.input_loading = false;
      return { ...state, loading: false, loaded: true };

    case ProjectConstants.REFRESH_PROJECT_SUCCESS:
    case ProjectConstants.COLLAB_SUCCESS:
      // console.log("collab success", action.response);

      let p_id = action.response.updated_project.project_id;
      return {
        ...state,
        loaded: true,
        loading: false,
        local: { ...state.local, [p_id]: action.response.updated_project },
      };

    case ProjectConstants.COLLAB_FAILURE:
      console.log(action);
      return state;

    case ProjectConstants.UPDATE_PROJECT_INPUT:
      const temp_state = { ...JSON.parse(JSON.stringify(state)) };

      temp_state[action.key] = action.value;

      return { ...temp_state };

    default:
      return state;
  }
}
