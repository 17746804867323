const project_schema = {
  type: "object",
  tab: "Project",
  properties: {
    project_name: {
      _id: "project_name",
      type: "string",
    },
    active: {
      _id: "active",
      type: "integer",
      enum: [0, 1],
    },
    // collab_on: {
    //   _id: "collab_on",
    //   type: "integer",
    //   enum: [0, 1],
    // },
    current_plan: {
      _id: "current_plan",
      type: "integer",
      minimum: 1,
    },
    mw_limit: {
      _id: "mw_limit",
      type: "integer",
      minimum: 0,
    },
  },
};

export { project_schema };
