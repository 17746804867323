const racking_schema = {
  type: "object",
  tab: "Racking",
  properties: {
    product_type: {
      type: "string",
    },
    racking_id: {
      _id: "racking_id",
      type: "string",
    },
    racking_name: {
      label: "Racking Name",
      _id: "racking_name",
      type: "string",
    },
    track_mode: {
      label: "Rack Type",
      _id: "track_mode",
      type: "integer",
      enum: [0, 1, 2],
    },
    mod_per_string: {
      label: "Modules Per String",
      _id: "mod_per_string",
      type: "integer",
    },
    design_temp: {
      label: "Design Temp",
      _id: "design_temp",
      type: "number",
    },
    rack_dims: {
      label: "Rack Dimensions",
      _id: "rack_dims",
      type: "number",
      enum: [0, 1],
    },
    modules_high: {
      label: "Modules High",
      _id: "modules_high",
      type: "integer",
    },
    orientation: {
      label: "Module Orientation",
      _id: "orientation",
      type: "integer",
    },
    racks: {
      label: "String per Rack/Face",
      _id: "racks",
      type: "array",
      items: {
        type: "object",
        properties: {
          key: { type: "integer" },
          active: { type: "integer" },
          xdim: { type: "number" },
          ydim: { type: "number" },
          module: { type: "integer" },
          string_count: { type: ["integer", "number"] },
        },
      },
      validateRacks: true,
    },
    module_gap: {
      label: "Mod Gap",
      _id: "module_gap",
      type: "number",
    },
    drive_gap: {
      label: "Drive Gap",
      _id: "drive_gap",
      type: "number",
    },
    rack_grade_limit: {
      label: "Racking Grade Limit",
      _id: "racking_grade_limit",
      type: "number",
    },
    gap: {
      label: "Rack to Rack Gap",
      _id: "gap",
      type: "number",
    },
    do_continuous: {
      label: "Display Continuous",
      _id: "do_continuous",
      type: "number",
    },
    bi_groundClearanceHeight: {
      label: "Height Above Ground",
      _id: "bi_groundClearanceHeight",
      type: "number",
    },
    bi_transmissionFactor: {
      label: "Transparent Fraction",
      _id: "bi_transmissionFactor",
      type: "number",
    },
    bi_structureShadeFactor: {
      label: "Structure Shade Factor",
      _id: "bi_structureShadeFactor",
      type: "number",
    },
    en_backtrack: {
      label: "Backtracking",
      _id: "en_backtrack",
      type: "integer",
    },
    en_irradtrack: {
      label: "Irrad. Optimized Tracking",
      _id: "en_irradtrack",
      type: "integer",
    },
    rlim: {
      label: "Tracking Angle",
      _id: "rlim",
      type: "number",
    },
    tilt_min: {
      label: "Tilt Angle",
      _id: "tilt_min",
      type: "number",
    },
    tilt_max: {
      label: "Tilt Max",
      _id: "tilt_max",
      type: "number",
    },
    tilt_inc: {
      label: "Tilts",
      _id: "tilt_inc",
      type: "number",
    },
    tilts: {
      label: "Tilts",
      type: "array",
      items: { type: "number" },
    },
    shade_mode: {
      label: "Shade Mode",
      _id: "shade_mode",
      type: "integer",
      enum: [1, 2],
    },
    Fshd_StringSteps: {
      label: "String Steps",
      _id: "Fshd_StringSteps",
      type: "integer",
    },
    Fshd_Celltrav: {
      label: "Cell Transverse",
      _id: "Fshd_Celltrav",
      type: "number",
    },
    // ⚠️⚠️
    // I don't believe the following inputs are used anymore
    // but I'm including them just in case.
    continuous: {
      type: "integer",
    },
    Fshd_CollWidth: {
      type: "number",
    },
    can_continuous: {
      type: "integer",
    },
    vert_gap: {
      type: "number",
    },
    hori_gap: {
      type: "number",
    },
    string_counts: {
      type: "array",
      items: { type: "integer" },
    },
  },
};

export { racking_schema };
