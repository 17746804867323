import styled from "styled-components";

const ExportMenuWrap = styled.ul`
  /* height: 30px;
  width: 285px;
  display: flex;
  align-items: center;
  justify-content: center; */

  .disabled-li {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export { ExportMenuWrap };
