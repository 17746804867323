const config_schema = {
  type: "object",
  tab: "Config",
  properties: {
    config_spacing_toggle: {
      type: "string",
      enum: ["gcr", "pitch"],
      validateConfig: true,
    },
    gcr_min: {
      label: "Min GCR",
      _id: "gcr_min",
      type: "number",
    },
    gcr_max: {
      label: "Max GCR",
      _id: "gcr_max",
      type: "number",
    },
    gcr_inc: {
      label: "GCR Step",
      _id: "gcr_inc",
      type: "number",
    },
    pitch_min: {
      label: "Min Pitch",
      _id: "pitch_min",
      type: "number",
    },
    pitch_max: {
      label: "Max Pitch",
      _id: "pitch_max",
      type: "number",
    },
    pitch_inc: {
      label: "Pitch Step",
      _id: "pitch_inc",
      type: "number",
    },
    spi_min: {
      label: "SPI Min",
      _id: "spi_min",
      type: "integer",
    },
    spi_max: {
      label: "SPI Max",
      _id: "spi_max",
      type: "integer",
    },
    spi_inc: {
      label: "String Per Inverter Step",
      _id: "spi_inc",
      type: "integer",
    },
    spi_min_limit: {
      _id: "spi_min_limit",
      type: "integer",
    },
    spi_max_limit: {
      _id: "spi_max_limit",
      type: "integer",
    },
    gcrs: {
      _id: "gcrs",
      type: "array",
      items: {
        type: "number",
      },
    },
    pitches: {
      _id: "pitches",
      type: "array",
      items: {
        type: "number",
      },
    },
    spis: {
      _id: "spis",
      type: "array",
      items: {
        type: "number",
      },
    },
    worker_count: {
      label: "Worker Count",
      _id: "worker_count",
      type: "integer",
    },
    worker_limit: {
      label: "Worker Limit",
      _id: "worker_limit",
      type: "number",
    },
    mod_target: {
      label: "Module Target",
      _id: "mod_target",
      type: "number",
    },
    mwh_target: { type: "number" },
    portfolio_sweep: { type: "number" },
    pitch_min_limit: { type: "number" },
    pitch_max_limit: { type: "number" },
    gcr: {
      _id: "gcr",
      type: "number",
    },
  },
};

export { config_schema };
