const module_schema = {
  type: "object",
  tab: "Module",
  properties: {
    mod_name: {
      label: "Module Name",
      _id: "mod_name",
      type: "string",
    },
    mod_rating: {
      label: "Module Rating",
      _id: "mod_rating",
      type: "number",
      validateModRating: true,
    },
    en_bifacial: {
      label: "Bifacial",
      _id: "en_bifacial",
      type: "integer",
    },
    bi_bifaciality: {
      label: "Bifaciality Factor",
      _id: "bi_bifaciality",
      type: "number",
    },
    mod_width: {
      label: "Dimension X",
      _id: "mod_width",
      type: "number",
    },
    mod_height: {
      label: "Dimension Y",
      _id: "mod_height",
      type: "number",
    },
    mod_area: {
      type: "number",
    },
    technology: {
      label: "technology",
      _id: "technology",
      type: "integer",
    },
    mlm_S_ref: {
      label: "Gref",
      _id: "mlm_S_ref",
      type: "number",
    },
    mlm_T_ref: {
      label: "Tref",
      _id: "mlm_T_ref",
      type: "number",
    },
    mlm_I_sc_ref: {
      label: "Isc",
      _id: "mlm_I_sc_ref",
      type: "number",
    },
    mlm_V_oc_ref: {
      label: "Voc",
      _id: "mlm_V_oc_ref",
      type: "number",
    },
    mlm_I_mp_ref: {
      label: "mlm_I_mp_ref",
      _id: "mlm_I_mp_ref",
      type: "number",
    },
    mlm_V_mp_ref: {
      label: "Vmp",
      _id: "mlm_V_mp_ref",
      type: "number",
    },
    mlm_alpha_isc: {
      label: "Tscoef of Isc",
      _id: "mlm_alpha_isc",
      type: "number",
    },
    mlm_beta_voc_spec: {
      label: "Tcoef of Voc",
      _id: "mlm_beta_voc_spec",
      type: "number",
    },
    mlm_N_series: {
      label: "Cells in Series",
      _id: "mlm_N_series",
      type: "integer",
    },
    mlm_N_parallel: {
      label: "Cells in Parallel",
      _id: "mlm_N_parallel",
      type: "integer",
    },
    mlm_R_shref: {
      label: "Rsh",
      _id: "mlm_R_shref",
      type: "number",
    },
    mlm_R_sh0: {
      label: "Rshunt at G=0",
      _id: "mlm_R_sh0",
      type: "number",
    },
    mlm_R_s: {
      label: "Rs",
      _id: "mlm_R_s",
      type: "number",
    },
    mlm_R_shexp: {
      label: "Shunt Exp",
      _id: "mlm_R_shexp",
      type: "number",
    },
    mlm_T_c_fa_alpha: {
      label: "Absorb Coef",
      _id: "mlm_T_c_fa_alpha",
      type: "number",
    },
    muPmpReq: {
      label: "Tcoef of Pmpp",
      _id: "muPmpReq",
      type: "number",
    },
    mlm_n_0: {
      label: "Gamma",
      _id: "mlm_n_0",
      type: "number",
    },
    mlm_mu_n: {
      label: "Tcoef of Gamma",
      _id: "mlm_mu_n",
      type: "number",
    },
    mlm_D2MuTau: {
      label: "Recombination di^2/MuTau",
      _id: "mlm_D2MuTau",
      type: "number",
    },
    modules: {
      type: "array",
    },
    mod_dims_calc: {
      type: "integer",
    },
    mlm_N_diodes: {
      type: "integer",
    },
    mlm_E_g: {
      type: "number",
    },
    module_iam_ang: {
      type: "array",
      items: { type: "number" },
    },
    module_iam_eff: {
      type: "array",
      items: { type: "number" },
    },
    simple_module: {
      _id: "simple_module",
      type: "number",
    },
    is_simplified: {
      _id: "is_simplified",
      type: "number",
    },
  },
};

export { module_schema };
