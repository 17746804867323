// prettier-ignore
import { accountConstants, siftConstants } from '../_constants';
import * as turf from "@turf/turf";
import { ConfigDefault, ModulesDD, InvertersDD, RackingDD, PerformanceDD, WeatherDD, FinanceDD, LayoutDD, TopographyDD } from "../../InputPanel";

import {
  create_UUID,
  getCenterPoint,
  fixInputs,
  fixRacking,
  fixFinance,
  fixPerformance,
  calculateSPI,
  setInverterCuts,
  calculatePitchOrGcr,
  calculateDCMaxPwr,
  roundOff,
  fixResultFiles,
  getBounds,
  validateInputs,
  calculateTotalArea,
  EPSGData,
  getTilts,
  calculate_racking_dims,
  checkForRackingErrors,
  calculate_finance,
  shape_and_sort_summary_data,
  sort_summary,
  cleanRackingName,
} from "../_helpers";

let finance_inputs = [
  // "toggle_finance_type",
  "module_dc_cost",
  "rack_a_finance",
  "rack_b_finance",
  "rack_c_finance",
  "bos_other",
  "inverter",
  "ac_aux",
  "mv_wire",
  "other_ac",
  "interconnection",
  "permits_fees",
  "engineering",
  "margin",
  "other_fixed",
  "rack_footprint",
  "boundary_area",
  "contingency",
  "spacing_gcr",
  "spacing_per_wp",
];

//import { flatten } from "./sift_inputs";

//import sample_result from "./sample_results";
import _, { filter } from "lodash";

//let runId = JSON.parse(localStorage.getItem("runId"));

const d = new Date();
//const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function copyDefault(selected) {
  selected.id = 0;
  selected.data.default = 0;
  selected.data.id = 0;
  selected.data.name = `${selected.data.name}`;
  return selected;
}

function getArea(coords) {
  let turfPoly = turf.polygon(coords);
  return turf.area(turfPoly) / 10000;
}

const tabCustom = {
  module: JSON.parse(JSON.stringify(ModulesDD["0"])),
  inverter: JSON.parse(JSON.stringify(InvertersDD["0"])),
  racking: JSON.parse(JSON.stringify(RackingDD["0"])),
  weather: JSON.parse(JSON.stringify(WeatherDD["0"])),
  performance: JSON.parse(JSON.stringify(PerformanceDD["0"])),
  finance: JSON.parse(JSON.stringify(FinanceDD["0"])),
  config: JSON.parse(JSON.stringify(ConfigDefault)),
  layout: JSON.parse(JSON.stringify(LayoutDD["0"])),
};

const tabDefaults = {
  module: copyDefault(JSON.parse(JSON.stringify(ModulesDD["364aa9e4cee04535aed7806302f3f652"]))),
  inverter: copyDefault(JSON.parse(JSON.stringify(InvertersDD["0f14d6b175444d6699dfe4d69f32c243"]))),
  racking: copyDefault(JSON.parse(JSON.stringify(RackingDD["49aa35992cf4480e9a2f1152c43edcda"]))),
  weather: JSON.parse(JSON.stringify(WeatherDD["0"])),
  performance: JSON.parse(JSON.stringify(PerformanceDD["0"])),
  finance: JSON.parse(JSON.stringify(FinanceDD["0"])),
  config: JSON.parse(JSON.stringify(ConfigDefault)),
  layout: JSON.parse(JSON.stringify(LayoutDD["0"])),
  topography: JSON.parse(JSON.stringify(TopographyDD["0"])),
};

const initialState = {
  uiState: {
    loading: false,
  },

  projectManager: {
    loading: false,
    loaded: false,
    new_project_loading: false,
    deleting: false,
    modal_visible: false,

    projects: undefined,
    localProjects: {},
    selectedProjectId: undefined,
    currentProjectId: undefined,

    collab_loading: false,
    collab_request: undefined,
    collab_projectId: undefined,
  },

  ioManager: {
    doFinance: false,
    uiState: {
      tab: "",
      loading_layout: false,
      loading_files: false,
      import_visible: false,
      import_loading: false,
      input_loading: false,
      weather_loading: false,
      topo_loading: false,
      account_visible: false,
      docs_visible: false,
      output_detail_visible: false,
      show_live_report: false,
      show_layout_editor: false,

      bug_visible: false,
      patch_visible: false,
      news_visible: false,
      helper_visible: false,
      helper_deep_visible: false,
      helper_drawer: undefined,

      tutorial_visible: false,
      hide_tutorial: localStorage.getItem("hideTutorial") || "false",

      export_kml: false,
      export_dxf: false,
      export_pvsyst: false,
      currentPlot: undefined,

      error_visible: false,
      error_messages: [],
      screenWidth: undefined,
      toggleMobileInputs: false,
      racking_error_present: false,
      racking_errors: undefined,

      layer_toggle: {
        show_layer_toggle_menu: false,
        show_boundaries: true,
        show_exclusions: true,
        show_inactive: true,
        show_racks: true,
        show_roads: true,
        show_inverters: true,
        show_combiners: false,
        show_wires: false,
        show_strings: false,
      },
    },
    dropdowns: {
      module: ModulesDD,
      inverter: InvertersDD,
      racking: RackingDD,
      weather: WeatherDD,
      performance: PerformanceDD,
      finance: FinanceDD,
      layout: LayoutDD,
    },
    inputs: {
      map: {
        features: {},

        // map state
        isDrawing: false,
        isEditing: false,
        selectedFeatureId: undefined,

        map_center: [34.6964398, -118.2827397],
        map_reset: false,
        loading_map: false,
        loading_msg: undefined,
        show_site_marker: false,
        pull_location: false,
        boundary_bbox: undefined,
        coord_system_bbox: undefined,
      },
      module: tabDefaults.module,
      inverter: tabDefaults.inverter,
      racking: tabDefaults.racking,
      weather: tabDefaults.weather,
      performance: tabDefaults.performance,
      layout: tabDefaults.layout,
      finance: tabDefaults.finance,
      config: tabDefaults.config,
      topo: tabDefaults.topography,
      // topo: {
      //   id: 'topo',
      //   topo_source: 'USGS',
      //   topo_live: false,
      //   topo_url: '',
      //   topo_scale_url: '',
      //   topo_id: undefined,
      //   topo_action: 'nothing',
      //   topo_error: undefined,
      //   topo_mode: '',
      //   ns_grade_limit: 8,
      //   ew_grade_limit: 20,
      //   u_grade_limit: 15,
      // },
    },
    outputs: {
      job_id: undefined,
      runState: {
        runId: undefined,
        running: false,
        can_cancel: false,
        generated: false,
        canceling: false,
        currentStep: "validating",
        errors: [],
        steps: {
          // process - wait - finish - error
          validating: { index: 0, percent: 0, status: "process", title: "Validating Inputs" },
          deploying: { index: 1, percent: 0, status: "wait", title: "Scaling Cloud" },
          layouts: { index: 2, percent: 0, status: "wait", title: "Creating Layouts" },
          modeling: { index: 3, percent: 0, status: "wait", title: "Modeling" },
          complete: { index: 4, percent: 0, status: "wait", title: "Complete" },
        },
      },
      meta: {},
      code: 150,
      errors: [],
      results: {},
      csvData: [],
      tsvData: [],
      fileName: "file-name",
      selectedResult: undefined,
      currentLayoutId: undefined,
      layout: {
        racks: undefined,
        roads: undefined,
        inverters: undefined,
        inverter_groups: undefined,
      },
    },

    report: {
      reportData: {},
      preparingReport: false,
      reportComplete: false,
    },
  },

  prefs: {},

  run_id: undefined,
  data: [],
  generating: false,
  code: 0,
  count: 0,
  local_run_count: 0,
  weather_id: undefined,
  summary: [],
  canceling: false,
  uploading: false,
  upload_error: undefined,
  valid_reason: undefined,
  autolayout: undefined,

  results: [],
  layout: {},

  // topo
  topo_live: false,
  topo_url: "",
  topo_scale_url: "",
  topo_id: undefined,

  // map?
  features: {},
  SiteLatLng: [34.6964398, -118.2827397],
  loading_map: false,
  reset_map: false,
  show_site_marker: false,
  pull_location: false,
  deleted_features: {},

  system_alerts: {
    poll_count: 0,
    last_polled: undefined,
    poll_message: [],
    isAuthed: false,
  },
};

export function sift(state = JSON.parse(JSON.stringify(initialState)), action) {
  //
  let ioManager = { ...state.ioManager };
  let uiState = { ...state.uiState };
  let inputs = { ...state.ioManager.inputs };
  let projectManager = { ...state.projectManager };

  //

  switch (action.type) {
    // ui state
    // case siftConstants.UPDATE_UISTATE:
    //   ioManager.uiState[action.toolbar] = action.bool
    //   return {  ...state, ioManager: {...ioManager}}}

    // PROJECT MANAGER
    case siftConstants.TOGGLE_PROJECTMANAGER_MODAL:
      projectManager.modal_visible = action.bool;
      return { ...state, projectManager };

    case siftConstants.TOGGLE_TOOLBAR_VISIBILITY:
      ioManager.uiState[action.toolbar] = action.bool;
      ioManager.uiState.helper_drawer = action.drawer;

      // if (action.toolbar = 'output_detail_visible') {
      //   ioManager.uiState.tab = action.bool ? 'results' : undefined;
      // }

      return { ...state, ioManager: ioManager };

    case siftConstants.NEW_PROJECT_REQUEST:
      uiState.loading = true;
      projectManager.loading = true;
      ioManager.uiState.input_loading = true;
      ioManager.uiState.currentPlot = undefined;
      ioManager.uiState.output_detail_visible = false;
      projectManager.selectedProjectId = undefined;
      projectManager.currentProjectId = undefined;
      projectManager.new_project_loading = true;

      // clear current inputs
      ioManager.inputs = { ...JSON.parse(JSON.stringify(initialState.ioManager.inputs)) };
      // ioManager.inputs.weather = JSON.parse(JSON.stringify(ioManager.dropdowns.weather["0"]));

      // clear results / layout
      ioManager.outputs = JSON.parse(JSON.stringify(initialState.ioManager.outputs));

      // move back to config tab
      ioManager.uiState.tab = "config";

      return { ...state, uiState, projectManager, ioManager: ioManager, deleted_features: {} };

    case siftConstants.NEW_PROJECT_COMPLETE:
      uiState.loading = false;
      projectManager.loading = false;
      ioManager.uiState.input_loading = false;
      projectManager.new_project_loading = false;

      // cleare report
      ioManager.uiState.show_live_report = false;
      ioManager.report = {
        reportData: {},
        preparingReport: false,
        reportComplete: false,
      };

      return { ...state, uiState, projectManager, ioManager };

    case siftConstants.SELECT_PROJECT:
      projectManager.selectedProjectId = action.id;
      return { ...state, projectManager };

    case siftConstants.CLEAR_PROJECT:
      projectManager.selectedProjectId = undefined;
      projectManager.currentProjectId = undefined;
      ioManager.uiState.currentPlot = undefined;

      // clear current inputs
      ioManager.inputs = { ...JSON.parse(JSON.stringify(initialState.ioManager.inputs)) };
      ioManager.inputs.weather = JSON.parse(JSON.stringify(ioManager.dropdowns.weather["0"]));

      // clear results / layout
      ioManager.outputs = JSON.parse(JSON.stringify(initialState.ioManager.outputs));
      return { ...state, projectManager, ioManager: ioManager };

    case siftConstants.LOAD_PROJECT_REQUEST:
      ioManager.report.reportData.file_name = undefined;
      return { ...state, ioManager };
      projectManager.loading = true;
      projectManager.currentProjectId = action.id;
      // clear results / layout
      ioManager.outputs = JSON.parse(JSON.stringify(initialState.ioManager.outputs));
      ioManager.uiState.currentPlot = undefined;
      ioManager.uiState.output_detail_visible = false;

      return { ...state, projectManager, ioManager: ioManager };

    case siftConstants.LOAD_PROJECT_DOWNLOADED:
      return state;
      // COMING FROM BACKEND NOW
      let proj_inputs = fixInputs(action.response.data);
      // load inputs from project into ioManager
      // let proj_inputs = { ...JSON.parse(JSON.stringify(projectManager.localProjects[action.id].inputs)) };

      // console.log("Load Project Downloaded - proj_inputs", proj_inputs);
      ioManager.inputs.map = proj_inputs.map;

      // ensures that when loading a saved copy of a project that the polygons are redrawn in the map
      if (Object.values(ioManager.inputs.map.features).length > 0) {
        let tempFeatures = {};
        Object.values(ioManager.inputs.map.features).map((feature) => {
          let id = create_UUID();
          feature.properties.index = id;
          tempFeatures[id] = feature;
        });
        ioManager.inputs.map.features = tempFeatures;
      }

      // check to see if file that is loaded contains features that are in an array format and then act accordingly
      // if (Array.isArray(ioManager.inputs.map.features)) {
      //   let tempFeatures = {};
      //   ioManager.inputs.map.features.map((feature) => {
      //     let id = create_UUID();
      //     feature.properties.index = id;
      //     tempFeatures = { ...tempFeatures, [id]: feature };
      //   });

      //   ioManager.inputs.map.features = tempFeatures;
      // }
      // console.log(proj_inputs.topo)

      ioManager.inputs.module = proj_inputs.module;
      ioManager.inputs.inverter = proj_inputs.inverter;
      ioManager.inputs.racking = proj_inputs.racking;
      ioManager.inputs.weather = proj_inputs.weather;
      ioManager.inputs.performance = proj_inputs.performance;

      if (!ioManager.inputs.racking.data.cell_trav || ioManager.inputs.racking.data.cell_trav == 0 || ioManager.inputs.racking.data.cell_trav == undefined) {
        ioManager.inputs.racking.data.cell_trav = 0.156;
      }
      if (!ioManager.inputs.racking.data.string_steps || ioManager.inputs.racking.data.string_steps == 0 || ioManager.inputs.racking.data.string_steps == undefined) {
        ioManager.inputs.racking.data.string_steps = 2;
      }

      // accomidate older projects that don't have the auto racking defaults
      // don't think this is needed anymore.. Everything should eventually be offloaded to the above fixInputs call...
      let load_racking = fixRacking(ioManager.inputs.racking, ioManager.inputs.performance.data.modules_per_string);
      // if (ioManager.inputs.racking.data.type == 0 || ioManager.inputs.racking.data.type == 1) {
      //   ioManager.inputs.racking.data.racks[0].string_count = proj_inputs.racking.data.racks[0].string_count ? proj_inputs.racking.data.racks[0].string_count : 3;
      //   ioManager.inputs.racking.data.racks[1].string_count = proj_inputs.racking.data.racks[1].string_count ? proj_inputs.racking.data.racks[1].string_count : 2;
      //   ioManager.inputs.racking.data.racks[2].string_count = proj_inputs.racking.data.racks[2].string_count ? proj_inputs.racking.data.racks[2].string_count : 1;
      //   ioManager.inputs.racking.data.rack_dims = proj_inputs.racking.data.rack_dims == 0 ? proj_inputs.racking.data.rack_dims : 1;
      //   ioManager.inputs.racking.data.modules_high = proj_inputs.racking.data.modules_high ? proj_inputs.racking.data.modules_high : 1;
      //   ioManager.inputs.racking.data.orientation = proj_inputs.racking.data.orientation ? proj_inputs.racking.data.orientation : 0;
      //   ioManager.inputs.racking.data.module_gap = proj_inputs.racking.data.module_gap ? proj_inputs.racking.data.module_gap : 0.02;
      //   ioManager.inputs.racking.data.drive_gap = proj_inputs.racking.data.drive_gap ? proj_inputs.racking.data.drive_gap : 2.0;
      // } else if (ioManager.inputs.racking.data.type == 2) {
      //   ioManager.inputs.racking.data.racks[0].string_count = proj_inputs.racking.data.racks[0].string_count ? proj_inputs.racking.data.racks[0].string_count : 2;
      //   ioManager.inputs.racking.data.racks[1].string_count = proj_inputs.racking.data.racks[1].string_count ? proj_inputs.racking.data.racks[1].string_count : 1;
      //   ioManager.inputs.racking.data.racks[2].string_count = proj_inputs.racking.data.racks[2].string_count ? proj_inputs.racking.data.racks[2].string_count : 0.5;
      //   ioManager.inputs.racking.data.rack_dims = proj_inputs.racking.data.rack_dims ? proj_inputs.racking.data.rack_dims : 1;
      //   ioManager.inputs.racking.data.modules_high = proj_inputs.racking.data.modules_high ? proj_inputs.racking.data.modules_high : 2;
      //   ioManager.inputs.racking.data.orientation = proj_inputs.racking.data.orientation ? proj_inputs.racking.data.orientation : 0;
      //   ioManager.inputs.racking.data.module_gap = proj_inputs.racking.data.module_gap ? proj_inputs.racking.data.module_gap : 0.02;
      // }
      ioManager.inputs.racking = load_racking;

      if (proj_inputs.topo.data === undefined) {
        // old project format
        ioManager.inputs.topo.data = { ...JSON.parse(JSON.stringify(tabDefaults.topography)).data, ...proj_inputs.topo };

        if (ioManager.inputs.topo.data.topo_live) {
          ioManager.inputs.topo.data.layers_generated = {
            ele: { avail: true, gavail: false },
            NS: { avail: true, gavail: false, limit: proj_inputs.topo.ns_grade_limit },
            EW: { avail: true, gavail: false, limit: proj_inputs.topo.ew_grade_limit },
            U: { avail: true, gavail: false, limit: proj_inputs.topo.u_grade_limit },
            CF: { avail: false, limit: 10 },
          };
        }
      } else {
        // new format
        ioManager.inputs.topo = proj_inputs.topo;
      }

      ioManager.inputs.config = proj_inputs.config;
      ioManager.inputs.finance = proj_inputs.finance;
      ioManager.inputs.layout = proj_inputs.layout;
      ioManager.inputs.config.project_name = projectManager.localProjects[action.id].name;

      // this turns off the doCustomFinance toggle on the front-end when a project is loaded that at the time of saving had the popout table turned on. All project finanical inputs are still present, but the popout table is turned off.
      ioManager.inputs.finance.data.doCustomSchedule = 0;

      // console.log(proj_inputs)
      // NS Fix for unset topo_action in layout tab on project load
      if (ioManager.inputs.layout.data.topo_action === undefined) {
        if (ioManager.inputs.topo.data.topo_action === undefined) {
          ioManager.inputs.layout.data.topo_action = "nothing";
          ioManager.inputs.topo.data.topo_action = "nothing";
        } else {
          ioManager.inputs.layout.data.topo_action = ioManager.inputs.topo.data.topo_action;
        }
      }
      // validate the worker_counts match the values in this input set

      let looper = true;
      let inc = parseFloat(ioManager.inputs.config.data.spi_step);
      let start = parseFloat(ioManager.inputs.config.data.spi_range[0]);
      let end = parseFloat(ioManager.inputs.config.data.spi_range[1]);
      let count = Math.ceil((end + inc - start) / inc);
      ioManager.inputs.config.data.spi_count = count;

      if (ioManager.inputs.finance.data.customSchedule.length > 0 && ioManager.inputs.finance.data.customSchedule[0].length !== 5) {
        for (var i = 0; i < ioManager.inputs.finance.data.analysis_period; i++) {
          if (ioManager.inputs.finance.data.customSchedule[i].length === 4) {
            // insert item for new $/ha field
            ioManager.inputs.finance.data.customSchedule[i].splice(3, 0, 0.0);
          }
        }
      }

      if (ioManager.inputs.config.data.selected_gcr_pitch == 0) {
        count = 0;
        looper = true;
        start = parseFloat(ioManager.inputs.config.data.gcr_range[0]);
        end = parseFloat(ioManager.inputs.config.data.gcr_range[1]);
        inc = parseFloat(ioManager.inputs.config.data.gcr_step);
        while (looper) {
          count += 1;
          if (start == end) {
            looper = false;
            break;
          } else {
            start += inc;
            if (start > end) {
              count += 1;
              looper = false;
              break;
            }
          }
        }
        ioManager.inputs.config.data.gcr_count = count;
      } else {
        count = 0;
        looper = true;
        start = parseFloat(ioManager.inputs.config.data.pitch_range[0]);
        end = parseFloat(ioManager.inputs.config.data.pitch_range[1]);
        inc = parseFloat(ioManager.inputs.config.data.pitch_step);
        while (looper) {
          count += 1;
          if (start == end) {
            looper = false;
            break;
          } else {
            start += inc;
            if (start > end) {
              count += 1;
              looper = false;
              break;
            }
          }
        }
        ioManager.inputs.config.data.pitch_count = count;
      }
      ioManager.inputs.config.data.worker_count = ioManager.inputs.config.data.spi_count * count;

      let dcac_min =
        (ioManager.inputs.config.data.spi_range[0] * ioManager.inputs.performance.data.modules_per_string * ioManager.inputs.module.data.rating) / 1000 / ioManager.inputs.inverter.data.inverterRating;
      let dcac_max =
        (ioManager.inputs.config.data.spi_range[1] * ioManager.inputs.performance.data.modules_per_string * ioManager.inputs.module.data.rating) / 1000 / ioManager.inputs.inverter.data.inverterRating;
      ioManager.inputs.config.data.dcac_range = [dcac_min.toFixed(4), dcac_max.toFixed(4)];

      ioManager.uiState.input_loading = true;
      ioManager.uiState.tab = "config";

      if (Object.keys(ioManager.inputs.map.features).length > 0) {
        ioManager.inputs.map.boundary_bbox = getBounds(Object.values(ioManager.inputs.map.features));
        ioManager.inputs.map.totalArea = calculateTotalArea(ioManager.inputs.map.features);
        ioManager.inputs.map.map_center = getCenterPoint(ioManager.inputs.map.features);
      } else {
        ioManager.inputs.map.boundary_bbox = undefined;
      }

      // NS: Checking is EPSG is present
      if (ioManager.inputs.layout.data.epsg == 0) {
        ioManager.inputs.map.coord_system_bbox = undefined;
      } else {
        let c = EPSGData[ioManager.inputs.layout.data.epsg]["bbox"];

        ioManager.inputs.map.coord_system_bbox = turf.flip(turf.bboxPolygon([c["south_latitude"], c["west_longitude"], c["north_latitude"], c["east_longitude"]]));
        ioManager.inputs.map.coord_system_bbox.properties.index = create_UUID();
        ioManager.inputs.map.coord_system_bbox.properties.skip_zoom_extent = true;
      }

      ioManager.inputs.map.selectedFeatureId = undefined;
      return { ...state, projectManager, ioManager: { ...ioManager } };

    case siftConstants.LOAD_PROJECT_COMPLETE:
    case siftConstants.LOAD_PROJECT_FAILED:
      return state;
      if (action.error) {
        console.log(action.error);
      }

      projectManager.loading = false;
      projectManager.modal_visible = false;
      ioManager.uiState.input_loading = false;

      return { ...state, projectManager };

    case siftConstants.DELETE_PROJECT_REQUEST:
      projectManager.localProjects = projectManager.localProjects.filter((p) => p.project != action.id);
      projectManager.loading = true;
      return { ...state, projectManager };

    case siftConstants.DELETE_PROJECT_SUCCESS:
      return state;

    case siftConstants.DELETE_PROJECT_COMPLETE:
      projectManager.loading = false;
      return { ...state, projectManager };

    case siftConstants.DELETE_PROJECT_FAILURE:
      projectManager.localProjects = JSON.parse(JSON.stringify(projectManager.projects));
      projectManager.loading = false;
      return { ...state, projectManager };

    case siftConstants.SAVE_PROJECT_REQUEST:
      projectManager.loading = true;
      return { ...state, projectManager };

    case siftConstants.SAVE_PROJECT_SUCCESS:
      // add project to  localProjects
      action.project.project = action.id;
      action.project.editDate = action.date;
      // console.log(action.id, action.date, action.project)

      projectManager.remainOpen = action.project.active == 0 || action.project.editing;

      if (action.project.active == 1) {
        // active project, overwrite
        if (action.project.editing) {
          // clear this and don't "load" the project
          action.project.editing = undefined;
          projectManager.remainOpen = true;
        } else {
          projectManager.selectedProjectId = action.id;
          projectManager.currentProjectId = action.id;
        }
        projectManager.localProjects[action.id] = action.project;
        projectManager.collab_projectId = action.id;
      } else {
        // deleted project, remove
        delete projectManager.localProjects[action.id];
        projectManager.selectedProjectId = undefined;
        projectManager.currentProjectId = action.id == projectManager.currentProjectId ? undefined : projectManager.currentProjectId;
        projectManager.remainOpen = true;
        projectManager.collab_projectId = undefined;
      }

      return { ...state, projectManager: { ...projectManager } };

    case siftConstants.SAVE_PROJECT_COMPLETE:
      // console.log(action)
      // action.response.url has a presigned URL of where projects are stored in S3 we can use to pull down
      // update localProjects if its not == to actions.projects
      projectManager.loading = false;
      if (projectManager.remainOpen) {
        projectManager.remainOpen = false;
      } else {
        projectManager.modal_visible = false;
      }

      return { ...state, projectManager };

    case siftConstants.SAVE_PROJECT_FAILURE:
      projectManager.localProjects = JSON.parse(JSON.stringify(projectManager.projects));
      projectManager.loading = false;
      if (projectManager.remainOpen) {
        projectManager.remainOpen = false;
      } else {
        projectManager.modal_visible = false;
      }
      return { ...state, projectManager };

    case accountConstants.GETALL_REQUEST:
      projectManager.loading = true;
      projectManager.loaded = false;
      return { ...state, projectManager };

    case accountConstants.GETALL_SUCCESS:
      // finished getting all, check if localProjects should be updated
      // let projects = {};
      // Object.values(action.details.projects).map((project) => {
      //   projects[project.project] = {
      //     ...project,
      //     // inputs: fixInputs(project.inputs),
      //   };
      // });

      // projectManager.projects = projects;
      // if (!projectManager.localProjects || projectManager.localProjects !== projects) {
      //   projectManager.localProjects = projects;
      // }

      // projectManager.loading = false;
      // projectManager.loaded = Object.keys(projectManager.localProjects).length > 0;

      let dropDowns = {};
      Object.keys(action.details?.dash).map((key) => {
        dropDowns[key] = {};
        Object.values(action.details.dash[key]).map((values, index) => {
          console.log(values);
          if (key === "racking") {
            // TODO: This should be refactored to call fixInput(key, values)
            // this way is can be called for every key and we can handle backwards comp.

            values = fixRacking(values);
            //removing the 26mod/str in the generic racking name that comes from the s3 bucket
            values.data["name"] = values.data["name"].replace("28mod/str", "26mod/str");
          }
          if (key === "performance") {
            values = fixPerformance(values);
          }

          if (key === "finance") {
            values = fixFinance(values);
          }

          let id = values.id || create_UUID();
          dropDowns[key][id] = {
            ...values,
          };
        });

        // append the custom selector
        if (key === "racking") {
          dropDowns[key] = {
            ...dropDowns[key],
          };
        } else {
          dropDowns[key] = {
            0: tabCustom[key],
            ...dropDowns[key],
          };
        }
      });
      ioManager.dropdowns = dropDowns;
      ioManager.inputs.module = JSON.parse(JSON.stringify(ioManager.dropdowns.module["93ed34aac7b249d98b4584cbd1b930bb"]));
      // put weather on sample data
      // DEPRECATED FOR AUTOMATIC WEATHER PULL
      // ioManager.inputs.weather = JSON.parse(JSON.stringify(ioManager.dropdowns.weather["70d1a88679dc424d8769d530f7c815c3"]))
      if (action.details.system_alerts) {
        state.system_alerts.poll_message = action.details.system_alerts;
      }

      return { ...state, ioManager, system_alerts: { ...state.system_alerts, isAuthed: true } };

    case accountConstants.GETALL_FAILURE:
      projectManager.loading = false;
      // projectManager.loaded = projectManager.localProjects && Object.keys(projectManager.localProjects).length > 0;
      projectManager.loaded = false;

      return { ...state, projectManager, system_alerts: { ...state.system_alerts, isAuthed: false } };

    case siftConstants.BUG_REPORT_REQUEST:
      // bug_sending = true
      return state;
    case siftConstants.BUG_REPORT_SUCCESS:
      // bug_sent = true
      return state;
    case siftConstants.BUG_REPORT_COMPLETE:
      // bug_visible = false
      // bug_sent = false
      // bug_sending = false
      return state;
    case siftConstants.BUG_REPORT_FAILURE:
      // bug_visible = false
      // bug_sent = false
      // bug_sending = false
      return state;

    case siftConstants.IMPORT_KMZ_REQUEST:
      inputs.map.import_name = action.name;
      inputs.map.loading_map = true;
      return { ...state, ioManager: ioManager };
    case siftConstants.IMPORT_KMZ_PROCESS:
      // console.log(action);
      //
      let output = action.output;

      // error
      if (output.error || action.output.features.length == 0) {
        ioManager.uiState.error_visible = true;
        ioManager.uiState.error_messages = ["There seems to have been an error uploading your file. Please file a bug report or contact a member of our SIFT Team.", output.error];

        // ioManager.uiState.error_messages = ["The KMZ or KML file failed to load. You may want to check the layers in Google Earth. For support, please send the KMZ to the SIFT team.", output.error];
      } else {
        // map features
        output.features.map((feature) => {
          let id = create_UUID();
          feature.properties["index"] = id;

          ioManager.inputs.map.features[id] = feature;
        });
      }
      // stop loading map
      ioManager.inputs.map.loading_map = false;
      // recenter map
      if (Object.keys(ioManager.inputs.map.features).length > 0) {
        ioManager.inputs.map.map_center = getCenterPoint(ioManager.inputs.map.features);
        ioManager.inputs.map.totalArea = calculateTotalArea(ioManager.inputs.map.features);
        ioManager.inputs.map.boundary_bbox = getBounds(Object.values(ioManager.inputs.map.features));
      } else {
        ioManager.inputs.map.totalArea = 0;
        ioManager.inputs.map.boundary_bbox = undefined;
      }

      return { ...state, ioManager: ioManager };

    case siftConstants.TOGGLE_ISDRAWING:
      inputs.map.isDrawing = action.bool;
      return { ...state, ioManager: ioManager };
    case siftConstants.SELECT_FEATURE_ID:
      inputs.map.selectedFeatureId = false;
      inputs.map.isEditing = false;
      if (action.id) inputs.map.isEditing = true;
      inputs.map.selectedFeatureId = action.id;
      return { ...state, ioManager: ioManager };

    case siftConstants.CREATE_FEATURE:
      // console.log(action.geoJson, action.id)
      inputs.map.selectedFeatureId = action.id;
      inputs.map.isEditing = true;
      inputs.map.isDrawing = false;
      // inputs.map.features[action.id] = action.geoJson;

      // if (Object.keys(inputs.map.features).length > 0) {
      //   inputs.map.boundary_bbox = getBounds(Object.values(inputs.map.features));
      //   // inputs.map.center_point = getCenterPoint(Object.values(inputs.map.features))
      //   inputs.map.center_point = getNewCenterPoint(Object.values(inputs.map.features));
      // } else {
      //   inputs.map.boundary_bbox = undefined;
      // }

      // rectangles: { ...rectangles, [action.id]: rect_object }
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.UPDATE_FEATURE:
      // DO NOTHING
      return {
        ...state,
      };

    // case siftConstants.UPDATE_FEATURE:
    //   // this case is assuming that the feature being updated is the selected..
    //   // which is wrong as it could be from the right click menu
    //   if (action.geoJson) {
    //     inputs.map.features[action.geoJson.properties.index] = action.geoJson;
    //   } else {
    //     // deletes selected
    //     // console.log('oops, update call to deleteFeature()')
    //     // delete inputs.map.features[inputs.map.selectedFeatureId]
    //     // inputs.map.isEditing = false
    //     // inputs.map.selectedFeatureId = undefined
    //   }
    //   if (Object.keys(inputs.map.features).length > 0) {
    //     inputs.map.boundary_bbox = getBounds(Object.values(inputs.map.features));
    //   } else {
    //     inputs.map.boundary_bbox = undefined;
    //   }

    //   return {
    //     ...state,
    //     ioManager: {
    //       ...ioManager,
    //       inputs: {
    //         map: ioManager.inputs.map,
    //         module: ioManager.inputs.module,
    //         inverter: ioManager.inputs.inverter,
    //         racking: ioManager.inputs.racking,
    //         weather: ioManager.inputs.weather,
    //         performance: ioManager.inputs.performance,
    //         layout: ioManager.inputs.layout,
    //         finance: ioManager.inputs.finance,
    //         config: ioManager.inputs.config,
    //         topo: ioManager.inputs.topo,
    //       },
    //     },
    //   };

    case siftConstants.DELETE_FEATURE:
      state.deleted_features = JSON.parse(JSON.stringify(ioManager.inputs.map.features));

      if (action.id) {
        // delete specific
        delete ioManager.inputs.map.features[action.id];
      } else {
        // delete all
        ioManager.inputs.map.features = {};
        ioManager.outputs.layout = {
          racks: undefined,
          roads: undefined,
          inverters: undefined,
        };
      }
      ioManager.inputs.map.isEditing = false;
      ioManager.inputs.map.isDrawing = false;
      ioManager.inputs.map.selectedFeatureId = undefined;
      ioManager.inputs.map.totalArea = calculateTotalArea(ioManager.inputs.map.features);

      return {
        ...state,
        ioManager: ioManager,
        deleted_features: state.deleted_features,
      };
    case siftConstants.UPDATE_TOTAL_AREA:
      inputs.map.totalArea = action.area;
      inputs.map.total_acreage = action.total_acreage;
      return { ...state, ioManager: ioManager };

    case siftConstants.UPDATE_MAP_CENTER:
      ioManager.inputs.map.map_center = action.point;
      // this will force the map to not zoom extents
      ioManager.inputs.map.map_reset = action.reset_map;
      return { ...state, ioManager: ioManager };
    // GET DXF
    case siftConstants.CREATEDXF_REQUEST:
      ioManager.inputs.map.loading_map = true;
      ioManager.inputs.map.loading_msg = action.msg;
      return { ...state, ioManager: { ...ioManager } };
    case siftConstants.CREATEDXF_SUCCESS:
      ioManager.inputs.map.loading_map = false;
      ioManager.inputs.map.loading_msg = undefined;
      return { ...state, ioManager: { ...ioManager } };
    case siftConstants.CREATEDXF_FAILURE:
      ioManager.inputs.map.loading_map = false;
      ioManager.inputs.map.loading_msg = undefined;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.OFFSETPOLYGON_REQUEST:
      ioManager.inputs.map.loading_map = true;
      ioManager.inputs.map.loading_msg = "Creating Offset Polygon...";
      return { ...state };
    case siftConstants.OFFSETPOLYGON_SUCCESS:
      // console.log(action)

      ioManager.inputs.map.loading_map = false;
      ioManager.inputs.map.loading_msg = undefined;
      ioManager.uiState.show_offset_tool = false;
      return { ...state };
    case siftConstants.OFFSETPOLYGON_FAILURE:
      ioManager.inputs.map.loading_map = false;
      ioManager.inputs.map.loading_msg = undefined;
      ioManager.uiState.show_offset_tool = false;
      return { ...state };

    // TAB CLICKED IN IO MANAGER
    case siftConstants.UPDATE_TAB:
      let prevTab = ioManager.uiState.tab;
      ioManager.uiState.tab = action.tab;

      if ((action.tab === "weather" || action.tab === "shading") && ioManager.inputs.weather.data.search_lat == 0 && ioManager.inputs.weather.data.search_lng == 0) {
        ioManager.inputs.map.pull_location = true;
      }

      if (action.tab !== "weather" && action.tab !== "shading" && ioManager.inputs.map.pull_location) {
        ioManager.inputs.map.pull_location = false;
      }

      if (prevTab === "results" && action.tab === undefined) {
        // turn  off
        ioManager.uiState.output_detail_visible = false;
      }
      if (action.tab === "results") {
        // turn on
        ioManager.uiState.output_detail_visible = true;
      }

      return { ...state, ioManager: ioManager };
    // INPUT UPDATED IN IO MANAGER
    case siftConstants.UPDATE_INPUT:
      let input_tab = action.tab || ioManager.uiState.tab;
      //
      // console.log("update inputs", action, input_tab);

      if (action.key === "layer_visiblity") {
        ioManager.uiState.layer_toggle[action.value.key] = action.value.value;
      } else {
        // force update
        if (action.key === "recalc") {
          // update the config tab
          calculateSPI(ioManager.inputs.config.data, ioManager.inputs.module.data.rating, ioManager.inputs.inverter.data.inverterRating, ioManager.inputs.performance.data.modules_per_string);
          setInverterCuts(ioManager.inputs.layout.data, ioManager.inputs.inverter.data.inverterRating);
          // code that updates pitch_min_max and makes sure pitch_range is within a real range
          let dimension = ioManager.inputs.racking.data.type == 0 ? ioManager.inputs.racking.data.racks[0]["ydim"] : ioManager.inputs.racking.data.racks[0]["xdim"];
          ioManager.inputs.config.data.pitch_min_max[0] = dimension / 1.0;
          ioManager.inputs.config.data.pitch_min_max[1] = dimension / 0.2;
          ioManager.inputs.config.data.pitch_range[0] = Math.max(ioManager.inputs.config.data.pitch_range[0], ioManager.inputs.config.data.pitch_min_max[0]);
          ioManager.inputs.config.data.pitch_range[1] = Math.min(ioManager.inputs.config.data.pitch_range[1], ioManager.inputs.config.data.pitch_min_max[1]);
        } else {
          // if (ioManager.inputs[input_tab].data.default == 1) {
          //   // only allow certain inputs to be updated for the default products
          // } else {
          let prevValue = ioManager.inputs[input_tab].data[action.key];

          //little janky, but this checks to see if an action key contains a period. Meaning you are trying to assign a nested value, and then it acts accordingly.
          if (action.key.includes(".")) {
            let splitString = action.key.split(".");
            ioManager.inputs[input_tab].data[splitString[0]][splitString[1]] = action.value;
          } else {
            ioManager.inputs[input_tab].data[action.key] = action.value;
          }

          if (action.key === "rating" || action.key === "inverterRating" || action.key === "modules_per_string") {
            if (action.key === "modules_per_string") {
              // this addresses the issue that happens when loading custom racking products. when you save your custom racking it saves the modules_per_string to the racking object, and when you select it from the dropdown it loads it into both the performance object and the racking object. This happens because the racking input panel uses the modules_per_string from the performance object. So we have to keep track of both of them.
              ioManager.inputs.racking.data.modules_per_string = action.value;
            }
            /* 
							changing module_rating, inverter_rating or modules_per_string
							will require updating a few inputs in the config tab:
							- dcac, spi_count, worker_count, spi_range, spi_min_max, spi_step          
						*/
            calculateSPI(ioManager.inputs.config.data, ioManager.inputs.module.data.rating, ioManager.inputs.inverter.data.inverterRating, ioManager.inputs.performance.data.modules_per_string);
            if (input_tab === "inverter") setInverterCuts(ioManager.inputs.layout.data, ioManager.inputs.inverter.data.inverterRating);
          }

          /* MODULE TAB */
          if (action.key === "technology") {
            // module tech
            ioManager.inputs.module.data.mlm_E_g = action.value == 2 ? 1.5 : 1.121;
          }
          if (action.key === "module_isbifacial" && ioManager.inputs.module.data.module_bifaciality <= 0) {
            ioManager.inputs.module.data.module_bifaciality = 0.7;
          }

          /* INVERTER TAB */
          if (action.key === "inv_pd_pacokw" || action.key === "maxEff") {
            ioManager.inputs.inverter.data.dc_max_pwr = calculateDCMaxPwr(ioManager.inputs.inverter.data.inv_pd_pacokw, ioManager.inputs.inverter.data.maxEff);
          }

          /* RACKING TAB */

          if (input_tab === "racking" && action.key === "rack_dims") {
            if (action.value == 0) {
              let def_rack_key;
              if (ioManager.inputs.racking.data.type == 0) {
                def_rack_key = "451693916eaa41329809004a12b4f5d8";
              } else if (ioManager.inputs.racking.data.type == 1) {
                def_rack_key = "49aa35992cf4480e9a2f1152c43edcda";
              } else if (ioManager.inputs.racking.data.type == 2) {
                def_rack_key = "07a81036b1c6468189f82b9dbbef81db";
              }

              ioManager.inputs.racking.data = {
                ...JSON.parse(JSON.stringify(RackingDD[def_rack_key])).data,
                name: ioManager.inputs.racking.data.name,
              };
            } else {
              ioManager.inputs.racking.data.rack_dims = action.value;
            }
          }

          if (input_tab === "racking" && action.key === "type") {
            // recalculate the pitch
            // let flipped_racks = [];
            // Object.values(ioManager.inputs.racking.data.racks).map((rack, index) => {
            //   let mod_count = rack.module;
            //   if (ioManager.inputs.racking.id == 0) {
            //     if (index == 0) mod_count = 60;
            //     if (index == 1) mod_count = 30;
            //     if (index == 2) mod_count = 20;

            //     if (action.value == 2) mod_count *= 2;
            //   }
            //   // let dont_flip_dims = (prevValue == 1 && action.value == 2) || (prevValue == 2 && action.value == 1);
            //   flipped_racks.push({
            //     ...rack,
            //     // ydim: dont_flip_dims ? rack.ydim : rack.xdim,
            //     // xdim: dont_flip_dims ? rack.xdim : rack.ydim,
            //     ydim: rack.ydim,
            //     xdim: rack.xdim,
            //     module: mod_count,
            //   });
            // });
            // ioManager.inputs.racking.data.racks = JSON.parse(JSON.stringify(flipped_racks));
            // REPLACE ABOVE CODE WITH A SIMPLE COPY OF DEFAULT VALUES BASED ON TYPE

            let copy_id =
              action.value == 0
                ? "451693916eaa41329809004a12b4f5d8"
                : action.value == 1
                ? "49aa35992cf4480e9a2f1152c43edcda"
                : action.value == 2
                ? "07a81036b1c6468189f82b9dbbef81db"
                : "49aa35992cf4480e9a2f1152c43edcda";
            ioManager.inputs.racking = copyDefault(JSON.parse(JSON.stringify(RackingDD[copy_id])));

            // this needs to happen after we've updated the rack data or we'll use stale data
            let dimension = ioManager.inputs.racking.data.type == 0 ? ioManager.inputs.racking.data.racks[0]["ydim"] : ioManager.inputs.racking.data.racks[0]["xdim"];
            ioManager.inputs.config.data.pitch_range = calculatePitchOrGcr(dimension, ioManager.inputs.config.data.gcr_range);

            // GFT OR EW
            // if (action.value == 0 || action.value == 2) {
            //   // swapped to GFT or E-W, turn off SAT variables
            //   ioManager.inputs.racking.data.gap = 0;
            //   ioManager.inputs.racking.data.tilt_min = 15;
            //   ioManager.inputs.racking.data.tilt_max = 30;
            //   ioManager.inputs.racking.data.backtrack = 0;
            //   ioManager.inputs.racking.data.track_angle = 5;
            //   ioManager.inputs.racking.data.shade_mode = 1;
            //   ioManager.inputs.racking.data.string_steps = 2;
            //   ioManager.inputs.racking.data.cell_trav = 0.156;
            //   ioManager.inputs.racking.data.module_clearance_height = 0.5;
            //   ioManager.inputs.racking.data.module_transmission_perc = 0;
            //   ioManager.inputs.racking.data.module_structure_shade_perc = 0;
            // } else {
            //   // swapped to SAT, turn off GFT variables

            //   ioManager.inputs.racking.data.gap = 2;
            //   ioManager.inputs.racking.data.tilt_min = 0;
            //   ioManager.inputs.racking.data.tilt_max = 0;
            //   ioManager.inputs.racking.data.backtrack = 1;
            //   ioManager.inputs.racking.data.track_angle = 60;
            //   ioManager.inputs.racking.data.shade_mode = 1;
            //   ioManager.inputs.racking.data.string_steps = 1;
            //   ioManager.inputs.racking.data.cell_trav = 0.156;
            //   ioManager.inputs.racking.data.module_clearance_height = 1.5;
            //   ioManager.inputs.racking.data.module_transmission_perc = 0;
            //   ioManager.inputs.racking.data.module_structure_shade_perc = 0;
            // }

            ioManager.inputs.layout.data.do_rack_align = 1;
            // ioManager.inputs.layout.data.do_inv_spacing = 1;
            setInverterCuts(ioManager.inputs.layout.data, ioManager.inputs.inverter.data.inverterRating);
            ioManager.inputs.layout.data.do_roads = 1;
          }

          /* PERFORMANCE TAB */
          if (action.key === "doGridPower" && ioManager.inputs.performance.data.grid_power_limit <= 0) {
            ioManager.inputs.performance.data.grid_power_limit = 10000;
          }

          /* FINANCE TAB */
          if (action.key === "analysis_period" && !isNaN(parseInt(action.value))) {
            ioManager.inputs.finance.data[action.key] = parseInt(action.value);
          }
          if (action.key === "debt_structure") {
            if (action.value == 0) ioManager.inputs.finance.data.debt_percent = 0;
            if (action.value == 1) ioManager.inputs.finance.data.debt_percent = 65;
          }

          let trigger_finance_update = finance_inputs.findIndex((type) => type == action.key);

          // this only runs if one of the new inputs from the finance menu as changed
          if (trigger_finance_update >= 0) {
            let racks = ioManager.inputs.racking.data.racks;
            let module_rating = ioManager.inputs.module.data.rating;
            let inverter_rating = ioManager.inputs.inverter.data.inverterRating;
            let calculated_value = calculate_finance(action, racks, module_rating, inverter_rating);
            ioManager.inputs.finance.data[calculated_value.key] = calculated_value.value;
          }

          /* LAYOUT TAB */
          if (action.key === "designer_margin" && !isNaN(parseFloat(action.value))) {
            ioManager.inputs.layout.data[action.key] = Math.max(Math.min(parseFloat(action.value), 20), 0);
          }
          if (action.key === "topo_action") {
            ioManager.inputs.topo.data.topo_action = action.value;
            ioManager.inputs.layout.data.topo_action = action.value;
          }

          /* CONFIG TAB */
          let dimension = ioManager.inputs.racking.data.type == 0 ? ioManager.inputs.racking.data.racks[0]["ydim"] : ioManager.inputs.racking.data.racks[0]["xdim"];

          if (action.key === "gcr_range" || action.key === "gcr_step") {
            let update_gcr_vars = true;
            if (action.key === "gcr_step" && (isNaN(action.value) || action.value == null || action.value[0] === "")) {
              update_gcr_vars = false;
              // ioManager.inputs.config.data.gcr_step = 0.01;
            }
            if (action.key === "gcr_range") {
              if (isNaN(action.value[0]) || action.value[0] == null || action.value[0] === "") {
                update_gcr_vars = false;
                // ioManager.inputs.config.data.gcr_range[0] = 0.2;
              }
              if (isNaN(action.value[1]) || action.value[1] == null || action.value[1] === "") {
                update_gcr_vars = false;
                // ioManager.inputs.config.data.gcr_range[1] = 0.9;
              }
            }

            if ((update_gcr_vars && action.key === "gcr_range" && action.value[0] > 0 && action.value[0] > 0) || (action.key === "gcr_step" && action.value > 0)) {
              ioManager.inputs.config.data.gcr_range = [Math.max(parseFloat(ioManager.inputs.config.data.gcr_range[0])), Math.min(parseFloat(ioManager.inputs.config.data.gcr_range[1]))];
              // ioManager.inputs.config.data.gcr_range = [Math.max(parseFloat(ioManager.inputs.config.data.gcr_range[0]), 0.2), Math.min(parseFloat(ioManager.inputs.config.data.gcr_range[1]), 1.0)];
              let pitches = [];
              let gcr = ioManager.inputs.config.data.gcr_range[0];
              let pitch = dimension / ioManager.inputs.config.data.gcr_range[0];
              if (ioManager.inputs.config.data.gcr_range[0] == ioManager.inputs.config.data.gcr_range[1]) {
                pitches.push(pitch);
              } else {
                let looper = true;
                while (looper) {
                  pitches.push(pitch);
                  if (gcr == ioManager.inputs.config.data.gcr_range[1]) {
                    looper = false;
                    break;
                  } else {
                    gcr += parseFloat(ioManager.inputs.config.data.gcr_step);

                    pitch = dimension / gcr;
                    if (gcr > ioManager.inputs.config.data.gcr_range[1]) {
                      gcr = ioManager.inputs.config.data.gcr_range[1];
                      pitch = dimension / gcr;
                    }
                  }
                }
              }
              ioManager.inputs.config.data.pitch_range = [pitches[pitches.length - 1], pitches[0]];
              ioManager.inputs.config.data.gcr_count = pitches.length;
              ioManager.inputs.config.data.pitch_count = pitches.length;
              ioManager.inputs.config.data.worker_count = ioManager.inputs.config.data.spi_count * pitches.length;
            } else {
              ioManager.inputs.config.data.gcr_count = 0;
              ioManager.inputs.config.data.pitch_count = 0;
              ioManager.inputs.config.data.worker_count = 0;
            }
          }

          if (action.key === "pitch_range" || action.key === "pitch_step") {
            let update_pitch_vars = true;
            if (action.key === "pitch_step" && (isNaN(action.value) || action.value == null || action.value[0] === "")) {
              update_pitch_vars = false;
              // ioManager.inputs.config.data.pitch_step = 1;
            }
            if (action.key === "pitch_range") {
              if (isNaN(action.value[0]) || action.value[0] == null || action.value[0] === "") {
                // ioManager.inputs.config.data.pitch_range[0] = ioManager.inputs.config.data.pitch_min_max[0];
                update_pitch_vars = false;
              }
              if (isNaN(action.value[1]) || action.value[1] == null || action.value[1] === "") {
                // ioManager.inputs.config.data.pitch_range[1] = ioManager.inputs.config.data.pitch_min_max[1];
                update_pitch_vars = false;
              }
            }

            if ((update_pitch_vars && action.key === "pitch_range" && action.value[0] > 0 && action.value[0] > 0) || (action.key === "pitch_step" && action.value > 0)) {
              // ioManager.inputs.config.data.pitch_range = [
              //   Math.max(parseFloat(ioManager.inputs.config.data.pitch_range[0]), ioManager.inputs.config.data.pitch_min_max[0]),
              //   Math.min(parseFloat(ioManager.inputs.config.data.pitch_range[1]), ioManager.inputs.config.data.pitch_min_max[1]),
              // ];
              ioManager.inputs.config.data.pitch_range = [Math.max(parseFloat(ioManager.inputs.config.data.pitch_range[0])), Math.min(parseFloat(ioManager.inputs.config.data.pitch_range[1]))];
              let gcrs = [];
              let pitch = ioManager.inputs.config.data.pitch_range[1];
              let gcr = dimension / ioManager.inputs.config.data.pitch_range[1];
              if (ioManager.inputs.config.data.pitch_range[0] == ioManager.inputs.config.data.pitch_range[1]) {
                gcrs.push(gcr);
              } else {
                let looper = true;
                while (looper) {
                  gcrs.push(gcr);
                  if (pitch == ioManager.inputs.config.data.pitch_range[0]) {
                    looper = false;
                    break;
                  } else {
                    pitch -= parseFloat(ioManager.inputs.config.data.pitch_step);
                    gcr = dimension / pitch;
                    if (pitch < ioManager.inputs.config.data.pitch_range[0]) {
                      pitch = ioManager.inputs.config.data.pitch_range[0];
                      gcr = dimension / pitch;
                    }
                  }
                }
              }
              ioManager.inputs.config.data.gcr_range = [gcrs[0], gcrs[gcrs.length - 1]];
              ioManager.inputs.config.data.gcr_count = gcrs.length;
              ioManager.inputs.config.data.pitch_count = gcrs.length;
              ioManager.inputs.config.data.worker_count = ioManager.inputs.config.data.spi_count * gcrs.length;
            } else {
              ioManager.inputs.config.data.gcr_count = 0;
              ioManager.inputs.config.data.pitch_count = 0;
              ioManager.inputs.config.data.worker_count = 0;
            }
          }

          if (action.key === "spi_range" || action.key === "spi_step") {
            if (action.key === "spi_step" && (action.value == 0 || isNaN(action.value) || action.value == null)) {
              ioManager.inputs.config.data.spi_step = 1;
            }
            if (action.key === "spi_range") {
              if (isNaN(action.value[0]) || action.value[0] == null || action.value[0] == 0) {
                ioManager.inputs.config.data.spi_range[0] = ioManager.inputs.config.data.spi_min_max[0];
              }
              if (isNaN(action.value[1]) || action.value[1] == null || action.value[1] == 0) {
                ioManager.inputs.config.data.spi_range[1] = ioManager.inputs.config.data.spi_min_max[1];
              }
            }
            let inc = parseInt(ioManager.inputs.config.data.spi_step);
            let start = ioManager.inputs.config.data.spi_range[0];
            let end = ioManager.inputs.config.data.spi_range[1];
            let count = Math.ceil((end + inc - start) / inc);

            ioManager.inputs.config.data.spi_count = count;
            ioManager.inputs.config.data.worker_count = ioManager.inputs.config.data.spi_count * ioManager.inputs.config.data.gcr_count;

            let dcac_min =
              (ioManager.inputs.config.data.spi_range[0] * ioManager.inputs.performance.data.modules_per_string * ioManager.inputs.module.data.rating) /
              1000 /
              ioManager.inputs.inverter.data.inverterRating;
            let dcac_max =
              (ioManager.inputs.config.data.spi_range[1] * ioManager.inputs.performance.data.modules_per_string * ioManager.inputs.module.data.rating) /
              1000 /
              ioManager.inputs.inverter.data.inverterRating;
            ioManager.inputs.config.data.dcac_range = [dcac_min.toFixed(4), dcac_max.toFixed(4)];
          }

          if (action.key === "selected_gcr_pitch") {
            let count = 0;
            let looper = true;
            let inc = 0;
            let start = 0;
            let end = 0;
            if (action.value == 0) {
              start = parseFloat(ioManager.inputs.config.data.gcr_range[0]);
              end = parseFloat(ioManager.inputs.config.data.gcr_range[1]);
              inc = parseFloat(ioManager.inputs.config.data.gcr_step);
              while (looper) {
                count += 1;
                start += inc;
                if (start > end) {
                  count += 1;
                  looper = false;
                  break;
                }
              }
              ioManager.inputs.config.data.gcr_count = count;
            } else {
              start = parseFloat(ioManager.inputs.config.data.pitch_range[0]);
              end = parseFloat(ioManager.inputs.config.data.pitch_range[1]);
              inc = parseFloat(ioManager.inputs.config.data.pitch_step);
              while (looper) {
                count += 1;
                start += inc;
                if (start > end) {
                  count += 1;
                  looper = false;
                  break;
                }
              }

              ioManager.inputs.config.data.pitch_count = count;
            }
            ioManager.inputs.config.data.worker_count = ioManager.inputs.config.data.spi_count * count;
          }

          if (ioManager.inputs.racking.data.type == 0 || ioManager.inputs.racking.data.type == 2) {
            // GFT
            if (action.key === "do_inv_spacing" && action.value == 1) {
              // ^ and inverters being turned ON
              inputs.layout.data.do_roads = 1;
            }
            if (action.key === "do_roads" && inputs.layout.data.do_inv_spacing == 1) {
              inputs.layout.data.do_inv_spacing = 0;
            }
          } else {
            // SAT
            if (action.key === "do_rack_align" && action.value == 0) {
              // ^ rack_align being turned OFF
              inputs.layout.data.do_inv_spacing = 0;
              inputs.layout.data.do_roads = 0;
              inputs.layout.data.road_spacing_option = 0;
            }
            if (action.key === "do_roads") {
              // ^ and road spacing
              if (action.value == 1) {
                // ^ ON
                inputs.layout.data.do_rack_align = 1;
              } else {
                // ^ OFF
                inputs.layout.data.do_inv_spacing = 0;
              }
            }
            if (action.key === "do_inv_spacing" && action.value == 1) {
              // ^ and inverters ON
              inputs.layout.data.do_rack_align = 1;
              inputs.layout.data.do_roads = 1;
            }
          }

          if (action.key === "epsg") {
            if (action.value == 0) {
              ioManager.inputs.map.coord_system_bbox = undefined;
            } else {
              let c = EPSGData[action.value]["bbox"];
              // east_longitude: -147.99
              // north_latitude: 70.63
              // south_latitude: 59.11
              // west_longitude: -152.01

              ioManager.inputs.map.coord_system_bbox = turf.flip(turf.bboxPolygon([c["south_latitude"], c["west_longitude"], c["north_latitude"], c["east_longitude"]]));
              ioManager.inputs.map.coord_system_bbox.properties.index = create_UUID();
              ioManager.inputs.map.coord_system_bbox.properties.skip_zoom_extent = true;
            }
          }

          if (input_tab === "topo" && action.key === "clearTopoData") {
            ioManager.inputs.topo = JSON.parse(JSON.stringify(tabDefaults.topography));
          }
          if (input_tab === "topo" && action.key === "clearLayers") {
            ioManager.inputs.topo.data.topo_url = "";
            ioManager.inputs.topo.data.topo_scale_url = "";

            ioManager.inputs.topo.data.layers_generated.ele.gavail = false;
            ioManager.inputs.topo.data.layers_generated.NS.avail = false;
            ioManager.inputs.topo.data.layers_generated.NS.gavail = false;
            ioManager.inputs.topo.data.layers_generated.NS.limit = 10;
            ioManager.inputs.topo.data.layers_generated.EW.avail = false;
            ioManager.inputs.topo.data.layers_generated.EW.gavail = false;
            ioManager.inputs.topo.data.layers_generated.EW.limit = 20;
            ioManager.inputs.topo.data.layers_generated.U.avail = false;
            ioManager.inputs.topo.data.layers_generated.U.gavail = false;
            ioManager.inputs.topo.data.layers_generated.U.limit = 15;
            ioManager.inputs.topo.data.has_layers = false;
            ioManager.inputs.topo.data.topo_mode = "off";

            ioManager.inputs.topo.data.ele_use_graded_data = false;
            ioManager.inputs.topo.data.u_grade_limit = 15;
            ioManager.inputs.topo.data.u_grade_enabled = false;
            ioManager.inputs.topo.data.u_grade_raw_enabled = false;
            ioManager.inputs.topo.data.ns_grade_limit = 10;
            ioManager.inputs.topo.data.ns_grade_enabled = false;
            ioManager.inputs.topo.data.ns_grade_raw_enabled = false;
            ioManager.inputs.topo.data.ew_grade_limit = 20;
            ioManager.inputs.topo.data.ew_grade_enabled = false;
            ioManager.inputs.topo.data.ew_grade_raw_enabled = false;
            ioManager.inputs.topo.data.topo_action = "nothing";
          }

          if (action.key === "topoData") {
            if (action.value.key === "topo_action") {
              ioManager.inputs.topo.data.topo_action == action.value.payload;
              ioManager.inputs.layout.data.topo_action == action.value.payload;
            } else {
              let topo_cat = action.value.key.split(".")[0];
              let cat_key = action.value.key.split(".")[1];
              ioManager.inputs.topo.data[topo_cat][cat_key] = action.value.payload;
            }
          }

          let run_dim_calc = ["rack_dims", "type", "modules_per_string", "modules_high", "orientation", "racks", "module_gap", "drive_gap", "mlm_Width", "mlm_Length"];
          let trigger_dim_calc = run_dim_calc.findIndex((type) => type == action.key >= 0);

          if (trigger_dim_calc) {
            let dim_calc_inputs = {
              type: ioManager.inputs.racking.data.type,
              modules_per_string: ioManager.inputs.performance.data.modules_per_string,
              modules_high: ioManager.inputs.racking.data.modules_high,
              orientation: ioManager.inputs.racking.data.orientation,
              racks: ioManager.inputs.racking.data.racks,
              module_gap: ioManager.inputs.racking.data.module_gap,
              drive_gap: ioManager.inputs.racking.data.drive_gap,
              mlm_Width: ioManager.inputs.module.data.mlm_Width,
              mlm_Length: ioManager.inputs.module.data.mlm_Length,
            };
            if (ioManager.inputs.racking.data.rack_dims == 0) {
              calculate_racking_dims(dim_calc_inputs);
            }
            // can we add the dim error check here and add it to another object within racking?
            let racking_errors = checkForRackingErrors(dim_calc_inputs);
            ioManager.uiState.racking_error_present = racking_errors.contains_errors;
            ioManager.uiState.racking_errors = racking_errors.errors;
          }
          // }
        }
      }

      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            map: ioManager.inputs.map,
            module: ioManager.inputs.module,
            inverter: ioManager.inputs.inverter,
            racking: ioManager.inputs.racking,
            weather: ioManager.inputs.weather,
            performance: ioManager.inputs.performance,
            layout: ioManager.inputs.layout,
            finance: { ...ioManager.inputs.finance },
            config: ioManager.inputs.config,
            topo: ioManager.inputs.topo,
          },
        },
      };
    // FINANCE TOGGLED IN IO MANAGER
    case siftConstants.UPDATE_FINANCE_TOGGLE:
      ioManager.doFinance = action.bool;
      ioManager.inputs.config.data.do_finance = action.bool ? 1 : 0;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.UPDATE_FINANCE_DEPRECIATION:
      if (action.which == 0) {
        // typical
        ioManager.inputs.finance.data.dep_5yrSL = 0;
        ioManager.inputs.finance.data.dep_15yrSL = 0;
        ioManager.inputs.finance.data.dep_20yrSL = 100;
        ioManager.inputs.finance.data.dep_39yrSL = 0;
        ioManager.inputs.finance.data.dep_5yrMACRS = 0;
        ioManager.inputs.finance.data.dep_15yrMACRS = 0;
        ioManager.inputs.finance.data.state_taxes = 0;
        ioManager.inputs.finance.data.federal_taxes = 20;
      } else {
        // typical US
        ioManager.inputs.finance.data.dep_5yrSL = 0;
        ioManager.inputs.finance.data.dep_15yrSL = 5;
        ioManager.inputs.finance.data.dep_20yrSL = 5;
        ioManager.inputs.finance.data.dep_39yrSL = 0;
        ioManager.inputs.finance.data.dep_5yrMACRS = 85;
        ioManager.inputs.finance.data.dep_15yrMACRS = 5;
        ioManager.inputs.finance.data.state_taxes = 0;
        ioManager.inputs.finance.data.federal_taxes = 21;
      }
      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            finance: ioManager.inputs.finance,
          },
        },
      };

    case siftConstants.UPDATE_UI_STATE:
      return {
        ...state,
        ioManager: {
          ...ioManager,
          uiState: {
            ...ioManager.uiState,
            [action.key]: action.value,
          },
        },
      };

    case siftConstants.DROPDOWN_SELECT:
      // ioManager.inputs[ioManager.uiState.tab] = ioManager.dropdowns[ioManager.uiState.tab].find(tab => tab.id == action.index)
      // console.log(action.index, ioManager.dropdowns[ioManager.uiState.tab])

      let selected = JSON.parse(JSON.stringify(ioManager.dropdowns[ioManager.uiState.tab][action.index]));
      if (selected.data.default == 1) {
        // user selected a default product, set id to 0 and turn default off
        // console.log('default selected')
        selected = copyDefault(selected);
      }

      ioManager.inputs[ioManager.uiState.tab] = {
        id: selected.id,
        data: {
          ...JSON.parse(JSON.stringify(tabDefaults[ioManager.uiState.tab])).data,
          ...selected.data,
        },
      };

      ioManager.inputs.module.data.mlm_D2MuTau = selected.data.mlm_D2MuTau && selected.data.mlm_D2MuTau !== "" ? selected.data.mlm_D2MuTau : 0;

      // the selected.data.toggle_finance_type is used here to determine if finance is in the new structure or the old structure
      // if (ioManager.uiState.tab == "finance" && selected.data.toggle_finance_type == undefined) {
      //   ioManager.inputs.finance = fixFinance(selected);
      // }
      // force update
      if (ioManager.uiState.tab === "module" || ioManager.uiState.tab === "inverter" || ioManager.uiState.tab === "racking" || ioManager.uiState.tab === "performance") {
        // update the config tab
        calculateSPI(ioManager.inputs.config.data, ioManager.inputs.module.data.rating, ioManager.inputs.inverter.data.inverterRating, ioManager.inputs.performance.data.modules_per_string);
        setInverterCuts(ioManager.inputs.layout.data, ioManager.inputs.inverter.data.inverterRating);
      }
      // kind of a janky way to detect if the racking is a saved custom rack or not.
      if (typeof ioManager.inputs.racking.data.id == "string") {
        ioManager.inputs.performance.data.modules_per_string = ioManager.inputs.racking.data.modules_per_string;
      } else {
        ioManager.inputs.racking.data.rack_dims = 0;
      }

      if (ioManager.uiState.tab === "racking" || ioManager.uiState.tab === "module" || ioManager.uiState.tab === "performance") {
        if (ioManager.inputs.racking.data.rack_dims == 0) {
          let dim_calc_inputs = {
            type: ioManager.inputs.racking.data.type,
            modules_per_string: ioManager.inputs.performance.data.modules_per_string,
            modules_high: ioManager.inputs.racking.data.modules_high,
            orientation: ioManager.inputs.racking.data.orientation,
            racks: ioManager.inputs.racking.data.racks,
            module_gap: ioManager.inputs.racking.data.module_gap,
            drive_gap: ioManager.inputs.racking.data.drive_gap,
            mlm_Width: ioManager.inputs.module.data.mlm_Width,
            mlm_Length: ioManager.inputs.module.data.mlm_Length,
          };
          // console.log(dim_calc_inputs)
          calculate_racking_dims(dim_calc_inputs);
          // can we add the dim error check here and add it to another object within racking?
          let racking_errors = checkForRackingErrors(dim_calc_inputs);
          ioManager.uiState.racking_error_present = racking_errors.contains_errors;
          ioManager.uiState.racking_errors = racking_errors.errors;
        }
      }

      return { ...state, ioManager: ioManager };

    case siftConstants.CLOSE_ERROR_SIFT:
      ioManager.uiState.error_visible = false;
      ioManager.uiState.error_messages = [];
      return { ...state, ioManager };

    case siftConstants.ERROR_SIFT:
      ioManager.uiState.error_visible = true;
      ioManager.uiState.error_messages = action.errors;

      return { ...state };

    case siftConstants.CLEAR_ERRORS:
      ioManager.outputs.runState.runId = undefined;
      ioManager.outputs.runState.generated = false;
      ioManager.outputs.runState.running = false;

      ioManager.outputs.results = {};
      ioManager.outputs.meta = {};
      ioManager.outputs.errors = [];
      ioManager.outputs.layout = JSON.parse(JSON.stringify(initialState.ioManager.outputs.layout));

      ioManager.outputs.runState.steps = JSON.parse(JSON.stringify(initialState.ioManager.outputs.runState.steps));
      ioManager.uiState.tab = "config";

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: {
            ...ioManager.outputs,
            runState: ioManager.outputs.runState,
          },
        },
      };

    case siftConstants.GET_RUNID_REQUEST:
      ioManager.uiState.error_messages = [];
      // ioManager.outputs.runState = JSON.parse(JSON.stringify(initialState.ioManager.outputs.runState))
      ioManager.outputs.runState.runId = undefined;
      ioManager.outputs.runState.generated = false;
      ioManager.outputs.runState.running = true;
      ioManager.outputs.runState.can_cancel = false;
      ioManager.outputs.job_id = undefined;
      ioManager.uiState.output_detail_visible = false;

      ioManager.outputs.results = {};
      ioManager.outputs.meta = {};
      ioManager.outputs.errors = [];
      ioManager.outputs.layout = JSON.parse(JSON.stringify(initialState.ioManager.outputs.layout));

      ioManager.outputs.runState.steps = JSON.parse(JSON.stringify(initialState.ioManager.outputs.runState.steps));

      ioManager.uiState.tab = "results";

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };
    case siftConstants.GET_RUNID_SUCCESS:
      ioManager.outputs.runState.runId = action.id;
      ioManager.uiState.tab = "results";
      ioManager.uiState.output_detail_visible = false;
      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };

    case siftConstants.GET_RUNID_FAILURE:
      return { ...state };

    case siftConstants.INIT_WITH_WEATHER_REQ:
      // console.log(action);
      return { ...state };
    case siftConstants.INIT_WITH_WEATHER_SUCCESS:
      // console.log(action);

      // Removing -- we should not override the search_lat/lng with the weather file info
      // ioManager.inputs.weather.data.search_lat = parseFloat(action.data.lat.toFixed(4));
      // ioManager.inputs.weather.data.search_lng = parseFloat(action.data.lng.toFixed(4));
      // ioManager.inputs.weather.data.search_ele = parseFloat(action.data.ele.toFixed(1));
      // ioManager.inputs.weather.data.search_tz = action.data.tz;

      return { ...state, ioManager: { ...ioManager } };
    case siftConstants.INIT_WITH_WEATHER_FAILURE:
      // console.log(action);
      ioManager.outputs.runState.canceling = false;
      ioManager.outputs.runState.running = false;
      ioManager.outputs.runState.generated = false;
      ioManager.outputs.errors = ["Error automatically pulling weather from source."];
      ioManager.outputs.runState.steps.validating.status = "error";
      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };

    case siftConstants.INIT_RESULTS_REQUEST:
      ioManager.outputs.selectedResult = undefined;
      ioManager.uiState.currentPlot = undefined;
      // console.log('init sift pressed and inputs validated locally')
      ioManager.outputs.meta = {
        module_rating: ioManager.inputs.module.data.rating,
        bifacial: ioManager.inputs.module.data.module_isbifacial == 1 ? "Yes" : "No",
        module_area: ioManager.inputs.module.data.module_area,
        inverter_rating: ioManager.inputs.inverter.data.inverterRating,
        racking_name: ioManager.inputs.racking.data.name,
        rack_type: ioManager.inputs.racking.data.type,
        backtrack: ioManager.inputs.racking.data.backtrack == 1 ? "Yes" : "No",
        track_angle: ioManager.inputs.racking.data.track_angle,
        perf_name: ioManager.inputs.performance.data.name,

        do_finance: ioManager.doFinance,
        finance_metric: ioManager.inputs.finance.data.metric,
        finance_name: ioManager.inputs.finance.data.name,
        finance_period: ioManager.inputs.finance.data.analysis_period,
        finance_discount_rate: ioManager.inputs.finance.data.discount_rate,
      };

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
          uiState: ioManager.uiState,
        },
      };
    case siftConstants.INIT_RESULTS_SUCCESS:
      // console.log('INIT_RESULTS_SUCCESS', action)
      return { ...state, ioManager: ioManager };
    case siftConstants.INIT_RESULTS_ERROR:
      // console.log('INIT_RESULTS_ERROR', action)
      ioManager.outputs.runState.canceling = false;
      ioManager.outputs.runState.running = false;
      ioManager.outputs.runState.generated = false;
      ioManager.outputs.errors = ["Error automatically pulling weather from source."];
      ioManager.outputs.runState.steps.validating.status = "error";
      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };

    case siftConstants.RUN_SIFT_REQUEST:
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.RUN_SIFT_SUCCESS:
      ioManager.outputs.job_id = action.job_id;
      ioManager.outputs.runState.can_cancel = true;
      // console.log('finished Scaling Cloud')
      ioManager.outputs.runState.steps.deploying.percent = 100;
      ioManager.outputs.runState.steps.deploying.status = "finish";
      ioManager.outputs.runState.step = "layouts";
      ioManager.outputs.runState.steps.layouts.status = "process";

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };
    case siftConstants.RUN_SIFT_ERROR:
      return { ...state, ioManager: ioManager };

    case siftConstants.GENERATE_RESULTS_REQUEST:
      // console.log('GENERATE_RESULTS_REQUEST', action)
      return { ...state, ioManager: ioManager };

    case siftConstants.RUN_CAN_CANCEL:
      ioManager.outputs.job_id = action.job_id ? action.job_id : state.ioManager.outputs.job_id;
      ioManager.outputs.runState.job_id = action.job_id ? action.job_id : state.ioManager.outputs.runState.job_id;
      ioManager.outputs.runState.can_cancel = action.can_cancel;
      // console.log('GENERATE_RESULTS_REQUEST', action)
      return { ...state, ioManager: ioManager };

    case siftConstants.GENERATE_RESULTS_VALIDATED:
      // console.log('finished validating results')
      ioManager.outputs.job_id = undefined;
      ioManager.outputs.runState.can_cancel = false;
      // validating:   { index: 0, percent: 0, status: "process", title:"Validating Inputs"},
      ioManager.outputs.runState.steps.validating.percent = 100;
      ioManager.outputs.runState.steps.validating.status = "finish";
      ioManager.outputs.runState.step = "deploying";
      ioManager.outputs.runState.steps.deploying.status = "process";

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };

    case siftConstants.GENERATE_RESULTS_UPDATE:
      // console.log('GENERATE_RESULTS_UPDATE', action)
      // overall completeness
      // action.response.complete

      if (ioManager.outputs.runState.steps.deploying.status === "finish") {
        // console.log("looking here now")
        ioManager.outputs.runState.steps.layouts.percent = action.job_poll.percent_layouts;
        ioManager.outputs.runState.steps.layouts.status = action.job_poll.percent_layouts == 100 ? "finish" : "process";
        if (ioManager.outputs.runState.steps.layouts.status === "finish") {
          // console.log('finished running layouts')
          let modeling_percent = action.job_poll.percent_results;
          ioManager.outputs.runState.steps.modeling.percent = modeling_percent;
          ioManager.outputs.runState.steps.modeling.status = modeling_percent == 100 ? "finish" : "process";
          if (ioManager.outputs.runState.steps.modeling.status === "finish") {
            // console.log('finished running modeling')
            ioManager.outputs.runState.steps.complete.status = "process";
          }
        }
      }

      ioManager.outputs.runState.canceling = false;

      // ioManager.outputs.runState.steps.validating.status = "finish"
      // ioManager.outputs.runState.step = "deploying"

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };

    case siftConstants.GENERATE_RESULTS_COMPLETE:
      ioManager.outputs.runState.steps.complete.status = "complete";
      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };

    case siftConstants.CANOPY_RUN_COMPLETED:
      ioManager.outputs.runState.running = false;
      ioManager.outputs.runState.generated = false;
      ioManager.outputs.runState.canceling = false;
      ioManager.outputs.runState.can_cancel = false;

      return {
        ...state,
        ioManager: {
          ...ioManager,
        },
      };
    case siftConstants.GENERATE_RESULTS_SUCCESS:
      // console.log("GENERATE_RESULTS_SUCCESS", action);
      // console.log('finished running SIFT')

      ioManager.outputs.code = 150;

      let j_layout = action.data.layout;

      let final_results = {};
      // console.log("action data", action.data.results);
      action.data.results.forEach((result) => {
        let resJson = result.results_json;
        // let pitch;
        // let r2r;
        // if (resJson?.meta?.track_mode == 0) {
        //   pitch = _.round(resJson?.meta?.racks[0]?.ydim / resJson?.meta?.gcr, 2);
        //   r2r = resJson?.meta?.racks[0]?.ydim / resJson?.meta?.gcr - resJson?.meta?.racks[0]?.ydim * Math.cos(resJson?.meta?.tilt * (Math.PI / 180));
        // } else {
        //   pitch = _.round(resJson?.meta?.racks[0]?.xdim / resJson?.meta?.gcr, 2);
        //   r2r = resJson?.meta?.racks[0]?.xdim / resJson?.meta?.gcr - resJson?.meta?.racks[0]?.xdim;
        // }

        // let MWp = _.round(resJson?.meta?.capacity / 1000, 2);
        // let MWac = _.round((resJson?.meta?.inv_rating * resJson?.meta?.inverter_qty) / 1000, 2);

        // flatening the finance summary object a little bit to reduce the total number of loops happening here and in the ResultDetails component
        let temp_summary = JSON.parse(JSON.stringify(resJson.summary));

        const validKeys = ["install_cost", "operating_cost", "tax_depreciation", "ac_units", "dc_units", "fixed_units", "debt", "revenue_incentives", "misc_units"];
        if (Object.values(temp_summary.finance).length > 0 && Object.values(temp_summary.finance.value)?.length > 0) {
          Object.keys(temp_summary.finance.value).map((key, index) => {
            if (validKeys.includes(key)) {
              temp_summary = {
                ...temp_summary,
                [key]: JSON.parse(JSON.stringify(temp_summary["finance"].value[key])),
              };
              delete temp_summary["finance"].value[key];
            }
          });
        }

        delete temp_summary["user"];
        temp_summary = sort_summary(temp_summary);

        Object.keys(temp_summary).map((key) => {
          if (Object.values(temp_summary[key]).length > 0 && temp_summary[key].value?.install_type?.value !== "Detailed") {
            temp_summary[key].value = shape_and_sort_summary_data(temp_summary[key], key);
          }
          if (key === "install_cost" && temp_summary[key].value?.install_type?.value === "Detailed") {
            delete temp_summary[key];
          }
        });

        let track_mode = action.data.meta.do_dual_tilt == 1 ? 2 : action.data.meta.track_mode;

        if (track_mode === 2) {
          temp_summary["racking"].value["type"].value = "East-West Fixed";
        }

        if (track_mode !== 1 && temp_summary["racking"].value["tilt"]) {
          temp_summary["racking"].value["tilt"].label = "Tilt (°)";
        }

        temp_summary["racking"].value["name"].value = cleanRackingName(temp_summary["racking"].value["name"].value);
        temp_summary["configuration"].value["gcr"].value = +temp_summary["configuration"].value["gcr"].value.toFixed(3);
        temp_summary["configuration"].value["dcac"].value = +temp_summary["configuration"].value["dcac"].value.toFixed(3);

        if (temp_summary["inverter"].value["name"].value === "Simple Inverter Input") {
          delete temp_summary["inverter"].value["rating"];
          temp_summary["inverter"].value["simple"] = { label: "DC:AC (ILR)", value: temp_summary["configuration"].value["dcac"].value };
          temp_summary["configuration"].value["inverter_qty"].value = "Simple Inverter Input";
        }

        if (action.data.meta.en_mod_target == 1) {
          temp_summary["configuration"].value["module_qty"].value = action.data.meta.mod_target;
          temp_summary["configuration"].value["string_qty"].value =
            //checking to see if the string_qty is an int or a float and the rounding accordingly
            temp_summary["configuration"].value["string_qty"].value % 1 == 0
              ? temp_summary["configuration"].value["string_qty"].value
              : parseFloat(temp_summary["configuration"].value["string_qty"].value.toFixed(2));
        }

        // console.log("results", result);
        final_results[result.id] = {
          ...resJson.report,
          ...resJson.lstack,
          ...action.data.meta,
          ...resJson.meta,
          summary: temp_summary,
          id: result.id,
          // MWh: _.round(resJson.lstack["eh. Available Energy at Inverter Output (MWh)"], 0),
          MWh: _.round(resJson.lstack["fh. Energy injected into Grid (MWh)"], 0),
          lcoe: resJson.finance?.lcoe ? +resJson.finance.lcoe : undefined,
          irr: resJson.finance?.irr ? +resJson.finance.irr : undefined,
          total_install_cost: resJson.finance?.total_install_cost ? +resJson.finance.total_install_cost : undefined,
          op_cost_array: resJson.finance?.op_cost_array ? resJson.finance.op_cost_array : undefined,
          cashflow_after_tax: resJson.finance?.cashflow_after_tax ? resJson.finance.cashflow_after_tax : undefined,
          npv: resJson.finance?.npv ? +resJson.finance.npv.toFixed(2) : undefined,

          track_mode: action.data.meta.do_dual_tilt == 1 ? 2 : action.data.meta.track_mode,

          // dcac: _.round(resJson.meta.MWp / resJson.meta.MWac, 3),
          _yield: _.round(resJson.lstack["y. Yield (kWh/kWp)"], 2),
          // below three were being fed from old performance, need to update calcs in new performance
          // tilit: 0,
          // rtr: r2r,
          // pitch: pitch,
        };
      });

      if (action.data.meta.en_mwh_toggle == 1) {
        let filtered_final_results;
        Object.values(final_results).map((result) => {
          if (result.MWh >= action.data.meta.mwh_target) {
            filtered_final_results = { ...filtered_final_results, [result.id]: result };
          }
        });

        if (filtered_final_results && Object.values(filtered_final_results).length > 0) {
          final_results = filtered_final_results;
        }
      }

      // let final_results = {};
      // Object.values(action.data.data).map((result) => {
      //   final_results[result.id] = {
      //     // fix/append results
      //     ...result,
      //     string_count_in_plot: (result.modules_in_layout / result.modules_per_string).toFixed(2),
      //     generation: result.generation / 1000,
      //     module_name: action.data.meta.module_name,
      //     module_rating: `${ioManager.inputs.module.data.rating}W`,
      //   };
      // });

      // 150 for no finance (code will be 0)
      // 151 for lcoe
      // 152 for irr
      // ioManager.outputs.code = 150 + action.data.finance_code;
      // console.log(final_results);
      ioManager.outputs.results = final_results;
      ioManager.outputs.selectedResult = Object.values(final_results)[0];

      // ioManager.outputs.meta = { ...ioManager.outputs.meta, ...action.data.meta };
      // ioManager.outputs.selectedResult = ioManager.uiState.currentPlot && ioManager.outputs.results[ioManager.uiState.currentPlot.id];
      // ioManager.uiState.currentPlot = ioManager.outputs.selectedResult;
      // make the csv data and copy data

      let code = action.data.meta.do_finance == 1 && action.data.meta.metric == 0 ? 151 : action.data.meta.do_finance != 0 && action.data.meta.metric == 1 ? 152 : 150;
      try {
        let fileData = fixResultFiles(final_results, ioManager.outputs.meta, code);
        ioManager.outputs.csvData = fileData.csvResult;
        ioManager.outputs.tsvData = fileData.tsvResult;
      } catch (error) {
        console.log(error);
      }

      // // set file name for export file
      // let resultsFileName = `SIFT_Results.csv`;
      // if (projectManager.currentProjectId) {
      //   resultsFileName = `SIFT_${projectManager.projects[projectManager.currentProjectId].name}.csv`;
      // }
      // ioManager.outputs.fileName = resultsFileName;

      if (ioManager.outputs.selectedResult) {
        let PROJECT_NAME = _.isEmpty(ioManager.outputs.selectedResult.project_name) ? "sift_output" : ioManager.outputs.selectedResult.project_name;
        let TRACK_MODE_STRING =
          ioManager.outputs.selectedResult.track_mode == 0 ? "GFT" : ioManager.outputs.selectedResult.track_mode == 1 ? "SAT" : ioManager.outputs.selectedResult.track_mode == 2 && "EWF";
        let MOD_RATING = ioManager.outputs.selectedResult.mod_rating;
        let INV_RATING = ioManager.outputs.selectedResult.inv_rating;
        let GCR = ioManager.outputs.selectedResult.gcr;
        let SPI = ioManager.outputs.selectedResult.spi;
        let MM = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        let DD = d.getDate() + 1 < 10 ? `0${d.getDate() + 1}` : d.getDate() + 1;
        let YYYY = d.getFullYear().toString();
        let MMDDYYYY = MM + DD + YYYY;
        let fileName = `${PROJECT_NAME}_${TRACK_MODE_STRING}_${MOD_RATING}W_${INV_RATING}kW_${_.round(GCR, 3)}_${SPI}_${MMDDYYYY}`;
        ioManager.report.reportData.file_name = fileName;
      }

      ioManager.outputs.runState.running = false;
      ioManager.outputs.runState.generated = true;
      ioManager.outputs.runState.canceling = false;

      return {
        ...state,
        local_run_count: state.local_run_count + 1,
        ioManager: {
          ...ioManager,
          outputs: { ...ioManager.outputs, layout: { ...j_layout, isLayout: true } },
          uiState: { ...ioManager.uiState },
          backup_inputs: { ...action.data.meta },
        },
      };

    case siftConstants.GENERATE_RESULTS_FAILURE:
      // console.log("GENERATE_RESULTS_FAILURE", action);

      ioManager.outputs.runState.running = false;
      ioManager.outputs.runState.generated = false;
      ioManager.outputs.runState.canceling = false;

      if (action.error === "Cancelled") {
        ioManager.uiState.error_messages = [];
        // ioManager.outputs.runState = JSON.parse(JSON.stringify(initialState.ioManager.outputs.runState))
        ioManager.outputs.runState.runId = undefined;

        ioManager.outputs.results = {};
        ioManager.outputs.meta = {};
        ioManager.outputs.errors = [];
        ioManager.outputs.layout = JSON.parse(JSON.stringify(initialState.ioManager.outputs.layout));
        ioManager.outputs.runState.canceling = false;

        ioManager.outputs.runState.steps = JSON.parse(JSON.stringify(initialState.ioManager.outputs.runState.steps));

        ioManager.uiState.tab = "config";
      } else if (action.failed) {
        ioManager.outputs.runState.canceling = false;
        // ioManager.outputs.errors = typeof action.error.msg == "string" ? [action.error.msg] : action.error.msg;
        ioManager.outputs.errors = action.error.error.msg;
        ioManager.outputs.runState.steps.validating.status = "error";
      } else {
        ioManager.outputs.runState.canceling = false;
        // ioManager.outputs.errors = typeof action.error.msg == "string" ? [action.error.msg] : action.error.msg;
        // ioManager.outputs.errors = action.error.errors.length > 4 ? action.error.errors.slice(0, 4) : action.error.errors;

        ioManager.outputs.errors = action.error.error.msg;
        ioManager.outputs.runState.steps.validating.status = "error";
      }

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };
    case siftConstants.CANCEL_RUN_REQUEST:
      // console.log('CANCEL_RUN_REQUEST', action)
      ioManager.outputs.runState.canceling = true;

      return {
        ...state,
        ioManager: {
          ...ioManager,
          outputs: ioManager.outputs,
        },
      };
    case siftConstants.RESULT_SELECT:
      console.log("RESULT_SELECT", action);

      ioManager.outputs.selectedResult = action.record;

      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.GET_LAYOUT_REQUEST:
      // console.log('GET_LAYOUT_REQUEST', action)
      ioManager.uiState.loading_layout = true;

      if (Object.keys(ioManager.inputs.map.features) == 0) {
        // someone deleted their site and wants to plot / bring it back up.
        ioManager.inputs.map.features = state.deleted_features;
      }

      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.GET_LAYOUT_SUCCESS:
      // console.log("GET_LAYOUT_SUCCESS", action);
      ioManager.uiState.loading_layout = false;
      ioManager.uiState.currentPlot = action.response.record;

      let layout = action.response.layout;
      ioManager.outputs.layout = { ...layout };

      // This doesn't need to be ran -- the values in the finance tab should not be impacted by the results of a run.
      // if (ioManager.inputs.config.data.do_finance == 1) {
      //   let racks = ioManager.inputs.racking.data.racks;
      //   let racking_qty = ioManager.outputs.currentPlot ? ioManager.outputs.currentPlot.rack_count : 1;
      //   let module_rating = ioManager.inputs.module.data.rating;
      //   let module_qty = ioManager.outputs.currentPlot ? ioManager.outputs.currentPlot.module_count : 1;
      //   let inverter_rating = ioManager.inputs.inverter.data.inverterRating;
      //   let inverter_qty = ioManager.outputs.currentPlot ? ioManager.outputs.currentPlot.inverter_count : 1;
      //   let footprint = ioManager.outputs.currentPlot ? parseFloat(ioManager.outputs.currentPlot.racking_footprint) : 1;

      //   finance_inputs.forEach((key) => {
      //     let input_object = { key, value: ioManager.inputs.finance.data[key] };
      //     let calculated_value = calculate_finance(input_object, racks, racking_qty, module_rating, module_qty, inverter_rating, inverter_qty, footprint);
      //     ioManager.inputs.finance.data[calculated_value.key] = calculated_value.value;
      //   });
      // }

      return {
        ...state,
        ioManager: {
          ...ioManager,
          uiState: { ...ioManager.uiState },
        },
      };
    case siftConstants.GET_LAYOUT_FAILURE:
      // console.log('GET_LAYOUT_FAILURE', action)
      ioManager.uiState.loading_layout = false;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.TOGGLE_IMPORT_VIS:
      ioManager.uiState.import_visible = action.bool;
      return { ...state };

    case siftConstants.IMPORTDATA_REQUEST:
      // console.log(action);
      if (action.options.tab === "map") {
        inputs.map.loading_map = true;
      } else {
        ioManager.uiState.import_loading = true;
      }

      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.IMPORTDATA_SUCCESS:
      ioManager.uiState.import_loading = false;
      ioManager.uiState.import_visible = false;
      ioManager.inputs.map.loading_map = false;

      console.log("import data", action);

      // console.log((action.response.s3contents && action.response.s3contents.error && action.response.s3contents.error !== ""))
      if (action.response.error || (action.response.s3contents && action.response.s3contents.error && action.response.s3contents.error !== "")) {
        // error happened
        if (action.response.tab === "map") {
          ioManager.uiState.error_visible = true;
          ioManager.uiState.error_messages = ["The KMZ or KML file failed to load. You may want to check the layers in Google Earth. For support, please send the KMZ to the SIFT team."];
          if (action.response.s3contents && action.response.s3contents.error) {
            ioManager.uiState.error_messages.push(action.response.s3contents.error);
          }
        } else {
          // weather/module/inverter import failed
          ioManager.uiState.error_visible = true;
          // ioManager.uiState.error_messages = [action.response.error];
          ioManager.uiState.error_messages = ["Import data failed, please check the integrity of your file and try again. If the problem persists, consider submitting a bug report"];
        }
        return { ...state, ioManager: { ...ioManager } };
      } else {
        let prod_data = JSON.parse(JSON.stringify(action.response.s3contents));
        let new_object = {};

        if (action.response.tab === "map") {
          prod_data.features.map((feature) => {
            let id = create_UUID();
            feature.properties["index"] = id;
            try {
              feature.properties["area"] = feature.geometry.type == "Polygon" && getArea(feature.geometry.coordinates);
              ioManager.inputs.map.features[id] = feature;
            } catch (error) {
              // this is an invalid polygon, skip it
              console.log(error);
            }
          });
          // recenter map
          let poly_features = Object.values(ioManager.inputs.map.features).filter((feature) => feature.geometry.type == "Polygon");

          if (Object.keys(poly_features).length > 0) {
            ioManager.inputs.map.map_center = getCenterPoint(poly_features);
            ioManager.inputs.map.totalArea = calculateTotalArea(poly_features);
            ioManager.inputs.map.boundary_bbox = getBounds(Object.values(poly_features));
          } else {
            ioManager.inputs.map.totalArea = 0;
            ioManager.inputs.map.boundary_bbox = undefined;
          }
        } else {
          if (action.response.tab === "weather") {
            try {
              let weather_object = JSON.parse(JSON.stringify(tabDefaults.weather));

              weather_object.data.tz = parseInt(prod_data["Timezone"]);
              weather_object.data.ele = parseFloat(prod_data["Elevation"]);
              weather_object.data.lat = parseFloat(prod_data["Latitude"]);
              weather_object.data.lng = parseFloat(prod_data["Longitude"]);
              weather_object.data.source = prod_data["Source"];
              weather_object.data.locationId = prod_data["name"];

              weather_object.data.search_tz = ioManager.inputs.weather.data.search_tz;
              weather_object.data.search_ele = ioManager.inputs.weather.data.search_ele;
              weather_object.data.search_lat = ioManager.inputs.weather.data.search_lat;
              weather_object.data.search_lng = ioManager.inputs.weather.data.search_lng;
              weather_object.data.weather_summary = { Summary: prod_data["Summary"] };

              weather_object.data.default = 2;
              weather_object.data.id = prod_data["weather_id"];
              weather_object.data.weather_id = prod_data["weather_id"];
              // weather_object.data.name = 'Imported Weather (Custom)';
              weather_object.data.name = action.response.file_name;

              weather_object.id = prod_data["weather_id"];

              new_object = weather_object;
            } catch (err) {
              new_object = ioManager.inputs[action.response.tab];
              ioManager.uiState.error_visible = true;
              ioManager.uiState.error_messages = [
                "There seems to have been an error importing your weather file. Please check the integrity of your file, or use our template located in the weather import window.",
              ];
            }
          } else {
            // module or inverter
            let temp_prod_data = prod_data;

            if (action.response.tab === "inverter") {
              let inputs_to_check = ["inverterRating", "pnom", "inv_pd_pacokw", "mppt_low_inverter", "mppt_hi_inverter", "inv_pd_vdcmax", "maxEff", "inv_pd_pnt", "pthresh"];
              inputs_to_check.forEach((key) => {
                temp_prod_data[key] = temp_prod_data[key] && !Array.isArray(temp_prod_data[key]) ? temp_prod_data[key] : 0;
              });
            }

            new_object = {
              id: prod_data["id"],
              data: temp_prod_data,
            };
            if (action.response.tab === "module") {
              ioManager.inputs.module.data.mlm_D2MuTau = prod_data.mlm_D2MuTau && prod_data.mlm_D2MuTau !== "" ? prod_data.mlm_D2MuTau : 0;
            }
          }

          // console.log(new_object)
          // update for weather/module/inverter
          ioManager.dropdowns[action.response.tab][new_object.id] = new_object;
          ioManager.inputs[action.response.tab] = JSON.parse(JSON.stringify(new_object));
        }

        return {
          ...state,
          ioManager: {
            ...ioManager,
            dropdowns: { ...ioManager.dropdowns },
            inputs: { ...ioManager.inputs },
          },
        };
      }

    case siftConstants.FIX_WEATHER_INPUTS:
      // action.inputs = { lat,lng,tz,ele }
      // console.log(action.inputs);

      let fixed_weather_inputs = {
        ...state.ioManager.inputs.weather,
        data: {
          ...state.ioManager.inputs.weather.data,
          search_lat: action.inputs.lat,
          search_lng: action.inputs.lng,
          search_ele: action.inputs.ele,
          search_tz: action.inputs.tz,
        },
      };
      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            weather: fixed_weather_inputs,
            map: {
              ...state.ioManager.inputs.map,
              map_center: [action.inputs.lat, action.inputs.lng],
              map_reset: !state.ioManager.inputs.map.map_reset,
            },
          },
        },
      };
    // if (action.response.type == 'weather') {
    //   let weather_object = JSON.parse(JSON.stringify(tabDefaults.weather));

    //   weather_object.data.tz = new_object.data['Timezone'];
    //   weather_object.data.ele = new_object.data['Elevation'];
    //   weather_object.data.lat = new_object.data['Latitude'];
    //   weather_object.data.lng = new_object.data['Longitude'];
    //   weather_object.data.source = new_object.data['Source'];
    //   weather_object.data.locationId = new_object.data['name'];

    //   weather_object.data.search_tz = ioManager.inputs.weather.data.search_tz;
    //   weather_object.data.search_ele = ioManager.inputs.weather.data.search_ele;
    //   weather_object.data.search_lat = ioManager.inputs.weather.data.search_lat;
    //   weather_object.data.search_lng = ioManager.inputs.weather.data.search_lng;
    //   weather_object.data.weather_summary = new_object.data;

    //   weather_object.data.default = 2;
    //   weather_object.data.id = new_object.id;
    //   weather_object.data.weather_id = new_object.id;
    //   weather_object.data.name = 'Imported Weather (Custom)';

    //   weather_object.id = new_object.id;

    //   new_object = weather_object;
    // }

    // create new entry in the specific dropdown
    // action.response.type is one of these: module, inverter, weather

    case siftConstants.IMPORTDATA_FAILURE:
      ioManager.uiState.import_loading = false;
      ioManager.uiState.import_visible = false;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.SAVEINPUT_REQUEST:
      // console.log(action);
      ioManager.uiState.input_loading = true;
      return { ...state };
    case siftConstants.SAVEINPUT_SUCCESS:
      ioManager.uiState.input_loading = false;
      console.log("save input success", action);
      // let new_preset = {
      //   ...action.payload.input,
      //   ...action.payload.response,
      //   default: 0,
      // };
      // let id_key = Object.keys(action.payload.response)[0];
      // console.log(new_preset[id_key]);

      // if (action.payload.key == "saveas") {
      //   // add to the dropdowns
      //   ioManager.dropdowns[ioManager.uiState.tab][new_preset[id_key]] = new_preset;
      // } else if (action.payload.key == "delete") {
      //   // if (ioManager.inputs[ioManager.uiState.tab].id == action.payload.input.id) {
      //   //   // set inputs to default if we're deleting the same input we've got loaded
      //   //   ioManager.inputs[ioManager.uiState.tab] = JSON.parse(JSON.stringify(tabDefaults[ioManager.uiState.tab]));
      //   // }
      //   delete ioManager.dropdowns[ioManager.uiState.tab][new_preset[id_key]];
      // } else if (action.payload.key == "save") {
      //   // update the input in dropdowns
      //   ioManager.dropdowns[ioManager.uiState.tab][new_preset[id_key]] = new_preset;
      // }

      return { ...state, ioManager };

    case siftConstants.SAVEINPUT_FAILURE:
      ioManager.uiState.input_loading = false;
      // console.log(action);
      return { ...state };

    case siftConstants.PULL_MAP_LOC:
      ioManager.inputs.map.pull_location = false;
      return { ...state };
    case siftConstants.PULL_MAP_LOC_SUCCESS:
      ioManager.inputs.map.pull_location = true;

      // close error window if recenter pin weather error is showing and is clicked
      if (ioManager.uiState.error_visible) {
        ioManager.uiState.error_visible = false;
        ioManager.uiState.error_messages = [];
      }

      return { ...state };

    case siftConstants.EXPORT_MAP_STARTED:
      ioManager.inputs.map.loading_map = true;
      ioManager.inputs.map.loading_msg = action.msg;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.EXPORT_MAP_COMPLETED:
      ioManager.inputs.map.loading_map = false;
      ioManager.inputs.map.loading_msg = undefined;
      if (action.error) {
        ioManager.uiState.error_messages = ["An error occured while exporting the KMZ file. Please try again. If the problem persist, consider submitting a bug report."];
      }
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.EXPORT_MAP:
      ioManager.uiState[action.fileType] = !ioManager.uiState[action.fileType];
      return { ...state };

    case siftConstants.UPDATE_MARKER_REQ:
      ioManager.uiState.weather_loading = true;
      return { ...state };

    case siftConstants.UPDATE_MARKER:
      // console.log(action.response)
      inputs.weather.data.search_tz = action.response.tz;
      inputs.weather.data.search_ele = roundOff(action.response.elevation, 1);
      inputs.weather.data.search_lat = roundOff(action.response.latlng[0], 4);
      inputs.weather.data.search_lng = roundOff(action.response.latlng[1], 4);
      inputs.weather.data.county = action.response.countyState.county;
      inputs.weather.data.state = action.response.countyState.state;

      // inputs.weather.data.search_tz = action.response.tz && action.response.tz.rawOffset / 3600 || 0
      // inputs.weather.data.search_ele = action.response.ele && action.response.ele.results[0].elevation.toFixed(2) || 0
      // inputs.weather.data.search_lat = action.response.latlng[0].toFixed(4)
      // inputs.weather.data.search_lng = action.response.latlng[1].toFixed(4)

      // if (action.response.county_state && action.response.county_state.results.length > 0) {
      //   inputs.weather.data.county = `${action.response.county_state.results[0]['county_name']}, `
      //   inputs.weather.data.state = action.response.county_state.results[0]['state_code']
      // } else {
      //   inputs.weather.data.county = ""
      //   inputs.weather.data.state = ""
      // }
      ioManager.uiState.weather_loading = false;
      return { ...state };

    case siftConstants.WEATHER_REQUEST:
      // console.log(action)
      ioManager.uiState.weather_loading = true;
      return { ...state };
    case siftConstants.WEATHER_SUCCESS:
      // console.log('polling for weather_id', action.id)
      return { ...state };
    case siftConstants.WEATHER_FAILURE:
      // ioManager.outputs.errors = [`show_weather_failure_error`];
      ioManager.outputs.errors = action.error;
      ioManager.uiState.weather_loading = false;
      return { ...state };
    case siftConstants.WEATHER_DATA_SUCCESS:
      // console.log(action)

      ioManager.uiState.weather_loading = false;
      ioManager.inputs.map.pull_location = false;

      let weather_object = JSON.parse(JSON.stringify(tabDefaults.weather));

      weather_object.data.upload = 0;

      weather_object.data.tz = action.data["Timezone"];
      weather_object.data.ele = action.data["Elevation"];
      weather_object.data.lat = action.data["Latitude"];
      weather_object.data.lng = action.data["Longitude"];
      weather_object.data.source = action.data["Source"];
      weather_object.data.locationId = action.data["name"];
      // console.log(action.data['name'])

      // DEPPRECATED
      // USE VALUES FROM MARKER NOW
      weather_object.data.search_tz = ioManager.inputs.weather.data.search_tz;
      weather_object.data.search_ele = ioManager.inputs.weather.data.search_ele;
      weather_object.data.search_lat = ioManager.inputs.weather.data.search_lat;
      weather_object.data.search_lng = ioManager.inputs.weather.data.search_lng;

      weather_object.data.weather_summary = action.data;

      weather_object.data.default = 2;
      weather_object.data.weather_id = action.data["weather_id"];
      let source = weather_object.data.source == 0 ? "NSRDB" : "NASA";
      weather_object.data.name = `Imported Weather (${source})`;

      weather_object.id = action.data["weather_id"];

      // create new entry in the specific dropdown
      ioManager.dropdowns.weather[weather_object.id] = weather_object;
      ioManager.inputs.weather = JSON.parse(JSON.stringify(weather_object));

      return { ...state };

    case siftConstants.WEATHER_DATA_FAILURE:
      ioManager.uiState.weather_loading = false;

      // stop run from happening
      ioManager.outputs.runState.canceling = false;
      ioManager.outputs.runState.running = false;
      ioManager.outputs.runState.generated = false;
      let w_source = ioManager.inputs.weather.data.source == 0 ? "NSRDB" : "NASA";
      ioManager.outputs.errors = [`Error connecting to ${w_source} Weather Source. Please try again, request manually, or switch to another source.`];
      ioManager.outputs.runState.steps.validating.status = "error";
      return { ...state };

    case siftConstants.CLEAR_WEATHER:
      ioManager.inputs.map.pull_location = false;
      let def_weather = JSON.parse(JSON.stringify(tabDefaults.weather));
      def_weather.data.search_tz = ioManager.inputs.weather.data.search_tz;
      def_weather.data.search_ele = ioManager.inputs.weather.data.search_ele;
      def_weather.data.search_lat = ioManager.inputs.weather.data.search_lat;
      def_weather.data.search_lng = ioManager.inputs.weather.data.search_lng;

      ioManager.inputs.weather = def_weather;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.DOWNLOAD_FILES_REQUEST:
      ioManager.uiState.loading_files = true;
      return { ...state, ioManager: { ...ioManager } };
    case siftConstants.DOWNLOAD_FILES_SUCCESS:
    case siftConstants.DOWNLOAD_FILES_FAILURE:
      ioManager.uiState.loading_files = false;
      return { ...state, ioManager: { ...ioManager } };

    case siftConstants.UPDATE_TOPO:
      inputs.topo.data[action.key] = action.value;

      /* TOPO TAB */
      if (action.key === "u_grade_limit" && !isNaN(parseInt(action.value))) {
        inputs.topo.data[action.key] = parseInt(action.value);
      }
      if (action.key === "ns_grade_limit" && !isNaN(parseInt(action.value))) {
        inputs.topo.data[action.key] = parseInt(action.value);
      }
      if (action.key === "ew_grade_limit" && !isNaN(parseInt(action.value))) {
        inputs.topo.data[action.key] = parseInt(action.value);
      }

      if (action.key === "topo_mode") {
        let layers_gen = {
          ele: { avail: true },
          NS: { avail: true, limit: parseFloat(inputs.topo.data.ns_grade_limit) },
          EW: { avail: true, limit: parseFloat(inputs.topo.data.ew_grade_limit) },
          U: { avail: true, limit: parseFloat(inputs.topo.data.u_grade_limit) },
        };
        let mode_ext = action.value === "ele" ? "ele" : `${action.value}/${layers_gen[action.value].limit}`;

        inputs.topo.data.topo_url = `https://topo-tiles.sunfig.com/test/${inputs.topo.data.topo_id}/${mode_ext}/{z}/{x}/{y}.png`;
        inputs.topo.data.topo_scale_url = `https://topo-tiles.sunfig.com/test/${inputs.topo.data.topo_id}/${mode_ext}/scale.png`;
      }

      return { ...state, ioManager: { ...ioManager, inputs: ioManager.inputs } };
    case siftConstants.CLEAR_TOPO_DATA:
      ioManager.uiState.topo_loading = false;
      ioManager.inputs.topo = JSON.parse(JSON.stringify(tabDefaults.topography));

      // inputs.topo.topo_mode = ""
      // inputs.topo.topo_url = ""
      // inputs.topo.topo_scale_url = ""
      // inputs.topo.topo_live = false
      // inputs.topo.layers_generated = {}
      // ioManager.inputs.topo.id = 'topo';
      // ioManager.inputs.topo.topo_source = 'USGS';
      // ioManager.inputs.topo.topo_live = false;
      // ioManager.inputs.topo.topo_url = '';
      // ioManager.inputs.topo.topo_scale_url = '';
      // ioManager.inputs.topo.topo_id = undefined;
      // ioManager.inputs.topo.topo_action = 'nothing';
      // ioManager.inputs.topo.topo_error = undefined;
      // ioManager.inputs.topo.topo_mode = '';
      // ioManager.inputs.topo.ns_grade_limit = 8;
      // ioManager.inputs.topo.ew_grade_limit = 20;
      // ioManager.inputs.topo.u_grade_limit = 15;

      return { ...state, ioManager: { ...ioManager, inputs: ioManager.inputs } };

    case siftConstants.GET_TOPO_ID_REQUEST:
      ioManager.inputs.topo.data.layers_generated.NS.limit = ioManager.inputs.topo.data.ns_grade_limit;
      ioManager.inputs.topo.data.layers_generated.EW.limit = ioManager.inputs.topo.data.ew_grade_limit;
      ioManager.inputs.topo.data.layers_generated.U.limit = ioManager.inputs.topo.data.u_grade_limit;
      ioManager.inputs.topo.data.topo_error = undefined;
      ioManager.uiState.topo_loading = true;
      return { ...state, ioManager: { ...ioManager, uiState: ioManager.uiState } };
    case siftConstants.GET_TOPO_ID_SUCCESS:
      ioManager.inputs.topo.data.topo_id = action.data.topo_id;
      return { ...state, ioManager: { ...ioManager, inputs: ioManager.inputs } };
      // case siftConstants.GET_TOPO_ID_FAILURE:
      //   ioManager.uiState.topo_loading = false;
      //   return { ...state, ioManager: { ...ioManager, uiState: ioManager.uiState } };
      // case siftConstants.GET_TOPO_DATA_REQUEST:
      //   return { ...state };
      // case siftConstants.GET_TOPO_DATA_SUCCESS:
      //   ioManager.uiState.topo_loading = false;

      let layers_gen = {
        ele: { avail: true },
        NS: { avail: true, limit: parseFloat(inputs.topo.ns_grade_limit) },
        EW: { avail: true, limit: parseFloat(inputs.topo.ew_grade_limit) },
        U: { avail: true, limit: parseFloat(inputs.topo.u_grade_limit) },
      };
      // let mode = inputs.racking.data.type == 0 ? 'EW' : 'NS';
      let mode = "U";
      let mode_ext = `${mode}/${layers_gen[mode].limit}`;

      inputs.topo.topo_mode = mode;
      inputs.topo.topo_url = `https://topo-tiles.sunfig.com/test/${inputs.topo.topo_id}/${mode_ext}/{z}/{x}/{y}.png`;
      inputs.topo.topo_scale_url = `https://topo-tiles.sunfig.com/test/${inputs.topo.topo_id}/${mode_ext}/scale.png`;
      // inputs.topo.topo_live = true;
      inputs.topo.layers_generated = layers_gen;

      return { ...state, ioManager: { ...ioManager, uiState: ioManager.uiState, inputs: ioManager.inputs } };
    case siftConstants.GET_TOPO_DATA_FAILURE:
      ioManager.inputs.topo.data.topo_loading = false;
      ioManager.inputs.topo.data.topo_live = false;
      ioManager.uiState.topo_loading = false;
      if (action.error) {
        ioManager.uiState.error_visible = true;
        ioManager.uiState.error_messages = [
          "Error Downloading Topography. Change your topography source via the Topography Tab and try again. If the problem persists, please consider submitting a bug report",
        ];
      }
      return { ...state, ioManager: { ...ioManager, uiState: ioManager.uiState } };

    case siftConstants.GET_TUTORIAL_REQUEST:
      uiState.loading = true;
      return { ...state, uiState };

    case siftConstants.GET_TUTORIAL_SUCCESS:
      let tut_inputs = fixInputs(JSON.parse(JSON.stringify(action.tutorialProject.inputs)));
      if (action.step && action.step == "tour_step_2") {
        ioManager.inputs.map = tut_inputs.map;
        ioManager.inputs.map.features["157c7efa-68ae-4c8e-be80-5a7b77cec68d"].properties.identity = 0;
        // ioManager.inputs.map.features['4832508e-9bbd-4b79-99cb-5618c9fd2f4b'].properties.identity = 0;
        ioManager.inputs.module = copyDefault(tut_inputs.module);
        ioManager.inputs.inverter = copyDefault(tut_inputs.inverter);
        ioManager.inputs.racking = copyDefault(tut_inputs.racking);
        ioManager.inputs.weather = tut_inputs.weather;
        ioManager.inputs.performance = tut_inputs.performance;
        ioManager.inputs.topo = tut_inputs.topo;
        ioManager.inputs.config = tut_inputs.config;
        ioManager.inputs.finance = tut_inputs.finance;
        ioManager.inputs.layout = tut_inputs.layout;
        ioManager.inputs.config.worker_count = ioManager.inputs.config.gcr_count * ioManager.inputs.config.worker_count;
        // ioManager.inputs.map.selectedFeatureId = 'db661209-54c4-4b40-9527-1d856476b79c';
        // ioManager.inputs.map.isEditing = true;
        projectManager.selectedProjectId = undefined;
        projectManager.currentProjectId = undefined;
        ioManager.outputs = JSON.parse(JSON.stringify(initialState.ioManager.outputs));

        // ioManager.uiState.tab = 'config';

        ioManager.inputs.map.boundary_bbox = getBounds(Object.values(ioManager.inputs.map.features));
        ioManager.inputs.map.totalArea = calculateTotalArea(ioManager.inputs.map.features);
        ioManager.inputs.map.map_center = getCenterPoint(ioManager.inputs.map.features);
      } else {
        // console.log(action)
        // console.log(fixInputs(action.tutorialProject.inputs))
        let tut_inputs = fixInputs(action.tutorialProject.inputs);
        ioManager.inputs.map = tut_inputs.map;
        ioManager.inputs.module = tut_inputs.module;
        ioManager.inputs.inverter = tut_inputs.inverter;
        ioManager.inputs.racking = tut_inputs.racking;
        ioManager.inputs.weather = tut_inputs.weather;
        ioManager.inputs.performance = tut_inputs.performance;
        ioManager.inputs.topo = tut_inputs.topo;
        ioManager.inputs.config = tut_inputs.config;
        ioManager.inputs.finance = tut_inputs.finance;
        ioManager.inputs.layout = tut_inputs.layout;

        ioManager.inputs.config.worker_count = ioManager.inputs.config.gcr_count * ioManager.inputs.config.worker_count;

        ioManager.uiState.input_loading = true;
        projectManager.selectedProjectId = undefined;
        projectManager.currentProjectId = undefined;
        ioManager.outputs = JSON.parse(JSON.stringify(initialState.ioManager.outputs));

        ioManager.uiState.tab = "config";

        ioManager.inputs.map.boundary_bbox = getBounds(Object.values(ioManager.inputs.map.features));
        ioManager.inputs.map.totalArea = calculateTotalArea(ioManager.inputs.map.features);
        ioManager.inputs.map.map_center = getCenterPoint(ioManager.inputs.map.features);
      }

      return { ...state, uiState, projectManager };

    case siftConstants.GET_TUTORIAL_COMPLETE:
      uiState.loading = false;
      ioManager.uiState.input_loading = false;
      return { ...state, uiState };

    case siftConstants.TOGGLE_COLLAB_MODAL:
      // console.log('toggle requested', action	)
      uiState.collabVisible = action.toggle;
      projectManager.modal_visible = false;

      // projectId is only assigned if the toggle is called from the Collaborate link in the
      //  project load modal table
      projectManager.collab_projectId = action.projectId || projectManager.currentProjectId;

      return { ...state, uiState, projectManager };

    case siftConstants.SHARE_COLLAB_REQUEST:
      // console.log('requested share', action.inputs)
      // projectManager.collab_request = action.inputs.type
      projectManager.collab_loading = true;
      projectManager.loading = true;
      return { ...state, projectManager };

    case siftConstants.CHECK_COLLAB_REQUEST:
      // console.log('requested check', action.inputs)
      // projectManager.collab_request = action.inputs.type
      projectManager.collab_loading = true;
      projectManager.loading = true;
      return { ...state, projectManager };

    case siftConstants.COLLAB_SUCCESS:
      // console.log('requested success', action.response)
      // update the project that was updated (could not be the currentProject)
      projectManager.collab_request = undefined;
      projectManager.collab_loading = false;
      projectManager.loading = false;
      let updated_id = action.response.updated_project.project;
      if (action.response.updated_project.active == 1) {
        projectManager.localProjects[updated_id] = action.response.updated_project;
      } else {
        // deleted project, remove
        delete projectManager.localProjects[updated_id];
      }

      return { ...state, projectManager };

    case siftConstants.COLLAB_FAILURE:
      // console.log("requested failed", action.response);
      projectManager.collab_loading = false;
      projectManager.loading = false;

      if (action.error) {
        ioManager.uiState.error_visible = true;
        ioManager.uiState.error_messages = [action.error];
      }

      return { ...state, projectManager, ioManager: { ...ioManager } };

    case siftConstants.REFRESH_COLLAB_REQUEST:
      // console.log('requested check', action.inputs)
      projectManager.collab_loading = true;
      return { ...state, projectManager };

    case siftConstants.REFRESH_COLLAB_SUCCESS:
      // update the project that was updated (could not be the currentProject)
      projectManager.collab_loading = false;
      projectManager.localProjects[action.response.updated_project.project] = action.response.updated_project;

      return { ...state, projectManager: { ...projectManager } };

    case siftConstants.TOGGLE_ACCOUNT_MODAL:
      ioManager.uiState.account_visible = action.bool;
      return { ...state, uiState };

    case siftConstants.UPDATE_SCREEN_WIDTH:
      ioManager.uiState.screenWidth = action.width;
      return { ...state, uiState };

    case siftConstants.TOGGLE_MOBILE_INPUTS:
      ioManager.uiState.toggleMobileInputs = action.bool;
      return { ...state, uiState };
    // // CANCEL A SIFT MID-run
    // case accountConstants.DASH_CANCELSIFT:
    //   return {
    //     ...state,
    //     canceling: true,
    //       run_id: undefined,
    //       generating: false,
    //       data: [],
    //       code: 0,
    //       count: 0,
    //   }

    // *** PULL TOPOGRAPHY REDUCERS ***
    case siftConstants.GET_TOPO_DATA_REQUEST:
      ioManager.inputs.topo.data.layers_generated.NS.limit = ioManager.inputs.topo.data.ns_grade_limit;
      ioManager.inputs.topo.data.layers_generated.EW.limit = ioManager.inputs.topo.data.ew_grade_limit;
      ioManager.inputs.topo.data.layers_generated.U.limit = ioManager.inputs.topo.data.u_grade_limit;
      ioManager.inputs.topo.data.topo_loading = true;
      ioManager.inputs.topo.data.topo_error = undefined;
      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            topo: {
              ...ioManager.inputs.topo,
              data: {
                ...ioManager.inputs.topo.data,
              },
            },
          },
        },
      };

    case siftConstants.GET_TOPO_DATA_SUCCESS:
      // console.log(action)
      let def_layer = ioManager.inputs.racking.data.type == 1 ? "NS" : "EW";
      let _mode_ext = `${def_layer}/${ioManager.inputs.topo.data.layers_generated[def_layer].limit}`;

      // ioManager.inputs.topo.data.topo_id = action.data.topo_id;
      ioManager.inputs.topo.data.topo_loading = false;
      ioManager.inputs.topo.data.topo_live = true;

      ioManager.inputs.topo.data.layers_generated.ele.avail = true;
      ioManager.inputs.topo.data.layers_generated.NS.avail = true;
      ioManager.inputs.topo.data.layers_generated.EW.avail = true;
      ioManager.inputs.topo.data.layers_generated.U.avail = true;

      ioManager.inputs.topo.data.topo_mode = def_layer;
      ioManager.inputs.topo.data.topo_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
      ioManager.inputs.topo.data.topo_scale_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/${_mode_ext}/scale.png`;
      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            topo: {
              ...ioManager.inputs.topo,
              data: {
                ...ioManager.inputs.topo.data,
              },
            },
          },
        },
      };
      // console.log(action)
      ioManager.inputs.topo.data.topo_live = true;
      ioManager.inputs.topo.data.topo_loading = false;
      if (action.data.topo_id !== undefined) ioManager.inputs.topo.data.topo_id = action.data.topo_id;
      let ele_generated_from = ioManager.inputs.topo.data.ele_generated_from;

      if (action.action === "pull_ele") {
        if (action.data.error) {
          ioManager.inputs.topo.data.topo_live = false;
          ioManager.inputs.topo.data.topo_error = action.data.error.msg;
        } else {
          ioManager.inputs.topo.data.topo_error = undefined;
          ioManager.inputs.topo.data.topo_mode = "ele";
          ioManager.inputs.topo.data.topo_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/ele/{z}/{x}/{y}.png`;
          ioManager.inputs.topo.data.topo_scale_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/ele/scale.png`;
        }
      }
      if (action.action === "calc_grade") {
        if (action.data.error) {
          ioManager.inputs.topo.data.topo_error = action.data.error.msg;
        } else {
          ioManager.inputs.topo.data.topo_mode = "CF";

          ioManager.inputs.topo.data.layers_generated.CF.avail = true;
          ioManager.inputs.topo.data.layers_generated.CF.limit = ioManager.inputs.topo.data.grading.grade_target;
          ioManager.inputs.topo.data.grading.generated = true;
          ioManager.inputs.topo.data.grading.cut_amt = action.data.output.cut_sum;
          ioManager.inputs.topo.data.grading.fill_amt = action.data.output.fill_sum;
          let _mode_ext = `${ioManager.inputs.topo.data.topo_mode}/${ioManager.inputs.topo.data.layers_generated[ioManager.inputs.topo.data.topo_mode].limit}`;
          ioManager.inputs.topo.data.topo_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
          ioManager.inputs.topo.data.topo_scale_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/${_mode_ext}/scale.png`;
        }
      }
      if (action.action === "gen_layers") {
        // console.log(action);
        if (action.data.error) {
          ioManager.inputs.topo.data.topo_error = action.data.error.msg;
          // console.log(action.data.error.msg);
          // console.log(ioManager.inputs.topo);
        } else {
          ioManager.inputs.topo.data.has_layers = true;

          let lyrs = action.inputs.generate_layers;
          // layer visibility
          ioManager.inputs.topo.data.layers_generated.ele.gavail = lyrs.generate_ele[1] || inputs.ele_use_graded_data;

          ioManager.inputs.topo.data.layers_generated.NS.avail = ioManager.inputs.topo.data.layers_generated.NS.avail || lyrs.generate_ns[0];
          ioManager.inputs.topo.data.layers_generated.EW.avail = ioManager.inputs.topo.data.layers_generated.EW.avail || lyrs.generate_ew[0];
          ioManager.inputs.topo.data.layers_generated.U.avail = ioManager.inputs.topo.data.layers_generated.U.avail || lyrs.generate_u[0];
          ioManager.inputs.topo.data.layers_generated.NS.gavail = ioManager.inputs.topo.data.layers_generated.NS.gavail || lyrs.generate_ns[1];
          ioManager.inputs.topo.data.layers_generated.EW.gavail = ioManager.inputs.topo.data.layers_generated.EW.gavail || lyrs.generate_ew[1];
          ioManager.inputs.topo.data.layers_generated.U.gavail = ioManager.inputs.topo.data.layers_generated.U.gavail || lyrs.generate_u[1];

          ioManager.inputs.topo.data.layers_generated.NS.limit = ioManager.inputs.topo.data.ns_grade_limit;
          ioManager.inputs.topo.data.layers_generated.EW.limit = ioManager.inputs.topo.data.ew_grade_limit;
          ioManager.inputs.topo.data.layers_generated.U.limit = ioManager.inputs.topo.data.u_grade_limit;

          ioManager.inputs.topo.data.ele_use_graded_data = false;
          ioManager.inputs.topo.data.u_grade_enabled = false;
          ioManager.inputs.topo.data.u_grade_raw_enabled = false;
          ioManager.inputs.topo.data.ns_grade_enabled = false;
          ioManager.inputs.topo.data.ns_grade_raw_enabled = false;
          ioManager.inputs.topo.data.ew_grade_enabled = false;
          ioManager.inputs.topo.data.ew_grade_raw_enabled = false;

          let def_layer = ioManager.inputs.racking.data.type == 1 ? "NS" : "EW";

          if (ioManager.inputs.topo.data.grading.generated && ioManager.inputs.topo.data.layers_generated[def_layer].gavail) {
            // switch it to the graded layers
            ioManager.inputs.topo.data.topo_mode = `${def_layer}/G`;
          }

          let _mode_ext = `${ioManager.inputs.topo.data.topo_mode}/${ioManager.inputs.topo.data.layers_generated[def_layer].limit}`;
          ioManager.inputs.topo.data.topo_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
          ioManager.inputs.topo.data.topo_scale_url = `https://topo-tiles.sunfig.com/test/${ioManager.inputs.topo.data.topo_id}/${_mode_ext}/scale.png`;
        }
      }

      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            topo: {
              ...ioManager.inputs.topo,
              data: {
                ...ioManager.inputs.topo.data,
              },
            },
          },
        },
      };
    case siftConstants.GET_TOPO_DATA_ERROR:
      ioManager.inputs.topo.data.topo_loading = false;
      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            topo: {
              ...ioManager.inputs.topo,
              data: {
                ...ioManager.inputs.topo.data,
              },
            },
          },
        },
        error_messages: [...action.error],
      };

    case siftConstants.UPDATE_TOPO_MODE:
      return {
        ...state,
        ioManager: {
          ...ioManager,
          inputs: {
            ...ioManager.inputs,
            topo: {
              ...ioManager.inputs.topo,
              data: {
                ...ioManager.inputs.topo.data,
                topo_mode: action.data.topo_mode,
                topo_url: action.data.topo_url,
                topo_scale_url: action.data.topo_scale_url,
              },
            },
          },
        },
      };

    case siftConstants.UNDO_FEATURES:
    // let tempUndoFeatures;

    // ioManager.inputs.map.features = {};
    // let cur_index = Math.max(action.undoState.present - 1, 0);
    // tempUndoFeatures = JSON.parse(JSON.stringify(action.undoState.past[cur_index].features));

    // Object.values(tempUndoFeatures).map((feature) => {
    //   let id = create_UUID();
    //   // if (action.undoState.past.selectedFeatureId && action.undoState.past.selectedFeatureId == feature.properties['index']) {
    //   //   ioManager.inputs.map.selectedFeatureId = id;
    //   // }
    //   feature.properties["index"] = id;
    //   ioManager.inputs.map.features[id] = feature;
    // });
    // // if (action.undoState.present > 0) {
    // // } else {
    // //   ioManager.inputs.map.features = {};
    // // }

    // return {
    //   ...state,
    //   ioManager,
    // };

    case siftConstants.REDO_FEATURES:
    // let tempRedoFeatures;

    // if (action.redoState.future.length) {
    //   ioManager.inputs.map.features = {};
    //   tempRedoFeatures = JSON.parse(JSON.stringify(action.redoState.past[action.redoState.future[0]].features));
    //   Object.values(tempRedoFeatures).map((feature) => {
    //     let id = create_UUID();
    //     // if (action.redoState.past.selectedFeatureId && action.redoState.past.selectedFeatureId == feature.properties['index']) {
    //     //   ioManager.inputs.map.selectedFeatureId = id;
    //     // }
    //     feature.properties["index"] = id;
    //     ioManager.inputs.map.features[id] = feature;
    //   });
    // }

    // return {
    //   ...state,

    // };

    case siftConstants.POLL_FOR_ALERTS:
      // console.log('got poll response', action)
      let new_system_alerts = { ...state.system_alerts };
      new_system_alerts.poll_count += 1;

      if (action.response && action.response.alerts) {
        new_system_alerts.poll_message = action.response.alerts;
      }

      return {
        ...state,
        system_alerts: new_system_alerts,
      };

    case siftConstants.PREPARE_REPORT:
      let tempRepData = action.reportData;
      let boundaryArea = 0;
      let exclusionArea = 0;
      let inactiveArea = 0;

      Object.values(ioManager.inputs.map.features).forEach((feature) => {
        if (feature.properties.identity == 1) boundaryArea += getArea(feature.geometry.coordinates);
        if (feature.properties.identity == 2) exclusionArea += getArea(feature.geometry.coordinates);
        if (feature.properties.identity == 0) inactiveArea += getArea(feature.geometry.coordinates);
      });

      tempRepData["boundaryArea"] = boundaryArea;
      tempRepData["exclusionArea"] = exclusionArea;
      tempRepData["inactiveArea"] = inactiveArea;
      tempRepData["totalArea"] = boundaryArea + exclusionArea + inactiveArea;

      return {
        ...state,
        ioManager: {
          ...ioManager,
          report: {
            ...ioManager.report,
            preparingReport: action.preparingReport,
            reportData: {
              ...tempRepData,
            },
          },
        },
      };

    case siftConstants.UPDATE_REPORT_DATA:
      let tempReportData = state.ioManager.report;
      let tempUIState = state.ioManager.uiState;

      if (action.reportIsDone) {
        tempReportData.reportComplete = action.reportIsDone;
        tempReportData.preparingReport = false;
      }
      if (action.key == "reset") {
        tempReportData.reportComplete = false;
        tempReportData.preparingReport = false;
        tempUIState.show_live_report = false;
        tempReportData = {
          reportData: {},
          preparingReport: false,
          reportComplete: false,
        };
      } else {
        tempReportData.reportData[action.key] = action.value;
      }

      return {
        ...state,
        ioManager: {
          ...ioManager,
          report: tempReportData,
          uiState: tempUIState,
        },
      };
    // // START REQUEST EVENTS
    // case accountConstants.CAPACITY_TOOL_REQUEST:
    //   return {
    //     ...state,
    //     // run_id: undefined,
    //     // canceling: false,
    //     generating: false,
    //       count: 0,
    //       local_run_count: state.local_run_count + 1,
    //   }
    // case accountConstants.CAPACITY_TOOL_SUCCESS:

    //   var code = action.response.code;
    //   return {
    //     ...state,
    //     // run_id: state.canceling ? undefined : run_id,
    //     generating: true,
    //       // canceling: state.canceling && false,
    //       code: state.canceling ? 0 : code,
    //   };
    // case accountConstants.CAPACITY_TOOL_FAILURE:
    //   return {
    //     ...initialState
    //   };

    //   // GET RESULTS EVENTS
    // case accountConstants.CAPACITY_RESULTS_REQUEST:
    //   return {
    //     ...state, count: 0
    //   };
    // case accountConstants.CAPACITY_RESULTS_UPDATE:
    //   var cnt = action.count;
    //   return {
    //     ...state, count: cnt
    //   };
    // case accountConstants.CAPACITY_RESULTS_SUCCESS:
    //   var data = action.response.data;
    //   var code = action.response.code;
    //   var if_error = action.response.error;

    //   return {
    //     ...state,
    //     // run_id:undefined,
    //     generating: false,
    //       data: data,
    //       code: code == 97 ? code : state.canceling ? 0 : code,
    //       code: code,
    //       // canceling: state.canceling && false,
    //       valid_reason: if_error,
    //       error_code: action.response.error_code,
    //       count: 0,
    //   };
    // case accountConstants.CAPACITY_RESULTS_FAILURE:
    //   return {
    //     ...state, valid_reason: 'Connection Failure. Please try again later.', code: 97, count: 0
    //   };

    //   // GET RACK/ROAD EVENTS
    // case accountConstants.CAPACITY_RR_REQUEST:
    //   return {
    //     ...state, autolayout: undefined
    //   };
    // case accountConstants.CAPACITY_RR_SUCCESS:
    //   var racks = action.response.racks;
    //   var roads = action.response.roads;
    //   var inverters = action.response.inverters;
    //   return {
    //     ...state,
    //     autolayout: {
    //       racks: racks,
    //       roads: roads,
    //       inverters: inverters
    //     }
    //   };
    // case accountConstants.CAPACITY_RR_FAILURE:
    //   return {
    //     ...state,
    //     valid_reason: 'Connection Failure. Please try again later.',
    //       code: 97,
    //       autolayout: {
    //         racks: [],
    //         roads: [],
    //         inverters: []
    //       }
    //   };

    //   // GET 8760 FILE EVENTS
    // case accountConstants.SIFT_FILE_REQUEST:
    //   return {
    //     ...state, result_url: undefined
    //   };
    // case accountConstants.SIFT_FILE_SUCCESS:
    //   return {
    //     ...state,
    //     result_url: action.response
    //   };
    // case accountConstants.SIFT_FILE_FAILURE:
    //   return {
    //     ...state,
    //     result_url: undefined
    //   };

    // case accountConstants.SIFT_FILE_CLEAR:
    //   return {
    //     ...state,
    //     result_url: undefined
    //   };

    // case accountConstants.DASH_CREATEDXF_CLEAR:
    //   return {
    //     ...state,
    //     dxf_url: undefined
    //   };

    // case accountConstants.DASH_LOADPROJECT:
    //   return {
    //     ...state, current_project: action.project, option: action.option
    //   }
    // case accountConstants.DASH_CLEARAUTOLAYOUT_CLEAR:
    //   return {
    //     ...state, autolayout: undefined
    //   }

    // // // SAVE PROJECT EVENTS
    // case accountConstants.DASH_SAVEPROJECT_REQUEST:
    //   return {
    //     ...state, projectId: undefined
    //   };
    // case accountConstants.DASH_SAVEPROJECT_SUCCESS:
    //   var projectId = action.response.project;
    //   // console.log(action.response.project)
    //   return {
    //     ...state, projectId: projectId
    //   };
    //   // case accountConstants.DASH_SAVEPROJECT_FAILURE:
    //   //   return { ...initialState };

    //   // RUN WEATHER EVENTS
    // case accountConstants.DASH_RUNWEATHER_REQUEST:
    //   return {
    //     ...state, weather_id: undefined,
    //   };
    // case accountConstants.DASH_RUNWEATHER_SUCCESS:
    //   var weatherId = action.response.weather_id;
    //   return {
    //     ...state,
    //     weather_id: weatherId
    //   };
    // case accountConstants.DASH_RUNWEATHER_FAILURE:
    //   return {
    //     ...initialState
    //   };

    //   // GET WEATHER SUMMARY EVENTS
    // case accountConstants.DASH_GETWEATHER_REQUEST:
    //   return {
    //     ...state
    //   };
    // case accountConstants.DASH_GETWEATHER_SUCCESS:
    //   var summ = action.response.data;
    //   return {
    //     ...state,
    //     weather_id: undefined,
    //       summary: summ
    //   };
    // case accountConstants.DASH_GETWEATHER_FAILURE:
    //   return {
    //     ...initialState
    //   };

    //   // VALIDATE INPUTS EVENTS
    // case accountConstants.DASH_VALIDATEINPUTS_REQUEST:
    //   return {
    //     ...state,
    //     canceling: false,
    //       validating_inputs: true,
    //       inputs_valid: false,
    //       valid_reason: undefined,
    //       data: [],
    //       code: 0,
    //       autolayout: undefined,
    //       count: 0
    //   };
    // case accountConstants.DASH_VALIDATEINPUTS_SUCCESS:
    //   var run_id = action.response.run_id
    //   // var valid = action.response.valid;
    //   // var reason = action.response.reason;
    //   return {
    //     ...state,
    //     run_id: state.canceling ? undefined : run_id,
    //       // inputs_valid: valid,
    //       // valid_reason: reason,
    //       // validating_inputs: false,
    //   };
    // case accountConstants.DASH_VALIDATEINPUTS_FAILURE:
    //   return {
    //     ...state,
    //     // run_id:undefined,
    //     generating: false,
    //       data: [],
    //       code: state.canceling ? 0 : 97,
    //       canceling: state.canceling && false,
    //       valid_reason: 'Input Validation Failed. Check Inputs.',
    //   };

    //   // GET TOPO DATA
    // case accountConstants.DASH_GETTOPO_SUCCESS:
    //   var topo_id = action.response.topo_id;
    //   return {
    //     ...state,
    //     topo_id: topo_id,
    //       topo_req_status: 99
    //   };
    // case accountConstants.DASH_GETTOPO_FAILURE:
    //   return {
    //     ...state, topo_id: undefined
    //   }

    // default
    default:
      return state;
  }
}

// takes in ioManager - only used by bug report
export function getProjectInputs(ioManager) {
  // console.log(ioManager)
  let inputs = {
    lat: ioManager.inputs.map.map_center[0],
    lng: ioManager.inputs.map.map_center[1],
    tz: 0,
    ele: 0,

    features: ioManager.inputs.map.features,
    module: ioManager.inputs.module,
    inverter: ioManager.inputs.inverter,
    racking: ioManager.inputs.racking,
    config: ioManager.inputs.config,
    weather: ioManager.inputs.weather,
    performance: ioManager.inputs.performance,
    layout: ioManager.inputs.layout,

    finance: ioManager.inputs.finance,

    topo: ioManager.inputs.topo.data.topo_live
      ? ioManager.inputs.topo
      : // ? {
        //     id: 'topo',
        // 		data: {
        // 			topo_source: ioManager.inputs.topo.data.topo_source,
        // 			topo_live: ioManager.inputs.topo.data.topo_live,
        // 			topo_url: ioManager.inputs.topo.data.topo_url,
        // 			topo_scale_url: ioManager.inputs.topo.data.topo_scale_url,
        // 			topo_id: ioManager.inputs.topo.data.topo_id,
        // 			topo_action: ioManager.inputs.topo.data.topo_action,
        // 			topo_error: undefined,
        // 			topo_mode: ioManager.inputs.topo.data.topo_mode,
        // 			ns_grade_limit: ioManager.inputs.topo.data.ns_grade_limit,
        // 			ew_grade_limit: ioManager.inputs.topo.data.ew_grade_limit,
        // 			u_grade_limit: ioManager.inputs.topo.data.u_grade_limit,
        // 			layers_generated: {
        // 				ele: { avail: true },
        // 				NS: { avail: true, limit: parseFloat(ioManager.inputs.topo.data.ns_grade_limit) },
        // 				EW: { avail: true, limit: parseFloat(ioManager.inputs.topo.data.ew_grade_limit) },
        // 				U: { avail: true, limit: parseFloat(ioManager.inputs.topo.data.u_grade_limit) },
        // 			},
        // 		}
        //   }
        undefined,
  };

  // console.log(inputs)
  return inputs;
}

export function getSIFTInputs(inputs, plan) {
  // console.log(inputs)
  let inputValidation = validateInputs({
    totalArea: inputs.map.totalArea,
    coord_system_bbox: inputs.map.coord_system_bbox,
    currentPlan: plan,
    features: Object.values(inputs.map.features).filter((feature) => feature.properties.identity > 0),
    racking: {
      ...inputs.racking.data,
      do_dualTilt: inputs.racking.data.type == 2 ? 1 : 0,
      tilts: inputs.racking.data.type == 0 ? getTilts(parseFloat(inputs.racking.data.tilt_min), parseFloat(inputs.racking.data.tilt_max), parseFloat(inputs.racking.data.tilt_inc)) : undefined,
      type: inputs.racking.data.type == 2 ? 0 : inputs.racking.data.type,
      tilt_max: inputs.racking.data.type == 2 ? inputs.racking.data.tilt_min : inputs.racking.data.tilt_max,
    },
    module: { ...inputs.module.data },
    inverter: { ...inputs.inverter.data },
    performance: {
      ...inputs.performance.data,

      weather: inputs.weather.data.weather_summary && inputs.weather.data.weather_id,
      weather_source: inputs.weather.data.source,
      lat: parseFloat(inputs.weather.data.search_lat),
      lng: parseFloat(inputs.weather.data.search_lng),
      tz: parseInt(inputs.weather.data.search_tz),
      ele: parseFloat(inputs.weather.data.search_ele),
      center: inputs.map.center_point || inputs.map.map_center,
      locationId: inputs.weather.data.locationId,
    },
    finance: { ...inputs.finance.data },
    config: {
      ...inputs.config.data,
      ...inputs.layout.data,
    },
    topo: {
      do_topo: inputs.topo.data.topo_live && inputs.topo.data.topo_action === "delete",
      // do_topo: inputs.topo.topo_action == 'nothing' ? 0 : 1,
      topo_id: inputs.topo.data.topo_id,
      topo_action: inputs.topo.data.topo_action,
      grade_limit: inputs.racking.data.grade_limit,
      // grade_limit: inputs.racking.type == 0 ? inputs.topo.ew_grade_limit : inputs.topo.ns_grade_limit,
    },
    weather: { ...inputs.weather.data },
  });

  // console.log(inputValidation)
  return inputValidation;
}
