const validateFinance = (input = undefined, inputs) => {
  let temp_inputs = { ...inputs };

  let errors = [];

  if (temp_inputs.do_finance === 1) {
    if (temp_inputs.analysis_period <= 0 || temp_inputs.analysis_period > 50) {
      errors.push({
        keyword: "validateFinance",
        message: "Invalid Analysis Period. In the Finance Tab, Analysis Period must be between 1 and 50 years. You may need to toggle off Custom Schedule to change the Analysis Period field.",
      });
    }
    if (temp_inputs.metric === 1 && temp_inputs.debt_structure > 0 && temp_inputs.analysis_period < temp_inputs.debt_tenor) {
      errors.push({
        keyword: "validateFinance",
        message: "Invalid Debt Tenor. In the Finance Tab, Debt Tenor must be less than or equal to Analysis Period.",
      });
    }
  }

  return errors.length > 0 ? errors : true;
};

export { validateFinance };
