import config from "config";
import { authHeader } from "../_helpers";
import { refreshToken } from "../_reducers";
import fetchUtil from "./fetchUtil";

export const siftService = {
  getProjects,
  save_project_inputs,
  getElevation,
  sendBugReport,
  addSavedResult,
  removeSavedResult,
  // sift run
  getRunId,
  initSIFT,
  runSIFT,
  getResults,
  pingImportedDataStatus,
  cancelRun,
  getLayout,
  getEditableLayout,
  downloadFromS3,
  saveInput,
  // topo
  getTopoId,
  checkTopoId,
  shareCollab,
  refreshCollab,

  offsetPolygon,
  runTopo,
  checkTopoId2,

  pollForAlerts,

  _init,
  _getResults,
};

function getProjects() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "GET",
    //headers: authHeader(),
  };
  //return fetch(`${config.apiUrl}/dash/project/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/project/`, requestOptions);
}

async function save_project_inputs(inputs) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };
  console.log("here", `${config.apiUrl}/dash/project/`);
  //return await fetch(`${config.apiUrl}/dash/project/`, requestOptions).then((response) => response.json());
  return await fetchUtil(`${config.apiUrl}/dash/project/`, requestOptions);
}

async function getElevation(latlng) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(latlng),
  };

  //return fetch(`${config.apiUrl}/dash/swm/eletz`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/eletz`, requestOptions);
}

async function getCountyState(lat, lon) {
  return fetchUtil(`https://geo.fcc.gov/api/census/area?lat=${lat}&lon=${lon}&format=json`, {
    method: "GET",
  });
}

function sendBugReport(params) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      message: params.message,
      inputs: params.inputs,
    }),
  };
  //return fetch(`${config.apiUrl}/dash/bug/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/bug/`, requestOptions);
}

async function getRunId() {
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/init`, {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  return await response.json();
}

async function initSIFT(inputs) {
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
      validate: true,
    }),
  });

  return await response.json();
}

async function runSIFT(inputs) {
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  });

  return await response.json();
}

async function getResults(id) {
  // console.log('_init ID', id);
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  return await response.json();
}

async function pingImportedDataStatus(id) {
  // console.log('_init ID', id);

  let response = await fetchUtil(`${config.apiUrl}/dash/import/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  });
  return await response;
}

async function cancelRun(job_id) {
  let response = await fetchUtil(`${config.apiUrl}/dash/jobs/`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      job_id,
      cancel: true,
    }),
  });

  return await response;
}

async function getRacks(id) {
  let token = await localStorage.getItem("access");
  if (await verifyToken(token)) {
    // GET https://localhost:8000/platform/rbi/result/?runId=a34ae313fc87420295e9417d61d18543&action=results
    let response = await fetchUtil(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return await response.json();
  } else {
    let refresh = localStorage.getItem("refresh");
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetchUtil(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
        method: "GET",
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${access}`,
        },
      });

      return await response.json();
    }
  }
}

function getLayout(id) {
  const requestOptions = {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  //return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions);
}

function getEditableLayout(id) {
  const requestOptions = {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  //return fetch(`${config.apiUrl}/dash/swm/layoutedit?resultId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/layoutedit?resultId=${id}`, requestOptions);
}

function addSavedResult(inputs_for_saved_result) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(inputs_for_saved_result),
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  //return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/save_result`, requestOptions);
}

function removeSavedResult(inputs_for_saved_result) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(inputs_for_saved_result),
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  //return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/delete_result`, requestOptions);
}

function downloadFromS3(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "attachment" },
  };
  return fetch(url, requestOptions).then(handleResponse);
  // return fetchUtil(url, requestOptions,false);
}

function downloadFromS3toPC(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Disposition": "attachment" },
  };
  //return fetch(url, requestOptions);
  return fetchUtil(url, requestOptions, false);
}

function saveInput(input, type) {
  // console.log("ineput", input);
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  //return fetch(`${config.apiUrl}/dash/${type}/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/${type}/`, requestOptions);
}

async function getTopoId(inputs) {
  // console.log("oin[its", inputs);
  // let response = await fetch(`https://api.sunfig.solar/swa-test/dash/topo/`, {
  let response = await fetchUtil(`${config.apiUrl}/dash/topo/`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...inputs }),
  });

  // console.log(responseJson);
  return await response.json();
}

async function checkTopoId(id) {
  let response = await fetchUtil(`${config.apiUrl}/dash/topo/?topo_id=${id}`, {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  return await response.json();
}

async function shareCollab(inputs) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };
  //return fetch(`${config.apiUrl}/dash/swm/collab`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/collab`, requestOptions);
}

async function refreshCollab(project_id) {
  const requestOptions = {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  //return fetch(`${config.apiUrl}/dash/swm/collab/?project_id=${project_id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/collab/?project_id=${project_id}`, requestOptions);
}

async function offsetPolygon(polygon, offset) {
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/offset`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ polygon, offset }),
  });

  return await response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    // console.log(response)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        location.reload(true);
      }

      // console.log(data)
      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;

      // console.log(error)
      return Promise.reject(error);
    }

    return data;
  });
}

async function runTopo(inputs) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };
  //return fetch(`${config.apiUrl}/dash/swm/topo/`, requestOptions).then((response) => response.json());
  return fetchUtil(`${config.apiUrl}/dash/swm/topo/`, requestOptions);
}

async function checkTopoId2(id) {
  const requestOptions = {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  //let response = await fetch(`${config.apiUrl}/dash/swm/topo/?topo_id=${id}`, requestOptions);
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/topo/?topo_id=${id}`, requestOptions);
  return await response.json();
}

async function pollForAlerts() {
  const requestOptions = {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  //let response = await fetch(`${config.apiUrl}/dash/swm/alerts`, requestOptions);
  let response = await fetchUtil(`${config.apiUrl}/dash/swm/alerts`, requestOptions);
  return await response.json();
}

async function log_downlaod(download_type, url, project_id) {
  // let response = await fetch(`${config.apiUrl}/dash/log_download/`, {
  //   method: "POST",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify({ download_type, url, project_id }),
  // });

  // let responseJson = await response.json();
  // return responseJson;
  return true;
}

//canopy
// async function _canopy_init(inputs) {
//   let response = await fetch(`https://api.sunfig.com/swa-exp/dash/swm/canopy`, {
//     method: "POST",
//     headers: { ...authHeader(), "Content-Type": "application/json" },
//     body: JSON.stringify({
//       ...inputs,
//     }),
//   });

//   let responseJson = await response.json();
//   return responseJson;
// }

// async function get_canopy_results(id) {
//   // console.log('_init ID', id);
//   let response = await fetch(`https://api.sunfig.com/swa-exp/dash/swm/?runId=${id}`, {
//     // mode: 'no-cors',
//     method: "GET",
//     headers: { ...authHeader(), "Content-Type": "application/json" },
//   });

//   let responseJson = await response.json();
//   // console.log("response", responseJson);
//   return responseJson;
// }

async function _init(inputs) {
  let response = await fetchUtil(`https://api.sunfig.com/swa-exp/dash/swm/canopy`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  });

  return await response.json();
}

async function _getResults(id) {
  // console.log('_init ID', id);
  let response = await fetchUtil(`https://api.sunfig.com/swa-exp/dash/swm/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  // console.log("response", responseJson);
  return await response.json();
}
