const environmental_shade_schema = {
  type: "object",
  tab: "Environmental Shade",
  properties: {
    shade_objects: {
      type: "object",
      properties: {
        _id: {
          type: "string",
        },
        geoJson: {
          type: "object",
          properties: {
            _id: {
              type: "string",
            },
            type: {
              type: "string",
            },
            properties: {
              type: "object",
              properties: {
                circleRadius: {
                  type: "number",
                },
                shape: {
                  type: "string",
                },
              },
            },
            geometry: {
              type: "object",
              properties: {
                coordinates: {
                  type: "array",
                  items: {
                    type: "array",
                    items: {
                      type: "array",
                      items: {
                        type: "number",
                      },
                      minItems: 2,
                      maxItems: 2,
                    },
                  },
                },
                type: {
                  type: "string",
                },
              },
            },
          },
        },
        corners: {
          type: "array",
          items: {
            type: "array",
            items: {
              type: "number",
            },
            minItems: 2,
            maxItems: 2,
          },
        },
        center: {
          type: "array",
          items: {
            type: "number",
          },
        },
        height: {
          type: "integer",
          minimum: 0,
        },
        obj_type: {
          type: "string",
        },
        shape: {
          type: "string",
        },
        type: {
          type: "string",
        },
        checked: {
          type: "boolean",
        },
        radius: {
          type: "number",
        },
      },
      en_shade_impact: { label: "Enable Shade Impact", _id: "en_shade_impact", type: "number" },
      shade_height_unit: { _id: "shade_height_unit", type: "string" },
      env_shade__id: {
        type: "string",
        pattern: "^[a-f0-9]{32}$",
      },
    },
  },
};

export { environmental_shade_schema };
