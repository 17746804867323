import { inputsConstants, UserConstants } from "../_constants";
import _ from "lodash";
import { inputsInitialState, weather_reset, default_topo, map_to_1_8 } from "./sift_inputs";
import { getBounds, getNewCenterPoint, getCenterOfLineString, uuid, getCenterPoint, calculateTotalArea, create_UUID, getCenterPointofFeature } from "../_helpers";

// check platform
import config from "config";
let run_side_effects;

if (config.platform === "internal_sift") {
  run_side_effects = require("./utils").run_side_effects;

  // run_side_effects = require("./utils").run_internal_sift_side_effects;
} else {
  run_side_effects = require("./utils").run_side_effects;
}

// console.log(JSON.parse(JSON.stringify(inputsInitialState)));

export function inputs(state = JSON.parse(JSON.stringify(inputsInitialState)), action) {
  switch (action.type) {
    case inputsConstants.SELECT_PRESET_BY_INDEX:
      console.log(action.preset);
      // console.log('module selected', JSON.parse(JSON.stringify(defaultModules[action.index])))
      let new_mod_inputs = {
        ...state,
        // ...JSON.parse(JSON.stringify(defaultModules[action.index])),
        // uiState: {
        // 	...state.uiState,
        // 	selected_module_index: action.index
        // }
      };
      return new_mod_inputs;

    case inputsConstants.UPDATE_INPUT_UI_STATE:
      // console.log("uistate updated", action.key, action.value);

      let new_ui_state = {
        ...state.uiState,
        [action.key]: action.value,
      };

      switch (action.key) {
        case "topo_loading":
          new_ui_state.topo_error = "";
          break;
        case "show_azi":
          new_ui_state.show_dims = false;
          break;
        case "show_dims":
          new_ui_state.show_azi = false;
          break;
        default:
          break;
      }

      // if (action.key === "topo_loading") {
      //   // indicates some topo job has started
      //   new_ui_state.topo_error = "";
      // }

      // if (action.key == "update_topo_grading_checks") {
      //   let topo_check_boxes = {
      //     ele_ungraded_checked: false,
      //     ele_graded_checked: false,
      //     ns_ungraded_checked: false,
      //     ns_graded_checked: false,
      //     ew_ungraded_checked: false,
      //     ew_graded_checked: false,
      //     max_ungraded_checked: false,
      //     max_graded_checked: false,
      //   };
      //   new_ui_state = { ...new_ui_state, ...topo_check_boxes, [action.value.key]: action.value.checked };
      // }

      return { ...state, uiState: new_ui_state };

    case UserConstants.GET_USER_LIB_COMP:
      // console.log("user data pulled -- lets run side effects please", action);

      let new_user_inputs = { ...state };
      new_user_inputs = run_side_effects(new_user_inputs, ["all"]);

      return new_user_inputs;

    case inputsConstants.UPDATE_INPUT_BY_NAME:
    case inputsConstants.UPDATE_BULK_INPUTS:
      // console.log("input updated", action);

      let new_input_state;
      let inputs_keys = [];

      if (action.type === "UPDATE_INPUT_BY_NAME") {
        inputs_keys.push(action.key);
        new_input_state = {
          ...state,
          changed_input_warning: !!state.res_generated,
          [action.key]: action.value,
        };
      }
      if (action.type === "UPDATE_BULK_INPUTS") {
        inputs_keys = Object.keys(action.bulk_inputs);
        new_input_state = {
          ...state,
          changed_input_warning: !!state.res_generated,
          ...action.bulk_inputs,
        };
      }

      new_input_state = run_side_effects(new_input_state, inputs_keys);

      return new_input_state;

    case inputsConstants.UPDATE_OFFSET_TYPE:
      return {
        ...state,
        [action.key]: action.value,
      };

    case inputsConstants.UPDATE_SITE_INPUT:
      let new_site_inputs = { site_features: { ...state.site_features }, selectedFeatureId: state.selectedFeatureId, uiState: { ...state.uiState } };
      let recalculate_bounds = true;

      if (action.key === "add_site_feature") {
        // new_site_inputs = { site_features: { ...state.site_features } };
        new_site_inputs.site_features[action.value.properties.index] = action.value;
        new_site_inputs.selectedFeatureId = action.value.properties.index;
        new_site_inputs.uiState.is_drawing = false;
      }
      if (action.key === "edit_site_feature") {
        // new_site_inputs = { site_features: { ...state.site_features } };
        new_site_inputs.site_features[action.value.properties.index] = action.value;
        new_site_inputs.selectedFeatureId = action.value.properties.index;
      }
      if (action.key === "remove_site_feature") {
        // new_site_inputs = { site_features: { ...state.site_features } };
        delete new_site_inputs.site_features[action.value];
        new_site_inputs.selectedFeatureId = new_site_inputs.selectedFeatureId == action.value ? undefined : action.value;
      }

      if (action.key === "add_bulk_features") {
        new_site_inputs = {
          ...new_site_inputs,
          site_features: {
            ...new_site_inputs.site_features,
            ...action.value,
          },
        };
      }

      if (action.key === "overwrite_bulk") {
        console.log(action);
        new_site_inputs = {
          ...new_site_inputs,
          site_features: {
            ...action.value,
          },
        };
      }

      if (action.key === "add_kmz_features") {
        // new_site_inputs = { site_features: { ...state.site_features } };
        Object.values(action.value).map((feature) => {
          //
          let new_id = uuid();
          // clear out properties so we don't get tainted data
          feature.properties = {
            index: new_id,
            identity: feature.properties.identity || 0,
            active: true,
          };
          //
          new_site_inputs.site_features[new_id] = feature;
        });
        new_site_inputs.recenter = 0;
      }

      if (action.key === "selectedFeatureId") {
        // new_site_inputs = { site_features: { ...state.site_features } };
        recalculate_bounds = false;
        new_site_inputs.selectedFeatureId = action.value;
      }

      if (recalculate_bounds) {
        let new_bounds = getBounds(new_site_inputs.site_features);
        new_site_inputs.boundary_bbox = new_bounds;
        // set latitude / longitude -- which are the "site_center lat/lng"
        new_site_inputs.latitude = new_bounds[1] + Math.abs(new_bounds[3] - new_bounds[1]) / 2;
        new_site_inputs.longitude = new_bounds[0] + Math.abs(new_bounds[2] - new_bounds[0]) / 2;
        // console.log(new_site_inputs.boundary_bbox, new_site_inputs.latitude, new_site_inputs.longitude)
      }

      return { ...state, ...new_site_inputs };

    case inputsConstants.LOAD_PROJECT_FROM_INPUTS:
      // console.log("LOAD_PROJECT_FROM_INPUTS", action);
      let project_inputs = JSON.parse(JSON.stringify(action.response));
      console.log("project_inputs", project_inputs);
      let fixed_inputs = JSON.parse(JSON.stringify(state));

      if (project_inputs.error) {
        console.log("🔥 🔥", project_inputs.error);
      } else if (project_inputs) {
        let temp_inputs = project_inputs;
        fixed_inputs = map_to_1_8(temp_inputs, fixed_inputs, action.current_plan);
        fixed_inputs.project_name = action.project_meta.project_name;
        fixed_inputs.variant_name = action.project_meta.variant_name;
        fixed_inputs.project_id = action.project_meta.project_id;
        fixed_inputs.variant_id = action.project_meta.variant_id;
        fixed_inputs.resave_project = false;
        // fixed_inputs.is_simplified = fixed_inputs.is_simplified ? fixed_inputs.is_simplified : 0;
        // fixed_inputs.simple_module = fixed_inputs.simple_module ? fixed_inputs.simple_module : 0;
        // fixed_inputs.inverter = fixed_inputs.inverter ? fixed_inputs.inverter : 1;
        // fixed_inputs.portfolio_sweep = fixed_inputs.portfolio_sweep ? fixed_inputs.portfolio_sweep : 1;
        // fixed_inputs.mod_target = fixed_inputs.mod_target ? fixed_inputs.mod_target : 0;
        // fixed_inputs.mwh_target = fixed_inputs.mwh_target ? fixed_inputs.mwh_target : 0;
        // fixed_inputs.do_continuous = fixed_inputs.do_continuous ? fixed_inputs.do_continuous : 0;
        // fixed_inputs.design_temp = fixed_inputs.design_temp ? fixed_inputs.design_temp : -10;

        if (_.isUndefined(fixed_inputs.en_topo_perf)) {
          fixed_inputs.en_topo_perf = 0;
        }
        if (!_.isUndefined(fixed_inputs.id)) {
          delete fixed_inputs.id;
        }
        if (!_.isUndefined(fixed_inputs.name)) {
          delete fixed_inputs.name;
        }
        if (typeof fixed_inputs.en_bifacial == "boolean") {
          fixed_inputs.en_bifacial = fixed_inputs.en_bifacial ? 1 : 0;
        }
        if (typeof fixed_inputs.derate_toggle == "boolean") {
          fixed_inputs.derate_toggle = fixed_inputs.derate_toggle ? 1 : 0;
        }

        fixed_inputs.recenter = state.recenter + 1;
        if (Object.keys(fixed_inputs.site_features).length > 0) {
          fixed_inputs.recenter += state.recenter;
          // Object.keys(fixed_inputs.site_features).map((feature) => {});
        }
        // console.log(fixed_inputs);
        if (action.project_meta.collab_on == 1) {
          fixed_inputs.collab_on = 1;
          fixed_inputs.creator = action.project_meta.creator;
          fixed_inputs.locked = action.project_meta.locked;
          fixed_inputs.locked_by_email = action.project_meta.locked_by_email;
          fixed_inputs.locked_by_me = action.project_meta.locked_by_me;
          fixed_inputs.notes = action.project_meta.notes;
          fixed_inputs.user_list = action.project_meta.user_list;
        }

        if (fixed_inputs.selectedFeatureId) {
          fixed_inputs.selectedFeatureId = undefined;
        }

        fixed_inputs = run_side_effects(fixed_inputs);
        fixed_inputs.uiState.collab_modal_visible = { visible: false, project_id: "" };
        fixed_inputs.uiState.topo_loading = false;
      }

      return fixed_inputs;

    case inputsConstants.RESET_INPUTS_TO_DEFAULT:
      return { ...JSON.parse(JSON.stringify(inputsInitialState)) };

    case inputsConstants.IMPORT_INPUTS:
      let import_error;
      if (action.response.error || (action.response.s3contents && action.response.s3contents.error && action.response.s3contents.error !== "")) {
        // error happened
        import_error = "The KMZ or KML file failed to load. You may want to check the layers in Google Earth. For support, please send the KMZ to the SIFT team.";
        console.log(import_error);
        return state;
      }

      let prod_data = JSON.parse(JSON.stringify(action.response.s3contents));
      // console.log(prod_data);
      let new_input_object = {};
      let updated_keys = [];

      if (action.response.tab === "map") {
        new_input_object = { site_features: { ...state.site_features } };
        Object.values(prod_data.features).map((feature) => {
          //
          let new_id = uuid();
          // clear out properties so we don't get tainted data
          feature.properties = {
            index: new_id,
            identity: feature.properties.identity || 0,
            center: feature.geometry.type == "Polygon" ? getCenterPointofFeature(feature.geometry.coordinates) : getCenterOfLineString(feature),
            sortIndex: feature.properties.sortIndex ? feature.properties.sortIndex : 1,
            name: feature.properties.name ? feature.properties.name : feature.properties.identity == 1 ? "Boundary1" : feature.properties.identity == 2 ? "Exclusions2" : "Inactive0",
            active: true,
          };
          //
          new_input_object.site_features[new_id] = feature;
        });

        let poly_features = Object.values(new_input_object.site_features).filter((feature) => feature.geometry.type == "Polygon");

        // recenter map
        new_input_object.recenter = state.recenter;
        if (Object.keys(poly_features).length > 0) {
          new_input_object.boundary_bbox = getBounds(poly_features);
          // set latitude / longitude -- which are the "site_center lat/lng"
          new_input_object.latitude = new_input_object.boundary_bbox[1] + Math.abs(new_input_object.boundary_bbox[3] - new_input_object.boundary_bbox[1]) / 2;
          new_input_object.longitude = new_input_object.boundary_bbox[0] + Math.abs(new_input_object.boundary_bbox[2] - new_input_object.boundary_bbox[0]) / 2;

          new_input_object.map_center = getCenterPoint(poly_features);
          new_input_object.recenter += 1;
          new_input_object.totalArea = calculateTotalArea(poly_features);
        } else {
          new_input_object.totalArea = 0;
          // new_input_object.boundary_bbox = undefined;
        }
      }
      // console.log(new_input_object);

      if (action.response.tab === "module") {
        new_input_object = {
          simple_module: 0,
          mod_name: prod_data.name,
          mod_id: prod_data.id,
          mod_rating: prod_data.rating,
          mod_width: prod_data.width,
          mod_height: prod_data.height,
          mod_pnom: prod_data.pnom,
          // module_area: prod_data.module_area,
          mlm_N_series: prod_data.mlm_N_series,
          mlm_N_parallel: prod_data.mlm_N_parallel,
          mlm_N_diodes: prod_data.mlm_N_diodes,
          mlm_V_mp_ref: prod_data.mlm_V_mp_ref,
          mlm_I_mp_ref: prod_data.mlm_I_mp_ref,
          mlm_V_oc_ref: prod_data.mlm_V_oc_ref,
          mlm_I_sc_ref: prod_data.mlm_I_sc_ref,
          mlm_S_ref: prod_data.mlm_S_ref,
          mlm_T_ref: prod_data.mlm_T_ref,
          mlm_R_shref: prod_data.mlm_R_shref,
          mlm_R_sh0: prod_data.mlm_R_sh0,
          mlm_R_shexp: prod_data.mlm_R_shexp,
          mlm_R_s: prod_data.mlm_R_s,
          mlm_alpha_isc: prod_data.mlm_alpha_isc,
          mlm_beta_voc_spec: prod_data.mlm_beta_voc_spec,
          mlm_n_0: prod_data.mlm_n_0,
          mlm_mu_n: prod_data.mlm_mu_n,
          mlm_T_c_fa_alpha: prod_data.mlm_T_c_fa_alpha,
          mlm_E_g: prod_data.mlm_E_g,
          module_iam_ang: prod_data.module_iam_ang,
          module_iam_eff: prod_data.module_iam_eff,

          muPmpReq: prod_data.muPmpReq,
          mlm_mu_n_perc: prod_data.mlm_mu_n_perc,
          technology: parseInt(prod_data.technology),
          mlm_D2MuTau: prod_data.mlm_D2MuTau && prod_data.mlm_D2MuTau !== "" ? prod_data.mlm_D2MuTau : 0,

          en_bifacial: prod_data.module_isbifacial,
          bi_bifaciality: prod_data.module_bifaciality,

          spi_min_limit: parseInt(Math.floor((state.inverterRating * 0.95) / ((state.mod_per_string * prod_data.rating) / 1000))),
          spi_max_limit: parseInt(Math.ceil((state.inverterRating * 3.01) / ((state.mod_per_string * prod_data.rating) / 1000))),
        };

        updated_keys = ["mod_width", "mod_height", "mod_rating"];
      }

      if (action.response.tab === "inverter") {
        new_input_object = {
          inv_name: prod_data.name,
          inv_id: prod_data.id,
          inv_rating: prod_data.inverterRating ? prod_data.inverterRating : 0,
          inv_pnom: prod_data.pnom ? prod_data.pnom : 0,

          derate_temps: prod_data.derate_temps,
          derate_maxoutput: prod_data.derate_maxoutput,
          derate_toggle: prod_data.derate_toggle,
          inv_pd_pacokw: prod_data.inv_pd_pacokw ? prod_data.inv_pd_pacokw : 0,
          maxEff: prod_data.maxEff ? prod_data.maxEff : 0,
          mppt_low_inverter: prod_data.mppt_low_inverter ? prod_data.mppt_low_inverter : 0,
          mppt_hi_inverter: prod_data.mppt_hi_inverter ? prod_data.mppt_hi_inverter : 0,
          inv_pd_vdcmax: prod_data.inv_pd_vdcmax ? prod_data.inv_pd_vdcmax : 0,
          inv_pd_pnt: prod_data.inv_pd_pnt ? prod_data.inv_pd_pnt : 0,
          pthresh: prod_data.pthresh && !Array.isArray(prod_data.pthresh) ? prod_data.pthresh : 0,
          imax_ac: prod_data.imax_ac,
          imax_dc: prod_data.imax_dc,
          inv_pd_pdco: prod_data.inv_pd_pdco,
          v1_pl: prod_data.v1_pl,
          v1_eff: prod_data.v1_eff,
          v2_pl: prod_data.v2_pl,
          v2_eff: prod_data.v2_eff,
          v3_pl: prod_data.v3_pl,
          v3_eff: prod_data.v3_eff,
          vs_effeu: prod_data.vs_effeu,
          vs_effmax: prod_data.vs_effmax,
          vs_vnom: prod_data.vs_vnom,

          inv_pd_eff_pout: prod_data.inv_pd_eff_pout,
          inv_pd_efficiency: prod_data.inv_pd_efficiency,

          spi_min_limit: parseInt(Math.floor((prod_data?.inverterRating * 0.95) / ((state.mod_per_string * state.mod_rating) / 1000))),
          spi_max_limit: parseInt(Math.ceil((prod_data?.inverterRating * 3.01) / ((state.mod_per_string * state.mod_rating) / 1000))),
        };
        updated_keys = ["inv_rating"];
      }

      if (action.response.tab === "weather") {
        new_input_object = {
          weather_id: prod_data.weather_id,
          weather_name: action.response.file_name,
          weather_source: prod_data.Source,
          weather_lat: prod_data.Latitude,
          weather_lng: prod_data.Longitude,
          weather_ele: prod_data.Elevation,
          weather_tz: prod_data.Timezone,
          locationId: prod_data.name,
          weather_summary: prod_data.Summary,
        };
      }

      let final_new_input_state = { ...state, ...new_input_object };

      if (updated_keys.length > 0) {
        final_new_input_state = run_side_effects(final_new_input_state, updated_keys);
      }

      // 	// console.log(new_object)
      // 	// update for weather/module/inverter
      // 	ioManager.dropdowns[action.response.tab][new_object.id] = new_object;
      // 	ioManager.inputs[action.response.tab] = JSON.parse(JSON.stringify(new_object));
      // }

      return final_new_input_state;

    case inputsConstants.RESET_WEATHER:
      console.log("weather_reset", weather_reset);
      let new_reset_weather_state = {
        ...state,
        ...weather_reset,
      };

      console.log("weather_reset", new_reset_weather_state);

      return new_reset_weather_state;

    case inputsConstants.CLEAR_TOPO_DATA:
      // by default we just clear all / reset to default topo
      let cleared_topo_data;

      if (action.which_data === "all") {
        cleared_topo_data = {
          ...state,
          ...default_topo.topo_state,
          boundary_bbox: state.boundary_bbox,
          uiState: { ...state.uiState, ...default_topo.uiState },
        };
      } else {
        cleared_topo_data = {
          ...state,
          [action.which_data]: false,
        };
      }

      if (action.which_data === "layers") {
        // if only clearing layers, hook up some older values
        cleared_topo_data.topo_id = state.topo_id;
        cleared_topo_data.graded_avail = state.graded_avail;
        cleared_topo_data.grade_target = state.grade_target;
        cleared_topo_data.grade_cut_amt = state.grade_cut_amt;
        cleared_topo_data.grade_fill_amt = state.grade_fill_amt;
        cleared_topo_data.topo_layout_surface = state.topo_layout_surface;
        cleared_topo_data.topo_layout_action = state.topo_layout_action;
      }
      return cleared_topo_data;

    case inputsConstants.TOPO_JOB_FAILURE:

    // INPUT TYPE JOB HANDLERS
    case inputsConstants.TOPO_JOB_COMPLETE:
      console.log("TOPO_JOB_COMPLETE", action);

      let topo_comp_uiState;
      let topo_comp_state;
      if (action.error) {
        // something failed
        topo_comp_uiState = {
          ...state.uiState,
          topo_loading: false,
          topo_error: "",
        };
      } else {
        // topo job complete
        topo_comp_uiState = {
          ...state.uiState,
          ...action.ui_updates,
        };

        topo_comp_state = {
          ...state,
          ...action.state_updates,
        };
      }

      return {
        ...topo_comp_state,
        uiState: topo_comp_uiState,
      };

    case inputsConstants.UNDO_FEATURES:
      let tempUndoFeatures;

      let new_undo_state = {
        ...state,
      };

      new_undo_state.site_features = {};
      let cur_index = Math.max(action.undoState.present - 1, 0);
      tempUndoFeatures = JSON.parse(JSON.stringify(action.undoState.past[cur_index].features));

      Object.values(tempUndoFeatures).map((feature) => {
        let id = create_UUID();

        feature.properties["index"] = id;
        new_undo_state.site_features[id] = feature;
      });

      return {
        ...new_undo_state,
      };

    case inputsConstants.REDO_FEATURES:
      let tempRedoFeatures;

      let new_redo_state = {
        ...state,
      };

      if (action.redoState.future.length) {
        new_redo_state.site_features = {};
        tempRedoFeatures = JSON.parse(JSON.stringify(action.redoState.past[action.redoState.future[0]].features));
        Object.values(tempRedoFeatures).map((feature) => {
          let id = create_UUID();

          feature.properties["index"] = id;
          new_redo_state.site_features[id] = feature;
        });
      }

      return {
        ...new_redo_state,
      };

    case inputsConstants.TOGGLE_SF_MODAL:
      let new_sf_state = {
        ...state.uiState,
        [action.key]: action.value,
        download_type: action.download_type,
      };

      return { ...state, uiState: new_sf_state };

    case inputsConstants.SF_CAPTURE_REQUEST:
      let sf_request_ui_state = {
        ...state.uiState,
        loading: true,
      };

      return { ...state, uiState: sf_request_ui_state };

    case inputsConstants.SF_CAPTURE_SUCCESS:
      let sf_success_state = {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
          show_sf_modal: false,
          download_lock: false,
        },
      };

      return sf_success_state;

    case inputsConstants.SF_CAPTURE_RESET:
      let sf_reset_state = {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
          show_sf_modal: false,
          download_lock: true,
        },
      };

      return sf_reset_state;

    // case inputsConstants.GET_EDITABLE_LAYOUT_REQUEST:
    //   return state;
    // case inputsConstants.GET_EDITABLE_LAYOUT_SUCCESS:
    //   let temp_editable_layouts = { ...JSON.parse(JSON.stringify(state.edited_layouts || {})) };
    //   temp_editable_layouts[action.value.id] = action.value;

    //   return { ...state, edited_layouts: temp_edited_layouts };
    // case inputsConstants.GET_EDITABLE_LAYOUT_FAILURE:
    //   return state;

    case inputsConstants.EDIT_LAYOUT:
      // let temp_edited_layouts = { ...JSON.parse(JSON.stringify(state.edited_layouts || {})) };
      let temp_edited_layouts = _.cloneDeep(state.edited_layouts || {});
      let temp_selected_rack_layout_id = state.selected_rack_layout_id;

      if (action.key == "add_edited_layout") {
        //if the layout exists already delete it, and replace it with the fresh layout
        if (temp_edited_layouts[action.layoutId]) {
          delete temp_edited_layouts[action.layoutId];
          temp_edited_layouts[action.layoutId] = action.value;
          temp_selected_rack_layout_id = undefined;
        } else {
          temp_edited_layouts[action.layoutId] = action.value;
        }
      }

      if (action.key == "toggle_rack_vis") {
        temp_edited_layouts[action.layoutId].inverter_groups[0].combiner_groups[0]["rack_groups"][action.value].enabled =
          !temp_edited_layouts[action.layoutId].inverter_groups[0].combiner_groups[0]["rack_groups"][action.value].enabled;
        temp_edited_layouts[action.layoutId].edited = true;
      }

      if (action.key == "change_rack_size" || action.key == "change_alignment") {
        temp_edited_layouts[action.layoutId].inverter_groups[0].combiner_groups[0]["rack_groups"][action.value.index] = action.value;
        temp_edited_layouts[action.layoutId].edited = true;
      }

      if (action.key == "undo_rack_edits") {
        // restore geojson
        temp_edited_layouts[action.layoutId].inverter_groups[0].combiner_groups[0]["rack_groups"][action.value].geoJson =
          temp_edited_layouts[action.layoutId].inverter_groups[0].combiner_groups[0]["rack_groups"][action.value].orgGeoJson;
        //restore enabled
        temp_edited_layouts[action.layoutId].inverter_groups[0].combiner_groups[0]["rack_groups"][action.value].enabled = true;
      }
      if (action.key == "undo_all_edits") {
        delete temp_edited_layouts[action.layoutId];
        temp_selected_rack_layout_id = undefined;
      }

      return { ...state, edited_layouts: temp_edited_layouts, selected_rack_layout_id: temp_selected_rack_layout_id };

    case inputsConstants.UPDATE_SAVED_RESULTS:
      let temp_saved_results = { ...state.saved_results };

      switch (action.key) {
        case "add_saved_result":
          temp_saved_results[action.value.result.id] = action.value.result;
          break;
        case "remove_saved_result":
          temp_saved_results[action.value.result.id].saved_result = false;
          break;
        case "delete_saved_result":
          delete temp_saved_results[action.value];
          break;
        default:
          break;
      }
      // debugger;

      return { ...state, saved_results: temp_saved_results, resave_project: true };

    default:
      return { ...state };
  }
}
