import React, { useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../DrawToolProvider";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, siftActions } from "../..";
import { Tooltip } from "antd";

const mapMenuItems = [
  {
    label: "Draw",
    action: "draw_polygon",
    disabled: false,
  },
  {
    label: "Toggle Dims On",
    action: "toggle_dims",
    disabled: false,
  },
  {
    label: "Toggle Azi On",
    action: "toggle_azi",
    disabled: false,
  },
  {
    label: "Export Map",
    action: null,
    disabled: false,
    submenu: [
      {
        label: "Export to KMZ",
        action: "export_kmz",
        disabled: false,
      },
      {
        label: "Export to CAD",
        action: "export_cad",
        disabled: false,
      },
    ],
  },
];

const MapboxContext = ({ menuItems = mapMenuItems }) => {
  const dispatch = useDispatch();
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();

  const site_features = useSelector((state) => state.inputs.site_features);
  const site_features_ref = useRef();

  const show_azi = useSelector((state) => state.inputs.uiState.show_azi);
  const show_dims = useSelector((state) => state.inputs.uiState.show_dims);

  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);

  useEffect(() => {
    site_features_ref.current = site_features;
  }, [site_features]);

  const contextMenuRef = useRef(null);
  const contextMenuWrapperRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    const contextMenu = document.createElement("section");
    contextMenu.className = "context-menu";

    document.body.appendChild(contextMenu);
    contextMenuRef.current = contextMenu;

    const handleClick = (e) => {
      const target = e.target;
      if (target && target.dataset.action) {
        handleMenuAction(target.dataset, draw);
        contextMenu.style.display = "none";
      }
    };

    const handleOutsideClick = (e) => {
      if (contextMenuWrapperRef.current && !contextMenuWrapperRef.current.contains(e.target)) {
        contextMenu.style.display = "none";
      }
    };

    contextMenu.addEventListener("click", handleClick);
    document.addEventListener("click", handleOutsideClick);

    map.on("contextmenu", (e) => {
      e.preventDefault();
      const { clientX: x, clientY: y } = e.originalEvent;
      const point = [e.point.x, e.point.y];

      const features = map.queryRenderedFeatures(point);
      let clicked_features_arr = [];

      if (features.length > 0) {
        features.forEach((feature) =>
          clicked_features_arr.push({
            label: `Polygon ${feature.properties.id.slice(0, 2)}`,
            highLight: true,
            action: "select_polygon",
            id: feature.properties.id,
            submenu: [
              { label: "Offset Polygon", action: "offset_poly", id: feature.properties.id },
              { label: "Set as Boundary", action: "set_as_boundary", id: feature.properties.id },
              { label: "Set as Exclusion", action: "set_as_exclusion", id: feature.properties.id },
              { label: "Set as Inactive", action: "set_as_inactive", id: feature.properties.id },
              { label: "Delete", action: "delete_poly", id: feature.properties.id },
            ],
          })
        );
      }

      if (show_dims) {
        menuItems[1].label = "Toggle Dims Off";
      } else if (!show_dims) {
        menuItems[1].label = "Toggle Dims On";
      }

      if (show_azi) {
        menuItems[2].label = "Toggle Azi Off";
      } else if (!show_azi) {
        menuItems[2].label = "Toggle Azi On";
      }

      if (Object.values(layout).length > 0) {
        menuItems[3].submenu[1].disabled = false;
      } else {
        menuItems[3].submenu[1].disabled = true;
      }

      if (Object.values(site_features).length > 0) {
        menuItems[3].submenu[0].disabled = false;
      } else {
        menuItems[3].submenu[0].disabled = true;
      }

      // console.log("menuItems", menuItems);

      contextMenu.innerHTML = renderMenuItems([...menuItems, ...clicked_features_arr]);

      // Attach hover events to menu items after rendering
      attachHoverEventsToMenuItems(contextMenu, map);

      contextMenu.style.left = `${x}px`;
      contextMenu.style.top = `${y}px`;
      contextMenu.style.display = "block";
    });

    return () => {
      document.body.removeChild(contextMenu);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [map, menuItems, draw, show_azi, show_dims, layout, site_features]);

  const renderMenuItems = (items) => {
    return `
      <ul>
        ${items
          .map((item) => {
            if ((item.action === "export_cad" || item.action === "export_kmz") && item.disabled) {
              return `
              <Tooltip title="${item.action === "export_cad" ? "At least 1 layout required for export" : "At least 1 site feature required for export"}">
          <li data-action="${item.action || ""}" data-id="${item.id || ""}" class="${"disabled-li"}">
            ${item.label}
          </li>
          </Tooltip>
        `;
            } else {
              return `
          <li data-action="${item.action || ""}" data-id="${item.id || ""}" class="${item.submenu ? "has-submenu" : ""}">
            ${item.label}
            ${item.submenu ? `${renderMenuItems(item.submenu)}` : ""}
          </li>
        `;
            }
          })
          .join("")}
      </ul>
    `;
  };
  const attachHoverEventsToMenuItems = (contextMenu, map) => {
    const menuItems = contextMenu.querySelectorAll("li[data-id]");

    menuItems.forEach((menuItem) => {
      const id = menuItem.getAttribute("data-id");

      if (id) {
        menuItem.addEventListener("mouseenter", () => handleMenuItemHover(id, map));
        menuItem.addEventListener("mouseleave", () => handleMenuItemUnhover(id, map));
      }
    });
  };

  const handleMenuItemHover = (id, map) => {
    if (map && id) {
      dispatch(inputsActions.update_input("cur_hover_id", id));
    }
  };

  const handleMenuItemUnhover = (id, map) => {
    if (map && id) {
      dispatch(inputsActions.update_input("cur_hover_id", undefined));
    }
  };

  const updateIdentity = (id, identity) => {
    let feature = site_features_ref.current[id];
    if (feature) {
      feature.properties.identity = identity;
      feature.properties.active = identity === 0 ? !feature.properties.active : true;
      dispatch(inputsActions.update_site_input("edit_site_feature", feature));
    }
  };

  const handleToggleDims = () => {
    let checked = !show_dims;
    dispatch(inputsActions.update_ui_state("show_dims", checked));
    // dispatch(inputsActions.update_ui_state("show_azi", false));
  };

  const handleToggleAzi = () => {
    let checked = !show_azi;
    dispatch(inputsActions.update_ui_state("show_azi", checked));
    // dispatch(inputsActions.update_ui_state("show_dims", false));
  };

  const deleteFeature = (id) => {
    if (!id) return;
    dispatch(inputsActions.update_site_input("remove_site_feature", id));
    draw.delete(id);
  };

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  const exportMap = (fileType) => {
    dispatch(siftActions.exportMap(fileType));
  };

  const handleMenuAction = (dataset, draw = undefined) => {
    console.log("contest data", dataset);
    switch (dataset.action) {
      case "draw_polygon":
        map.fire("draw.polygon");
        // draw.changeMode(dataset.action);
        break;
      case "toggle_dims":
        handleToggleDims();
        break;
      case "toggle_azi":
        handleToggleAzi();
        break;
      case "offset_poly":
        map.fire("toggleOffset", { id: dataset.id, selected_from: "context" });
        break;
      case "export_kmz":
        exportMap("export_kml");
        break;
      case "export_cad":
        exportMap("export_dxf");
        break;
      case "set_as_boundary":
        updateIdentity(dataset.id, 1);
        break;
      case "set_as_exclusion":
        updateIdentity(dataset.id, 2);
        break;
      case "set_as_inactive":
        updateIdentity(dataset.id, 0);
        break;
      case "delete_poly":
        deleteFeature(dataset.id);
        break;
      case "select_polygon":
        selectFeatureOnMap(dataset.id);
        break;
      default:
        break;
    }
  };

  return <div ref={contextMenuWrapperRef} />;
};

export { MapboxContext };
